import React, { useEffect, useState } from "react";

const AdressForm = ({ modalType, modal, onClose, isOpen, addressList, setIsOtpVisible, updateAddreshUuid, action }) => {

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        mobileNumber: "",
        addressLine1: "",
        countryTitle: 'India', // Default country
        stateTitle: "",
        landMark: "",
        cityTitle: "",
        pinCode: "",
    });

    useEffect(() => {
        setFormData({
            firstName: updateAddreshUuid?.firstName || "",
            lastName: updateAddreshUuid?.lastName || "",
            mobileNumber: updateAddreshUuid?.mobileNumber || "",
            addressLine1: updateAddreshUuid?.addressLine1 || "",
            countryTitle: updateAddreshUuid?.countryTitle || 'India',
            stateTitle: updateAddreshUuid?.stateTitle || "",
            landMark: updateAddreshUuid?.landMark || "",
            cityTitle: updateAddreshUuid?.cityTitle || "",
            pinCode: updateAddreshUuid?.pinCode || "",
        });
    }, [updateAddreshUuid]);

    const resetFunction = () => {
        setFormData({
            firstName: "",
            lastName: "",
            mobileNumber: "",
            addressLine1: "",
            countryTitle: 'India', // Default country
            stateTitle: "",
            landMark: "",
            cityTitle: "",
            pinCode: "",
        });
    };

    // console.log(updateAddreshUuid, "uuid", formData)
    const [error, setError] = useState("");

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const key = localStorage.getItem('userId');

    const states = ["Rajasthan", "Maharashtra", "Karnataka", "Gujarat", "Delhi", "Uttar Pradesh"]; // List of states

    if (!isOpen) return null;

    const validateForm = () => {
        const isValid = Object.values(formData).every((value) => value?.trim() !== "");
        if (!isValid) {
            setError("All fields are required.");
            return false;
        }
        setError("");
        return true;
    };

    const addAddress = async () => {
        if (!validateForm()) return;

        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/save`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${key}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                onClose();
                addressList("All");
                setIsOtpVisible(true);
            }
        } catch (e) {
            console.log(e, 'error in add Address');
        }
    };

    const UpdateAddress = async () => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/update/${updateAddreshUuid?.uuid}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${key}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                onClose();
                addressList("All");
                setIsOtpVisible(true);
            }
        } catch (e) {
            console.log(e, "error in update Address");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev, [name]: value,
        }));
    };

    const isFieldEmpty = (fieldName) => {
        return formData[fieldName]?.trim() === "";
    };

    return (
        <>
            <div className={`overlay ${isOpen ? "visible" : ""}`} onClick={onClose}></div>
            <div className="address-modal">
                <div className=" address-detail-form-b">
                    <div className="address-modal-header">
                        <h3>Add Address</h3>
                        <span className="close-modal" onClick={() => { onClose(); resetFunction() }}>✕</span>
                    </div>
                    <div className="address-box">
                        <div className="form-row">
                            <div className="form-group">
                                <label>Country/Region</label>
                                <input
                                    type="text"
                                    name="countryTitle"
                                    value={formData.countryTitle}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>Phone <span className={isFieldEmpty("mobileNumber") ? "required" : ""}>*</span></label>
                                <input
                                    className="in"
                                    type="number"
                                    placeholder="Enter Number"
                                    name="mobileNumber"
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>First Name <span className={isFieldEmpty("firstName") ? "required" : ""}>*</span></label>
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name <span className={isFieldEmpty("lastName") ? "required" : ""}>*</span></label>
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Apartment, Suite, etc. <span className={isFieldEmpty("addressLine1") ? "required" : ""}>*</span></label>
                            <input
                                type="text"
                                placeholder="Apartment, Suite, etc."
                                name="addressLine1"
                                value={formData.addressLine1}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Land Mark  <span className={isFieldEmpty("landMark") ? "required" : ""}>*</span></label>
                            <input
                                type="text"
                                placeholder="Land Mark"
                                name="landMark"
                                value={formData.landMark}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>City <span className={isFieldEmpty("cityTitle") ? "required" : ""}>*</span></label>
                                <input
                                    type="text"
                                    placeholder="City"
                                    name="cityTitle"
                                    value={formData.cityTitle}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>State <span className={isFieldEmpty("stateTitle") ? "required" : ""}>*</span></label>
                                <select
                                    name="stateTitle"
                                    value={formData.stateTitle}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Select State</option>
                                    {states.map((state) => (
                                        <option key={state} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Pin Code <span className={isFieldEmpty("pinCode") ? "required" : ""}>*</span></label>
                                <input
                                    className="pin"
                                    type="number"
                                    placeholder="Pin Code"
                                    name="pinCode"
                                    value={formData.pinCode}
                                    onChange={handleChange}
                                />

                            </div>

                        </div>

                        {error && <p className="error-message">{error}</p>}
                        <div className="form-actions">
                            <button className="cancel-button" onClick={onClose}>Cancel</button>
                            <button className="save-button" onClick={() => {
                                if (action === "Edit") {
                                    UpdateAddress()
                                } else {
                                    addAddress()
                                }
                                resetFunction();
                            }}>{
                                    action === 'Edit' ? "Update" : "Save"
                                }</button>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
};

export { AdressForm };

