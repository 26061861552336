const BASE_URL = process.env.REACT_APP_PP_URL;
const key = localStorage.getItem('userId');
const deviceId = localStorage.getItem('device_id');


export const placeOrder = async (cartUuid) => {
    try {
        const res = await fetch(`${BASE_URL}/customer/v1/order/placeOrder/${cartUuid}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${key}`
            }
        });
        const getRes = await res.json();

        if (getRes.errorCode === 0) {
            // CartDataa();
            console.log(getRes, "aaaaaaaaa")
            return getRes
        }

    } catch (e) {
        console.log(e, 'error in ordes apic')
    }
};

export const Getdata = async () => {
    try {
        const response = await fetch(`${BASE_URL}/guest-customer/v1/homePage/homepage-data`, {
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const getRes = await response.json();
        if (getRes.errorCode === 0) {
            return getRes
        }

    } catch (error) {
        console.error("Fetch error:", error);
        // setError('An error occurred during login.');
    }
};


export const AddToCart = async (uuid, itemUuid, couponCodeUuid) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };

        // Add Authorization header only if key exists
        if (key) {
            headers['Authorization'] = `Bearer ${key}`;
        }

        const response = await fetch(`${BASE_URL}/guest-customer/v1/cart/updateCart/${deviceId}/${uuid}`, {
            method: "PUT",
            headers,
            body: JSON.stringify({
                // Send book or product data as needed
                // addressUuid: '',
                itemUuid: itemUuid,
                couponCodeUuid: couponCodeUuid,
            }),
        });

        if (!response.errorCode === 0) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        if (result.errorCode === 0) {
            // CartDataa();
            return result
        };
        console.log(result, 'Add');
    } catch (error) {
        console.error("Fetch error in cart add:", error);

    }
};

export const itemQuantity = async (uuid, itemUuid, itemQuantity) => {
    try {

        const headers = {
            'Content-Type': 'application/json',
        };

        // Add Authorization header only if key exists
        if (key) {
            headers['Authorization'] = `Bearer ${key}`;
        }
        const response = await fetch(`${BASE_URL}/guest-customer/v1/cart/updateItemQuantity/${uuid}/${itemUuid}/${itemQuantity}`, {
            method: "PUT",
            headers,
        });
        if (!response.errorCode === 0) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        if (result.errorCode === 0) {
            // CartDataa();
            return result
        }
        console.log(result, 'Quantity');
    } catch (error) {
        console.error("Fetch error in cart add:", error);
    }

};

export const removeCartItem = async (uuid, itemUuid) => {
    console.log(itemUuid, 'itemUuidddddd')
    try {
        const headers = {
            'Content-Type': 'application/json',
        };

        // Add Authorization header only if key exists
        if (key) {
            headers['Authorization'] = `Bearer ${key}`;
        }
        const res = await fetch(`${BASE_URL}/guest-customer/v1/cart/removeItemFromCart/${uuid}/${itemUuid}`, {
            method: "PUT",
            headers,
        });
        const getRes = await res.json();
        if (getRes.errorCode === 0) {
            return getRes

        };
    } catch (e) {
        console.log(e, 'error in cart remove api')
    }
};

export const removeAllCart = async (uuid) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };

        // Add Authorization header only if key exists
        if (key) {
            headers['Authorization'] = `Bearer ${key}`;
        }
        const res = await fetch(`${BASE_URL}/guest-customer/v1/cart/clearCart/${uuid}`, {
            method: "PUT",
            headers,
        });
        const getRes = await res.json();
        if (getRes.errorCode === 0) {
            return getRes
        }
    } catch (e) {
        console.log(e, 'error in remove all api')
    }
};

export const CartDataa = async () => {
    try {

        const headers = {
            'Content-Type': 'application/json',
        };

        // Add Authorization header only if key exists
        if (key) {
            headers['Authorization'] = `Bearer ${key}`;
        }
        const response = await fetch(`${BASE_URL}/guest-customer/v1/cart/getCartDetail/${deviceId}`, {
            headers,
        });
        const result = await response.json();
        if (result.errorCode === 0) {
            return result;
        }

    } catch (error) {
        console.error("Fetch error in getCartDetail:", error);
    }
};


export const cartCount = async (cartUuid) => {

    try {
        const headers = {
            'Content-Type': 'application/json',
        };

        // Add Authorization header only if key exists
        if (key) {
            headers['Authorization'] = `Bearer ${key}`;
        }
        const response = await fetch(`${BASE_URL}/guest-customer/v1/cart/getCartItemCount/${cartUuid}`, {
            headers,
        });
        const result = await response.json();
        if (result.errorCode === 0) {
            console.log(result, 'runrurn run run runr urn run');
            return result
        }

    } catch (error) {
        console.error("Fetch error in getCartDetail:", error);
    }
};