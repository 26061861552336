import React, { useEffect, useRef, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useRole } from "../../../../../Routing/Routing";
import PaginationComponent from "../../pagination";
import { InputPopup } from "../../../CommonComponents/InputPopup";
import { Link } from "react-router-dom";
import notificationSound from "../../../../../Assets/Image/mixkit-correct-answer-tone-2870.wav";
import OrderPrint from "./OrdersPrint";
import Select from "react-select";

function Order() {

    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);
    console.log(userPermissions, 'useerPermision');
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);
    const [type, setType] = useState('ALL');

    const item = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/order/getOrderList`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
                setType('ALL');
            }
        } catch (e) {
            console.log(e, 'error in order list api')
        }
    };

    useEffect(() => {
        item();
    }, []);


    const [showPopup, setShowPop] = useState(false);
    const [message, setMessage] = useState();
    const [getId, setGetId] = useState();
    const [trackId, setTrackId] = useState();

    console.log(trackId, 'tracking id');
    // this is update order list 
    const orderStatus = async (orderId, orderStatus) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/order/updateOrderStatus/${orderId}/${orderStatus}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {

            }
        } catch (e) {
            console.log(e, 'error in update order list statues');
        }
    };

    // TRACKING ID
    const trackingId = async (orderId) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/order/updateOrderTrackingId/${orderId}/${trackId}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setShowPop(false);
                item();
                orderStatus(orderId, 'DISPATCHED');
            };
        } catch (e) {
            console.log(e, 'error in tracking id api');
        }
    };

    const handleStatus = (id) => {
        setGetId(id);
        setShowPop(true);
        setMessage('Enter Tracking Id');
    };

    const handleOk = () => {
        if (getId && trackId) {
            trackingId(getId);
        }
    };

    const handleCancle = () => {
        setShowPop(false);
        setGetId('');
        setTrackId('');
    };

    const [searchInput, setSearchInput] = useState();

    const handleSearch = (e) => {
        const event = e.target.value;
        setSearchInput(event);
        const res = list.filter((itm) => itm.customerDetail?.userCustomerFirstName?.toLowerCase().includes(event.toLowerCase()) || itm.customerDetail?.userCustomerLastName?.toLowerCase().includes(event.toLowerCase()));
        setFilterList(res);
        setCurrentPage(1);
    };

    // PAID
    const filPaid = () => {
        const res = list.filter((itm) => itm.orderStatus === 'PENDING');
        setFilterList(res);
        setSearchInput('');
        setType('PAID');
    };

    const filUnPaid = () => {
        const res = list.filter((itm) => itm.orderStatus !== 'PENDING');
        setFilterList(res);
        setSearchInput('');
        setType('UNPAID');
    };

    // PAGINATION LOGIC
    const [currentPage, setCurrentPage] = useState(1);

    const resultPerPage = [
        { value: 10, label: "10 items per page" },
        { value: 20, label: "20 items per page" },
        { value: 50, label: "50 items per page" },
        { value: 100, label: "100 items per page" },
        { value: 200, label: "200 items per page" },
    ];
    const [lengthOfPage, setLengthOfPage] = useState(() => {
        return JSON.parse(sessionStorage.getItem("ordersPerPage")) || resultPerPage[0]
    });

    useEffect(() => {
        sessionStorage.setItem("ordersPerPage", JSON.stringify(lengthOfPage));
    }, [lengthOfPage]);


    // Pagination logic
    const itemsPerPage = lengthOfPage?.value; // Show 10 items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // FILTER LIST
    const [sortShow, setSortShow] = useState(false);

    // HANDLE OUTSIDE CLICK
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setSortShow(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    // SORT METHOD
    const sort = [
        { id: 1, title: "A-Z" },
        { id: 2, title: "Z-A" },
        { id: 5, title: "Paid" },
        { id: 6, title: 'Unpaid' },
        { id: 3, title: "High to low price" },
        { id: 4, title: "low to high price" },
    ];

    const [sortBy, setSortBy] = useState();

    const handleSort = async (by) => {
        setSortBy(by);
        if (by === 'A-Z') {
            const sortAz = [...list].sort((a, b) => a.customerDetail?.userCustomerFirstName?.localeCompare(b.title));
            setFilterList(sortAz);
            setCurrentPage(1);
            setSortShow(false);
            setSearchInput('');
            setType('ALL');
        } else if (by === "Z-A") {
            const sortAz = [...list].sort((a, b) => b.customerDetail?.userCustomerFirstName?.localeCompare(a.title));
            setFilterList(sortAz);
            setCurrentPage(1);
            setSortShow(false);
            setSearchInput('');
            setType('ALL');
        } else if (by === 'Paid') {
            const sortAz = [...list].filter((itm) => itm.orderStatus === 'PANDING');
            setFilterList(sortAz);
            setCurrentPage(1);
            setSortShow(false);
            setSearchInput('');
            setType('PAID');
        } else if (by === 'Unpaid') {
            const sortAz = [...list].filter((itm) => itm.orderStatus !== 'PANDING');
            setFilterList(sortAz);
            setCurrentPage(1);
            setSortShow(false);
            setSearchInput('');
            setType("UNPAID");
        } else if (by === 'High to low price') {
            const sortAz = [...list].sort((a, b) => b.orderTotal - a.orderTotal);
            setFilterList(sortAz);
            setCurrentPage(1);
            setSortShow(false);
            setSearchInput('');
        } else if (by === 'low to high price') {
            const sortAz = [...list].sort((a, b) => a.orderTotal - b.orderTotal);
            setFilterList(sortAz);
            setCurrentPage(1);
            setSortShow(false);
            setSearchInput('');

        }
    };

    const audio = new Audio(notificationSound);
    const [noti, setNoti] = useState(localStorage.getItem("audioEnabled"));

    const enableNotification = () => {
        localStorage.setItem("audioEnabled", "true");
        audio.play();
        setNoti(true);
    };

    const disAbleNoti = () => {
        localStorage.removeItem("audioEnabled");
        setNoti(false);
    };

    const [showPrintPreview, setShowPrintPreview] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    console.log(selectedItems, 'selected items');

    const [isSelectAll, setIsSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (isSelectAll) {
            setSelectedItems([]); // Deselect all items
        } else {
            setSelectedItems(filterList.map((order) => order.orderId)); // Select all items
        }
        setIsSelectAll(!isSelectAll);
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelected) =>
            prevSelected.includes(itemId)
                ? prevSelected.filter((id) => id !== itemId) // Uncheck if already selected
                : [...prevSelected, itemId] // Add to selected items
        );
    };

    // Ensure "select all" checkbox reflects the state of individual checkboxes
    useEffect(() => {
        setIsSelectAll(selectedItems.length === filterList.length && filterList.length > 0);
    }, [selectedItems, filterList]);


    return (
        <>
            <div className="category">
                <div className="row">
                    <div className="category" style={{ display: "block", }}>
                        <div className="category-nav" >
                            <div className="category-text">
                                <h4>Order List</h4>
                            </div>
                            <div className="category-btn">
                                <Select
                                    defaultValue={resultPerPage[0]}
                                    closeMenuOnSelect={true}
                                    name="colors"
                                    options={resultPerPage}
                                    value={lengthOfPage}
                                    onChange={(e) => { setLengthOfPage(e); setCurrentPage(1) }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                                {noti ? <button onClick={disAbleNoti}>Disable Notification</button> : <button onClick={enableNotification} >Enable Notification</button>}
                            </div>
                        </div>
                        <hr />
                        <div className="category-filter">
                            <div className="filterr">
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'ALL' ? "white" : "black", color: type === 'ALL' ? "black" : "white" }} onClick={() => item()}>All</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'PAID' ? "white" : "black", color: type === 'PAID' ? "black" : "white" }} onClick={filPaid}>Paid</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'UNPAID' ? "white" : "black", color: type === 'UNPAID' ? "black" : "white" }} onClick={filUnPaid}>Unpaid</button>
                                </div>
                                <div className="btn-1">
                                    <button onClick={() => setSortShow(!sortShow)}><FaFilter /></button>
                                    {
                                        sortShow && <div className="sort-list" style={{ width: "338%" }} ref={ref}>
                                            {
                                                sort.map((item) => {
                                                    return (
                                                        <>
                                                            <div className="list-item" id="sort" onClick={() => handleSort(item.title)}>
                                                                <div className="outer-c" >
                                                                    <div className="inner-c" style={{ backgroundColor: sortBy === item.title ? "black" : "" }}></div>
                                                                </div>
                                                                <label for={item.id}>{item.title}</label>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                            <div className="search">
                                <h4>Search:</h4>
                                <input type="text" placeholder="Search all Orders" value={searchInput} className="input" onChange={(e) => handleSearch(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="order-list-container sticky-container-order ">
                        <div className="order-list-header ">
                            <div className="tittle"><input type="checkbox"
                                checked={isSelectAll}
                                onChange={handleSelectAll}
                            /></div>
                            {selectedItems.length > 0 ?
                                <div className="printer-btn-main">
                                    <button onClick={() => setShowPrintPreview(true)}>Print</button>
                                </div>
                                :
                                <>
                                    <div className="tittle">Order Id</div>
                                    {/* <div>Date</div> */}
                                    <div className="tittle">Customer</div>
                                    {/* <div className="tittle">Channel</div> */}
                                    <div className="tittle">Total</div>
                                    <div className="tittle">Payment Status</div>
                                    {/* <div>Fulfillment Status</div> */}
                                    <div className="tittle">Items</div>
                                    <div className="tittle">Delivery Status</div>
                                    <div className="tittle">Tracking Id</div>
                                    <div className="tittle">Action</div>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="order-list-container">

                    {
                        filterList?.slice(indexOfFirstItem, indexOfLastItem).map((order, index) => (
                            <div key={index} className="order-list-row">
                                <div className="tittle">
                                    <input type="checkbox"
                                        checked={selectedItems.includes(order.orderId)}
                                        onChange={() => handleCheckboxChange(order.orderId)}
                                    />
                                </div>
                                <Link to='/admin/orderDetail' state={order.orderId}>
                                    <div className="tittle">{order.orderId}</div>
                                </Link>
                                {/* <div>{order.date}</div> */}
                                <div className="tittle">
                                    {order.customerDetail?.userCustomerFirstName} {order.customerDetail?.userCustomerLastName}
                                    <p>({order.customerDetail?.userCustomerIsdCode}{order.customerDetail?.userCustomerMobile})</p>
                                </div>
                                {/* <div className="tittle">{order.paymentType}</div> */}
                                <div className="tittle">{order.orderTotal}</div>
                                <div className="tittle">
                                    <span className={`status ${order.paymentStatus !== 'PENDING' ? 'paid' : 'unpaid'}`}>
                                        {order.paymentStatus}
                                    </span>
                                </div>
                                {/* <div>
                    <span className={`status ${order.fulfillmentStatus === 'Fulfilled' ? 'fulfilled' : 'unfulfilled'}`}>
                        {order.fulfillmentStatus}
                    </span>
                </div> */}
                                <div className="tittle">{order.itemDetailList?.length}</div>
                                <div className="tittle">{order.orderStatus}</div>
                                <div className="tittle">{order.orderTrackingId ? order.orderTrackingId : <p style={{ cursor: "pointer" }}>NaN</p>} </div>

                                <div className="btn" >
                                    {
                                        order.orderTrackingId ?
                                            <span title="Tracking Id Assigned" className="status paid" style={{ cursor: "pointer" }} >Assigned</span>
                                            :
                                            (permission('admin/v1/order/updateOrderTrackingId') && (<span onClick={() => handleStatus(order.orderId)} className="status unpaid" title="Enter Tracking Id" style={{ cursor: "pointer" }} >Assign Track Id</span>))
                                    }
                                </div>
                            </div>
                        ))}
                </div>

                <div className="pagination">
                    <span>Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filterList?.length)} of {filterList?.length} entries</span>
                    <span className="pagination-buttons">
                        <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} totalItem={filterList?.length} lengthOfPage={lengthOfPage} />
                    </span>
                </div>
            </div>



            <InputPopup
                isOpen={showPopup}
                onOk={handleOk}
                onCancel={handleCancle}
                ok='ok'
                cancel='cancel'
                message={message}
                inValue={trackId}
                inChange={setTrackId}
            />

            <OrderPrint
                orders={selectedItems}
                isOpen={showPrintPreview}
                onClose={() => setShowPrintPreview(false)}
            />

        </>
    )
}

export { Order };
