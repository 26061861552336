import React from "react";
import { AccountMerge } from "../../AccountMerge";
import { OrderDetailChild } from "../OrderDetailChild/OrderDetailChild";

function OrderDetailParent(){
    return(
        <>
        <AccountMerge getComponent={<OrderDetailChild/>}/>
        </>
    )
}
export{OrderDetailParent}