import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { IoAdd } from "react-icons/io5";
import { RiSubtractLine } from "react-icons/ri";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import "slick-carousel/slick/slick.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";




const FeaturedBrand = ({ featured, cart, addToCart, itemQuantity, removeCartItem }) => {

    const data = useSelector((state) => state.header.data);
    const [quantities, setQuantities] = useState([]);
    const [showAddButtons, setShowAddButtons] = useState([]);
    const [currentSlides, setCurrentSlides] = useState([]);
    // const [error, setError] = useState('');
    // const token = sessionStorage.getItem('tokenKey');
    // const deviceId = localStorage.getItem("device_id");
    const [slidesToShow, setSlidesToShow] = useState(5); // Default to 5 items

    console.log(cart, 'cartt');

    useEffect(() => {
        const itemsCount = featured.responsePacket?.displayCategoryList?.flatMap(item => item.displayCategoryItemList)?.length || 0;
        setQuantities(new Array(itemsCount).fill(1));
        setShowAddButtons(new Array(itemsCount).fill(false));
        setCurrentSlides(new Array(featured.responsePacket?.displayCategoryList?.length || 0).fill(0));
    }, [featured]);


    useEffect(() => {
        // Update slidesToShow based on screen width
        const updateSlidesToShow = () => {
            if (window.innerWidth <= 600) {
                setSlidesToShow(2);
            } else if (window.innerWidth <= 750) {
                setSlidesToShow(3);
            } else if (window.innerWidth <= 950) {
                setSlidesToShow(3);
            } else if (window.innerWidth <= 1280) {
                setSlidesToShow(4);
            } else {
                setSlidesToShow(5);
            }
        };

        updateSlidesToShow(); // Initial call to set the correct slidesToShow
        window.addEventListener("resize", updateSlidesToShow);

        return () => window.removeEventListener("resize", updateSlidesToShow);
    }, []);


    const NextArrow = ({ className, style, onClick, categoryIndex }) => {
        const itemsInCategory = featured.responsePacket.displayCategoryList[categoryIndex]?.displayCategoryItemList?.length || 0;
        const maxSlides = Math.ceil(itemsInCategory / slidesToShow) - 1;
        console.log(itemsInCategory, "itemin category", maxSlides)
        return (
            currentSlides[categoryIndex] < itemsInCategory - slidesToShow && (
                <div className={`${className} slider-arrow`} style={{ ...style, display: "block", zIndex: 1 }} onClick={onClick}>
                    <SlArrowRight />
                </div>
            )
        );
    };

    const PrevArrow = ({ className, style, onClick, categoryIndex }) => {
        return (
            currentSlides[categoryIndex] > 0 && (
                <div className={`${className} slider-arrow`} style={{ ...style, display: "block", zIndex: 1 }} onClick={onClick}>
                    <SlArrowLeft />
                </div>
            )
        );
    };

    const handleSlideChange = (index, categoryIndex) => {
        const newCurrentSlides = [...currentSlides];
        newCurrentSlides[categoryIndex] = index;
        setCurrentSlides(newCurrentSlides);
    };

    // popo up 
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const key = localStorage.getItem('userId')
    const [open, setOpen] = useState(false);
    const [mobile, setMobile] = useState();
    const [itemId, setItemId] = useState();

    const noti = async () => {
        try {
            const res = await fetch(`${BASE_URL}/guest-customer/v1/item-notify/save-notify`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${key}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    itemUuid: itemId,
                    customerIsdCode: 91,
                    customerMobile: mobile
                })
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setOpen2(true);
            };

        } catch (e) {
            console.log(e, 'error in notification');
        }
    };

    const handleNoti = (uuid) => {
        setOpen(true);
        setItemId(uuid);
    };

    const handleConfirm = () => {
        if (itemId && mobile.length === 10) {
            noti();
            setOpen(false);
            setItemId('');
            setOpen2(true);
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setItemId('');
    };

    const open2Ref = useRef(null);
    const [open2, setOpen2] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!open2Ref?.current?.contains(event.target)) {
                setOpen2(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [open2Ref]);

    return (
        <>
            {
                featured.responsePacket?.displayCategoryList?.map((itm, categoryIndex) => {
                    const settings = {
                        infinite: false,
                        speed: 300,
                        slidesToShow: slidesToShow,
                        slidesToScroll: window.innerWidth <= 600 ? slidesToShow : 1, // Smooth scroll under 600px
                        nextArrow: <NextArrow categoryIndex={categoryIndex} />,
                        prevArrow: <PrevArrow categoryIndex={categoryIndex} />,
                        afterChange: (index) => handleSlideChange(index, categoryIndex),
                    };

                    return (
                        <div className="featuredbrand" key={categoryIndex}>
                            <div className="featured-row">
                                <div className="text">
                                    <h2>{itm.title}</h2>
                                </div>

                                <div className="slider-container">
                                    <Slider {...settings}>
                                        {itm.displayCategoryItemList?.map((book, index) => {
                                            const overallIndex = categoryIndex * itm.displayCategoryItemList.length + index;
                                            return (
                                                <div className="book" key={overallIndex}>
                                                    <div className="book-img">
                                                        {/* Discount Badge */}
                                                        {/* {book.discountPercentage && ( */}

                                                        {/* )} */}
                                                        <Link to={`/pagedetail/${book.uuid}`} state={book}>
                                                            {book.mrp > book.sellingPrice && (
                                                                <div className="discount-badge">
                                                                    <p>{Math.round(((book.mrp - book.sellingPrice) / book.mrp) * 100)}% OFF</p>
                                                                </div>
                                                            )}
                                                            <img src={book.itemImageUrls?.[0]} alt={book.title} />
                                                        </Link>
                                                        {book.stockOut && (
                                                            <p
                                                                style={{
                                                                    position: "absolute",
                                                                    zIndex: 100,
                                                                    width: "90%",
                                                                    padding: "10px",
                                                                    bottom: 0,
                                                                    textAlign: "center",
                                                                    backgroundColor: "white",
                                                                    color: "black",
                                                                    opacity: 0.8,
                                                                    height: "235px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center"
                                                                }}
                                                            >
                                                                Out off Stock
                                                            </p>
                                                        )}
                                                    </div>
                                                    <h3 className="shipping">{book.title}</h3>

                                                    <div className="book-btn">
                                                        <p>
                                                            <span className="price">&#8377;{book.sellingPrice}</span>
                                                            {book.sellingPrice !== book.mrp && (
                                                                <strike>
                                                                    <span className="original-price">&#8377;{book.mrp}</span>
                                                                </strike>
                                                            )}
                                                        </p>

                                                        {book.stockOut ? (
                                                            <button className="notify" onClick={() => handleNoti(book.uuid)}>
                                                                Notify Me
                                                            </button>
                                                        ) : !cart?.itemDetailList?.some((itm) => itm.itemUuid === book.uuid) ? (
                                                            <button onClick={() => addToCart(book.uuid)}>ADD</button>
                                                        ) : (
                                                            <div className="add-btn">
                                                                <span
                                                                    onClick={() => {
                                                                        const quantity = cart?.itemDetailList?.find(
                                                                            (itm) => itm.itemUuid === book.uuid
                                                                        );
                                                                        {
                                                                            quantity.quantity === 1
                                                                                ? removeCartItem(quantity.itemUuid)
                                                                                : itemQuantity(book.uuid, quantity.quantity - 1);
                                                                        }
                                                                    }}
                                                                >
                                                                    <RiSubtractLine />
                                                                </span>
                                                                <p className="p">{cart?.itemDetailList?.find((itm) => itm.itemUuid === book.uuid).quantity}</p>
                                                                <span
                                                                    onClick={() => {
                                                                        const quantity = cart?.itemDetailList?.find(
                                                                            (itm) => itm.itemUuid === book.uuid
                                                                        ).quantity;
                                                                        itemQuantity(book.uuid, quantity + 1);
                                                                    }}
                                                                >
                                                                    <IoAdd />
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Slider>
                                </div>

                            </div>
                        </div>
                    );
                })
            }

            {
                open &&
                <div className="modal-overlay-c" >

                    <div className="modal-content-c">
                        <p onClick={handleCancel}><RxCross1 /></p>
                        <h3>Please enter your number</h3>
                        <input type="number" placeholder="Mobile no. for notify " minLength={10} value={mobile} onChange={(e) => setMobile(e.target.value)} maxLength={10} />
                        <div className="modal-actions-c">

                            <button className="cancel-btn-c" onClick={handleConfirm} >Submit</button>
                        </div>
                    </div>
                </div>
            }

            {
                open2 &&
                <div className="modal-overlay-c" ref={open2Ref}>
                    <div className="modal-content-c">
                        <h3>Your requested has submited successfully</h3>
                        <div className="modal-actions-c">
                            {/* <button className="confirm-btn-c" onClick={() => setOpen2(false)}>Yes</button> */}
                            <button className="cancel-btn-c" onClick={() => setOpen2(false)}>OK</button>
                        </div>
                    </div>
                </div>
            }

        </>
    );
};

export default FeaturedBrand;











