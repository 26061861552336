import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { UpdateCoupon } from "../GiftCardChild/UpdateCoupon";

function UpdateCouponParent() {
    return (
        <>
            <MergeComponents getComponent={<UpdateCoupon />} />
        </>
    )
}
export { UpdateCouponParent }