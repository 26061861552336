import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { UpdateDisplayCate } from "../DisplayCatChild/UpdateDisplayCat";

function UpdateDisplayParent() {
    return (
        <>
        <MergeComponents getComponent={<UpdateDisplayCate/>}/>
        </>
    )
}
export { UpdateDisplayParent }