import React from "react";
import { Navbar } from "../../CommonComponents/Navbar";
import { AccountSlide } from "./AccountSlide";
import Footer from "../../CommonComponents/Footer";

function AccountMerge({getComponent,portName,portMobile}) {

    return (
        <>
            <Navbar />
            <div className="merge-data">
                <div className="merge-row">
                    <div className="merge-text">
                        <AccountSlide portName={portName} portMobile={portMobile} />
                    </div>
                    <div className="merge-text-b">
                    {getComponent}
                    </div>
                </div>

            </div>
            <Footer/>


        </>
    )
}
export { AccountMerge }