import React, { useState } from "react";
import { IoMdMenu } from "react-icons/io";
import img from "../../../Assets/Image/logo.png";
import { useNavigate } from "react-router-dom";

function Header({ getActiveClass }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false); // State to toggle dropdown visibility
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const navtoAdmin = useNavigate();
    const handleLogout = () => {
        alert("Logged out");
        sessionStorage.removeItem('tokenKey')
        sessionStorage.removeItem('userRole');
        navtoAdmin('/admin');
        window.location.reload();
        // Add your logout logic here, like clearing user session or tokens
    };

    const handleUpdatePassword = () => {
        alert("Redirecting to update password page...");
        // Redirect to update password page or trigger an update password modal
    };
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');
    const refresh = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/configuration/refresh-configuration`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

        } catch (e) {
            console.log(e, "error in refresh")
        }
    };

    return (
        <>
            <div className="header">
                <div className="row">
                    <div className="container">
                        {/* Menu Icon */}
                        <div className="icon" onClick={() => { getActiveClass() }}>
                            <h4><IoMdMenu /></h4>
                        </div>


                        <div className="main-logo">
                            <div className="btn submit-btn" style={{ width: "fitContant" }}>
                                <button onClick={refresh}>Refresh</button>
                            </div>

                            <div className="logo">

                                <img
                                    src={img}
                                    alt="Logo"
                                    onClick={toggleDropdown}  // Toggle dropdown on image click
                                    className="logo"
                                    style={{ cursor: 'pointer' }}  // Make the image look clickable
                                />

                            </div>
                        </div>
                        {isDropdownOpen && (
                            <div className="dropdown-menu">
                                <ul>
                                    <li onClick={handleLogout}>Logout</li>
                                    <li onClick={handleUpdatePassword}>Update Password</li>
                                </ul>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    );
}

export { Header };
