import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

function Authentication() {

    const [authe, setAuthe] = useState(false);
    const token = sessionStorage.getItem('tokenKey');

    useEffect(() => {
        console.log(token,'tokennn');
        if (token && token !== ''){
            setAuthe(true);
        }
    }, []);
   
    

    return (
        <>

            {
                token && token !== '' ?
                
                <Outlet />
                
                :
                <Navigate to='/login'/>
            }

        </>
    )
};
export { Authentication }