import { BrowserRouter} from 'react-router-dom';
import './App.css';
import { Routing } from './Routing/Routing';
import { Provider } from 'react-redux';
import { store } from './Store/ConfigRedux';

function App() {


  return (
    <>

      <Provider store={store}>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </Provider>


    </>
  )
}

export { App }