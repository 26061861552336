import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit2 } from 'react-icons/fi';
import { useRole } from "../../../../../Routing/Routing";


function Cms() {

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');
    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);

    const [cmsItems, setCmsItems] = useState([]);
    const Cmslist = async () => {
        try {
            const res = await fetch(`http://localhost:8094/admin/v1/website-cms/list-keys`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const getRes = await res.json();
            console.log(getRes, "cmssss")
            if (getRes.errorCode === 0) {
                setCmsItems(getRes.responsePacket); // Update cmsItems with API responsePacket
            }
        } catch (e) {
            console.log(e, 'error in member api')
        }
    }
    useEffect(() => {
        Cmslist();
    }, []);

    const navto = useNavigate();
    const updateCms = async (settingKey) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/website-cms/${settingKey}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navto('/admin/updateCms', {
                    state: {
                        cmsData: getRes.responsePacket,
                        settingKey: settingKey
                    }
                })
            }
        } catch (e) {
            console.log(e, 'error in get itme')
        }
    };


    return (
        <>

            <div className="category">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>CMS List</h4>
                        </div>
                        <div className="category-btn">

                        </div>
                    </div>
                    <hr />

                    <div className="cms-container m-t2">
                        <h2>CMS Key</h2>
                        <ul className="cms-list">
                            {cmsItems?.map((item, index) => (
                                <li key={index} className="cms-item">
                                    <span className="cms-title">{item}</span>
                                    {permission("admin/v1/website-cms") && <span onClick={() => updateCms(item)}> <FiEdit2 className="edit-icon" /></span>}
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}
export { Cms }