import React, { useEffect, useRef, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";
import JoditEditor from "jodit-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddCoupon() {

    const navigate = useNavigate();
    const token = sessionStorage.getItem('tokenKey');

    // Array for storing image previews
    const BASE_URL = process.env.REACT_APP_PP_URL;

    const [couponCode, setCouponCode] = useState();
    const [description, setDescription] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [discountType, setDiscountType] = useState('Amount');
    const [discountValue, setDiscountValue] = useState();
    const [minOrderValue, setMinOrderValue] = useState();
    const [maxDiscountAmount, setMaxDiscountAmount] = useState();
    const [maxUsePerUser, setMaxUsePerUser] = useState();
    const [usedCount, setUsedCount] = useState();


    const content = {
        // title: title,
        couponCode: couponCode,
        description: description,
        startDate: startDate,
        endDate: endDate,
        discountType: discountType,
        minOrderValue: minOrderValue,
        discountValue: discountValue,
        ...(discountType === 'Percentage' && { maxDiscountAmount: maxDiscountAmount }),
        // usedCount: usedCount,
        maxUsePerUser: maxUsePerUser
    }

    console.log(content, 'content')

    const members = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/couponCode/save`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(content)
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setAction('your coupon added successfully !');
                setShowPop(true);
                setNavv('ok');
                setMore('Add more');
            } else {
                setShowPop(true);
                setAction('something went wrong');
                setNavv('OK');
                setMore('cancel');
            }
        } catch (e) {
            console.log(e, 'error in member api')
        }
    };

    const [showPop, setShowPop] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();

    const navToCat = useNavigate();
    const confirmPopUp = () => {
        if (navv === 'ok') {
            navToCat('/admin/gift');
            setShowPop(false);
        } else if (navv === 'OK') {
            setShowPop(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    };

    const onCloseBtn = () => {
        if (more === 'Add more') {
            setShowPop(false);
            setCouponCode('');
            setDescription('');
            setStartDate('');
            setEndDate('');
            setDiscountType('');
            setDiscountValue('');
            setMinOrderValue('');
            setMaxDiscountAmount('');
            setMaxUsePerUser('');

            window.scrollTo({
                top: 0,
                behavior: 'smooth'  // Optional: Use 'auto' for instant scroll
            });
        } else if (more === 'cancel') {
            navToCat('/admin/gift');
        }
    };

    const handelStartDate = async (e) => {
        const getDate = e.target.value
        await setStartDate(getDate)
        if (endDate && new Date(endDate) < new Date(getDate)) {
            const trikh = new Date(getDate)
            // console.log('starte function')
            trikh.setDate(trikh.getDate() + 1)
            setEndDate(trikh.toISOString().split('T')[0]);
        }
    };

    const stDate = new Date().toISOString().split('T')[0];

    const [showSuggestion, setShowSuggestion] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setShowSuggestion(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    const disType = ['Amount', 'Percentage'];
    const [fillDis, setFillDis] = useState([...disType]);
    const [disValue, setDisValue] = useState('Rs');

    const fillDisType = (e) => {
        const get = e
        const fill = disType.filter((itm) => itm.toLowerCase().includes(get.toLowerCase()))
        setFillDis(fill);
    };
    const addValue = (e) => {
        setDiscountType(e);
        setShowSuggestion(false);
        if (e === 'Amount') {
            setDisValue('Rs');
            setMaxDiscountAmount();
        } else if (e === 'Percentage') {
            setDisValue('%');
        }
    };



    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Add Coupon</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form">

                            {/* NAME*/}
                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add ">
                                    <label>Coupon Code</label>
                                    <input
                                        type="text"
                                        value={couponCode}
                                        onChange={(e) => setCouponCode(e.target.value)}
                                    />
                                </div>

                                <div className="add ">
                                    <label>Min. Order Value</label>
                                    <input
                                        type="text"
                                        value={minOrderValue}
                                        onChange={(e) => { setMinOrderValue(e.target.value) }}
                                    />
                                </div>

                            </div>

                            {/* Mobile */}
                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add">
                                    <label>StartDate</label>
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={handelStartDate}
                                        className="date-input"
                                        min={stDate}
                                    />
                                </div>
                                <div className="add">
                                    <label>End Date</label>
                                    <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        className="date-input"
                                        min={content?.startDate || stDate}
                                    />
                                </div>
                            </div>

                            {/* email */}
                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add parentid">
                                    <label>Discount Type</label>
                                    <div className="btn-AddCat">
                                        <input
                                            type="text"
                                            value={discountType}
                                            onChange={(e) => { setDiscountType(e.target.value); fillDisType(e.target.value) }}
                                            onFocus={() => setShowSuggestion(true)}
                                        />
                                    </div>
                                    {
                                        showSuggestion && <div className="multiple-cat" ref={ref}>
                                            {
                                                fillDis.map((itm) => {
                                                    return (
                                                        <>
                                                            <div className="options">
                                                                <li onClick={() => { addValue(itm) }}>
                                                                    <p>{itm}</p>
                                                                </li>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>

                                <div className="add parentid">
                                    <label>Discount Value</label>
                                    <div className="btn-AddCat">
                                        <input
                                            type="text"
                                            value={discountValue}
                                            onChange={(e) => setDiscountValue(e.target.value)}
                                        />
                                        <button>{disValue}</button>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="d-flex w-100 m-b2 d-other">
                                <div className="add">
                                    <label>Discount Value</label>
                                    <input
                                        type="text"
                                        value={discountValue}
                                        onChange={(e) => setDiscountValue(e.target.value)}
                                    />
                                </div>
                            </div> */}

                            <div className="d-flex w-100 m-b2 d-other">

                                <div className="add">
                                    <label>Max Used per person</label>
                                    <input
                                        type="text"
                                        value={maxUsePerUser}
                                        onChange={(e) => setMaxUsePerUser(e.target.value)}
                                    />
                                </div>

                                {
                                    discountType === 'Percentage' &&
                                    (
                                        <div className="add ">
                                            <label>Max. Discount Amount</label>
                                            <input
                                                type="text"
                                                value={maxDiscountAmount}
                                                onChange={(e) => { setMaxDiscountAmount(e.target.value) }}
                                            />
                                        </div>
                                    )
                                }

                                {/* <div className="add ">
                                    <label>Used Count</label>
                                    <input
                                        type="text"
                                        value={usedCount}
                                        onChange={(e) => { setUsedCount(e.target.value) }}
                                    />
                                </div> */}
                            </div>

                            <div className="editer m-b2">
                                {/* Jodit Editor */}
                                <div className="add w-100">
                                    <label>Description</label>
                                    {/* <JoditEditor
                                        // ref={editor}
                                        value={description}
                                        tabIndex={1}
                                        onChange={(newContent) => setDescription(newContent)}
                                    /> */}
                                       <CKEditor
                                            editor={ClassicEditor}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        "heading",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "link",
                                                        "bulletedList",
                                                        "numberedList",
                                                        "|",
                                                        "indent",
                                                        "outdent",
                                                        "|",
                                                        "imageUpload",
                                                        "blockQuote",
                                                        "mediaEmbed",
                                                        "undo",
                                                        "redo",
                                                    ],
                                                },
                                            }}
                                            data={description}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDescription(data);
                                            }}
                                        />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={members}>Submit</button>
                    </div>
                </div>
            </div>

            <SuccessPopUp
                isOpen={showPop}
                action={action}
                navv={navv}
                FutherMore={more}
                onClose={onCloseBtn}
                onConfirm={confirmPopUp}
            />
        </>
    )
}
export { AddCoupon }