import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { AssignUrl } from '../ManagePageChild/AssignUrl';

function AssignUrlParent() {
    return (
        <>
            <MergeComponents getComponent={<AssignUrl />} />
        </>
    )
}
export { AssignUrlParent }