import React from "react";
import { AccountMerge } from "../../AccountMerge";
import { AddaddressChild } from "../AddaddressChild/AddadressChild";

function AddaddressParent(){
    return(
        <>
        <AccountMerge getComponent={<AddaddressChild/>}/>
        </>
    )
}
export{AddaddressParent}