import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { AiFillDelete } from "react-icons/ai";
// import { HTMLToPlainText } from "../../CommonComponents/HtmlToPlainText";
import { FaCheckCircle } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { FaRedo } from "react-icons/fa";
// import Modal from "../../../../CommonComponents/Popup";
import Modal from "../../../CommonComponents/Popup";
import { useDispatch } from "react-redux";
import { staff } from "../../../../../Store/staffSlice";
import PaginationComponent from "../../pagination";
import { useRole } from "../../../../../Routing/Routing";
import Select from "react-select";
import { MagnifyImage } from "../../../CommonComponents/MagnifyImage";

function Banner() {

    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);
    const BASE_URL = process.env.REACT_APP_PP_URL;

    const token = sessionStorage.getItem('tokenKey');
    const [action, setAction] = useState();
    const [popup, setPopup] = useState(false);

    // ITME LIST
    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);
    const [getID, setGetId] = useState();
    const [page, setPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    // ALL ITEMS LISTS
    const [type, setType] = useState('ACTIVE');
    const items = async (data) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/banner/list-data/${data}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
                console.log(getRes, 'banner list')
            };
            setInputSearch('');

        } catch (e) {
            console.log(e, 'error in itme list');
        }
    };
    useEffect(() => {
        items('Active');
    }, []);

    // ACIVE API AND INACTIVE API
    const activeItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/banner/activate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in active itme api')
        }
    };
    // INACTIVE ITEMS
    const inactiveItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/banner/inactivate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in inactive api of itme')
        }
    };

    // DELETED API FOR ITEM

    const [deletedItem, setDeletedItem] = useState([]);
    const [filterDeleted, setFilterDeleted] = useState([...deletedItem]);

    const deletedItems = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/banner/deleted-list`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    search: '',
                    pageSize: page,
                    pageNumber: 0
                })
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in deleted api in item')
        }
    };

    // DELTED ITEMS FROM ITEMS
    const removeItems = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/banner/delete/${uuid}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in delted itms api')
        }
    };
    // REVIVE ITEMS

    const revive = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/banner/revive/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in revive api')
        }
    };


    // HANDEL REVIVE API
    const handelRevive = (uuid) => {
        setAction('RESTORE');
        setPopup(true);
        setGetId(uuid);
    }
    // HANDEL DELTED
    const handelDeleted = (uuid) => {
        setAction('DELETED');
        setGetId(uuid);
        setPopup(true);
    };
    // HANDEL ACTIVE AND INACTIVE SECTIONS
    const handelActive = (uuid) => {
        setAction('ACTIVE');
        setGetId(uuid);
        setPopup(true);
    };
    const handelInactive = (uuid) => {
        setAction('INACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    // HANDEL UPDATE STAFF
    const navTo = useNavigate();
    const dispatch = useDispatch();
    const update = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/banner/update/${uuid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            console.log(getRes, 'responce from update staff');
            if (getRes.errorCode === 0) {
                dispatch(staff(getRes.responsePacket))
                navTo(`/admin/updateBanner/${getRes.responsePacket.uuid}`);
            }
        } catch (e) {
            console.log(e, 'errror in update staff api')
        }
    };
    const handelUpdate = (item) => {
        navTo(`/admin/updateBanner/${item.uuid}`, { state: item })
    };


    // CONFIRM POP UP
    const confirmPop = async () => {
        if (popup) {
            switch (action) {
                case 'ACTIVE': await activeItem(getID);
                    break;
                case 'INACTIVE':
                    await inactiveItem(getID);
                    break;
                case "DELETED":
                    await removeItems(getID);
                    break;
                case 'RESTORE':
                    await revive(getID);
                    await activeItem(getID)
                    break;
                case 'Update':
                    await update(getID);
                    break;
                default:
                    console.log('no such action available');
            }
        };
        setAction('');
        setPopup(false);
    };
    // CANCEL THE POPUP
    const closePop = () => {
        setPopup(false);
        setAction('');
    };

    const [inputSearch, setInputSearch] = useState();

    const handleSearch = (e) => {
        const get = e.target.value;
        setInputSearch(get);
        const search = list.filter((itm) => itm.title && itm.title.toLowerCase().includes(get.toLowerCase()))
        setFilterList(search);
        setCurrentPage(1);
    };

    // PAGINATION LOGIC
    const resultPerPage = [
        { value: 10, label: "10 items per page" },
        { value: 20, label: "20 items per page" },
        { value: 50, label: "50 items per page" },
        { value: 100, label: "100 items per page" },
        { value: 200, label: "200 items per page" },
    ];
    const [lengthOfPage, setLengthOfPage] = useState(() => {
        return JSON.parse(sessionStorage.getItem("bannerPerPage")) || resultPerPage[0]
    });

    useEffect(() => {
        sessionStorage.setItem("bannerPerPage", JSON.stringify(lengthOfPage));
    }, [lengthOfPage]);

    // Pagination logic
    const itemsPerPage = lengthOfPage?.value; // Show 10 items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };



    return (
        <>
            <div className="category">
                <div className="row">
                    <div className="category" style={{ display: "block" }}>
                        <div className="category-nav">
                            <div className="category-text">
                                <h4>Banner</h4>
                            </div>
                            <div className="category-btn">
                                <Select
                                    defaultValue={resultPerPage[0]}
                                    closeMenuOnSelect={true}
                                    name="colors"
                                    options={resultPerPage}
                                    value={lengthOfPage}
                                    onChange={(e) => { setLengthOfPage(e); setCurrentPage(1) }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                                {permission('admin/v1/banner/create') &&
                                    <Link to="/admin/addBanner">
                                        <button>Add Banner</button>
                                    </Link>
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="category-filter">
                            <div className="filterr">
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'ALL' ? "white" : "black", color: type === 'ALL' ? "black" : "white" }} onClick={() => { items('ALL'); setType('ALL') }}>All</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'ACTIVE' ? "white" : "black", color: type === 'ACTIVE' ? "black" : "white" }} onClick={() => { items('Active'); setType('ACTIVE') }}>Active</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'INACTIVE' ? "white" : "black", color: type === 'INACTIVE' ? "black" : "white" }} onClick={() => { items('Inactive'); setType('INACTIVE') }}>Inactive</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'DELETED' ? "white" : "black", color: type === 'DELETED' ? "black" : "white" }} onClick={() => { items('Deleted'); setType('DELETED') }}><AiFillDelete /></button>
                                </div>
                            </div>
                            <div className="search">
                                <h4>Search:</h4>
                                <input type="text" value={inputSearch} onChange={(e) => handleSearch(e)} placeholder="Search all Category" className="input" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu-table-container sticky-container-order">
                <div className="order-list">
                    <div className="order-list-header banner-list-header">
                        {/* <div className='tittle'>User Id</div> */}
                        <div className='tittle'>Image</div>
                        <div className='tittle'>Title</div>
                        {/* <div className='tittle'>Email</div> */}
                        <div className='tittle'>Banner Type</div>
                        <div className='tittle'>Status</div>
                        <div className='tittle'>Action</div>
                    </div>
                </div>
            </div>
            <div className="menu-table-container">
                <div className="order-list">

                    {
                        filterList.map(item => (
                            <div key={item.uuid} className='order-list-row banner-list-header'>
                                {/* <div className='tittle'>{item.firstName}</div> */}
                                <div className='tittle'><img src={item.bannerImageUrl} alt="" className="img" onClick={() => maginifyImage(item.bannerImageUrl)} /></div>
                                <div className='tittle'>{item.title}</div>
                                <div className='tittle'>{item.bannerTypeEnum}</div>
                                {/* <div className='tittle'><HTMLToPlainText htmlString={item.description} /></div> */}
                                {/* <div className='tittle'>{item.jobRole}</div> */}
                                <div className='tittle'>
                                    <button
                                        className={`status ${item.active ? 'active-btn' : 'inactive'}`}
                                    >
                                        {item.active ? 'Active' : 'Inactive'}
                                    </button>
                                </div>
                                <div className='btn flex-btns'>


                                    {
                                        item.active ?
                                            (
                                                permission('admin/v1/banner/inactivate') &&
                                                (<button
                                                    className={`status inactive`}
                                                    onClick={() => handelInactive(item.uuid)}
                                                ><CgUnavailable />Inactive</button>))
                                            :
                                            (permission('admin/v1/banner/activate') &&
                                                (< button
                                                    className={`status active-btn`}
                                                    onClick={() => handelActive(item.uuid)}
                                                ><FaCheckCircle />Active</button>)
                                            )
                                    }

                                    {
                                        permission('admin/v1/banner/update') &&

                                        <button className="action-btn edit-btn action-btnnn" onClick={() => handelUpdate(item)}><FaEdit /> Edit</button>

                                    }
                                    {
                                        item.deleted ?
                                            (
                                                permission('admin/v1/banner/revive') &&
                                                (<button className="status active-btn" onClick={() => { handelRevive(item.uuid) }}>
                                                    <FaRedo /> Revive
                                                </button>)
                                            )
                                            :
                                            (
                                                permission('admin/v1/banner/delete') &&
                                                (<button className="status inactive" onClick={() => handelDeleted(item.uuid)}>
                                                    <AiFillDelete /> Delete
                                                </button>))
                                    }
                                </div>
                            </div>
                        ))
                    }




                    <div className="pagination">
                        <span>Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filterList.length)} of {filterList.length} entries</span>
                        <span className="pagination-buttons">
                            {/* <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                            <button>{currentPage}</button>
                            <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button> */}
                            <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} totalItem={filterList.length} lengthOfPage={lengthOfPage} />
                        </span>
                    </div>

                </div>
            </div >


            {/* modal */}
            < Modal
                isOpen={popup}
                onClose={closePop}
                onConfirm={confirmPop}
                action={action}
            />

            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />
        </>
    )
};
export { Banner }