import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "./Navbar";
import Footer from "./Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddToCart, Getdata, itemQuantity, removeCartItem } from "../../../Utilities/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartCount, fetchHeaderData } from "../../../Store/apiSlice";
import { IoAdd } from "react-icons/io5";
import { RiSubtractLine } from "react-icons/ri";
import { Skeleton } from "../CommonComponents/Skeleton";
import { RxCross1 } from "react-icons/rx";

function PageDetail2() {

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const navigate = useNavigate(); 
    const token = sessionStorage.getItem("tokenKey");
    const [dataa, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    

    const location = useLocation();
    const book = location && location.state;
    console.log(book, 'boookk');
    const otherData = book?.otherDataJson ? JSON.parse(book?.otherDataJson) : {}
    const [mainImage, setMainImage] = useState(book?.itemImageUrls?.[0]);

    const { id } = useParams();

    const getItem = async (categoryUuid) => {
        try {
            const res = await fetch(`${BASE_URL}/guest-customer/v1/homePage/get-category-item-details/${categoryUuid}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setData(getRes.responsePacket);
            }
        } catch (e) {
            console.error("Error fetching item details:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getItem(id);
    }, [id]);


const [info,setInfo] = useState();
    useEffect(() => {
        if (dataa) {
            const res = dataa?.displayCategoryList?.map((itm) => itm.displayCategoryItemList).find((item) => item.uuid === book?.uuid);
            console.log(res, 'info detail', id);
            setInfo(res);
        }
    }, [dataa]);

    const handleThumbnailClick = (image) => {
        setMainImage(image);
    };

    const RenderHTML = ({ htmlContent }) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
    };



    useEffect(() => {
        if (info?.itemImageUrls?.length) {
            setMainImage(info.itemImageUrls[0]); // Set the first image as the main image
        }
    }, [info]);

    const thumbnails = [
        info?.itemImageUrls?.map((itm) => itm)
    ];

    const dispatch = useDispatch();
    const data = useSelector((state) => state.header.data);
    console.log(data, "data");

    const addCart = async (itemId) => {
        try {
            const result = await AddToCart(data?.responsePacket?.uuid, itemId);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.log(e, 'errron in add to cart api')
        }
    };

    const additems = async (itemId, quantity) => {
        try {
            const result = await itemQuantity(data?.responsePacket?.uuid, itemId, quantity);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.log(e, 'errron in add to cart api')
        }
    };

    const cartItemRemove = async (itemId) => {
        try {
            const result = await removeCartItem(data?.responsePacket?.uuid, itemId);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.log(e, 'error in remove item')
        }
    };


    const key = localStorage.getItem('userId')
    const [open, setOpen] = useState(false);
    const [mobile, setMobile] = useState();
    const [itemId, setItemId] = useState();

    const noti = async () => {
        try {
            const res = await fetch(`${BASE_URL}/guest-customer/v1/item-notify/save-notify`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${key}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    itemUuid: itemId,
                    customerIsdCode: 91,
                    customerMobile: mobile
                })
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setOpen2(true);
            };

        } catch (e) {
            console.log(e, 'error in notification');
        }
    };

    const handleNoti = (uuid) => {
        setOpen(true);
        setItemId(uuid);
    };

    const handleConfirm = () => {
        if (itemId && mobile.length === 10) {
            noti();
            setOpen(false);
            setItemId('');
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setItemId('');
    };

    const open2Ref = useRef(null);
    const [open2, setOpen2] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!open2Ref?.current?.contains(event.target)) {
                setOpen2(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [open2Ref]);



    return (
        <>
            <Navbar />
            <div className="page-heading">
                {loading ? (
                    <Skeleton className="skeleton-heading" style={{ height: "40px", width: "200px" }} />
                ) : (
                    <div className="page-head">
                    <div></div>
                    <h1>Item Detail</h1>
                    <h1 onClick={() => navigate(-1)}>
                            <RxCross1 />
                        </h1>
                </div>
                )}
            </div>
            <div className="book-details-container">
                <div className="book-image-section">
                    <div className="main-image">
                        {loading ? (
                            <Skeleton className="skeleton-image" style={{ height: "300px", width: "100%" }} />
                        ) : (
                            <img src={mainImage} alt="Main Book" />
                        )}
                    </div>
                    <div className="thumbnail-container">
                        {loading ? (
                            <>
                                {[...Array(4)].map((_, i) => (
                                    <Skeleton
                                        key={i}
                                        className="skeleton-thumbnail"
                                        style={{ height: "50px", width: "50px", margin: "5px" }}
                                    />
                                ))}
                            </>
                        ) : (
                            book?.itemImageUrls?.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Thumbnail ${index + 1}`}
                                    className="thumbnail"
                                    onClick={() => handleThumbnailClick(image)}
                                />
                            ))
                        )}
                    </div>
                </div>

                <div className="product-details-section">
                    {loading ? (
                        <Skeleton className="skeleton-title" style={{ height: "20px", width: "200px" }} />
                    ) : (
                        <div className="title">
                            <h2>{book?.title}</h2>
                        </div>
                    )}
                    <div className="txt">
                        {loading ? (
                            <>
                                <Skeleton className="skeleton-price" style={{ height: "20px", width: "100px" }} />
                                <Skeleton className="skeleton-price-info" style={{ height: "15px", width: "150px" }} />
                                <Skeleton className="skeleton-button" style={{ height: "40px", width: "150px" }} />
                            </>
                        ) : (
                            <>
                                <div className="txt-b">
                                    <h3 className="price">Price ₹{book?.sellingPrice}</h3>
                                    <strike>
                                    <p className="price-info">₹{book?.mrp}</p>
                                    </strike>
                                </div>
                                {book?.stockOut ? (
                                    <button onClick={() => handleNoti(book?.uuid)}>Notify Me</button>
                                ) : !data?.responsePacket?.itemDetailList?.some((itm) => itm.itemUuid === book?.uuid) ? (
                                    <div className="add-to-cart-button" onClick={() => addCart(book?.uuid)}>
                                        <button>Add To Cart</button>
                                    </div>
                                ) : (
                                    <div className="add-btn">
                                        <span onClick={() => {
                                            const quantity = data?.responsePacket?.itemDetailList?.find((itm) => itm.itemUuid === book?.uuid)
                                            { quantity.quantity === 1 ? cartItemRemove(quantity.itemUuid) : additems(book?.uuid, quantity.quantity - 1) }
                                        }}><RiSubtractLine /></span>
                                        <p className="pp">{data?.responsePacket?.itemDetailList?.find((itm) => itm.itemUuid === book?.uuid)?.quantity}</p>
                                        <span onClick={() => {
                                            const quantity = data?.responsePacket?.itemDetailList?.find((itm) => itm.itemUuid === book?.uuid)?.quantity
                                            additems(book?.uuid, quantity + 1)
                                        }}><IoAdd /></span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <h4 className="section-title">
                        {loading ? (
                            <Skeleton className="skeleton-section-title" style={{ height: "20px", width: "150px" }} />
                        ) : (
                            "Product Details"
                        )}
                    </h4>
                    {/* <h4>
                        {loading ? (
                            <Skeleton className="skeleton-title" style={{ height: "20px", width: "250px" }} />
                        ) : (
                            book?.title
                        )}
                    </h4> */}
                    <p className="detail">
                        <strong>Type:</strong>
                        {loading ? (
                            <Skeleton className="skeleton-detail" style={{ height: "20px", width: "120px" }} />
                        ) : (
                            "Book"
                        )}
                    </p>
                    <p className="detail">
                        <strong>Key Features:</strong>
                        {loading ? (
                            <Skeleton className="skeleton-detail" style={{ height: "20px", width: "100%" }} />
                        ) : (
                            <RenderHTML htmlContent={book?.description} />
                        )}
                    </p>
                    <h4 className="section-title">Highlights</h4>
                    <ul className="highlights-list">
                        {otherData?.author && <li>Author:-  {otherData?.author}</li>}
                        {otherData?.noOfPages && <li>{otherData?.noOfPages} Pages</li>}
                        {otherData?.medium && <li>Language:-  {otherData?.medium}</li>}
                        {otherData?.publisher && <li>Publisher:-  {otherData?.publisher}</li>}
                    </ul>
                </div>
            </div>
            <Footer />

            {
                open &&
                <div className="modal-overlay-c" >
                    <div className="modal-content-c">
                        <h3>Please enter your number</h3>
                        <input type="number" placeholder="mobile" minLength={10} value={mobile} onChange={(e) => setMobile(e.target.value)} maxLength={10} />
                        <div className="modal-actions-c">
                            <button className="confirm-btn-c" onClick={handleConfirm}>Yes</button>
                            <button className="cancel-btn-c" onClick={handleCancel}>No</button>
                        </div>
                    </div>
                </div>
            }

            {
                open2 &&
                <div className="modal-overlay-c" ref={open2Ref}>
                    <div className="modal-content-c">
                        <h3>Your requested has submited successfully</h3>
                        <div className="modal-actions-c">
                            {/* <button className="confirm-btn-c" onClick={() => setOpen2(false)}>Yes</button> */}
                            <button className="cancel-btn-c" onClick={() => setOpen2(false)}>OK</button>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}
export { PageDetail2 }