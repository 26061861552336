import React, { useEffect, useState } from "react";
import img from "../../../../../Assets/Image/book1.jpg";
import { GoChevronDown } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { MagnifyImage } from "../../../CommonComponents/MagnifyImage";

function CartDetail() {

  const id = useLocation();
  const objectId = id && id.state;

  const BASE_URL = process.env.REACT_APP_PP_URL;
  const token = sessionStorage.getItem('tokenKey');

  const [info, setInfo] = useState();
  const [filter, setFilter] = useState([]);
  const detail = async () => {
    try {
      const res = await fetch(`${BASE_URL}/admin/v1/order/getCartDetail/${objectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const getRes = await res.json();
      if (getRes.errorCode === 0) {
        console.log(getRes, 'qwerty');
        setInfo(getRes.responsePacket)
        setFilter([getRes.responsePacket])
      }
    } catch (e) {
      console.log(e, 'error in detail api of cart')
    }
  };

  useEffect(() => {
    detail();
  }, []);

  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: "Iphone 11 pro",
      details: "256GB, Navy Blue",
      quantity: 2,
      price: 900,
      image: img,
    },
    {
      id: 2,
      name: "Samsung galaxy Note 10",
      details: "256GB, Navy Blue",
      quantity: 2,
      price: 900,
      image: img,
    },
    {
      id: 3,
      name: "Canon EOS M50",
      details: "Onyx Black",
      quantity: 1,
      price: 1199,
      image: img,
    },
    {
      id: 4,
      name: "MacBook Pro",
      details: "1TB, Graphite",
      quantity: 1,
      price: 1799,
      image: img,
    },
    {
      id: 5,
      name: "MacBook Pro dd",
      details: "1TB, Graphite",
      quantity: 3,
      price: 1899,
      image: img,
    }, {
      id: 6,
      name: "MacBook ss",
      details: "1TB, Graphite",
      quantity: 7,
      price: 14699,
      image: img,
    }, {
      id: 7,
      name: "MacBook Pro aa",
      details: "1TB, Graphite",
      quantity: 5,
      price: 12299,
      image: img,
    },
  ]);

  const [sortMenuVisible, setSortMenuVisible] = useState(false);
  const [activeSort, setActiveSort] = useState(""); // Track active sort option

  // Handle sorting logic
  const handleSort = (criteria) => {
    let sortedItems;
    if (criteria === "priceAsc") {
      sortedItems = [...filter].sort((a, b) => a.itemDetailList?.sellingPrice - b.sellingPrice);
    } else if (criteria === "priceDesc") {
      sortedItems = [...filter].sort((a, b) => b.itemDetailList?.sellingPrice - a.sellingPrice);
    } else if (criteria === "name") {
      sortedItems = [...filter].sort((a, b) => a.itemDetailList?.title.localeCompare(b.title));
    }
    setInfo(sortedItems);
    setActiveSort(criteria); // Set active sort option
    setSortMenuVisible(false); // Hide dropdown after sorting
  };

  // Image Magifinify
  const [imgUrl, setImgUrl] = useState();
  const [showImg, setShowImg] = useState(false);
  const maginifyImage = (e) => {
    setShowImg(true);
    setImgUrl(e);
  };

  return (
    <>
      <div className="order-detail">
        <div className="orderr">
          <div className="card-order">
            <h3 className="card-title">Customer Detail</h3>
            <div className="row">
              <span className="label">Full Name</span>
              <span className="value">{info?.customerDetail?.userCustomerFirstName}</span>
            </div>
            <div className="row">
              <span className="label">Mobile Number</span>
              <span className="value"> {info?.customerDetail?.userCustomerMobile}</span>
            </div>
            <div className="row">
              <span className="label">Email</span>
              <span className="value"></span>
            </div>
          </div>
        </div>



        <div className="ordered-item-container">
          <div className="card-b">
            <h3 className="card-title">Ordered Item Details</h3>
            <table className="item-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  {/* <th>Customization</th> */}
                  {/* <th>Rating</th> */}
                  <th>Selling Price</th>
                  <th>Qty.</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>

                {info?.itemDetailList?.map((item, index) => (
                  <tr key={item.id}>

                    <td style={{ width: "20%" }}>
                      <div className="img" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={item?.itemImageUrls?.[0]} alt="img" width={50} style={{ width: "40%", height: "100px", }} onClick={() => maginifyImage(item?.itemImageUrls?.[0])} />
                      </div>


                    </td>
                    <td >
                      {item.title} <span className="info-icon"></span>
                    </td>
                    {/* <td>{item.customization}</td>
                    <td>{item.rating}</td> */}
                    <td>{item.sellingPrice}</td>
                    <td>{item.quantity}</td>
                    <td>{item.sellingPrice * item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Order Summary */}
            <div className="summary">
              <div className="summary-row">
                <span>Item Total</span>
                <span>{info?.subTotal}</span>
              </div>
              <div className="summary-row">
                <span>Coupon Discount</span>
                <span className="negative">{info?.couponDiscountAmount}</span>
              </div>
              <div className="summary-row">
                <span>Delivery Charges</span>
                <div>
                  <div>{info?.deliveryCharges}</div>
                </div>
              </div>
              <div className="summary-row">
                <span>Packing Charges</span>
                <div>
                  <div>{info?.packingCharges}</div>
                </div>
              </div>
              <div className="summary-row total">
                <span>Order Total</span>
                <span>{info?.orderTotal}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />

    </>
  );
}

export { CartDetail };

