import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import JoditEditor from "jodit-react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";
import '../../../AdminStyle.css';
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";
import { uploadImage } from "../../../CommonComponents/ImageUpload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { MagnifyImage } from "../../../CommonComponents/MagnifyImage";

function UpdateItem() {
    const capitalizeWords = (value) => {
        return value
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
    };
    const navigate = useNavigate();
    const editor = useRef(null);
    // Array for storing image previews
    const BASE_URL = process.env.REACT_APP_PP_URL;

    // SUCCESS POPUP

    const [action, setAction] = useState();
    const [showPop, setShowPop] = useState(false);
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();
    const navToCat = useNavigate();
    const confirmPopUp = () => {
        if (navv === 'ok') {
            setShowPop(false);
            navToCat('/admin/item');
        } else if (navv === 'OK') {
            setShowPop(false);
            // window.location.reload()
        }
    };

    const onCloseBtn = () => {
        if (more === 'Add more') {
            setShowPop(false);
            setTitle('');
            setDescription('');
            setWeight('');
            setMrp('');
            setSequenceNo('');
            setSellingPrice('');
            setParentCategoryUuids('');
            setSubCategoryUuids('');
            setManageCat([]);
            setSubCat([]);
            if (subCat !== '') {
                setFilterSubCat([...subCat]);
                setManageSubCat([]);
            };
            window.scrollTo({
                top: 0,
                behavior: 'smooth'  // Optional: Use 'auto' for instant scroll
            });
        } else if (more === 'cancel') {
            navToCat('/admin/item');
        }
    };


    // UPDATE ITEM
    const token = sessionStorage.getItem('tokenKey');
    const location = useLocation();
    const product = location && location.state;
    console.log(product, "product", product?.otherDataJson ? JSON.parse(product?.otherDataJson).medium : "");

    const [title, setTitle] = useState(product.title);
    const [description, setDescription] = useState(product.description);
    const [weight, setWeight] = useState(product.weight);
    const [mrp, setMrp] = useState(product.mrp);
    const [sequenceNo, setSequenceNo] = useState();
    const [sellingPrice, setSellingPrice] = useState(product.sellingPrice);
    const [parentCategoryUuids, setParentCategoryUuids] = useState(product.parentCategoryDetails?.map((itm) => ({ value: itm?.parentUuid, label: itm?.parentTitle })));
    const [subCategoryUuids, setSubCategoryUuids] = useState(product.subCategoryDetails?.map((itm) => ({ value: itm?.parentUuid, label: itm?.parentTitle })));
    const [imagePreviews, setImagePreviews] = useState(product.itemImageUrls?.map((itm) => itm));
    const [offerApplicable, setOfferApplicable] = useState(product.offerApplicable);
    const [taxUuid, setTaxUuid] = useState(product?.taxDetails && { value: product?.taxDetails?.parentUuid, label: product?.taxDetails?.parentTitle });
    const [tax, setTax] = useState(product?.taxDetails?.parentTitle || '');
    const [publisherUuid, setPublisherUuid] = useState(product?.publisherDetails && { value: product?.publisherDetails?.parentUuid, label: product?.publisherDetails?.parentTitle });
    const [publisher, setPublisher] = useState(product?.publisherDetails?.parentTitle || '');
    const [getMedium, setGetMedium] = useState({ value: 'aw2', label: product?.otherDataJson && JSON.parse(product?.otherDataJson).medium });
    const [otherDataJson, setOtherDataJson] = useState(product?.otherDataJson ? JSON.parse(product?.otherDataJson) : {
        medium: getMedium?.label || getMedium,
        author: "",
        noOfPages: "",
        publicationYear: "",
        hsnCode: "",
    });
    console.log(publisherUuid, "getmedium")
    useEffect(() => {
        setOtherDataJson((prev) => ({
            ...prev, medium: getMedium?.label
        }))
    }, [getMedium]);

    const mediumRef = useRef();

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!mediumRef?.current?.contains(event.target)) {
                setShowMedium(false)
            };
        };
        document.addEventListener("mousedown", handleOutSideClick);
    }, [mediumRef]);

    const [showMedium, setShowMedium] = useState(false);

    const [selectMedium, setSelectMedium] = useState([
        { value: "1", label: "Hindi", },
        { value: "2", label: "English" },
        { value: "3", label: "Bilingual" },
        { value: "4", label: "Urdu" },
        { value: "5", label: "Sanskrit" },
    ]);

    const [filterMedium, setFilterMedium] = useState([...selectMedium]);

    const searchMedium = (text) => {
        setOtherDataJson((prev) => ({
            ...prev, medium: text
        }));
        const res = selectMedium.filter((itm) => itm.toLowerCase().includes(text.toLowerCase()));
        setFilterMedium(res);
        setShowMedium(true);
    };

    const enterMedium = (itm) => {
        setOtherDataJson((prev) => ({
            ...prev, medium: itm
        }))
        setShowMedium(false);
    };

    const handleOnChange = (key, value) => {
        setOtherDataJson((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    // HSN CODE 
    // HSN CODE
    const [showHsnCode, setShowHsnCode] = useState(false);

    const [hsnCodeList, setHsnCodeList] = useState([
        "20", "25", "30", "35", "45", "4901"
    ]);

    const hsnRef = useRef();

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!hsnRef.current?.contains(event.target)) {
                setShowHsnCode(false);
            }
        }
        document.addEventListener("mousedown", handleOutSideClick)
    }, [hsnRef]);

    const handleTex = (text) => {
        if (text === "Exempted") {
            setOtherDataJson((prev) => ({
                ...prev, hsnCode: "4901"
            }))
        } else if (otherDataJson.taxCat !== "Exempted") {
            setOtherDataJson((prev) => ({
                ...prev, hsnCode: ''
            }))
        }
    };

    const [filterHsnCode, setFilterHsnCode] = useState([...hsnCodeList]);

    const searchHsnCode = (text) => {
        setOtherDataJson((prev) => ({
            ...prev, hsnCode: +text
        }));
        const res = hsnCodeList.filter((itm) => itm.toString().includes(text));
        setFilterHsnCode(res);
        setShowHsnCode(true);
    };

    const selectHsnCode = (key) => {
        setOtherDataJson((prev) => ({
            ...prev, hsnCode: key
        }));
        setShowHsnCode(false);
    };


    const content = {
        title: title,
        description: description,
        weight: weight,
        mrp: mrp,
        sequenceNo: sequenceNo,
        itemImageUrls: imagePreviews,
        sellingPrice: sellingPrice,
        parentCategoryUuids: parentCategoryUuids?.map(itm => itm.value),
        subCategoryUuids: subCategoryUuids?.map(itm => itm.value),
        offerApplicable: offerApplicable,
        taxUuid: taxUuid?.value,
        publisherUuid: publisherUuid?.value,
        otherDataJson: JSON.stringify(otherDataJson)
    };

    // SAVE ITEMS API
    const saveItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/update/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(content)
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                console.log(getRes, "responce from add item");
                setAction('your item updated successfully !');
                setShowPop(true);
                setNavv('ok');
                setMore('cancel');
            } else {
                setShowPop(true);
                setAction('something went wrong');
                setNavv('OK');
                setMore('cancel');
            }
        } catch (e) {
            console.log(e, "error in add itme api");
        }
    };

    // SELECT CATEGORY 
    const [seletedCat, setSelectedCat] = useState([]);
    const [filterCat, setFilterCat] = useState([]);

    const saveCat = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/category/parent-category-list-in-key-value`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setSelectedCat(getRes.responsePacket);
                setFilterCat(getRes.responsePacket);
            }
            console.log(getRes)
        } catch (e) {
            console.log(e, 'errorn in add item select parent cate api')
        }
    };

    useEffect(() => {
        const parentCategoryData = seletedCat.map((itm) => ({
            value: itm.value,
            label: itm.label,
        }));
        setFilterCat(parentCategoryData);
    }, [seletedCat]);

    useEffect(() => {
        saveCat();
    }, []);

    const [catInput, setCatInput] = useState();
    const handelSearch = (e) => {
        const get = e.target.value
        setCatInput(get);
        const res = seletedCat.filter((itm) => itm.label.toLowerCase().includes(get.toLowerCase()));
        setFilterCat(res)
        setShowSuggestion(true);
    };

    const [manageCat, setManageCat] = useState(product.parentCategoryDetails);

    const addCateInput = (itm, title) => {
        if (itm && !manageCat.includes(itm) && !manageCat.some(item => item.parentTitle === title || item.label === title)) {
            setParentCategoryUuids((prev) => [...prev, itm.value]); // Add value to parentCategoryUuids
            setManageCat((prev) => [...prev, itm]); // Add label to manageCat
        } else if (itm && manageCat.some(item => item.parentTitle === title || item.label === title)) {
            setManageCat(manageCat.filter((category) => category.parentTitle !== title && category.label !== title));
            setParentCategoryUuids(parentCategoryUuids.filter((uuid) => uuid !== itm.value));
        }
        setCatInput("");
        setFilterCat(seletedCat);
    };

    const removeAddCat = (label) => {
        setManageCat(manageCat.filter((itm) => itm !== label));
        if (label.value) {
            setParentCategoryUuids(parentCategoryUuids.filter((uuid) => uuid !== label.value));
        } else if (label.parentUuid) {
            setParentCategoryUuids(parentCategoryUuids.filter((uuid) => uuid !== label.parentUuid));
        }
    };

    // SUGGESTION and OUTSIDE CLICK
    const ref = useRef();
    const [showSuggestion, setShowSuggestion] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setShowSuggestion(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);



    // SUB CATEGPRY
    const [subCat, setSubCat] = useState([]);
    const [filterSubCat, setFilterSubCat] = useState([]);
    useEffect(() => {
        if (subCat !== '') {
            setFilterSubCat([...subCat]);
        };
    }, []);

    const saveSub = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/category/sub-category-list-in-key-value`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    parentCategoryUuids: parentCategoryUuids?.map(itm => itm.value)
                })
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setSubCat(getRes.responsePacket);
                setFilterSubCat(getRes.responsePacket);
                console.log(getRes, 'getres saveSub');
            } else {
                console.log('error in savasub api responce')
            }
        } catch (e) {
            console.log(e, 'error in sub category in add  item')
        }
    };
    useEffect(() => {
        if (parentCategoryUuids !== '') {
            saveSub();
        }
        if (parentCategoryUuids.length === 0) {
            setSubCat([]);
            setFilterSubCat([]);
            setManageSubCat([]);
            setSubCategoryUuids([]);
        }
    }, [parentCategoryUuids]);

    useEffect(() => {
        if (subCat?.length > 0) {
            const updatedManageSubCat = manageSubCat.filter((item) =>
                subCat.some((subItem) => subItem.value === item.value));
            const updateSubUuid = subCategoryUuids.filter((item) =>
                subCat.some((subItem) => subItem.value === item.value)
            )
            setSubCategoryUuids(updateSubUuid);
            setManageSubCat(updatedManageSubCat);
        }
    }, [subCat]);

    const handelSubSearch = (e) => {
        const get = e.target.value;
        const res = subCat.filter((itm) => itm.label.toLowerCase().includes(get.toLowerCase()));
        setFilterSubCat(res);
        setShowSub(true);
        console.log('search sub')
    };
    console.log(subCategoryUuids, "subcategryUuids");
    const [manageSubCat, setManageSubCat] = useState(product.subCategoryDetails);

    const addSubCat = (id, title) => {
        if (id && !manageSubCat.some((itm) => itm.value === id.value) && !manageSubCat.some(item => item.parentTitle === title || item.label === title)) {
            setSubCategoryUuids((prev) => [...prev, id.value]);
            setManageSubCat((perv) => [...perv, id]);
            console.log(subCategoryUuids, 'subcategoryid');
        } else if (id && manageSubCat.some(item => item.parentTitle === title || item.label === title)) {
            setManageSubCat(manageSubCat.filter((itm) => itm.parentTitle !== title && itm.label !== title));
            setSubCategoryUuids(subCategoryUuids.filter((itm) => itm !== id.value));
        }

    };

    const removeSubCat = (id) => {
        setManageSubCat(manageSubCat.filter((itm) => itm !== id));
        if (id.parentUuid) {
            setSubCategoryUuids(subCategoryUuids.filter((itm) => itm !== id.parentUuid));
        } else if (id.label) {
            setSubCategoryUuids(subCategoryUuids.filter((itm) => itm !== id.value));
        }

    };

    // SHOW SUB PARENT SUGGESTION AND OUTSIDE CLICK
    const [showSub, setShowSub] = useState(false);
    const subRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!subRef?.current?.contains(event.target)) {
                setShowSub(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [subRef]);

    // UPLOAD IMGE
    const handleFileChange = async (e) => {
        const files = e.target.files;
        const uploadedUrls = [];
        // Loop through each selected file and upload
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                // uploadedUrls.push(imageUrl);
                setImagePreviews((prev => prev ? [...prev, imageUrl.responsePacket] : [imageUrl.responsePacket]));
                console.log('image upload');
            }
        };
        // setImagePreviews(uploadedUrls); // Update state with all uploaded image URLs
    };

    const handleDeleteImage = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
            // Remove the image from the preview and formData arrays
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews   
        }
    };



    const [errors, setErrors] = useState({});

    const titleRef = useRef(null);
    const mrpRef = useRef(null);
    const sellingPriceRef = useRef(null);
    const weightRef = useRef(null);
    const parentCateRef = useRef(null);
    const mediumReff = useRef(null);
    const publisherRef = useRef(null);
    const hsnCodeRef = useRef(null);
    const taxRef = useRef(null);

    const handleValidation = () => {
        let newErrors = {};
        // Check each field
        if (!title?.trim()) newErrors.title = "Title is required.";
        if (parentCategoryUuids?.length === 0 && subCategoryUuids?.length === 0) newErrors.parentCategoryUuids = "tax Category is required.";
        if ((!mrp && mrp !== 0) || (typeof mrp === "string" && !mrp.trim()) || (typeof mrp === "number" && mrp <= 0)) newErrors.mrp = "tax Category is required.";
        if ((!sellingPrice && sellingPrice !== 0) || (typeof sellingPrice === "string" && !sellingPrice.trim()) || (typeof sellingPrice === "number" && sellingPrice <= 0)) newErrors.sellingPrice = "tax Category is required.";
        if ((!weight && weight !== 0) || (typeof weight === "string" && !weight.trim()) || (typeof weight === "number" && weight <= 0)) newErrors.weight = "tax Category is required.";
        if (!otherDataJson?.medium) newErrors.medium = "Medium is required.";
        if (!publisherUuid) newErrors.publisher = "Publisher is required.";
        if (!taxUuid) newErrors.taxCat = "tax Category is required.";
        if ((!otherDataJson?.hsnCode && otherDataJson?.hsnCode !== 0) || (typeof otherDataJson?.hsnCode === "string" && !otherDataJson?.hsnCode.trim()) || (typeof otherDataJson?.hsnCode === "number" && otherDataJson?.hsnCode <= 0)) newErrors.hsnCode = "HSN Code is required.";
        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }
        return true;
    };

    const scrollToField = (field) => {
        switch (field) {
            case "title":
                titleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "medium":
                mediumReff.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "publisher":
                publisherRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "hsnCode":
                hsnCodeRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "taxCat":
                taxRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "weight":
                weightRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "sellingPrice":
                sellingPriceRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "mrp":
                mrpRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "parentCategoryUuids":
                parentCateRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        handleValidation();
        if (handleValidation()) {
            saveItem(product.uuid);
        }
    };

    const [showPublisher, setShowPublisher] = useState(false);
    const [publisherList, setPublisherList] = useState([]);
    const [filterPublisherList, setFilterPublisherList] = useState([...publisherList]);

    const getPublisherList = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/publisher/list-in-key-value`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setPublisherList(getRes.responsePacket);
                setFilterPublisherList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in publisher api')
        }
    };
    useEffect(() => {
        getPublisherList();
    }, []);

    const publisherReff = useRef();
    useEffect(() => {
        const handleClick = (event) => {
            if (!publisherReff.current?.contains(event.target)) {
                setShowPublisher(false);
            }
        };
        document.addEventListener("mousedown", handleClick)
    }, [publisherReff]);

    const [showTax, setShowTax] = useState(false);
    const [taxList, setTaxList] = useState([]);
    const [filtertaxList, setFilterTaxList] = useState([...taxList]);
    const getTaxList = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/tax/list-in-key-value`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setTaxList(getRes.responsePacket);
                setFilterTaxList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in publisher api')
        }
    };
    useEffect(() => {
        getTaxList();
    }, []);

    const taxReff = useRef();
    useEffect(() => {
        const handleClick = (event) => {
            if (!taxReff.current?.contains(event.target)) {
                setShowTax(false);
            }
        };
        document.addEventListener("mousedown", handleClick);
    }, [taxReff]);

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Update Item </h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">

                        <div className="add-form">
                            {/* Title Input */}
                            <div className="add w-100 m-b2" ref={titleRef}>
                                <label style={errors.title && { color: "red" }}>Title *</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            {/* Category Input */}
                            <div className="d-flex w-100 m-b2 d-other" ref={parentCateRef}>
                                <div className="add parentid">
                                    <label style={errors.parentCategoryUuids && { color: "red" }}>Category</label>
                                    {/* <div className="catt">
                                        {
                                            manageCat.map((itm, index) => {
                                                return (
                                                    <>
                                                        <div className="selectedItm" key={index}>
                                                            <span onClick={() => removeAddCat(itm)}>X</span>
                                                            {
                                                                itm.parentTitle ? <span>{itm.parentTitle}</span> : <span>{itm.label}</span>
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <input
                                        type="text"
                                        onClick={() => setShowSuggestion(!showSuggestion)}
                                        onChange={(e) => handelSearch(e)}
                                        value={catInput}
                                    />
                                    {
                                        showSuggestion && <div className="multiple-cat" ref={ref}>
                                            {
                                                filterCat.length > 0 ? (
                                                    filterCat.map((itm) => {
                                                        return (
                                                            <>
                                                                <div className="options" key={itm.key}>
                                                                    <li onClick={() => { addCateInput(itm, itm.label) }}>
                                                                        <p>{itm.label}</p>
                                                                    </li>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                )
                                                    :
                                                    <div className="options" >
                                                        <li>
                                                            <p>No such category is available</p>
                                                        </li>
                                                    </div>
                                            }
                                        </div>
                                    } */}
                                    <Select
                                        // defaultValue={[temp[2], temp[3]]}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        name="colors"
                                        options={filterCat}
                                        value={parentCategoryUuids}
                                        onChange={(e) => setParentCategoryUuids(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>

                                <div className="add parentid">
                                    <label style={errors.parentCategoryUuids && { color: "red" }}>Select Sub Category</label>
                                    {/* <div className="catt">
                                        {
                                            manageSubCat.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className="selectedItm" key={item.parentUuid}>
                                                            <span onClick={() => removeSubCat(item)}>X</span>
                                                            {item.parentTitle ? <span>{item.parentTitle}</span> : <span>{item.label}</span>}
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>

                                    <input
                                        type="text"
                                        onClick={() => setShowSub(!showSub)}
                                        onChange={(e) => handelSubSearch(e)}
                                    />

                                    {
                                        showSub && <div className="multiple-cat" ref={subRef}>
                                            {
                                                filterSubCat.length > 0 ? (
                                                    filterSubCat.map((itm) => {
                                                        return (
                                                            <>
                                                                <div className="options" key={itm.key}>
                                                                    <li onClick={() => { addSubCat(itm, itm.label) }}>
                                                                        <p>{itm.label}</p>
                                                                    </li>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                )
                                                    :
                                                    <div className="options" >
                                                        <li>
                                                            <p>{parentCategoryUuids?.length > 0 ? 'No category is available' : 'Select parent category'}</p>
                                                        </li>
                                                    </div>
                                            }
                                        </div>
                                    } */}

                                    <Select
                                        // defaultValue={[temp[2], temp[3]]}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        name="colors"
                                        options={filterSubCat}
                                        value={subCategoryUuids}
                                        onChange={(e) => setSubCategoryUuids(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>
                            </div>

                            {/* MRP Input */}
                            <div className="d-flex w-100 m-b2 d-other" ref={mrpRef}>
                                <div className="add">
                                    <label style={errors.mrp && { color: "red" }}>MRP *</label>
                                    <input
                                        type="number"
                                        // placeholder="MRP"
                                        value={mrp}
                                        onChange={(e) => setMrp(e.target.value)}
                                    />
                                </div>
                                <div className="add" ref={sellingPriceRef}>
                                    <label style={errors.sellingPrice && { color: "red" }}>Selling Price *</label>
                                    <input
                                        type="number"
                                        // placeholder="Selling Price"
                                        onChange={(e) => setSellingPrice(e.target.value)}
                                        value={sellingPrice}
                                    />
                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add">
                                    <div className="d-flex check-box-avail">
                                        <input
                                            type="checkbox"
                                            name="availableAllTime"
                                            checked={offerApplicable}
                                            onChange={() => setOfferApplicable(!offerApplicable)}
                                            id="check-box"
                                        />
                                        <label htmlFor="check-box">Coupon available for item</label>
                                    </div>
                                </div>

                                <div className="add" ref={weightRef}>
                                    <label style={errors.weight && { color: "red" }}>Weight *</label>
                                    <div className="btn-AddCat">
                                        <input
                                            type="number"
                                            onChange={(e) => setWeight(e.target.value)}
                                            value={weight}
                                        />
                                        <button>gm</button>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add parentid" ref={mediumReff}>
                                    <label style={errors.medium && { color: "red" }}>Medium *</label>
                                    {/* <input
                                        type="text"
                                        value={otherDataJson.medium}
                                        onChange={(e) => { handleOnChange("medium", e.target.value); searchMedium(e.target.value) }}
                                        onClick={() => setShowMedium(!showMedium)}
                                    />
                                    {
                                        showMedium &&
                                        <div className="multiple-cat" ref={mediumRef}>
                                            {
                                                filterMedium.map((itm, index) => {
                                                    return (
                                                        <>
                                                            <div className="options" key={index}>
                                                                <li onClick={() => { enterMedium(itm) }}>
                                                                    <p>{itm}</p>
                                                                </li>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    } */}
                                    {/* <select value={otherDataJson.medium} onChange={(e) => handleOnChange("medium", e.target.value)}>
                                        <option value=""></option>
                                        {
                                            filterMedium.map((itm) => {
                                                return (
                                                    <>
                                                        <option value={itm.label}>{itm.label}</option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select> */}

                                    <Select
                                        // defaultValue={[filterMedium[0]]}
                                        closeMenuOnSelect={true}
                                        name="colors"
                                        options={selectMedium}
                                        value={getMedium}
                                        onChange={(e) => setGetMedium(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>

                                <div className="add parentid" ref={publisherRef}>
                                    <label style={errors.publisher && { color: "red" }}>Publisher *</label>
                                    {/* <input
                                        type="text"
                                        value={publisher}
                                        onChange={(e) => { searchPublisher(e) }}
                                        onClick={() => setShowPublisher(!showPublisher)}
                                    />
                                    {
                                        showPublisher &&
                                        <div className="multiple-cat" ref={publisherReff}>
                                            {
                                                filterPublisherList.map((itm, index) => {
                                                    return (
                                                        <>
                                                            <div className="options" key={index}>
                                                                <li onClick={() => { addPublisher(itm) }}>
                                                                    <p>{itm.label}</p>
                                                                </li>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    } */}
                                    {/* <select value={publisherUuid} onChange={(e) => setPublisherUuid(e.target.value)}>
                                        <option value=""></option>
                                        {
                                            filterPublisherList.map((itm) => {
                                                return (
                                                    <option value={itm.value}>{itm.label}</option>
                                                )
                                            })
                                        }
                                    </select> */}
                                    <Select
                                        // defaultValue={[temp[2], temp[3]]}
                                        closeMenuOnSelect={true}
                                        name="colors"
                                        options={filterPublisherList}
                                        value={publisherUuid}
                                        onChange={(e) => setPublisherUuid(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">

                                <div className="add ">
                                    <label>Author</label>
                                    <input
                                        type="text"
                                        value={otherDataJson.author}
                                        onChange={(e) => handleOnChange("author", e.target.value)}
                                    />
                                </div>

                                <div className="add ">
                                    <label>No. of Pages</label>
                                    <input
                                        type="number"
                                        value={otherDataJson.noOfPages}
                                        onChange={(e) => handleOnChange("noOfPages", e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add">
                                    <label>Publication Year</label>
                                    <input
                                        type="number"
                                        value={otherDataJson.publicationYear}
                                        onChange={(e) => handleOnChange("publicationYear", e.target.value)}
                                    />

                                </div>

                                <div className="add parentid" ref={taxRef}>
                                    <label style={errors.taxCat && { color: "red" }}>Tax Category *</label>
                                    {/* <input
                                        type="text"
                                        value={tax}
                                        onChange={(e) => { handleTex(e.target.value); searchTax(e) }}
                                        onClick={() => setShowTax(!showTax)}
                                    />
                                    {
                                        showTax &&
                                        <div className="multiple-cat" ref={taxReff}>
                                            {
                                                filtertaxList.map((itm, index) => {
                                                    return (
                                                        <>
                                                            <div className="options" key={index}>
                                                                <li onClick={() => { addTax(itm) }}>
                                                                    <p>{itm.label}</p>
                                                                </li>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    } */}

                                    {/* <select value={taxUuid} onChange={(e) => setTaxUuid(e.target.value)}>
                                        <option value=""></option>
                                        {
                                            filtertaxList.map((itm) => {
                                                return (
                                                    <option value={itm.key}>{itm.label}</option>
                                                )
                                            })
                                        }
                                    </select> */}
                                    <Select
                                        // defaultValue={taxUuid?.[0]}
                                        closeMenuOnSelect={true}
                                        name="colors"
                                        options={filtertaxList}
                                        value={taxUuid}
                                        onChange={(e) => setTaxUuid(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add parentid" ref={hsnCodeRef}>
                                    <label style={errors.hsnCode && { color: "red" }}>HSN Code *</label>
                                    <input
                                        type="number"
                                        value={otherDataJson.hsnCode}
                                        onChange={(e) => { handleOnChange("hsnCode", e.target.value); searchHsnCode(e.target.value) }}
                                        onClick={() => setShowHsnCode(!showHsnCode)}
                                    />
                                    {/* {
                                        showHsnCode &&
                                        <div className="multiple-cat" ref={hsnRef}>
                                            {
                                                filterHsnCode.map((itm, index) => {
                                                    return (
                                                        <>
                                                            <div className="options" key={index}>
                                                                <li onClick={() => { selectHsnCode(itm) }}>
                                                                    <p>{itm}</p>
                                                                </li>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    } */}
                                </div>
                            </div>

                            <div className="editer m-b2">
                                {/* Jodit Editor */}
                                <div className="add w-100">
                                    <label>Description</label>
                                    {/* <JoditEditor
                                        ref={editor}
                                        value={description}
                                        tabIndex={1}
                                        onChange={(newContent) => setDescription(newContent)}
                                    /> */}

                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    "heading",
                                                    "|",
                                                    "bold",
                                                    "italic",
                                                    "link",
                                                    "bulletedList",
                                                    "numberedList",
                                                    "|",
                                                    "indent",
                                                    "outdent",
                                                    "|",
                                                    "imageUpload",
                                                    "blockQuote",
                                                    "mediaEmbed",
                                                    "undo",
                                                    "redo",
                                                ],
                                            },
                                        }}
                                        data={description}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                        }}
                                    />

                                </div>
                            </div>

                            {/* Category Icon File Upload */}
                            <div className="add w-100">
                                <label>Item Image</label>
                                {/* Input Section */}
                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        multiple
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews?.length > 0 ?
                                            <label htmlFor="category-icon" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="custom-label">
                                                    <RiImageAddFill />
                                                    <span>Add more</span>
                                                </div>
                                            </label>
                                            :
                                            <label htmlFor="category-icon" className="custom-file-input" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="text">
                                                    <div className="icon">
                                                        <h1><IoCloudUploadOutline /></h1>
                                                        <h4>Add Images</h4>
                                                    </div>
                                                </div>
                                            </label>
                                    }

                                </div>

                                {/* Image Preview Section */}
                                {
                                    imagePreviews?.length > 0 && (
                                        <div className="image-preview-wrapper">
                                            {
                                                imagePreviews?.map((preview, index) => (
                                                    <div key={index} className="image-preview-container">
                                                        <img
                                                            src={preview}
                                                            alt={`Category Icon ${index + 1}`}
                                                            style={{ objectFit: 'cover' }}
                                                            onClick={() => maginifyImage(preview)}
                                                        />

                                                        {/* Delete Button */}
                                                        <button
                                                            type="button"
                                                            onClick={() => handleDeleteImage(index)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-5px',
                                                                left: '-5px',
                                                                background: 'red',
                                                                color: 'white',
                                                                border: 'none',
                                                                borderRadius: '50%',
                                                                width: '25px',
                                                                height: '25px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    )
                                }
                            </div>

                        </div>

                    </div>
                    <div className="btn submit-btn">
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </div>
            </div>

            {/* POPUP MODAL */}

            <SuccessPopUp
                isOpen={showPop}
                action={action}
                navv={navv}
                // FutherMore={more}
                onClose={onCloseBtn}
                onConfirm={confirmPopUp}
            />

            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />
        </>
    );
}

export { UpdateItem };


