import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";

function AddUpdatePublisher() {
    const navigate = useNavigate();

    const token = sessionStorage.getItem('tokenKey');

    // Array for storing image previews
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const location = useLocation();
    const id = location && location.state;

    console.log(id, "idd");

    const [title, setTitle] = useState(id?.title);

    const postPublisher = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/publisher/save`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({
                    title: title
                })
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setAction('your Publisher added successfully !');
                setShowPop(true);
                setNavv('ok');
                setMore('Add more');
            } else {
                setShowPop(true);
                setAction('something went wrong');
                setNavv('OK');
                setMore('cancel');
            }
        } catch (e) {
            console.log(e, 'error in publisher post api')
        }
    };

    const [showPop, setShowPop] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();

    const updatePublisher = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/publisher/update/${uuid}`, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-type": 'application/json'
                },
                body: JSON.stringify({
                    title: title
                })
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navToCat(-1);
            }
        } catch (e) {
            console.log(e, 'error in update publisher')
        }
    };

    const navToCat = useNavigate();
    const confirmPopUp = () => {
        if (navv === 'ok') {
            navToCat('/admin/publisher');
            setShowPop(false);
        } else if (navv === 'OK') {
            setShowPop(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    };

    const onCloseBtn = () => {
        if (more === 'Add more') {
            setTitle("")
            window.scrollTo({
                top: 0,
                behavior: 'smooth'  // Optional: Use 'auto' for instant scroll
            });
            setShowPop(false)
        } else if (more === 'cancel') {
            navToCat('/admin/publisher');
            setShowPop(false)
        }
    };

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>{id ? "Update Publisher" : "Add Publisher"}</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="add-form">
                        <div className="add w-100 m-b2">
                            <label>Publisher Name</label>
                            <input
                                type="text"
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={() => { id ? updatePublisher(id?.uuid) : postPublisher() }}>{id ? "Update" : "Save"}</button>
                    </div>

                </div>
            </div>

            <SuccessPopUp
                isOpen={showPop}
                action={action}
                navv={navv}
                FutherMore={more}
                onClose={onCloseBtn}
                onConfirm={confirmPopUp}
            />
        </>

    )
}
export { AddUpdatePublisher }