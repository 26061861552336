import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useNavigation } from 'react-router-dom';
import { RxCross1 } from "react-icons/rx";
import JoditEditor from 'jodit-react';


function AddModule() {

    const navigate = useNavigate();
    const editor = useRef(null);

    const token = sessionStorage.getItem('tokenKey');
    const BASE_URL = process.env.REACT_APP_PP_URL;

    const [modalList, setModalList] = useState([]);
    const [filterModalList, setFilterModalList] = useState([...modalList]);
    const [showModalList, setShowModalList] = useState(false);

    const [showMasterList, setShowMasterList] = useState(false);
    const refMaster = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!refMaster.current?.contains(event.target)) {
                setShowMasterList(false)
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [refMaster]);

    const getModalListApi = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/list-in-key-value`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setModalList(getRes.responsePacket);
                setFilterModalList(getRes.responsePacket);
            }

        } catch (e) {
            console.log(e, 'error in getModal list')
        }
    }

    useEffect(() => {
        getModalListApi();
    }, []);

    const handelSearchModalList = (e) => {
        const res = modalList.filter((itm) => itm.label.toLowerCase().includes(e.target.value.toLowerCase()));
        setFilterModalList(res);
        setModalName(e.target.value);
    };

    const selectModule = (itm) => {
        setModuleUuid(itm.key);
        setModalName(itm.label);
        setShowMasterList(false);
    };

    const [title, setTitle] = useState();
    const [showUrls, setShowUrls] = useState(false);
    const navToBack = useNavigate();

    const postMasterModule = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/save`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    title: title
                })
            })
            const getRes = await res.json();
            console.log(getRes, 'res from post model master')
            if (getRes.errorCode === 0) {
                setShowUrls(false);
                getModalListApi();
                alert(getRes.message);
                navToBack('/admin/managePage')
            }
        } catch (e) {
            console.log(e, 'error in posting marster api')
        }
    };

    const [showSuggestion, setShowSuggestion] = useState(false);
    const ref = useRef(null);
   
    const assessType = [
        {
            id: 1,
            name: "CREATE",
        },
        {
            id: 2,
            name: "READ",
        },
        {
            id: 3,
            name: "UPDATE",
        },
        {
            id: 4,
            name: "DELETE",
        },
    ]

    const [showMethods, setShowMethods] = useState(false);
    const methodRef = useRef();

    const methods = [
        {
            id: 1,
            name: "GET",
        },
        {
            id: 2,
            name: "PUT",
        },
        {
            id: 3,
            name: "POST",
        },
        {
            id: 4,
            name: "DELETE",
        },
        {
            id: 1,
            name: "OTHER",
        },
    ];

    const [forms, setForms] = useState([
        { title: "", description: '', methodType: "", url: "", accessType: "", showMethods: false, showSuggestion: false },
    ]);

    // Handle input changes
    const handleChange = (index, event) => {
        const updatedForms = [...forms];
        console.log(event, 'type of event');
        if (typeof event === "string") {
            updatedForms[index]["description"] = event;
            setForms(updatedForms);
        } else {
            const { name, value } = event.target;
            updatedForms[index][name] = value;
            setForms(updatedForms);
        }
    };

    const toggleDropdown = (index, dropdownType) => {
        const updatedForms = forms.map((form, i) => ({
            ...form,
            showMethods: dropdownType === 'method' && i === index ? !form.showMethods : form.showMethods,
            showSuggestion: dropdownType === 'accessType' && i === index ? !form.showSuggestion : form.showSuggestion,
        }));
        setForms(updatedForms);
    };

    // outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!methodRef?.current?.contains(event.target)) {
                const updatedForms = forms.map(form => ({
                    ...form,
                    showMethods: false,
                }));
                setForms(updatedForms);
            } else if (!ref?.current?.contains(event.target)) {
                const updatedForms = forms.map(form => ({
                    ...form,
                    showSuggestion: false,
                }));
                setForms(updatedForms);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [forms]);

    useEffect(() => {

        const handleClickOutside = (event) => {

            if (!ref?.current?.contains(event.target)) {
                const updatedForms = forms.map(form => ({
                    ...form,
                    showSuggestion: false,
                }));
                setForms(updatedForms);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [forms]);

    // Add a new form
    const handleAddForm = () => {
        setForms([
            ...forms,
            { title: "", description: "", methodType: "", url: "", accessType: "", showMethods: false, showSuggestion: false },
        ]);
    };

    const handleRemoveForm = (index) => {
        setForms(forms.filter((_, i) => i !== index));
    };

    const [moduleUuid, setModuleUuid] = useState();
    const [modalName, setModalName] = useState();

    // Submit the forms data
    const navv = useNavigate();

    const handleSubmit = async () => {

        const sanitizedForms = forms.map(({ showMethods, showSuggestion, ...rest }) => rest);
        const apiUrl = `${BASE_URL}/admin/v1/module/${moduleUuid}/save-module-urls`; // Replace with your actual API endpoint

        console.log(sanitizedForms, 'senitizated form')
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(sanitizedForms),

            });

            const result = await response.json();
            if (result.errorCode === 0) {
                console.log("Data posted successfully:", result);
                navv('/admin/managePage')
            } else {
                console.error("Failed to post data:", response.status);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };


    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Add Modules</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="add-form">
                        <div className="add w-100 m-b2">
                            <label>Module Title</label>
                            <input
                                type="text"
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={postMasterModule}>Save Modal</button>
                    </div>

                    {/* <div className="main-container">

                        <div className="add-form">
                            <>
                                <div className="add w-100 m-b2 parentid">
                                    <label>Select Modal</label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={modalName}
                                        onChange={(e) => handelSearchModalList(e)}
                                        onFocus={() => setShowMasterList(true)}
                                    />
                                    {
                                        showMasterList && <div className="multiple-cat" ref={refMaster}>
                                            {
                                                filterModalList.map((itm) => {
                                                    return (
                                                        <>
                                                            <div className="options">
                                                                <li onClick={() => { selectModule(itm) }}>

                                                                    <p>{itm.label}</p>
                                                                </li>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>


                                {
                                    forms.map((form, index) => {
                                        return (
                                            <>
                                                {index !== 0 && <div className="add-field">
                                                    <button onClick={() => handleRemoveForm(index)}>- Remove</button>
                                                </div>}
                                                <div className="d-flex w-100 m-b2 d-other">
                                                    <div className="add parentid">
                                                        <label>Select Title</label>
                                                        <input
                                                            type="text"
                                                            name='title'
                                                            value={form.title}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />

                                                    </div>
                                                    <div className="add parentid">
                                                        <label>Method</label>
                                                        <input
                                                            type="text"
                                                            name='methodType'
                                                            value={form.methodType}
                                                            onChange={(event) => handleChange(index, event)}
                                                            onFocus={() => toggleDropdown(index, 'method')}
                                                        />
                                                        {
                                                            form.showMethods && <div className="multiple-cat" ref={methodRef}>
                                                                {
                                                                    methods.map((itm) => {
                                                                        return (
                                                                            <>
                                                                                <div className="options">
                                                                                    <li onClick={() => { handleChange(index, { target: { name: 'methodType', value: itm.name } }); toggleDropdown(index, 'method') }}>
                                                                                        <p>{itm.name}</p>
                                                                                    </li>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="d-flex w-100 m-b2 d-other">
                                                    <div className="add ">
                                                        <label>URL</label>
                                                        <input
                                                            type="text"
                                                            name='url'
                                                            value={form.url}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />


                                                    </div>
                                                    <div className="add parentid">
                                                        <label>Access Type</label>
                                                        <input
                                                            type="text"
                                                            name='accessType'
                                                            value={form.accessType}
                                                            onChange={(event) => handleChange(index, event)}
                                                            onFocus={() => toggleDropdown(index, 'accessType')}
                                                        />
                                                        {
                                                            form.showSuggestion && <div className="multiple-cat" ref={ref}>
                                                                {
                                                                    assessType.map((itm) => {
                                                                        return (
                                                                            <>
                                                                                <div className="options">
                                                                                    <li onClick={() => { handleChange(index, { target: { name: 'accessType', value: itm.name } }); toggleDropdown(index, 'accessType') }}>
                                                                                        <p>{itm.name}</p>
                                                                                    </li>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </>


                            <div className="add-field">
                                <button onClick={handleAddForm}>+ More URL</button>
                            </div>
                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={handleSubmit}>Save Modal URL</button>
                    </div> */}


                </div>
            </div>
        </>
    )
}
export { AddModule }