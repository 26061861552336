import React, { useEffect, useState } from 'react';
import img from "../../../../../Assets/Image/book2.jpg";
import { GoChevronDown } from "react-icons/go";
import { useLocation } from 'react-router-dom';
import { HTMLToPlainText } from '../../../CommonComponents/HtmlToPlainText';
import { MagnifyImage } from '../../../CommonComponents/MagnifyImage';

function OrderDetail() {

  const id = useLocation();
  const objectId = id && id.state;
  console.log(objectId, 'idddddddddddddd')

  const BASE_URL = process.env.REACT_APP_PP_URL;
  const token = sessionStorage.getItem('tokenKey');

  const [info, setInfo] = useState();
  const [filter, setFilter] = useState([]);
  const detail = async () => {
    try {
      const res = await fetch(`${BASE_URL}/admin/v1/order/getOrderDetail/${objectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const getRes = await res.json();
      if (getRes.errorCode === 0) {
        console.log(getRes, 'qwerty');
        setInfo(getRes.responsePacket)
        setFilter([getRes.responsePacket])
      }
    } catch (e) {
      console.log(e, 'error in detail api of cart')
    }
  };

  useEffect(() => {
    detail();
  }, []);


  const restaurantDetail = { title: "Kaapi & More - IIT Delhi" };

  const orderedStatus = [
    { id: 1, status: "Saved", recordedAt: "31-03-2023 01:12 PM" },
    { id: 2, status: "Ordered", recordedAt: "31-03-2023 01:14 PM" },
    { id: 3, status: "Preparing", recordedAt: "31-03-2023 01:30 PM" },
    { id: 4, status: "Ready", recordedAt: "31-03-2023 01:34 PM" },
    { id: 5, status: "Delivered", recordedAt: "31-03-2023 01:37 PM" },
  ];

  // Image Magifinify
  const [imgUrl, setImgUrl] = useState();
  const [showImg, setShowImg] = useState(false);
  const maginifyImage = (e) => {
    setShowImg(true);
    setImgUrl(e);
  };

  return (
    <>
      <div className="order-detail">
        <div className="container">
          <div className="card">
            <h3 className="card-title">Book Store Detail</h3>
            <div className="row">
              <span className="label">Title</span>
              <span className="value">The Books24</span>
            </div>
          </div>

          {/* Customer Detail */}
          <div className="card">
            <h3 className="card-title">Customer Detail</h3>
            <div className="row">
              <span className="label">Full Name</span>
              <span className="value">{info?.customerDetail?.userCustomerFirstName}</span>
            </div>
            <div className="row">
              <span className="label">Mobile Number</span>
              <span className="value"> {info?.customerDetail?.userCustomerMobile}</span>
            </div>
            <div className="row">
              <span className="label">Email</span>
              <span className="value"></span>
            </div>
          </div>

          {/* Order Detail */}
          <div className="card">
            <h3 className="card-title">Order Detail</h3>
            <div className="row">
              <span className="label">Order Reference #</span>
              <span className="value">{info?.orderId}</span>
            </div>
            <div className="row">
              <span className="label">Order Date Time</span>
              <span className="value">12345678765</span>
            </div>
            <div className="row">
              <span className="label">Payment Type</span>
              <span className="value">{info?.paymentType}</span>
            </div>
            <div className="row">
              <span className="label">Payment Status</span>
              <span className="value">{info?.paymentStatus}</span>
            </div>
            <div className="row">
              <span className="label">Order Total</span>
              <span className="value">{info?.orderTotal}</span>
            </div>
            {/* <div className="row">
              <span className="label">Paid By Wallet</span>
              <span className="value">{orderDetail.wallet}</span>
            </div> */}
          </div>

          {/* Tip Detail */}
          {/* <div className="card">
          <h3 className="card-title">Tip</h3>
          <div className="row">
            <span className="label">Tip Amount</span>
            <span className="value">{tipDetail}</span>
          </div>
        </div> */}

          {/* Ordered Status */}
          <div className="card">
            <h3 className="card-title">Ordered Status</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Sr #</th>
                  <th>Status</th>
                  <th>Recorded At</th>
                </tr>
              </thead>
              <tbody>
                {orderedStatus.map((status) => (
                  <tr key={status.id}>
                    <td>{status.id}</td>
                    <td>{status.status}</td>
                    <td>{status.recordedAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
        <div className="ordered-item-container">
          <div className="card-b">
            <h3 className="card-title">Ordered Item Details</h3>
            <table className="item-table">
              <thead>
                <tr>
                  <th>Sr #</th>
                  <th>Title</th>
                  {/* <th>Customization</th> */}
                  {/* <th>Rating</th> */}
                  <th>Selling Price</th>
                  <th>Qty.</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>

                {info?.itemDetailList?.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <div className="img" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={item?.itemImageUrls?.[0]} alt="img" style={{ width: "40%", height: "100px", }} onClick={()=>maginifyImage(item?.itemImageUrls?.[0])}/>
                      </div>

                    </td>
                    <td>
                      {item.title} <span className="info-icon"></span>
                    </td>
                    {/* <td>{item.customization}</td>
                    <td>{item.rating}</td> */}
                    <td>{item.sellingPrice}</td>
                    <td>{item.quantity}</td>
                    <td>{item.sellingPrice * item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Order Summary */}
            <div className="summary">
              <div className="summary-row">
                <span>Item Total</span>
                <span>{info?.subTotal}</span>
              </div>
              <div className="summary-row">
                <span>Coupon Discount</span>
                <span className="negative">{info?.couponDiscountAmount}</span>
              </div>
              <div className="summary-row">
                <span>Delivery Charges</span>
                <div>
                  <div>{info?.deliveryCharges}</div>
                </div>
              </div>
              <div className="summary-row">
                <span>Packing Charges</span>
                <div>
                  <div>{info?.packingCharges}</div>
                </div>
              </div>
              <div className="summary-row total">
                <span>Order Total</span>
                <span>{info?.orderTotal}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />
    </>
  )
}
export { OrderDetail }