import React from "react";

function SuccessPopUp({ isOpen, action, navv, FutherMore, onConfirm, onClose, moreBtn, onMoreBtn }) {

    if (!isOpen) {
        return null
    }

    return (
        <>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>{action}</h3>
                    <div className="modal-actions">
                        {navv && <button className="confirm-btn bg-bl-cw" onClick={onConfirm} >{navv}</button>}
                        {moreBtn && <button className="confirm-btn bg-bl-cw" onClick={onMoreBtn} >{moreBtn}</button>}
                        {FutherMore && <button className="cancel-btn" onClick={onClose}>{FutherMore}</button>}
                    </div>
                </div>
            </div>
        </>
    )
}
export { SuccessPopUp }