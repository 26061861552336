import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { UpdateBanner } from '../BannerChild/UpdateBanner';

function UpdateBannerParent() {
    return (
        <>
            <MergeComponents getComponent={<UpdateBanner />} />
        </>
    )
}
export { UpdateBannerParent }