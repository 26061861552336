import React from 'react';
import { RiInstagramFill } from "react-icons/ri";
import { FaTwitter, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import img1 from "../../../Assets/Image/footer-img1.png"
import img2 from "../../../Assets/Image/footer-img2.png"
import img3 from "../../../Assets/Image/footer-img3.png"
import img4 from "../../../Assets/Image/footer-img4.png"
import img5 from "../../../Assets/Image/footer-img5.png"
import img6 from "../../../Assets/Image/footer-img6.png"
import logo from "../../../Assets/Image/Books24-Logo.webp"

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-links">
                    <div className="footer-column section">

                        <div className="footer-logo">
                            <img src={logo} alt="Syam Book" />
                        </div>
                        <p className="footer-p">
                            Follow us Social Media
                        </p>
                        <hr />
                        <div className="footer-social-media">

                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={{
                                color: "blue",
                                textDecoration: "none",
                            }}>
                                <FaFacebookF />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={{color:"skyblue"}}>
                                <FaTwitter />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{color: "#d62976",}}>
                                <RiInstagramFill />
                            </a>


                        </div>
                        {/* <div className="footer-social-media">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <FaTwitter />
                            </a>
                            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                                <FaLinkedinIn />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <RiInstagramFill />
                            </a>
                        </div> */}
                    </div>
                    <div className="footer-column">
                        <h3>About</h3>
                        <ul>
                            <li>About Us</li>
                            <li>Contact Us</li>

                            <li>Privacy Policy</li>
                            <li>Terms & Conditions</li>

                        </ul>
                    </div>

                    <div className="footer-column">
                        <h3>Customer Service</h3>
                        <ul className='ul'>
                            <li>Track Order</li>

                            <li>Refund Policy</li>
                            <li>Shipping Policy</li>
                            <li>Cancellation Policy</li>

                            <li>Return & Replacement</li>
                        </ul>
                    </div>

                    <div className="footer-column">
                        <h3>Support</h3>
                        <p>
                            <span role="img" aria-label="phone">📞</span> Call: 9782938018
                        </p>
                        <p>
                            <span role="img" aria-label="email">✉️</span> Email: Thebooks24india@gmail.com 
                        </p>
                        <div className="adress">
                            {/* <h3><IoLocation /></h3> */}
                            <p>

                                <strong>Address:-</strong>Shri shyam book depot,swadisht bakery ke pass
main kanta churaha, kharini phatak road, jhotwara
Jaipur, Rajasthan, 302012
                            </p>
                        </div>


                    </div>
                </div>
            </div>
            <hr />

            <a
                href="https://wa.me/9782938018" // Add your WhatsApp number here
                target="_blank"
                rel="noopener noreferrer"
                className="fixed-whatsapp"
                aria-label="Chat with us on WhatsApp"
            >
                <FaWhatsapp />
            </a>
            <div className="footer-payment-methods">
                <img src={img1} alt="Mastercard" />
                <img src={img2} alt="Visa" />
                <img src={img3} alt="Net Banking" />
                <img src={img4} alt="Paytm" />
                <img src={img5} alt="Cash on Delivery" />
                <img src={img6} alt="Cash on Delivery" className='img' />
            </div>

            <div className="copy">
                <p>Copyright © 2024 . syambook.com. All Rights Reserved</p>
            </div>
        </div>
    );
}

export default Footer;
