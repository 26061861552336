import React from 'react';

const HTMLToPlainText = ({ htmlString }) => {
    const convertToPlainText = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    return (
        <div>
            {convertToPlainText(htmlString)}
        </div>
    );
};

export { HTMLToPlainText };
