import React, { useEffect, useState } from "react";
import img from "../../../../../../Assets/Image/logo.png"
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { profileData } from "../../../../../../Store/ProfileSlice";

function ProfileChild({ setPortName, setPortMobile }) {

    const dispatch= useDispatch();
    const BASE_URL = process.env.REACT_APP_PP_URL
    const key = localStorage.getItem('userId');

    const data = useSelector((state)=>state.profileData.data);

    const [cutData, setCutData] = useState();

    const customer = async () => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/get`, {
                headers: {
                    'Authorization': `Bearer ${key}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setCutData(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in customer')
        }
    };

    useEffect(() => {
        // customer();
        dispatch(profileData());
        // setCutData(data?.responsePacket);
    }, []);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [photoImageUrl, setPhotoImageUrl] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setemail] = useState('');

    useEffect(() => {
        if (data) {
            setFirstName(data?.responsePacket?.firstName || ""); // Provide a fallback to avoid errors
            setLastName(data?.responsePacket?.lastName || "");
            // setemail(data?.responsePacket?.email || "")
            setPhotoImageUrl(data?.responsePacket?.photoImageUrl || "");
            setMobile(data?.responsePacket?.mobile || "");
            setPortName(data?.responsePacket?.firstName);
            setPortMobile(data?.responsePacket?.mobile);
        }
    }, [data]);

    // console.log(cutData, 'cutdatt',photoImageUrl);
    const [editTrue, setEditTrue] = useState(true);

    const handleEdit = () => {
        setEditTrue(!editTrue);
    };

    const updateUser = async () => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/update-profile`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${key}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    photoImageUrl: photoImageUrl
                })
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // customer();
                dispatch(profileData());
                alert(getRes.message);
                setEditTrue(true);
            }
        } catch (e) {
            console.log(e, 'error in update customer')
        }
    };

    const uploadImg = async (e) => {
        try {
            const file = e.target.files[0]; // Get the selected file
            console.log(file, 'file');
            // Create FormData instance
            const formData = new FormData();
            formData.append("file", file);
            const res = await fetch(`${BASE_URL}/v1/file/upload`, {
                method: 'POST',
                body: formData
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setPhotoImageUrl(getRes.responsePacket)
                console.log('update');
            }
        } catch (e) {
            console.log(e, 'error in image');
        }
    };


    return (
        <>
            <div className="profile-main">

                <div className="profile-row">
                    <div className="profile-img">
                        <div className="profile-img-b">
                            <div className="img">
                                <img src={photoImageUrl ? photoImageUrl : img} alt="profile" />
                            </div>
                           {!editTrue && <span className="edit-icon">
                                <input type="file" id="p-img" style={{ display: "none" }} onChange={uploadImg} />
                                <label htmlFor="p-img"><HiOutlinePencilSquare /></label>
                            </span>}
                        </div>
                    </div>

                    <div className="txt">
                        <h3>{data?.responsePacket?.firstName} {data?.responsePacket?.lastName}</h3>
                    </div>

                    <div className="profile-form">

                        <div className="form-container">

                            <div className="form-a">
                                {key &&
                                    <div className="btn">
                                        {editTrue ? (
                                            <button onClick={handleEdit}>Edit</button>
                                        ) : (
                                            <button onClick={handleEdit}>Cancel</button>
                                        )}
                                    </div>
                                }
                                <form className="user-form" onSubmit={(e) => {
                                    e.preventDefault();
                                }}>
                                    <div className="form-row">
                                        <div className="form-field">
                                            <label htmlFor="name">Name*</label>
                                            <input type="text" id="name" placeholder="Enter your name " value={firstName} onChange={(e) => setFirstName(e.target.value)} disabled={editTrue} />
                                        </div>
                                        <div className="form-field">
                                            <label htmlFor="last">Last Name</label>
                                            <input type="text" id="last" placeholder="Enter your last name" value={lastName} onChange={(e) => setLastName(e.target.value)} disabled={editTrue} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-field">
                                            <label htmlFor="email">Email*</label>
                                            <input type="email" id="email" placeholder="Enter your Email" value={email} onChange={(e) => setLastName(e.target.value)} disabled={editTrue}  />
                                        </div>
                                        <div className="form-field">
                                            <label htmlFor="mobile">Mobile No.</label>
                                            <input type="number" id="mobile" placeholder="Mobile No." value={mobile} onChange={(e) => setMobile(e.target.value)} disabled={editTrue} />
                                        </div>
                                    </div>
                                    <div className="form-submit">
                                        {!editTrue && <button onClick={() => updateUser()}>Submit</button>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export { ProfileChild }