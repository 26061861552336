import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { AddMember } from "../AddStaff";

function AddStaffParent() {
    return (
        <>
            <MergeComponents getComponent={<AddMember />} />
        </>
    )
};
export { AddStaffParent }