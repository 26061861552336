import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RxCross1 } from "react-icons/rx";


function AssignUrl() {
    let navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');
    const location = useLocation();
    const staff = location?.state;
    console.log(staff, 'staff');

    const [urlList, setUrlList] = useState([]);
    const [filterList, setFilterList] = useState([...urlList]);

    const urls = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/module-url-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setUrlList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            };
        } catch (e) {
            console.log(e, 'error in uls list of assign')
        }
    };

    const assignedUrl = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/staff/get-assign-url/${uuid}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                console.log(getRes, 'getres ffffffffff')
                setUrlIds(getRes.responsePacket.map((itm)=>itm.uuid))
            }
        } catch (e) {
            console.log(e, 'error in assigned url api')
        }
    };

    useEffect(() => {
        urls();
        assignedUrl(staff?.uuid);
    }, []);

    const [selectedOption,setSelectedOption] = useState('');

    const [urlIds,setUrlIds] = useState([]);

    const navBack = useNavigate()
    const assignStaff=async()=>{
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/staff/assign-url/${staff.uuid}`,{
                method:"PUT",
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${token}`
                },
                body:JSON.stringify(urlIds) 
            });
            const getRes =await res.json();
            if(getRes.errorCode === 0){
                alert('urls assign successfully');
                navBack(-1);
            }
        } catch (w) {
            console.log(w,'error in assign url to staff')
        }
    };


    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (id) => {
        setUrlIds((prevIds) => 
            prevIds.includes(id)
                ? prevIds.filter((urlId) => urlId !== id) // Remove id if it's already in the list
                : [...prevIds, id] // Add id if it's not in the list
        );
    };

    console.log(urlIds,'urlIdsss');

    const handleRadioChange = (option, item) => {
        setSelectedOption((prevOptions) => ({
            ...prevOptions,
            [item.title]: option
        }));

        if (option === 'full-access') {
            const itemUrlIds = item.urlList.map(itm => itm.uuid);
            setUrlIds((prevIds) => Array.from(new Set([...prevIds, ...itemUrlIds])));
        } else if(option === 'Choose') {
            // If not full access, remove all checkboxes of the item from urlIds
            const itemUrlIds = item.urlList.map(itm => itm.uuid);
            setUrlIds((prevIds) => prevIds.filter((id) => !itemUrlIds.includes(id)));
        }
    };


    return (
        <>
            <div className="add-item">

                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>{staff.firstName} {staff.lastName}</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div style={{ width: "49%" }}>
                            {
                                filterList?.map((item) => {
                                    return (
                                        <>
                                            <div className="add-form" style={{ width: "100%" }}>
                                                <div className="add w-100">
                                                    <h3>{item.title}</h3>
                                                    <div className='d-flex'>
                                                        {/* <div className="d-flex m-r2">
                                                            <input type="radio" name={`access-${item.title}`} id="Choose" onChange={()=>handleRadioChange('Choose',item)} checked={selectedOption[item.title] === 'choose'}/><label htmlFor="Choose">Choose</label>
                                                        </div>
                                                        <div className="d-flex">
                                                            <input type="radio" name={`access-${item.title}`} id="full-access" onChange={()=>handleRadioChange('full-access',item)} checked={selectedOption[item.title] === 'full-access'} /><label htmlFor="full-access">Full Access</label>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="url-list">
                                                    {
                                                        item?.urlList?.map((itm) => {
                                                            return (
                                                                <>
                                                                    <li className='url-li d-flex'>
                                                                        <input type="checkbox"  id={itm.uuid}  onChange={() => handleCheckboxChange(itm.uuid)} checked={urlIds.includes(itm.uuid)} />
                                                                        <label htmlFor={itm.uuid}>{itm.title}</label>
                                                                    </li>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        <div className="submit-btnnn">
                        <button onClick={assignStaff}>Save Urls</button>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export { AssignUrl }