import React, { useState } from "react";

function ChangePasswordChild() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [requirements, setRequirements] = useState({
    uppercase: false,
    number: false,
    length: false,
  });

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    setRequirements({
      uppercase: /[A-Z]/.test(value),
      number: /[0-9]/.test(value),
      length: value.length >= 8,
    });
  };

  const isFormValid =
    requirements.uppercase && requirements.number && requirements.length;
  return (
    <>
      <div className="change-password-container">

        <div className="form">
          <div className="input-group">
            <h2>Change your password</h2>
            <label>New Password</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className={isFormValid ? "valid" : ""}
            />
            {isFormValid && <span className="checkmark">✔</span>}
          </div>

          <div className="input-group">
            <label>Confirm New Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <button className="change-password-button" disabled={!isFormValid}>
            Change my password
          </button>
        </div>

        <div className="password-requirements">
          <h4>Password must contain:</h4>
          <ul>
            <li className={requirements.uppercase ? "met" : ""}>
              At least 1 upper case letter (A-Z)
            </li>
            <li className={requirements.number ? "met" : ""}>
              At least 1 number (0-9)
            </li>
            <li className={requirements.length ? "met" : ""}>
              At least 8 characters
            </li>
          </ul>
        </div>
      </div>

    </>
  )
}
export { ChangePasswordChild }