const uploadImage = async (imageFile) => {
  const formData = new FormData();
  formData.append('file', imageFile);

  const token = sessionStorage.getItem('tokenKey');
  const BASE_URL = process.env.REACT_APP_PP_URL;
  try {
    const response = await fetch(`${BASE_URL}/v1/file/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    });
    // console.log('Response Type:', response.headers.get('Content-Type'));
    const data = await response.json();
    if (data.errorCode === 0) {
      console.log(data, 'response from file upload');
      return data;
    } else {
      console.log(data.message, 'error in res of image  upload')
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    return null;
  }
};
export { uploadImage }
