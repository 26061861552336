import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RxCross1 } from "react-icons/rx";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdTextIncrease } from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { SuccessPopUp } from '../../../CommonComponents/SuccessPopUp';

function AddField() {

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');

    const navigate = useNavigate();

    const [showSuggestion, setShowSuggestion] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setShowSuggestion(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    const disType = [
        { title: 'date', icon: <BsFillCalendarDateFill /> },
        { title: 'checkbox', icon: <IoMdCheckboxOutline /> },
        { title: 'text', icon: <MdTextIncrease /> },
        // {title:'',icon:''}
    ];
    const [fillDis, setFillDis] = useState([...disType]);
    const [type, setType] = useState();
    const [title, setTitle] = useState();
    const [symbol, setSymbole] = useState();

    const fillDisType = (e) => {
        const get = e
        setType(e);
        const fill = disType.filter((itm) => itm.title.toLowerCase().includes(get.toLowerCase()))
        setFillDis(fill);
    };

    const addValue = (e) => {
        setShowSuggestion(false);
        setType(e.title);
        setSymbole(e.icon);
    };

    const [showPop, setShowPop] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();
    const navBack = useNavigate();

    const confirmPopUp = () => {
        if (navv === 'Add More') {
            setTitle('');
            setType('');
            setShowPop(false);
        } else if (navv === 'Ok') {
            setShowPop(false);
            navBack('/admin/otherField');
        }
    };

    const onCloseBtn = () => {
        if (more === 'cancel') {
            navBack('/admin/otherField');
            setShowPop(false);
        };
    };

    const addField = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/dynamicField/save`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    title: title,
                    type: type
                })
            });

            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setAction(`Field Added Successfully`);
                setShowPop(true);
                setNavv(`Add More`);
                setMore(`cancel`);
            }

        } catch (e) {
            setAction(`something went wrong !`);
            setShowPop(true);
            setNavv('Ok');
            setMore(`cancel`)
        }
    };


    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Add Field</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form">

                            {/* NAME*/}
                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add ">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </div>

                                <div className="add parentid">
                                    <label>Type</label>
                                    <div className="btn-AddCat">

                                        <input
                                            type="text"
                                            value={type}
                                            onChange={(e) => { fillDisType(e.target.value) }}
                                            onFocus={() => setShowSuggestion(true)}
                                        />
                                        {type?.length > 3 && fillDis?.length > 0 && <button>{symbol}</button>}
                                    </div>
                                    {
                                        showSuggestion && <div className="multiple-cat" ref={ref}>
                                            {
                                                fillDis.map((itm) => {
                                                    return (
                                                        <>
                                                            <div className="options">
                                                                <li onClick={() => { addValue(itm) }}>
                                                                    <span>{itm.icon}</span>
                                                                    <p>{itm.title}</p>
                                                                </li>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={addField}>Submit</button>
                    </div>
                </div>
            </div>

            <SuccessPopUp
                isOpen={showPop}
                action={action}
                navv={navv}
                FutherMore={more}
                onClose={onCloseBtn}
                onConfirm={confirmPopUp}
            />
        </>
    )
}
export { AddField }