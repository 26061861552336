import React, { createContext, useState, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { DashboardParent } from "../Components/Admin/ScreenComponents/Dashboard/DashboardParent/DashboardParent";
import { Home } from "../Components/User/ScreenComponents/Home";
import { CategoryParent } from "../Components/Admin/ScreenComponents/Category/CategoryParent/CategoryParent";
import { ProfileParent } from "../Components/Admin/ScreenComponents/Profile/ProfileParent/ProfileParent";
import { ItemsParents } from "../Components/Admin/ScreenComponents/Items/ItemsParent/ItemsParents";
import { AddcategoryParent } from "../Components/Admin/ScreenComponents/Category/CategoryParent/AddcategoryParent";
import { AdditemParent } from "../Components/Admin/ScreenComponents/Items/ItemsParent/AdditemParent";
import { OrderParent } from "../Components/Admin/ScreenComponents/Order/OrderParent/OrderParent";
import ScrollToTop from "../Components/Admin/CommonComponents/ScrollToTop";
import { Login } from "../Components/Admin/Login/Login";
import { Authentication } from "../Components/Admin/Auth/Authentication";
import { UpdateCatParent } from "../Components/Admin/ScreenComponents/Category/CategoryParent/UpdateCatParent";
import { CollageBooksCollection1 } from "../Components/User/ScreenComponents/BooksCollection/CollageBooks/CollageBookCollection1";
import { StaffMain } from "../Components/Admin/Login/Staff/StaffMain";
import { AddStaffParent } from "../Components/Admin/Login/Staff/StaffParent/AddStaffParent";
import { UpdateItemParent } from "../Components/Admin/ScreenComponents/Items/ItemsParent/UpdateItemParent";
import { UpdateStaffParent } from "../Components/Admin/Login/Staff/StaffParent/UpdateStaffParent";
import { DisplayCatParent } from "../Components/Admin/ScreenComponents/DisplayCategory/DisplayCateParent/DisplayCatMain";
import { AddDisplayCatePatrent } from "../Components/Admin/ScreenComponents/DisplayCategory/DisplayCateParent/AddDisplayCatParent";
import { UpdateDisplayParent } from "../Components/Admin/ScreenComponents/DisplayCategory/DisplayCateParent/UpdateDisplayParent";
import { GiftMain } from "../Components/Admin/ScreenComponents/GiftCard/GiftCardParent/GiftMain";
import { AddCouponParent } from "../Components/Admin/ScreenComponents/GiftCard/GiftCardParent/AddCouponParent";
import { BannerParent } from "../Components/Admin/ScreenComponents/Banner/BannerParent/BannerParent";
import { AddBannerParent } from "../Components/Admin/ScreenComponents/Banner/BannerParent/AddBannerParent";
import { UpdateBannerParent } from "../Components/Admin/ScreenComponents/Banner/BannerParent/UpdateBannerParent";
import { UpdateCouponParent } from "../Components/Admin/ScreenComponents/GiftCard/GiftCardParent/UpdateCouponParent";
import { AccountProfileParent } from "../Components/User/ScreenComponents/AccountSection/Profile/ProfileParent/ProfileParent";
import { OtherFieldParent } from "../Components/Admin/ScreenComponents/OtherFields/OtherFieldParent/OtherFieldParent";
import { AddFieldParent } from "../Components/Admin/ScreenComponents/OtherFields/OtherFieldParent/AddFieldParent";
import { MyorderParent } from "../Components/User/ScreenComponents/AccountSection/MyOrder/MyorderParent/Myorderparent";
import { AddaddressParent } from "../Components/User/ScreenComponents/AccountSection/AddAddress/AddadressParent/AddaddressParent";
import { OrderDetailParent } from "../Components/User/ScreenComponents/AccountSection/OrderDetail/OrderDetailParent/OrderDetailParent";
import { ManagePageParent } from "../Components/Admin/ScreenComponents/ManageStaffPage/ManagePageParent/ManagePageParent";
import { AddModuleParent } from "../Components/Admin/ScreenComponents/ManageStaffPage/ManagePageParent/AddModuleParent";
import { UpdatePageParent } from "../Components/Admin/ScreenComponents/ManageStaffPage/ManagePageParent/UpdatePageParent";
import { AssignUrlParent } from "../Components/Admin/ScreenComponents/ManageStaffPage/ManagePageParent/AssignUrlParent";
import { AddressDetailParent } from "../Components/User/ScreenComponents/AccountSection/AddresDetail/AddressDetailParent/AddressDetailParent";
import { PageDetail } from "../Components/User/CommonComponents/PageDetail";
import { SettingParent } from "../Components/Admin/ScreenComponents/Setting/SettingParent/SettingParent";
import { CmsParent } from "../Components/Admin/ScreenComponents/Cms/CmsParent/CmsParent";
import { UpdateCmsParent } from "../Components/Admin/ScreenComponents/Cms/CmsParent/UpdateCmsParent";
import { UpdateFieldParent } from "../Components/Admin/ScreenComponents/OtherFields/OtherFieldParent/UpdateFieldParent";
import { CartParent } from "../Components/Admin/ScreenComponents/Cart/CartParent/CartParent";
import { CustomerParent } from "../Components/Admin/ScreenComponents/Customer/CustomerParent/CustomerParent";
import { ChangePasswordParent } from "../Components/User/ScreenComponents/AccountSection/ChangePassword/ChangePasswordParent/ChangePasswordParent";
import { CartDetailParent } from "../Components/Admin/ScreenComponents/Cart/CartParent/CartDetailParent";
import { OrderDetailParents } from "../Components/Admin/ScreenComponents/Order/OrderParent/OrderdetailParent";
import { PageDetail2 } from "../Components/User/CommonComponents/PageDetail2";
import { NotifymeParent } from "../Components/Admin/ScreenComponents/Notifyme/NotifymeParent/NotifymeParent";
import { Loading } from "../Components/User/CommonComponents/Loading";
import OrderPrint from "../Components/Admin/ScreenComponents/Order/OrderChild/OrdersPrint";
import { AdressForm } from "../Components/User/CommonComponents/Cart/AdressForm";
import { PublisherParent } from "../Components/Admin/ScreenComponents/Publisher/PublisherParent/PublisherParent";
import { AddPublisherPatrent } from "../Components/Admin/ScreenComponents/Publisher/PublisherParent/AddPublisherParent";
import { SearchDetail } from "../Components/User/CommonComponents/SearchDetail";


const RoleContext = createContext();
export const useRole = () => useContext(RoleContext);


function Routing() {

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');

    const [access, setAccess] = useState();
    const [userPermissions, setUserPermission] = useState([]);
    const [loading, setLoading] = useState(true);

    const getUrls = async () => {
        setLoading(true);
        try {
            const res = await fetch(`${BASE_URL}/ops/v1/common-user/get-assign-urls`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setAccess(getRes);
                setUserPermission(getRes?.responsePacket?.map(itm => itm.url));
            };
        } catch (e) {
            console.log(e, 'error in get url api')
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getUrls()
    }, []);


    const routesPages = [
        // { path: 'dash', component: DashboardParent, module: 'dashboard', },
        { path: 'item', component: ItemsParents, module: 'item', },
        { path: 'UpdateItem/:id', component: UpdateItemParent, module: 'item', },
        { path: 'additemparent', component: AdditemParent, module: 'item', url: 'admin/v1/item/save' },
        { path: 'category', component: CategoryParent, module: 'category', },
        { path: 'addcategory', component: AddcategoryParent, module: 'category', url: 'admin/v1/category/save' },
        { path: 'Updatecategory/:id', component: UpdateCatParent, module: 'category', },
        { path: 'order', component: OrderParent, module: 'order', },
        { path: 'orderDetail', component: OrderDetailParents, module: 'order', },
        { path: 'staff', component: StaffMain, module: 'staff', },
        { path: 'member', component: AddStaffParent, module: 'staff', url: 'admin/v1/staff/save' },
        { path: 'updateStaff/:id', component: UpdateStaffParent, module: 'staff', },
        { path: 'displayCat', component: DisplayCatParent, module: "display_category" },
        { path: 'addDisplayCat', component: AddDisplayCatePatrent, module: "display_category", url: 'admin/v1/display-category/save' },
        { path: 'updateDisplay/:id', component: UpdateDisplayParent, module: "display_category" },
        { path: 'gift', component: GiftMain, module: 'coupon', },
        { path: 'addGift', component: AddCouponParent, module: 'coupon', url: 'admin/v1/couponCode/save' },
        { path: 'updateCoupon/:id', component: UpdateCouponParent, module: 'coupon' },
        { path: 'banner', component: BannerParent, module: 'banner', },
        { path: 'addBanner', component: AddBannerParent, module: 'banner', url: 'admin/v1/banner/create' },
        { path: 'updateBanner/:id', component: UpdateBannerParent, module: 'banner', },
        { path: 'otherField', component: OtherFieldParent, module: 'other_fields', },
        { path: 'addField', component: AddFieldParent, module: 'other_fields', },
        { path: 'updateField', component: UpdateFieldParent, module: 'other_fields', },
        { path: 'managePage', component: ManagePageParent, module: 'manage_pages', },
        { path: 'addmanage', component: AddModuleParent, module: 'manage_pages', url: 'admin/v1/module/save' },
        { path: 'updateModal/:id', component: UpdatePageParent, module: 'manage_pages', },
        { path: 'assignUrl', component: AssignUrlParent, module: 'staff', url: "admin/v1/staff/assign-url" },
        { path: 'setting', component: SettingParent, module: 'setting', },
        { path: 'cms', component: CmsParent, module: 'cms' },
        { path: 'updateCms', component: UpdateCmsParent, module: 'cms', url: "admin/v1/website-cms" },
        { path: 'cart', component: CartParent, module: 'cart' },
        { path: 'cartDetail', component: CartDetailParent, module: 'cart' },
        { path: 'customer', component: CustomerParent, module: 'customer' },
        { path: 'notify', component: NotifymeParent, module: 'notify_me_items' },
        { path: 'print', component: OrderPrint, module: "order" },
        { path: "publisher", component: PublisherParent, module: "publisher" },
        { path: "addPublisher", component: AddPublisherPatrent, module: "publisher" },
    ];


    return (
        <>

            <RoleContext.Provider value={{ access, userPermissions }}>
                <ScrollToTop />

                <Routes>

                    <Route path="/login" element={<Login />} />

                    <Route path="/admin" element={<Authentication />}>

                        <Route path="dash" element={<DashboardParent />} />
                        {
                            routesPages.map((itm) => {
                                if (!itm.url || userPermissions?.includes(itm.url)) {
                                    return (
                                        access?.responsePacket?.some((item) => item.moduleDetail?.parentTitle === itm.module) &&
                                        <Route key={itm.path} path={itm.path} element={<itm.component />} />
                                    );
                                }
                            })
                        }

                    </Route>

                    <Route path="/" element={<Home />} />

                    <Route path='/collegebook/:id' element={<CollageBooksCollection1 />} />

                    <Route path="/pagedetail/:id" element={<PageDetail />} />

                    <Route path="/pagedetail2/:id" element={<PageDetail2 />} />

                    <Route path="*" element={loading ? <div className="loading-container" style={{ height: "100vh" }}>
                        <div className="spinner"></div>
                        <p>Loading...</p>
                    </div> : <div>page not found</div>} />

                    <Route path="/accountparentprofile" element={<AccountProfileParent />} />

                    <Route path="/accountparentmyorder" element={<MyorderParent />} />

                    <Route path="/accountparentaddaddress" element={<AddaddressParent />} />

                    <Route path="/accountparentoderdetail" element={<OrderDetailParent />} />

                    <Route path="/accountparentmyorder" element={<MyorderParent />} />

                    <Route path="/accountparentaddaddress" element={<AddaddressParent />} />

                    <Route path="/accountparentoderdetail" element={<OrderDetailParent />} />

                    <Route path="/accountparentaddressdetail" element={<AddressDetailParent />} />

                    <Route path="/changepassword" element={<ChangePasswordParent />} />

                    <Route path="/loading" element={<Loading />} />

                    <Route path="/addaddres" element={<AdressForm />} />

                    <Route path="/searchdetail" element={<SearchDetail />} />

                </Routes>

            </RoleContext.Provider>
        </>
    )
}
export { Routing }