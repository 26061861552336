import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import '../../../AdminStyle.css';
import { UpdateItem } from "../ItemsChild/UpdateItem";

function UpdateItemParent() {
    return (
        <>
            <MergeComponents getComponent={<UpdateItem />} />
        </>
    )
}
export { UpdateItemParent }