import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { IoAdd } from "react-icons/io5";

import { FaUser } from "react-icons/fa";


const NewAdressForm = ({ isOpen, onClose, filterList, setGetAddress, setShowAddingAddress, updateAddreshUuid, action }) => {
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const key = localStorage.getItem('userId');

    if (!isOpen) return null;

    const updateList = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/get/${uuid}`, {
                headers: {
                    'Authorization': `Bearer ${key}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // navToo('/accountparentaddressdetail',{state:getRes.responsePacket});
                updateAddreshUuid(getRes.responsePacket)
                setShowAddingAddress(true)
            }
        } catch (e) {
            console.log(e, 'error in getting address for update')
        }
    };



    return (
        <>
            <div className={`overlay ${isOpen ? "visible" : ""}`} onClick={onClose}></div>
            {
                <div className="new-address-form">
                    <div className="flex">
                        <div className="text" >
                            <div></div>
                            <h2>Select Delivery Address</h2>
                            <span onClick={onClose} style={{ fontSize: "20px" }}><RxCross1 /></span>
                        </div>
                    </div>
                    <hr />

                    <div className="btn" onClick={() => { setShowAddingAddress(true); action("") }}>
                        <h3><span><IoAdd /></span>Add a new Address</h3>
                    </div>

                    <div className="form-text">
                        {
                            filterList.map((itm) => {
                                return (
                                    <>
                                        <div className="form" >

                                            <div className="form-lite" >
                                                <div className="form-a" onClick={() => { setGetAddress(itm); onClose() }}>
                                                    <div className="icon">
                                                        <span><FaUser /></span>
                                                    </div>
                                                    <div className="inner-text" >
                                                        <h3>{itm.firstName} {itm.lastName}</h3>
                                                        <p>{itm.addressLine1}</p>
                                                        <p>{itm.mobileNumber}</p>
                                                    </div>
                                                </div>
                                                <div className="form-btn">
                                                    <button onClick={() => { updateList(itm.uuid); setShowAddingAddress(true); action("Edit") }}>Edit</button>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}
export { NewAdressForm }