import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { OrderDetail } from '../OrderChild/OrderDetail';

function OrderDetailParents() {
    return (
        <>
            <MergeComponents getComponent={<OrderDetail />} />
        </>
    )
}
export { OrderDetailParents }