import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";
import { uploadImage } from "../../../CommonComponents/ImageUpload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { MagnifyImage } from "../../../CommonComponents/MagnifyImage";

function Addcategory() {

    let navigate = useNavigate();
    const getToken = sessionStorage.getItem('tokenKey');
    const BASE_URL = process.env.REACT_APP_PP_URL;

    // POST CATEGORY VALUE
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [parentCategoryId, setParentCategoryId] = useState();
    const [imagePreviews, setImagePreviews] = useState([]); // Array for storing image previews

    const bodyData = {
        title: title,
        description: description,
        categoryIconUrl: imagePreviews.map(itm => itm.responsePacket).join(''),
        parentCategoryUuid: parentCategoryId?.value,
    };

    // POSTING CATEGORIES
    const PostData = async (btnId) => {
        try {
            const response = await fetch(`${BASE_URL}/admin/v1/category/save`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getToken}`, // Ensure getToken is returning a valid token
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyData) // Directly pass the FormData object
            });

            const getRes = await response.json();
            // Check if response is okay
            if (getRes.errorCode === 0) {

                if (btnId === "okNavv") {
                    navToCat('/admin/category');
                    setShow(false);
                } else if (btnId === "okAddMore") {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    setTitle('');
                    setDescription('');
                    setParentCategoryId('');
                    setImagePreviews([]);
                    setShow(false);
                }

            } else {
                setShow(true);
                setAction('something went wrong!')
                setNavv();
                setExtraBtn();
                setMore('Ok');
            };

        } catch (e) {
            console.log(e, 'error in category post api');
            setShow(true);
            setAction('server crashed!');
            setNavv();
            setExtraBtn();
            setMore('ok')
        };
    };

    // GETING CATEGORY LIST
    const [multiplecate, setMultiplecate] = useState([]);
    const [filterCategory, setFilterCategory] = useState([...multiplecate]);

    const PostCate = async () => {
        try {
            const response = await fetch(`${BASE_URL}/admin/v1/category/parent-category-list-in-key-value`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${getToken}`
                }
            });
            const getResponce = await response.json();
            if (getResponce.errorCode === 0) {
                setMultiplecate(getResponce?.responsePacket);
                setFilterCategory(getResponce?.responsePacket);
            }

        } catch (e) {
            console.log(e, 'post category parent list api')
        }
    };
    useEffect(() => {
        PostCate();
    }, []);

    const [cateInput, setCateInput] = useState('');

    const [show, setShow] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();
    const navToCat = useNavigate();
    const [extraBtn, setExtraBtn] = useState();

    const onMoreBtn = () => {
        setShow(false);
        PostData("okAddMore");
        setNavv();
    };

    const handleSuccesBtn = () => {
        setShow(false);
        PostData("okNavv")
    };

    const handleCencel = () => {
        setAction('');
        setExtraBtn();
        setMore('');
        setShow(false);
    };

    // HANDLE IMAGE SECTION
    const [loading, setLoading] = useState(false);

    const handleFileChange = async (e) => {
        setLoading(true);
        try {
            const files = Array.from(e.target.files);
            const uploadedUrls = await Promise.all(
                files.map(file => uploadImage(file))
            );
            setImagePreviews(uploadedUrls);
        } catch (e) {
            console.error("Error during image upload:", e);
        } finally {
            setLoading(false);
        }
        // Update state with all uploaded image URLs
    };


    const handleDeleteImage = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this image?");
        if (confirmDelete) {
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews
        }
    };

    const [errors, setErrors] = useState({});
    // Refs for scrolling
    const titleRef = useRef(null);
    const handleValidation = () => {
        let newErrors = {};
        // Check each field
        if (!title?.trim()) newErrors.title = "Title is required.";
        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }
        return true;
    };

    const scrollToField = (field) => {
        switch (field) {
            case "title":
                titleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        if (handleValidation()) {
            setNavv("ok");
            setAction("Confirm submit");
            setMore("cancel");
            setExtraBtn("Add More");
            setShow(true);
        }
    };

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Add Category</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form">
                            <div className="d-flex w-100 m-b2 d-other">
                                {/* Select Parent Category */}
                                <div className="add parentid">
                                    <label>Select Parent Category</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        name="colors"
                                        options={filterCategory}
                                        value={parentCategoryId}
                                        onChange={(e) => setParentCategoryId(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>

                                {/* Title Input */}
                                <div className="add" ref={titleRef}>
                                    <label style={errors.title && { color: "red" }}>Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        onChange={(e) => { e.preventDefault(); setTitle(e.target.value) }}
                                        value={title}
                                    />
                                </div>
                            </div>

                            <div className="editer m-b2">
                                {/* Jodit Editor */}
                                <div className="add w-100">
                                    <label>Description</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    "heading",
                                                    "|",
                                                    "bold",
                                                    "italic",
                                                    "link",
                                                    "bulletedList",
                                                    "numberedList",
                                                    "|",
                                                    "indent",
                                                    "outdent",
                                                    "|",
                                                    "imageUpload",
                                                    "blockQuote",
                                                    "mediaEmbed",
                                                    "undo",
                                                    "redo",
                                                ],
                                            },
                                        }}
                                        data={description}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Category Icon File Upload */}

                            <div className="add w-100">
                                <label>Category Icon</label>
                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews.length > 0 ?
                                            (loading ?
                                                <div className="loading-container">
                                                    <div className="spinner"></div>
                                                    <p>Loading...</p>
                                                </div>
                                                :
                                                <label htmlFor="category-icon" tabIndex="0"
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            document.getElementById("category-icon").click();
                                                        }
                                                    }}
                                                >
                                                    <div className="custom-label">
                                                        <RiImageAddFill />
                                                        <span>Change Image</span>
                                                    </div>
                                                </label>
                                            )
                                            :
                                            (
                                                loading ?
                                                    <div className="loading-container">
                                                        <div className="spinner"></div>
                                                        <p>Loading...</p>
                                                    </div>
                                                    :
                                                    <label htmlFor="category-icon" className="custom-file-input" tabIndex="0" onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            document.getElementById("category-icon").click();
                                                        }
                                                    }}>
                                                        <div className="text">
                                                            <div className="icon">
                                                                <h1><IoCloudUploadOutline /></h1>
                                                                <h4>Add Images</h4>
                                                            </div>
                                                        </div>
                                                    </label>)
                                    }

                                </div>

                                {/* Image Preview Section */}
                                {
                                    imagePreviews.length > 0 && <div className="image-preview-wrapper">
                                        {
                                            imagePreviews.map((preview, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img
                                                        src={preview.responsePacket}
                                                        alt={`Category Icon ${index + 1}`}
                                                        style={{ objectFit: 'cover' }}
                                                        onClick={() => maginifyImage(preview.responsePacket)}
                                                    />
                                                    {/* Delete Button */}
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteImage(index)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: '1px',
                                                            background: 'red',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>

            {/* show SUCCESS */}

            <SuccessPopUp
                isOpen={show}
                action={action}
                onConfirm={handleSuccesBtn}
                moreBtn={extraBtn}
                onMoreBtn={onMoreBtn}
                navv={navv}
                onClose={handleCencel}
                FutherMore={more}
            />

            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />

        </>
    );
}

export { Addcategory };