import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "./Navbar";
import Footer from "./Footer";
import { useNavigate, useParams } from "react-router-dom";
import { AddToCart, Getdata, itemQuantity, removeCartItem } from "../../../Utilities/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartCount, fetchHeaderData } from "../../../Store/apiSlice";
import { IoAdd } from "react-icons/io5";
import { RiSubtractLine } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import { Skeleton } from "../CommonComponents/Skeleton"; // Import Skeleton

function PageDetail() {
    const BASE_URL = process.env.REACT_APP_PP_URL;

    const [dataa, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Getdata(); // Call the imported function
                if (result) {
                    setData(result.responsePacket); // Save the fetched data
                }
            } catch (err) {
                console.log("An error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const { id } = useParams();
    const info = dataa?.displayCategoryList?.map((itm) => itm.displayCategoryItemList).flat().find((item) => item.uuid === id);
    const otherData = info?.otherDataJson ? JSON.parse(info?.otherDataJson) : {}
    console.log(info, "info");

    const handleThumbnailClick = (image) => {
        setMainImage(image);
    };

    const RenderHTML = ({ htmlContent }) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
    };

    const [mainImage, setMainImage] = useState(info?.itemImageUrls?.[0]);

    useEffect(() => {
        if (info?.itemImageUrls?.length) {
            setMainImage(info.itemImageUrls[0]); // Set the first image as the main image
        }
    }, [info]);

    const thumbnails = [
        info?.itemImageUrls?.map((itm) => itm)
    ];

    const dispatch = useDispatch();
    const data = useSelector((state) => state.header.data);

    const addCart = async (itemId) => {
        try {
            const result = await AddToCart(data?.responsePacket?.uuid, itemId);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.log(e, 'errron in add to cart api')
        }
    };

    const additems = async (itemId, quantity) => {
        try {
            const result = await itemQuantity(data?.responsePacket?.uuid, itemId, quantity);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.log(e, 'errron in add to cart api')
        }
    };

    const cartItemRemove = async (itemId) => {
        try {
            const result = await removeCartItem(data?.responsePacket?.uuid, itemId);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.log(e, 'error in remove item')
        }
    };

    const key = localStorage.getItem('userId')
    const [open, setOpen] = useState(false);
    const [mobile, setMobile] = useState();
    const [itemId, setItemId] = useState();

    const noti = async () => {
        try {
            const res = await fetch(`${BASE_URL}/guest-customer/v1/item-notify/save-notify`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${key}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    itemUuid: itemId,
                    customerIsdCode: 91,
                    customerMobile: mobile
                })
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setOpen2(true);
            };

        } catch (e) {
            console.log(e, 'error in notification');
        }
    };

    const handleNoti = (uuid) => {
        setOpen(true);
        setItemId(uuid);
    };

    const handleConfirm = () => {
        if (itemId && mobile.length === 10) {
            noti();
            setOpen(false);
            setItemId('');
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setItemId('');
    };

    const open2Ref = useRef(null);
    const [open2, setOpen2] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!open2Ref?.current?.contains(event.target)) {
                setOpen2(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [open2Ref]);

    return (
        <>
            <Navbar />
            <div className="page-heading">
                {loading ? <Skeleton className="skeleton-heading" style={{ height: '40px', width: '200px' }} /> :

                    <div className="page-head">
                        <div></div>
                        <h1>Item Detail</h1>
                        <h1 onClick={() => navigate(-1)}>
                            <RxCross1 />
                        </h1>
                    </div>

                }
            </div>
            <div className="book-details-container">

                <div className="book-image-section">
                    <div className="main-image">
                        {loading ? <Skeleton className="skeleton-image" style={{ height: '300px', width: '100%' }} /> : <img src={mainImage} alt="Main Book" />}
                    </div>
                    <div className="thumbnail-container">
                        {loading ? (
                            <Skeleton className="skeleton-thumbnail" style={{ height: '50px', width: '50px', margin: '5px' }} />
                        ) : (
                            thumbnails.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Thumbnail ${index + 1}`}
                                    className="thumbnail"
                                    onClick={() => handleThumbnailClick(image)}
                                />
                            ))
                        )}
                    </div>
                </div>

                <div className="product-details-section">
                    {loading ? (
                        <Skeleton className="skeleton-title" style={{ height: '20px', width: '200px' }} />
                    ) : (
                        <div className="title">
                            <h2>{info?.title}</h2>
                        </div>
                    )}
                    <div className="txt">
                        {loading ? (
                            <>
                                <Skeleton className="skeleton-price" style={{ height: '20px', width: '100px' }} />
                                <Skeleton className="skeleton-price-info" style={{ height: '15px', width: '150px' }} />
                            </>
                        ) : (
                            <div className="txt-b">

                                <h3 className="price">Price ₹{info?.sellingPrice}</h3>
                                <strike>
                                    <p className="price-info">₹{info?.mrp}</p>
                                </strike>
                            </div>
                        )}

                        {loading ? (
                            <Skeleton className="skeleton-button" style={{ height: '40px', width: '150px' }} />
                        ) : info?.stockOut ? (
                            <button onClick={() => handleNoti(info?.uuid)}>Notify Me</button>
                        ) : !data?.responsePacket?.itemDetailList?.some((itm) => itm.itemUuid === info?.uuid) ? (
                            <div className="add-to-cart-button" onClick={() => addCart(info?.uuid)}>
                                <button>Add To Cart</button>
                            </div>
                        ) : (
                            <div className="add-btn">
                                <span onClick={() => {
                                    const quantity = data?.responsePacket?.itemDetailList?.find((itm) => itm.itemUuid === info?.uuid)
                                    { quantity.quantity === 1 ? cartItemRemove(quantity.itemUuid) : additems(info?.uuid, quantity.quantity - 1) }
                                }}><RiSubtractLine /></span>
                                <p className="pp">{data?.responsePacket?.itemDetailList?.find((itm) => itm.itemUuid === info?.uuid).quantity}</p>
                                <span onClick={() => {
                                    const quantity = data?.responsePacket?.itemDetailList?.find((itm) => itm.itemUuid === info?.uuid).quantity
                                    additems(info?.uuid, quantity + 1)
                                }}><IoAdd /></span>
                            </div>
                        )}
                    </div>

                    <h4 className="section-title">
                        {loading ? <Skeleton className="skeleton-section-title" style={{ height: '20px', width: '150px' }} /> : 'Product Details'}
                    </h4>

                    {/* <h4>
                        {loading ? <Skeleton className="skeleton-title" style={{ height: '20px', width: '250px' }} /> : info?.title}
                    </h4> */}

                    <p className="detail">
                        {loading ? (
                            <Skeleton className="skeleton-detail" style={{ height: '20px', width: '120px' }} />
                        ) : (
                            <>
                                <strong>Type:</strong> Book
                            </>
                        )}
                    </p>

                    <p className="detail">
                        <strong>Key Features:</strong>
                        {loading ? (
                            <Skeleton className="skeleton-detail" style={{ height: '20px', width: '100%' }} />
                        ) : (
                            <RenderHTML htmlContent={info?.description} />
                        )}
                    </p>

                    {/* <p className="detail">
                        <strong>Description:</strong>
                        {loading ? (
                            <Skeleton className="skeleton-detail" style={{ height: '20px', width: '100%' }} />
                        ) : (
                            <RenderHTML htmlContent={info?.description} />
                        )}
                    </p> */}
                    <h4 className="section-title">Highlights</h4>
                    <ul className="highlights-list">
                        {otherData?.author && <li>Author:-  {otherData?.author}</li>}
                        {otherData?.noOfPages && <li>{otherData?.noOfPages} Pages</li>}
                        {otherData?.medium && <li>Language:-  {otherData?.medium}</li>}
                        {otherData?.publisher && <li>Publisher:-  {otherData?.publisher}</li>}
                    </ul>
                </div>
            </div>
            <Footer />
            {
                open &&
                <div className="modal-overlay-c" >
                    <div className="modal-content-c">
                        <h3>Please enter your number</h3>
                        <input type="number" placeholder="mobile" minLength={10} value={mobile} onChange={(e) => setMobile(e.target.value)} maxLength={10} />
                        <div className="modal-actions-c">
                            <button className="confirm-btn-c" onClick={handleConfirm}>Yes</button>
                            <button className="cancel-btn-c" onClick={handleCancel}>No</button>
                        </div>
                    </div>
                </div>
            }

            {
                open2 &&
                <div className="modal-overlay-c" ref={open2Ref}>
                    <div className="modal-content-c">
                        <h3>Your requested has submited successfully</h3>
                        <div className="modal-actions-c">
                            {/* <button className="confirm-btn-c" onClick={() => setOpen2(false)}>Yes</button> */}
                            <button className="cancel-btn-c" onClick={() => setOpen2(false)}>OK</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export { PageDetail };
