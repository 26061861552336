import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { Cart } from '../CartChild/Cart';

function CartParent() {
    return (
        <>
            <MergeComponents getComponent={<Cart />} />
        </>
    )
}
export { CartParent }