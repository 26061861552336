import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaFilter, FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../../CommonComponents/Popup';
import { FaCheckCircle } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { FaRedo } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { pro } from '../../../../../Store/ItemSlice';
import PaginationComponent from '../../pagination';
import { useRole } from '../../../../../Routing/Routing';
import { FaBoxOpen } from "react-icons/fa";
import { FaBox } from "react-icons/fa";
import Select from "react-select";
import { MagnifyImage } from '../../../CommonComponents/MagnifyImage';

function Items() {

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);

    const token = sessionStorage.getItem('tokenKey');
    const [action, setAction] = useState();
    const [popup, setPopup] = useState(false);

    // ITME LIST
    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);
    const [getID, setGetId] = useState();

    // ALL ITEMS LISTS
    const [type, setType] = useState('ACTIVE');

    const items = async (data) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/list-data/${data}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
                setSelectedItems([]);
                setIsSelectAll(false);
            }
            setInputSearch("");
            setCategoryValue(null);
            setPublisherValue(null);
        } catch (e) {
            console.log(e, 'error in itme list');
        }
    };

    useEffect(() => {
        items('Active');
    }, []);

    // ACIVE API AND INACTIVE API
    const activeItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/activate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in active itme api')
        }
    };

    // INACTIVE ITEMS
    const inactiveItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/inactivate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in inactive api of itme')
        }
    };

    // DELTED ITEMS FROM ITEMS
    const removeItems = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/delete/${uuid}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                        break;
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in delted itms api')
        }
    };

    // REVIVE ITEMS
    const revive = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/revive/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                        break;
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in revive api')
        }
    };

    // HANDEL REVIVE API
    const handelRevive = (uuid) => {
        setAction('RESTORE');
        setPopup(true);
        setGetId(uuid);
    };

    // HANDEL DELTED
    const handelDeleted = (uuid) => {
        setAction('DELETED');
        setGetId(uuid);
        setPopup(true);
    };

    // HANDEL ACTIVE AND INACTIVE SECTIONS
    const handelActive = (uuid) => {
        setAction('ACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    const handelInactive = (uuid) => {
        setAction('INACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    // CONFIRM POP UP
    const confirmPop = async () => {
        if (popup) {
            switch (action) {
                case 'ACTIVE': await activeItem(getID);
                    break;
                case 'INACTIVE':
                    await inactiveItem(getID);
                    break;
                case "DELETED":
                    await removeItems(getID);
                    break;
                case 'RESTORE':
                    await revive(getID);
                    await activeItem(getID)
                    break;
                case 'Out Of Stoke':
                    await stoke(getID);
                    break;
                case "Stock-in":
                    await stoke(getID);
                    break;
                case "Inactive the selected":
                    await multipleInactive();
                    setPopup(false);
                    break;
                case "Active the selected":
                    await multipleActive();
                    setPopup(false);
                    break;
                default:
                    console.log('no such action available');
                    break;
            }
        };
        setAction('');
        setPopup(false);
    };

    // CANCEL THE POPUP
    const closePop = () => {
        setPopup(false);
        setAction('');
    };

    // UPDATE ITEMS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const updateItem = async (recordId) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/get/${recordId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                dispatch(pro(getRes?.responsePacket));
                navigate(`/admin/UpdateItem/${getRes?.responsePacket.uuid}`, { state: getRes?.responsePacket });
            };
        } catch (e) {
            console.log(e, 'error in update api');
        };
    };

    // HANDLE STOKE AND OUTOFSTOKE
    const stoke = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/update-stock-out/${uuid}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in stoke api')
        }
    };

    const handleStoke = (uuid) => {
        setAction('Out Of Stoke');
        setGetId(uuid);
        setPopup(true);
    };
    //  Stock-in
    const handleStokeIn = (uuid) => {
        setAction('Stock-in');
        setGetId(uuid);
        setPopup(true);
    };

    const [currentPage, setCurrentPage] = useState(1);

    const [inputSearch, setInputSearch] = useState();

    const handleSearch = (e) => {
        const get = e.target.value;
        setInputSearch(get);
        const search = list.filter((itm) =>
            itm.title?.toLowerCase()?.includes(get.toLowerCase()) ||
            itm.description?.toLowerCase()?.includes(get.toLowerCase())
        );
        setFilterList(search);
        setCurrentPage(1);
    };

    // FILTER LIST
    const [sortShow, setSortShow] = useState(false);

    // HANDLE OUTSIDE CLICK
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setSortShow(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    // SORT METHOD
    const sort = [
        { id: 1, title: "A-Z" },
        { id: 2, title: "Z-A" },
        { id: 4, title: "Latest Added" },
        { id: 5, title: "Oldest Added" },
        { id: 6, title: "High to Low price" },
        { id: 7, title: "Low to High price" },
    ];

    const [sortBy, setSortBy] = useState();
    const handleSort = async (by) => {
        setSortBy(by);
        if (by === 'A-Z') {
            const sortAz = [...filterList].sort((a, b) => a.title.localeCompare(b.title));
            setFilterList(sortAz);
            setCurrentPage(1);
            setSortShow(false);
            setInputSearch('');
        } else if (by === "Z-A") {
            const sortAz = [...filterList].sort((a, b) => b.title.localeCompare(a.title));
            setFilterList(sortAz);
            setCurrentPage(1);
            setInputSearch('');
            setSortShow(false);
        } else if (by === 'Latest Added') {
            const sortAz = [...filterList].sort((a, b) => b.createdAtTimeStamp - a.createdAtTimeStamp);
            setFilterList(sortAz);
            setCurrentPage(1);
            setInputSearch('');
            setSortShow(false);
        } else if (by === 'Oldest Added') {
            const sortAz = [...filterList].sort((a, b) => a.createdAtTimeStamp - b.createdAtTimeStamp);
            setFilterList(sortAz);
            setCurrentPage(1);
            setInputSearch('');
            setSortShow(false);
        } else if (by === 'High to Low price') {
            const sortAz = [...filterList].sort((a, b) => b.createdAtTimeStamp - a.createdAtTimeStamp);
            setFilterList(sortAz);
            setCurrentPage(1);
            setInputSearch('');
            setSortShow(false);
        } else if (by === 'Low to High price') {
            const sortAz = [...filterList].sort((a, b) => a.createdAtTimeStamp - b.createdAtTimeStamp);
            setFilterList(sortAz);
            setCurrentPage(1);
            setInputSearch('');
            setSortShow(false);
        }
    };

    const resultPerPage = [
        { value: 10, label: "10 items per page" },
        { value: 20, label: "20 items per page" },
        { value: 50, label: "50 items per page" },
        { value: 100, label: "100 items per page" },
        { value: 200, label: "200 items per page" },
    ];

    const [lengthOfPage, setLengthOfPage] = useState(() => {
        return JSON.parse(sessionStorage.getItem("itemPerPage")) || resultPerPage[0]
    });

    useEffect(() => {
        sessionStorage.setItem("itemPerPage", JSON.stringify(lengthOfPage));
    }, [lengthOfPage]);


    // Pagination logic
    const itemsPerPage = lengthOfPage?.value; // Show 10 items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const timeFormate = (time) => {
        const date = new Date(time);
        const options = {
            weekday: 'short',  // Thu
            day: 'numeric',    // 5
            month: 'short',    // Dec
            year: 'numeric',   // 2024
            hour: '2-digit',   // 10
            minute: '2-digit'  // 14
        };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
        return formattedDate
    };

    // Filter By Publihser
    const [getPublisherList, setGetPublisherList] = useState([]);
    const [filterPublisherList, setFilterPublisherList] = useState([...getPublisherList]);

    const publisherList = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/publisher/list-in-key-value`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setGetPublisherList(getRes.responsePacket);
                setFilterPublisherList(getRes.responsePacket);

            }
        } catch (e) {
            console.log(e, "error in publisher List api");
        }
    };
    useEffect(() => {
        publisherList();
        categoryList();
    }, []);

    const [publisherValue, setPublisherValue] = useState();
    const filterByPublisher = (e) => {
        if (e) {
            const res = list.filter((itm) => itm?.publisherDetails?.parentUuid === e?.value);
            setFilterList(res);
            setCategoryValue(null)
        } else if (!e) {
            setFilterList(list);
        }
    };

    // Filter Category 
    const [getCategoryList, setGetCategoryList] = useState([]);
    const [categoryValue, setCategoryValue] = useState(null);

    const categoryList = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/category/list-in-key-value`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setGetCategoryList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, "error in category list api")
        }
    };

    const filterByCategory = (e) => {
        if (e) {
            const res = list.filter((itm) => itm.parentCategoryDetails.some((itm) => itm.parentUuid === e.value) || itm.subCategoryDetails.some((itm) => itm.parentUuid === e.value));
            setFilterList(res);
            setPublisherValue(null);
        } else if (!e) {
            setFilterList(list)
        }
    };

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    // Multiple active and inactive 
    const [selectedItems, setSelectedItems] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (isSelectAll) {
            setSelectedItems([]); // Deselect all items
        } else {
            setSelectedItems(filterList.map((itm) => itm)); // Select all items
        }
        setIsSelectAll(!isSelectAll);
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelected) =>
            prevSelected.includes(itemId)
                ? prevSelected.filter((id) => id !== itemId) // Uncheck if already selected
                : [...prevSelected, itemId] // Add to selected items
        );
    };

    const multipleActive = async () => {
        try {
            const data = selectedItems.filter((itm) => itm.active === false).map((itm) =>
                fetch(`${BASE_URL}/admin/v1/item/activate/${itm.uuid}`, {
                    method: "PUT",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }).then(async (response) => {
                    return await response.json();
                })
            )
            const res = await Promise.all(data);
            const allSuccess = res.every((response) => response.errorCode === 0);
            if (allSuccess) {
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
                setSelectedItems([]);
                setCurrentPage(1);
            }

        } catch (e) {
            console.log(e, "error in multiple")
        }
    };

    const handleMultiActive = () => {
        setPopup(true);
        setAction("Active the selected")
    };

    const multipleInactive = async () => {
        try {
            const data = selectedItems.filter((itm) => itm.active === true).map((itm) =>
                fetch(`${BASE_URL}/admin/v1/item/inactivate/${itm.uuid}`, {
                    method: "PUT",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }).then(async (response) => {
                    return await response.json();
                })
            )
            const res = await Promise.all(data);
            const allSuccess = res.every((response) => response.errorCode === 0)
            if (allSuccess) {
                setSelectedItems([]);
                setCurrentPage(1);
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                        break;
                }
            };
        } catch (e) {
            console.log(e, "error in multiple")
        }
    };

    const handleMultiInactive = () => {
        setPopup(true);
        setAction("Inactive the selected")
    };



    return (
        <>
            <div className="category" style={{ display: "flex", justifyContent: "center", }}>
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Items</h4>
                        </div>

                        <div className="category-btn">

                            <Select
                                // defaultValue={resultPerPage[0]}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                name="colors"
                                options={filterPublisherList}
                                value={publisherValue}
                                onChange={(e) => { setPublisherValue(e); setCurrentPage(1); filterByPublisher(e) }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Filter by Publisher"
                            />

                            <Select
                                // defaultValue={resultPerPage[0]}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                name="colors"
                                options={getCategoryList}
                                value={categoryValue}
                                onChange={(e) => { setCategoryValue(e); setCurrentPage(1); filterByCategory(e) }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Filter by Category"
                            />

                            <Select
                                defaultValue={resultPerPage[0]}
                                closeMenuOnSelect={true}
                                name="colors"
                                options={resultPerPage}
                                value={lengthOfPage}
                                onChange={(e) => { setLengthOfPage(e); setCurrentPage(1) }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />

                            {
                                permission('admin/v1/item/save') && <Link to="/admin/additemparent">
                                    <button>Add Item</button>
                                </Link>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className="category-filter">
                        <div className="filterr">
                            {
                                selectedItems.length > 0 ?
                                    <>
                                        {/* <div className="printer-btn-main">
                                            {(type === "INACTIVE" || type === "ALL") && <button onClick={() => handleMultiActive()}>Active</button>}
                                            {(type === "ACTIVE" || type === "ALL") && <button onClick={() => handleMultiInactive()}>Inactive</button>}
                                        </div> */}

                                        <div className="btn-1">
                                            {(type === "INACTIVE" || type === "ALL") && <button style={{ backgroundColor: "#d4edda", color: "#155724", borderColor: "", border: "none" }} onClick={() => handleMultiActive()}>Active Items</button>}
                                        </div>
                                        <div className="btn-1">
                                            {(type === "ACTIVE" || type === "ALL") && <button style={{ backgroundColor: "#f8d7da", color: "#ff0019", borderColor: "", border: "none" }} onClick={() => handleMultiInactive()}>Inactive Items</button>}
                                        </div>
                                    </> :
                                    <>
                                        <div className="btn-1">
                                            <button style={{ backgroundColor: type === 'ALL' ? 'white' : 'black', color: type === 'ALL' ? 'black' : 'white' }} onClick={() => { items('ALL'); setType('ALL') }} >All</button>
                                        </div>
                                        <div className="btn-1">
                                            <button style={{ backgroundColor: type === 'ACTIVE' ? 'white' : 'black', color: type === 'ACTIVE' ? 'black' : 'white' }} onClick={() => { items('Active'); setType('ACTIVE') }}>Active</button>
                                        </div>
                                        <div className="btn-1">
                                            <button style={{ backgroundColor: type === 'INACTIVE' ? 'white' : 'black', color: type === 'INACTIVE' ? 'black' : 'white' }} onClick={() => { items('Inactive'); setType('INACTIVE') }}>Inactive</button>
                                        </div>
                                        <div className="btn-1">
                                            <button style={{ backgroundColor: type === 'DELETED' ? 'white' : 'black', color: type === 'DELETED' ? 'black' : 'white' }} onClick={() => { items('Deleted'); setType('DELETED') }}><AiFillDelete /></button>
                                        </div>
                                        <div className="btn-1">
                                            <button onClick={() => setSortShow(!sortShow)}><FaFilter /></button>
                                            {
                                                sortShow && <div className="sort-list" ref={ref}>
                                                    {
                                                        sort.map((item) => {
                                                            return (
                                                                <>
                                                                    <div className="list-item" id="sort" onClick={() => handleSort(item.title)}>
                                                                        <div className="outer-c" >
                                                                            <div className="inner-c" style={{ backgroundColor: sortBy === item.title ? "black" : "" }}></div>
                                                                        </div>
                                                                        <label for={item.id}>{item.title}</label>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </>
                            }


                        </div>
                        <div className="search">
                            <h4>Search:</h4>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={inputSearch}
                                onChange={(e) => handleSearch(e)}
                                className='inputItem'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu-table-container sticky-container">
                <div className="order-list">
                    <div className="order-list-header">
                        <div className='tittle'><input type="checkbox"
                            checked={isSelectAll}
                            onChange={handleSelectAll}
                        /></div>

                        <div className="tittle">Image</div>
                        {/* <div>Date</div> */}
                        <div className="tittle">Title</div>
                        {/* <div className="tittle">Channel</div> */}
                        <div className="tittle">Created At</div>
                        <div className="tittle">Parent Category</div>
                        {/* <div>Fulfillment Status</div> */}
                        <div className="tittle">Sub Category</div>
                        <div className="tittle">Selling Price</div>
                        <div className="tittle">Status</div>
                        <div className="tittle">Action</div>


                    </div>
                </div>
            </div>
            <div className="menu-table-container">
                <div className="order-list">
                    {
                        filterList.slice(indexOfFirstItem, indexOfLastItem).map((item, index) => (
                            <div key={item.uuid} className='order-list-row'>
                                <div className='tittle'>
                                    <input type="checkbox"
                                        checked={selectedItems.includes(item)}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                </div>
                                <div className='tittle'><img src={item.itemImageUrls?.[0]} alt="" className="img" onClick={() => maginifyImage(item.itemImageUrls?.[0])} /></div>
                                <div className='tittle'>{item.title}</div>
                                <div className='tittle'>{timeFormate(item.createdAtTimeStamp)}</div>
                                <div className='tittle'>{item.parentCategoryDetails.map((itm) => {
                                    return (
                                        <>
                                            <div>{itm.parentTitle}</div>
                                        </>
                                    )
                                }
                                )}
                                </div>
                                <div className='tittle'>{item.subCategoryDetails?.map((itm) => {
                                    return (
                                        <>
                                            <div>{itm.parentTitle}</div>
                                        </>
                                    )
                                })}</div>
                                <div className='tittle'>{item.sellingPrice}
                                </div>

                                <div className='tittle'>
                                    <button
                                        className={`status ${item.active ? 'active-btn' : 'inactive'}`}
                                    >
                                        {item.active ? 'Active' : 'Inactive'}
                                    </button>
                                </div>
                                <div className='btn flex-btns'>
                                    {
                                        item.active ?
                                            (permission('admin/v1/item/inactivate') && (<button className={`status inactive`} title="Inactive" onClick={() => handelInactive(item.uuid)} ><CgUnavailable />Inactive</button>))
                                            :
                                            (permission('admin/v1/item/activate') && (<button className={`status active-btn`} title='Active' onClick={() => handelActive(item.uuid)}><FaCheckCircle />Active</button>))
                                    }

                                    {permission('admin/v1/item/update') && <button className="action-btn edit-btn action-btnnn" title='Edit' onClick={() => updateItem(item.uuid)}><FaEdit />Edit</button>}
                                    {
                                        item.deleted ?
                                            (permission('admin/v1/item/revive') && (<button className="status active-btn" title='Revive' onClick={() => { handelRevive(item.uuid) }}>
                                                <FaRedo />Revive
                                            </button>))
                                            :
                                            (permission('admin/v1/item/delete') && (<button className="status inactive" title='Delete' onClick={() => handelDeleted(item.uuid)}>
                                                <AiFillDelete />Delete
                                            </button>))
                                    }
                                    {item.stockOut ?
                                        (permission('admin/v1/item/update-stock-out') && (<button className="action-btnnn" title='stock-in' onClick={() => handleStokeIn(item.uuid)}><FaBoxOpen /> Stock-In</button>))
                                        :
                                        (permission('admin/v1/item/update-stock-out') && (<button className="action-btnnn" title='stock-out' onClick={() => handleStoke(item.uuid)}><FaBox /> Stock-out</button>))
                                    }

                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="pagination">
                    <span>Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filterList.length)} of {filterList.length} entries</span>
                    <span className="pagination-buttons">
                        <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} totalItem={filterList.length} lengthOfPage={lengthOfPage} />
                    </span>
                </div>
                {/* Modal for Delete Confirmation */}
                <Modal
                    isOpen={popup}
                    onClose={closePop}
                    onConfirm={confirmPop}
                    action={`Are you sure you want to ${action} Item ?`}
                />

                <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />

            </div>
        </>
    );
}

export { Items };