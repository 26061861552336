import React from "react";

function Skeleton({ style, className }){
    return(
        <>
        
        <div className={`animated-background ${className}`} style={style}></div>
        </>
    )
}
export{Skeleton}