import React, { useEffect, useState } from "react";

function Loading (){
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => setLoading(false), 3000); // Simulated delay
      return () => clearTimeout(timer); // Cleanup timer
    }, []);
    return(
        <>
           <div>
      {loading ? (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <div>
          <h1>Welcome to the App!</h1>
          <p>The content has loaded.</p>
        </div>
      )}
    </div>
        </>
    )
}
export{Loading};