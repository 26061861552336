import React, { useEffect, useRef, useState } from "react";
import { useRole } from "../../../../../Routing/Routing";
import { FaFilter, FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { FaRedo } from "react-icons/fa";
import Modal from "../../../CommonComponents/Popup";

function Notifyme() {

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);
    const token = sessionStorage.getItem('tokenKey');

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);
    const [type, setType] = useState('ACTIVE');
    const [action, setAction] = useState();
    const [getID, setGetId] = useState();

    const items = async (data) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/stock-in-notification/list/${data}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in itme list');
        }
    };

    useEffect(() => {
        items('Active');
    }, []);

    const [popup, setPopup] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [inputSearch, setInputSearch] = useState();

    const handleSearch = (e) => {
        const get = e.target.value;
        setInputSearch(get);

        const search = list.filter((itm) =>
            itm.itemDetails.parentTitle.toLowerCase().includes(get.toLowerCase()) ||
            itm.customerMobile.toLocaleString().includes(get.toLocaleString())
        );
        setFilterList(search);
    };

    // DELTED ITEMS FROM ITEMS
    const removeItems = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/stock-in-notification/delete/${uuid}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "NOTIFIED":
                        await items("Notified");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in delted itms api')
        }
    };

    // HANDEL DELTED
    const handelDeleted = (uuid) => {
        setAction('DELETED');
        setGetId(uuid);
        setPopup(true);
    };

    // Pagination logic
    const itemsPerPage = 10; // Show 10 items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;


    // CONFIRM POP UP
    const confirmPop = async () => {
        if (popup) {
            switch (action) {
                case "DELETED":
                    await removeItems(getID);
                    break;
                default:
                    console.log('no such action available');
            }
        };
        setAction('');
        setPopup(false);
    };

    const closePop = () => {
        setPopup(false);
        setAction('');
    };


    return (
        <>
            <div className="category">
                <div className="row">
                    <div className="category" style={{ display: "block" }}>
                        <div className="category-nav">
                            <div className="category-text">
                                <h4>Notify Requests</h4>
                            </div>
                            <div className="category-btn">
                            </div>
                        </div>
                        <hr />
                        <div className="category-filter">
                            <div className="filterr">
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'ALL' ? 'white' : 'black', color: type === 'ALL' ? 'black' : 'white' }} onClick={() => { items('ALL'); setType('ALL') }} >All</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'ACTIVE' ? 'white' : 'black', color: type === 'ACTIVE' ? 'black' : 'white' }} onClick={() => { items('Active'); setType('ACTIVE') }}>Active</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'INACTIVE' ? 'white' : 'black', color: type === 'INACTIVE' ? 'black' : 'white' }} onClick={() => { items('Notified'); setType('INACTIVE') }}>Notified</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'DELETED' ? 'white' : 'black', color: type === 'DELETED' ? 'black' : 'white' }} onClick={() => { items('Deleted'); setType('DELETED') }}><AiFillDelete /></button>
                                </div>
                                {/* <div className="btn-1">
                                <button onClick={() => setSortShow(!sortShow)}><FaFilter /></button>
                                {
                                    sortShow && <div className="sort-list" ref={ref}>
                                        {
                                            sort.map((item) => {
                                                return (
                                                    <>
                                                        <div className="list-item" id="sort" onClick={() => handleSort(item.title)}>
                                                            <div className="outer-c" >
                                                                <div className="inner-c" style={{ backgroundColor: sortBy === item.title ? "black" : "" }}></div>
                                                            </div>
                                                            <label for={item.id}>{item.title}</label>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div> */}
                            </div>
                            <div className="search">
                                <h4>Search:</h4>
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={inputSearch}
                                    onChange={(e) => handleSearch(e)}
                                    className='inputItem'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu-table-container sticky-container-order ">
                <div className="order-list">
                    <div className="order-list-header dynamic-head">
                        <div className='tittle'>Item Name</div>
                        <div className="tittle">Customer Mobile No.</div>
                        <div className='tittle'>Status</div>
                        <div className='tittle'>Action</div>
                    </div>
                </div>
            </div>
            <div className="menu-table-container">

                {
                    filterList.map((item) => {
                        return (
                            <>
                                <div key={item} className='order-list-row dynamic-head'>
                                    <div className='tittle'>{item?.itemDetails?.parentTitle}</div>
                                    <div className='tittle'>{item.customerMobile}</div>
                                    <div className='tittle'>
                                        <button
                                            className={`status ${item.active ? 'active-btn' : 'inactive'}`}
                                        >
                                            {item.active ? 'Active' : 'Inactive'}
                                        </button>
                                    </div>
                                    <div className='btn'>

                                        {/* <div>

                                                {
                                                    item.active ? <button
                                                        className={`status inactive`} onClick={() => handelInactive(item.uuid)} ><CgUnavailable /></button>
                                                        :
                                                        <button
                                                            className={`status active-btn`} onClick={() => handelActive(item.uuid)} ><FaCheckCircle /></button>
                                                }

                                            </div> */}
                                        {/* <button className="action-btn edit-btn" onClick={() => updateItem(item.uuid)}><FaEdit /></button> */}

                                        {
                                            item.deleted ?
                                                <button>Deleted</button>
                                                :
                                                (permission("admin/v1/stock-in-notification/delete") && (<button className="action-btn delete-btn" onClick={() => { handelDeleted(item.uuid) }}>
                                                    <AiFillDelete />
                                                </button>))
                                        }

                                    </div>
                                </div>
                            </>
                        )
                    })}

            </div>





            <Modal
                isOpen={popup}
                onClose={closePop}
                onConfirm={confirmPop}
                action={`Are you sure you want to ${action} this Request ?`}
            />
        </>
    )
}
export { Notifyme }