import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { UpdatePage } from "../ManagePageChild/UpdatePage";

function UpdatePageParent() {
    return (
        <>
            <MergeComponents getComponent={<UpdatePage />} />
        </>
    )
}
export { UpdatePageParent }