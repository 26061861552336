import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { GiftCard } from '../GiftCardChild/GiftCard';

function GiftMain() {
    return (
        <>
            <MergeComponents getComponent={<GiftCard />} />
        </>
    )
}
export { GiftMain }