import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import { Navbar } from "./Navbar";
import { Link } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import { RiSubtractLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { AddToCart, itemQuantity, removeCartItem } from "../../../Utilities/Utils";
import { fetchCartCount, fetchHeaderData } from "../../../Store/apiSlice";

function SearchDetail() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.header.data);


    const [search, setsearchDetail] = useState([]);
    console.log(search, "ssssssssssssssssssssssssss")
    

    const handleAddToCart = async (itemId) => {
        try {
            const result = await AddToCart(data?.responsePacket?.uuid, itemId);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.error("Error in AddToCart API:", e);
        }
    };

    const handleItemQuantity = async (itemId, quantity) => {
        try {
            const result = await itemQuantity(data?.responsePacket?.uuid, itemId, quantity);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.error("Error updating item quantity:", e);
        }
    };

    const handleRemoveCartItem = async (itemId) => {
        try {
            const result = await removeCartItem(data?.responsePacket?.uuid, itemId);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.error("Error removing cart item:", e);
        }
    };

    return (
        <>
            <Navbar setsearchDetail={setsearchDetail} />
            <div className="springboard-notes">
                <div className="home-row home-row-b">
                    <div className="books-container">
                        {search?.map((book) => (
                            <div className="book-card" key={book.uuid}>

                                <div className="book-image">

                                    <Link to={`/pagedetail2/${search.uuid}`} state={book}>
                                        {book.mrp > book.sellingPrice && (
                                            <div className="discount-badge">
                                                <p>{Math.round(((book.mrp - book.sellingPrice) / book.mrp) * 100)}% OFF</p>
                                            </div>
                                        )}
                                        <img src={book.itemImageUrls?.[0]} alt={search.subtitle} />
                                    </Link>
                                </div>

                                <h3>{book.title}</h3>
                                <div className="price-section">
                                    <div className="price">
                                        <p className="p">
                                            <span className="mrp-price">&#8377;{book.sellingPrice}</span>
                                        </p>
                                        <strike>
                                            <span className="original-price">&#8377;{book.mrp}</span>
                                        </strike>
                                    </div>
                                    {book.stockOut ? (
                            <button>Notify Me</button>
                        ) : !data?.responsePacket?.itemDetailList?.some(
                            (itm) => itm.itemUuid === book.uuid
                        ) ? (
                            <button onClick={() => handleAddToCart(book.uuid)}>ADD</button>
                        ) : (
                            <div className="add-btn">
                                <span
                                    onClick={() => {
                                        const quantity = data?.responsePacket?.itemDetailList?.find(
                                            (itm) => itm.itemUuid === book.uuid
                                        ).quantity;
                                        if (quantity === 1) {
                                            handleRemoveCartItem(book.uuid);
                                        } else {
                                            handleItemQuantity(book.uuid, quantity - 1);
                                        }
                                    }}
                                >
                                    <RiSubtractLine />
                                </span>
                                <p className="pp">
                                    {
                                        data?.responsePacket?.itemDetailList?.find(
                                            (itm) => itm.itemUuid === book.uuid
                                        ).quantity
                                    }
                                </p>
                                <span
                                    onClick={() => {
                                        const quantity = data?.responsePacket?.itemDetailList?.find(
                                            (itm) => itm.itemUuid === book.uuid
                                        ).quantity;
                                        handleItemQuantity(book.uuid, quantity + 1);
                                    }}
                                >
                                    <IoAdd />
                                </span>
                            </div>
                        )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}
export { SearchDetail }