import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";
import { uploadImage } from "../../../CommonComponents/ImageUpload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


function AddDisplayCat() {
    let navigate = useNavigate();
    const editor = useRef(null);

    const getToken = sessionStorage.getItem('tokenKey');
    const BASE_URL = process.env.REACT_APP_PP_URL;

    // HANDLE OUT SIDE CLICK
    const [showSuggestion, setShowSuggestion] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setShowSuggestion(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);


    // POST CATEGORY VALUE
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [imagePreviews, setImagePreviews] = useState([]);

    const bodyData = {
        title: title,
        description: description,
        // categoryIconUrl: imagePreviews.map(itm => itm.responsePacket).join(''),
    };



    // POSTING CATEGORIES
    const PostData = async (okId) => {
        try {
            const response = await fetch(`${BASE_URL}/admin/v1/display-category/save`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getToken}`, // Ensure getToken is returning a valid token
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyData) // Directly pass the FormData object
            });

            const getRes = await response.json();
            // Check if response is okay
            if (getRes.errorCode === 0) {

                if (okId === "addMore") {
                    setTitle('');
                    setDescription('');
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'  // Optional: Use 'auto' for instant scroll
                    });
                    setShow(false);
                } else if (okId === "ok") {
                    navToCat('/admin/displayCat');
                    setShow(false);
                }
            } else {
                setShow(true);
                setAction('something went wrong!')
                setNavv();
                setExtraBtn();
                setMore('ok');
            };

        } catch (e) {
            console.log(e, 'error in category post api');
            setShow(true);
            setAction('something went wrong!')
            setNavv();
            setExtraBtn();
            setMore('ok');
        };
    };

    // const removeAddCat = (indexxx) => {
    //     // const newParentStore = parentStore.filter((_,index)=> index !== indexxx);
    //     setParentStore((prev) => prev.filter((_, index) => _.key !== indexxx));
    //     setParentCat((prev) => prev.filter((_, index) => _ !== indexxx));
    //     // setParentCat((prev) => prev.filter((_, index) => index !== indexxx));
    //     console.log('itm removed')
    // };


    // SUCCESSFULL HANDLE


    const [show, setShow] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();
    const [extraBtn, setExtraBtn] = useState();
    const navToCat = useNavigate();


    const handleSuccesBtn = () => {
        PostData("ok")
    };
    const handleCencel = () => {
        setShow(false);
        
    };
    const onMoreBtn = () => {
        PostData("addMore")
    };

    // HANDLE IMAGE SECTION



    // HANDLE IMAGE SECTION
    const handleFileChange = async (e) => {
        const files = e.target.files;
        const uploadedUrls = [];
        // Loop through each selected file and upload
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                uploadedUrls.push(imageUrl);
                // setImagePreviews((prev=> [...prev, imageUrl]));
                console.log('image upload');
            }
        };
        setImagePreviews(uploadedUrls); // Update state with all uploaded image URLs
    };

    const handleDeleteImage = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this image?");
        if (confirmDelete) {
            // Remove the image from the preview and formData arrays
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews
        };
    };

    const [errors, setErrors] = useState({});
    // Refs for scrolling
    const titleRef = useRef(null);
    const handleValidation = () => {
        let newErrors = {};
        // Check each field
        if (!title?.trim()) newErrors.title = "Title is required.";
        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }

        return true;
    };

    const scrollToField = (field) => {
        switch (field) {
            case "title":
                titleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        handleValidation();
        if (handleValidation()) {
            setAction("Confirm Submit");
            setExtraBtn("Add More");
            setMore("cancel");
            setNavv("ok");
            setShow(true)
        }
    };


    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Add Display Category</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form" ref={titleRef}>

                            {/* Title Input */}
                            <div className="add w-100 m-b2">
                                <label style={errors.title && { color: "red" }}>Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    onChange={(e) => { e.preventDefault(); setTitle(e.target.value) }}
                                    value={title}
                                // placeholder="Tittle"
                                />
                            </div>


                            {/* <div className="d-flex w-100 m-b2 d-other">
                                <div className="add">
                                    <label>Select Tax (Multi Select)</label>
                                    <input
                                        type="text"
                                        name="tax"
                                        value={formData.tax}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="add">
                                    <label>Time Availability</label>
                                    <div className="d-flex check-box-avail">
                                        <input
                                            type="checkbox"
                                            name="availableAllTime"
                                            checked={formData.availableAllTime}
                                            onChange={handleInputChange}
                                            id="check-box"
                                        />
                                        <label htmlFor="check-box">Available All Time</label>
                                    </div>
                                </div>
                            </div> */}

                            <div className="editer m-b2">
                                {/* Jodit Editor */}
                                <div className="add w-100">
                                    <label>Description</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    "heading",
                                                    "|",
                                                    "bold",
                                                    "italic",
                                                    "link",
                                                    "bulletedList",
                                                    "numberedList",
                                                    "|",
                                                    "indent",
                                                    "outdent",
                                                    "|",
                                                    "imageUpload",
                                                    "blockQuote",
                                                    "mediaEmbed",
                                                    "undo",
                                                    "redo",
                                                ],
                                            },
                                        }}
                                        data={description}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Category Icon File Upload */}

                            {/* <div className="add w-100">
                                <label>Category Icon</label>
                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        // multiple
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews.length > 0 ?
                                            <label htmlFor="category-icon">
                                                <div className="custom-label">
                                                    <RiImageAddFill />
                                                    <span>Change Image</span>
                                                </div>
                                            </label>
                                            :
                                            <label htmlFor="category-icon" className="custom-file-input">
                                                <div className="text">
                                                    <div className="icon">
                                                        <h1><IoCloudUploadOutline /></h1>
                                                        <h4>Add Images</h4>
                                                    </div>
                                                </div>
                                            </label>
                                    }

                                </div>

                                {
                                    imagePreviews.length > 0 && <div className="image-preview-wrapper">
                                        {
                                            imagePreviews.map((preview, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img
                                                        src={preview.responsePacket}
                                                        alt={`Category Icon ${index + 1}`}
                                                        style={{ objectFit: 'cover' }}
                                                    />

                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteImage(index)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: '1px',
                                                            background: 'red',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div> */}


                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>

            {/* show SUCCESS */}

            <SuccessPopUp
                isOpen={show}
                action={action}
                onConfirm={handleSuccesBtn}
                navv={navv}
                onClose={handleCencel}
                FutherMore={more}
                moreBtn={extraBtn}
                onMoreBtn={onMoreBtn}
            />
            
        </>
    );
}

export { AddDisplayCat };

