import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { AddUpdatePublisher } from "../PublisherChild/AddUpdatePublisher";

function AddPublisherPatrent() {
    return (
        <>
            <MergeComponents getComponent={<AddUpdatePublisher />} />
        </>
    )
}
export { AddPublisherPatrent }