// store/headerSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useState } from "react";
const BASE_URL = process.env.REACT_APP_PP_URL;
const key = localStorage.getItem('userId');
const deviceId = localStorage.getItem('device_id');

// Async thunk for fetching header data

export const fetchHeaderData = createAsyncThunk(
  "header/fetchHeaderData",
  async () => {
    const headers = {
      'Content-Type': 'application/json',
    };

    // Add Authorization header only if key exists
    if (key) {
      headers['Authorization'] = `Bearer ${key}`;
    };
    const response = await fetch(`${BASE_URL}/guest-customer/v1/cart/getCartDetail/${deviceId}`, {
      headers,
    });
    const result = await response.json();

    if (result.errorCode === 0) {
      return result;
    };
  }
);

export const fetchCartCount = createAsyncThunk(
  "header/fetchCartCount",
  async (id, { getState }) => {
    const state = getState().header;
    id = id || state.id; // Use ID from Redux if not provided
    if (!id) {
      throw new Error("Cart ID is missing");
    }
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      if (key) {
        headers["Authorization"] = `Bearer ${key}`;
      }

      const res = await fetch(
        `${BASE_URL}/guest-customer/v1/cart/getCartItemCount/${id}`,
        {
          headers,
        }
      );
      const result = await res.json();
      if (result.errorCode === 0) {
        return result.responsePacket; // Return cart count
      } else {
        throw new Error("Error fetching cart count");
      }
    } catch (error) {
      console.error(error, "Error in fetchCartCount");
      throw error;
    }
  }
);


const headerSlice = createSlice({
  name: "header",
  initialState: {
    data: null,
    cartInfo: null,
    loading: false,
    error: null,
    id: null, // Add ID to state
  },
  reducers: {
    updateHeaderData(state, action) {
      state.data = action.payload;
    },
    updateCartCount(state, action) {
      state.cartInfo = action.payload
    },
    setId(state, action) { // New reducer to store ID
      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeaderData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHeaderData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchHeaderData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchCartCount.fulfilled, (state, action) => {
        console.log("fetchCartCount fulfilled:", action.payload); // Check payload here
        state.cartInfo = action.payload;
        state.loading = false;
      })
      .addCase(fetchCartCount.rejected, (state, action) => {
        console.error("fetchCartCount rejected:", action.payload); // Log errors
        state.error = action.payload;
        state.loading = false;
      })
  },
});

export const { updateHeaderData, updateCartCount, setId } = headerSlice.actions;
export default headerSlice.reducer;
