import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { UpdateCategory } from "../CategoryChild/UpdateCategory";
import '../../../AdminStyle.css';

function UpdateCatParent() {
    return (
        <>
            <MergeComponents getComponent={<UpdateCategory />} />
        </>
    )
};
export { UpdateCatParent }