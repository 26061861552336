import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { UpdateCms } from '../CmsChild/UpdateCms';

function UpdateCmsParent() {
    return (
        <>
            <MergeComponents getComponent={<UpdateCms />} />
        </>
    )
}
export { UpdateCmsParent }