import React, { useState } from "react";
import { AccountMerge } from "../../AccountMerge";
import { ProfileChild } from "../ProfileChild/ProfileChild";


function AccountProfileParent() {
    const [portName, setPortName] = useState();
    const [portMobile, setPortMobile] = useState();
 
    return (
        <>
            <AccountMerge portName={portName} portMobile={portMobile} getComponent={<ProfileChild setPortName={setPortName} setPortMobile={setPortMobile} />} />
        </>
    )
}
export { AccountProfileParent }