import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { CartDetail } from '../CartChild/CartDetail';

function CartDetailParent() {
    return (
        <>
            <MergeComponents getComponent={<CartDetail />} />
        </>
    )
}
export { CartDetailParent }