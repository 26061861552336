import React, { useEffect, useState, useRef } from "react";
import { FaEdit, FaFilter, FaTrashAlt } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../../CommonComponents/Popup";
import { HTMLToPlainText } from "../../../CommonComponents/HtmlToPlainText";
import { FaRedo } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { data } from "../../../../../Store/ReduxSlice";
import PaginationComponent from "../../pagination";
import { CgUnavailable } from "react-icons/cg";
import { FaCheckCircle } from "react-icons/fa";
import { useRole } from "../../../../../Routing/Routing";
import Select from "react-select";
import { MagnifyImage } from "../../../CommonComponents/MagnifyImage";

function CategorySection() {

    // get list data 
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);

    const token = sessionStorage.getItem('tokenKey');
    const [getListData, setGetListData] = useState([]);
    const [filterListData, setFilterListData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [type, setType] = useState('ACTIVE');
    const setDataList = async (data) => {
        try {
            const response = await fetch(`${BASE_URL}/admin/v1/category/list-data/${data}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },

            });

            const getResponse = await response.json();
            if (!getResponse.errorCode === 0) {
                throw new Error(`HTTP error! status: ${response.status}`);
            };
            setGetListData(getResponse?.responsePacket);
            setFilterListData(getResponse?.responsePacket);
            setSearchInput('');
            setParentCurrentValue(null);
        } catch (error) {
            console.log(error, 'error in category list items');
        };
    };

    useEffect(() => {
        setDataList('Active');
    }, []);

    // filter by search 
    const [searchInput, setSearchInput] = useState();
    const handleSearch = (e) => {
        const get = e.target.value;
        setSearchInput(get);
        const search = getListData.filter((itm) =>
            itm.title.toLowerCase().includes(get.toLowerCase()) ||
            itm.description.toLowerCase().includes(get.toLowerCase())
        );
        setFilterListData(search);
        setCurrentPage(1);
    };

    // const filter by parent Category
    const [parentValues, setParentValues] = useState([]);
    const [parentCurrentValue, setParentCurrentValue] = useState(null);
    useEffect(() => {
        const res = getListData.map((itm) => ({ value: itm?.parentCategoryDetail?.parentUuid, label: itm.parentCategoryDetail?.parentTitle }));
        const removeDublicate = res.filter((itm, index, self) =>
            index === self.findIndex((t) => t.value === itm.value)
        );
        setParentValues(removeDublicate);

    }, [getListData]);

    const filterByParentCategory = (e) => {
        if (e) {
            const res = getListData.filter((itm) => itm.parentCategoryDetail?.parentUuid === e.value);
            setFilterListData(res);
        } else if (!e) {
            setFilterListData(getListData);
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [action, setAction] = useState();
    const [getRecordedId, setGetRecordedId] = useState();

    const deactivateList = async (recordId, updatedId) => {
        try {
            const response = await fetch(`${BASE_URL}/admin/v1/category/inactivate/${recordId}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedId)
            });

            const getRes = await response.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await setDataList("ALL");
                        break;
                    case "ACTIVE":
                        await setDataList('Active');
                        break;
                    case "INACTIVE":
                        await setDataList("Inactive");
                        break;
                    case "DELETED":
                        await setDataList('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }

            } else {
                console.log('Error in response');
            }
            setSearchInput('');
        } catch (e) {
            console.log(e, 'error in deactivate api');
        }
    };

    const handleDeactivateList = (recordId) => {
        setIsModalOpen(true);
        setAction('Deactivate')
        setGetRecordedId(recordId)
    };

    // ACTIVATE CATELIST DATA 
    const activateList = async (recordId, updatedId) => {
        try {
            const response = await fetch(`${BASE_URL}/admin/v1/category/activate/${recordId}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedId)
            });
            const getRes = await response.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await setDataList("ALL");
                        break;
                    case "ACTIVE":
                        await setDataList("Active");
                        break;
                    case "INACTIVE":
                        await setDataList("Inactive");
                        break;
                    case "DELETED":
                        await setDataList('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setSearchInput('');
        } catch (e) {
            console.log(e, 'error in activate api')
        };
    };

    const handleActivateList = (recordId) => {
        setIsModalOpen(true)
        setAction("Activate")
        setGetRecordedId(recordId)
    };

    // DELETED API OF ACTEGORY LIST 
    const deletedList = async (recordId) => {
        try {
            const response = await fetch(`${BASE_URL}/admin/v1/category/delete/${recordId}`, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
            const getRes = await response.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await setDataList("ALL");
                        break;
                    case "ACTIVE":
                        await setDataList("Active");
                        break;
                    case "INACTIVE":
                        await setDataList("Inactive");
                        break;
                    case "DELETED":
                        await setDataList('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }

            setSearchInput('');

        } catch (e) {
            console.log(e, 'errror in deleted api')
        }

    };

    // DELETED CATES
    const handleDeleteList = (recordId) => {
        setIsModalOpen(true)
        setAction('Delete')
        setGetRecordedId(recordId)
    };

    // RESTORE API 
    const restore = async (recordId) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/category/revive/${recordId}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await setDataList("ALL");
                        break;
                    case "ACTIVE":
                        await setDataList("Active");
                        break;
                    case "INACTIVE":
                        await setDataList("Inactive");
                        break;
                    case "DELETED":
                        await setDataList('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setSearchInput('');
        } catch (e) {
            console.log(e, 'error in restore api')
        }
    };

    const handleRestore = (recordId) => {
        setIsModalOpen(true);
        setAction('Restore');
        setGetRecordedId(recordId);
    };

    // UPDATE ITEMS 

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const updateItem = async (recordId) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/category/get/${recordId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                dispatch(data(getRes?.responsePacket));
                navigate(`/admin/Updatecategory/${getRes?.responsePacket.uuid}`, { state: getRes?.responsePacket });
            };
            setSearchInput('');
        } catch (e) {
            console.log(e, 'error in update api');
        };
    };

    // Confirm the okay button
    const handleConfirm = () => {
        if (isModalOpen) {
            switch (action) {
                case 'Activate':
                    activateList(getRecordedId);
                    break;
                case 'Delete':
                    deletedList(getRecordedId);
                    break;
                case 'Deactivate':
                    deactivateList(getRecordedId);
                    break;
                case 'Restore':
                    restore(getRecordedId);
                    break;
                default:
                    console.log('no such action available');
            };
        };
        setIsModalOpen(false);
        setAction('');
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false); // Close modal
        setAction(''); // Clear the item to delete
    };

    // FILTER LIST
    const [sortShow, setSortShow] = useState(false);

    // HANDLE OUTSIDE CLICK
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setSortShow(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    // SORT METHOD
    const sort = [
        { id: 1, title: "A-Z" },
        { id: 2, title: "Z-A" },
        { id: 3, title: "Newest Added" },
        { id: 4, title: "Oldest Added" },

    ];
    const [sortBy, setSortBy] = useState();
    const handleSort = async (by) => {
        setSortBy(by);
        if (by === 'A-Z') {
            const sortAz = [...filterListData].sort((a, b) => a.title.localeCompare(b.title));
            setFilterListData(sortAz);
            setCurrentPage(1);
            setSortShow(false);
            setSearchInput('');
        } else if (by === "Z-A") {
            const sortAz = [...filterListData].sort((a, b) => b.title.localeCompare(a.title));
            setFilterListData(sortAz);
            setCurrentPage(1);
            setSearchInput('');
            setSortShow(false);
        } else if (by === 'Newest Added') {
            const sortAz = [...filterListData].sort((a, b) => b.createdAtTimeStamp - a.createdAtTimeStamp);
            setFilterListData(sortAz);
            setCurrentPage(1);
            setSearchInput('');
            setSortShow(false);
        } else if (by === 'Oldest Added') {
            const sortAz = [...filterListData].sort((a, b) => a.createdAtTimeStamp - b.createdAtTimeStamp);
            setFilterListData(sortAz);
            setCurrentPage(1);
            setSearchInput('');
            setSortShow(false);
        }
    };

    const resultPerPage = [
        { value: 10, label: "10 items per page" },
        { value: 20, label: "20 items per page" },
        { value: 50, label: "50 items per page" },
        { value: 100, label: "100 items per page" },
        { value: 200, label: "200 items per page" },
    ];
    const [lengthOfPage, setLengthOfPage] = useState(() => {
        return JSON.parse(sessionStorage.getItem("categoryPerPage")) || resultPerPage[0]
    });

    useEffect(() => {
        sessionStorage.setItem("categoryPerPage", JSON.stringify(lengthOfPage));
    }, [lengthOfPage]);

    // Pagination logic
    const itemsPerPage = lengthOfPage?.value; // Show 10 items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    return (
        <>
            <div className="category">
                <div className="row">
                    <div className="category" style={{ display: "block" }}>
                        <div className="category-nav">
                            <div className="category-text">
                                <h4>Category List</h4>
                            </div>

                            <div className="category-btn">
                                <Select
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    name="colors"
                                    options={parentValues}
                                    value={parentCurrentValue}
                                    onChange={(e) => { filterByParentCategory(e); setCurrentPage(1); setParentCurrentValue(e) }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Parent Category"
                                />

                                <Select
                                    defaultValue={resultPerPage[0]}
                                    closeMenuOnSelect={true}
                                    name="colors"
                                    options={resultPerPage}
                                    value={lengthOfPage}
                                    onChange={(e) => { setLengthOfPage(e); setCurrentPage(1) }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                                {
                                    permission('admin/v1/category/save') &&
                                    <Link to="/admin/addcategory">
                                        <button>Add Category</button>
                                    </Link>
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="category-filter">
                            <div className="filterr">
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'ALL' ? "white" : "black", color: type === 'ALL' ? "black" : "white" }} onClick={() => { setDataList('ALL'); setType('ALL'); setSortBy(''); setCurrentPage(1) }}>All</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'ACTIVE' ? "white" : "black", color: type === 'ACTIVE' ? "black" : "white" }} onClick={() => { setDataList('Active'); setType('ACTIVE'); setSortBy(''); setCurrentPage(1) }}>Active</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'INACTIVE' ? "white" : "black", color: type === 'INACTIVE' ? "black" : "white" }} onClick={() => { setDataList('Inactive'); setType('INACTIVE'); setSortBy(''); setCurrentPage(1) }}>Inactive</button>
                                </div>
                                <div className="btn-1">
                                    <button style={{ backgroundColor: type === 'DELETED' ? "white" : "black", color: type === 'DELETED' ? "black" : "white" }} onClick={() => { setDataList('Deleted'); setType('DELETED'); setSortBy(''); setCurrentPage(1) }}><AiFillDelete /></button>
                                </div>

                                {/* sort button in filter */}
                                <div className="btn-1">
                                    <button onClick={() => setSortShow(!sortShow)}><FaFilter /></button>

                                    {
                                        sortShow && <div className="sort-list" ref={ref}>
                                            {
                                                sort.map((item) => {
                                                    return (
                                                        <>
                                                            <div className="list-item" id="sort" onClick={() => handleSort(item.title)}>
                                                                <div className="outer-c" >
                                                                    <div className="inner-c" style={{ backgroundColor: sortBy === item.title ? "black" : "" }}></div>
                                                                </div>
                                                                <label for={item.id}>{item.title}</label>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="search">
                                <h4>Search:</h4>
                                <input type="text" value={searchInput} onChange={(e) => handleSearch(e)} placeholder="Search all Category" className="input" />
                            </div>
                        </div>
                    </div>
                    <div className="order-list sticky-container">
                        <div className="order-list-header ">
                            <div className="tittle">S.No.</div>
                            <div className="tittle">Image</div>
                            <div className="tittle">Title</div>
                            <div className="tittle">Parent Category</div>
                            <div className="tittle">Status</div>
                            <div className="tittle">Action</div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="order-list">

                    {/* slice(indexOfFirstItem, indexOfLastItem) */}
                    {
                        filterListData.slice(indexOfFirstItem, indexOfLastItem).map((item, index) => {
                            return (
                                <div key={item.key} className="order-list-row aaa">
                                    <div className="tittle">{indexOfFirstItem + index + 1}</div>
                                    <div className="tittle"><img src={item.categoryIconUrl} alt="" className="img" onClick={() => maginifyImage(item.categoryIconUrl)} /></div>
                                    <div className="tittle">{item.title}</div>
                                    {/* <div className="tittle">{item.parentCategoryName}</div> */}
                                    <div className="tittle">{item?.parentCategoryDetail?.parentTitle}</div>

                                    <div className="tittle">
                                        <span
                                            className={`status ${item.active ? 'activee' : 'inactive'}`}
                                        >
                                            {item.active ? 'Active' : 'Inactive'}
                                        </span>
                                    </div>
                                    <div className="btn flex-btns">

                                        {
                                            item.active ?
                                                (
                                                    permission('admin/v1/category/inactivate') &&
                                                    (<button
                                                        className={`status inactive`}
                                                        title="Inactive"
                                                        onClick={() => handleDeactivateList(item.uuid)}
                                                    >
                                                        <CgUnavailable />Inactive
                                                    </button>))
                                                :
                                                (
                                                    permission('admin/v1/category/activate') &&
                                                    (<button
                                                        className={`status activee`}
                                                        onClick={() => handleActivateList(item.uuid)} title="Active"
                                                        disabled={item.active ? true : false}
                                                    >
                                                        <FaCheckCircle />Active
                                                    </button>))
                                        }

                                        {
                                            permission('admin/v1/category/update') &&

                                            <button onClick={() => updateItem(item.uuid)} className="action-btn edit-btn action-btnnn" title="Edit">
                                                <FaEdit />Edit
                                            </button>
                                        }

                                        {
                                            item.deleted ?
                                                (permission('admin/v1/category/revive') && (<button className="status active-btn" title="Revive" onClick={() => handleRestore(item.uuid)}><FaRedo />Revive</button>))
                                                :
                                                (permission('admin/v1/category/delete') && (<button className="status inactive" title="Delete" onClick={() => handleDeleteList(item.uuid)}><FaTrashAlt />Delete</button>))
                                        }

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="pagination">
                    <span>Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filterListData.length)} of {filterListData.length} entries</span>
                    <span className="pagination-buttons">
                        <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} totalItem={filterListData.length} lengthOfPage={lengthOfPage} />
                    </span>
                </div>
            </div>
            {/* Modal for Delete Confirmation */}
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                onConfirm={handleConfirm}
                action={`Are you sure you want to ${action} this category ?`}
            />


            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />
        </>
    );
}

export { CategorySection };


