import React from 'react'
import { MergeComponents } from '../../../CommonComponents/MergeComponents'
import { ManagePage } from '../ManagePageChild/ManagePage'

function ManagePageParent() {
    return (
        <>
            <MergeComponents getComponent={<ManagePage />} />
        </>
    )
}
export { ManagePageParent }