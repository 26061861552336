import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { Customer } from '../CustomerChild/Customer';

function CustomerParent() {
    return (
        <>
            <MergeComponents getComponent={<Customer />} />
        </>
    )
}
export { CustomerParent }