import React from "react";
import { AccountMerge } from "../../AccountMerge";
import { AddressDetailChild } from "../AddressDetailChild/AddressDetailChild";

function AddressDetailParent(){
    return(
        <>
        <AccountMerge getComponent={<AddressDetailChild/>}/>
        </>
    )
}
export{AddressDetailParent}