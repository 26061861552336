import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { AiFillDelete } from "react-icons/ai";
// import { HTMLToPlainText } from "../../CommonComponents/HtmlToPlainText";
import { FaCheckCircle } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { FaRedo } from "react-icons/fa";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdTextIncrease } from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { useRole } from "../../../../../Routing/Routing";
import Modal from "../../../CommonComponents/Popup";

function OtherField() {
  
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);
    const token = sessionStorage.getItem('tokenKey');

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);
    const [inputSearch, setInputSearch] = useState();
    const [getID, setGetId] = useState();
    const [action, setAction] = useState();
    const [popup, setPopup] = useState(false);

    const [type, setType] = useState('ALL');

    const items = async (data) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/dynamicField/list/${data}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in filed apiaa')
        }
    };

    useEffect(() => {
        items('All');
    }, []);

    // Active Items
    const activeItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/dynamicField/activate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in active itme api')
        }
    };

    // INACTIVE ITEMS
    const inactiveItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/dynamicField/inactivate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in inactive api of itme')
        }
    };

    // DELTED ITEMS FROM ITEMS
    const removeItems = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/dynamicField/delete/${uuid}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in delted itms api')
        }
    };

    // // REVIVE ITEMS
    // const revive = async (uuid) => {
    //     try {
    //         const res = await fetch(`${BASE_URL}/admin/v1/dynamicField/revive/${uuid}`, {
    //             method: "PUT",
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         });
    //         const getRes = await res.json();
    //         if (getRes.errorCode === 0) {
    //             // Assuming listType is a variable that determines which data list to set
    //             switch (type) {
    //                 case "ALL":
    //                     await items("ALL");
    //                     break;
    //                 case "ACTIVE":
    //                     await items("Active");
    //                     break;
    //                 case "INACTIVE":
    //                     await items("Inactive");
    //                     break;
    //                 case "DELETED":
    //                     await items('Deleted');
    //                     break;
    //                 default:
    //                     console.log("Unknown list type");
    //             }
    //         } else {
    //             console.log('Error in response');
    //         }
    //         setInputSearch("");
    //     } catch (e) {
    //         console.log(e, 'error in revive api')
    //     }
    // };

    // HANDEL REVIVE API
    const handelRevive = (uuid) => {
        setAction('RESTORE');
        setPopup(true);
        setGetId(uuid);
    };

    // HANDEL DELTED
    const handelDeleted = (uuid) => {
        setAction('DELETED');
        setGetId(uuid);
        setPopup(true);
    };

    // HANDEL ACTIVE AND INACTIVE SECTIONS
    const handelActive = (uuid) => {
        setAction('ACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    const handelInactive = (uuid) => {
        setAction('INACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    // Update
    const navToUpdate = useNavigate();

    const updateItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/dynamicField/get/${uuid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navToUpdate('/admin/updateField', { state: getRes.responsePacket })
            }
        } catch (e) {
            console.log(e, 'erron in get Update in api of fields');
        }
    };

    // CONFIRM POP UP
    const confirmPop = async () => {
        if (popup) {
            switch (action) {
                case 'ACTIVE': await activeItem(getID);
                    break;
                case 'INACTIVE':
                    await inactiveItem(getID);
                    break;
                case "DELETED":
                    await removeItems(getID);
                    break;
                case 'RESTORE':
                    // await revive(getID);
                    await activeItem(getID)
                    break;
                case 'UPDATE':
                    // await updateItem(getID)
                    break;
                case 'Out Of Stoke':
                    // await stoke(getID);
                    break;

                default:
                    console.log('no such action available');
            }
        };
        setAction('');
        setPopup(false);
    };

    // CANCEL THE POPUP
    const closePop = () => {
        setPopup(false);
        setAction('');
    };

    return (
        <>
            <div className="category">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Dynamic Fields</h4>
                        </div>
                        <div className="category-btn">
                            <Link to='/admin/addField'>
                                <button>Add Fields</button>
                            </Link>
                        </div>
                    </div>
                    <hr />

                    <div className="menu-table-container">
                        <div className="order-list">
                            <div className="order-list-header dynamic-head">
                                {/* <div className='tittle'>User Id</div> */}
                                <div className='tittle'>Type</div>
                                <div className="tittle">Title</div>
                                <div className='tittle'>Status</div>
                                <div className='tittle'>Action</div>
                            </div>


                            {filterList.map((item) => {
                                return (
                                    <>
                                        <div key={item} className='order-list-row dynamic-head'>
                                            <div className='tittle'>{item.type}</div>
                                            <div className='tittle'>{item.title}</div>
                                            <div className='tittle'>
                                                <button
                                                    className={`status ${item.active ? 'active-btn' : 'inactive'}`}
                                                >
                                                    {item.active ? 'Active' : 'Inactive'}
                                                </button>
                                            </div>
                                            <div className='btn'>

                                                <div>

                                                    {
                                                        item.active ? <button
                                                            className={`status inactive`} onClick={() => handelInactive(item.uuid)} ><CgUnavailable /></button>
                                                            :
                                                            <button
                                                                className={`status active-btn`} onClick={() => handelActive(item.uuid)} ><FaCheckCircle /></button>
                                                    }

                                                </div>
                                                <button className="action-btn edit-btn" onClick={() => updateItem(item.uuid)}><FaEdit /></button>
                                                {
                                                    item.deleted ?
                                                        <button className="action-btn revive-btn" onClick={() => { handelRevive(item.uuid) }}>
                                                            <FaRedo />
                                                        </button>
                                                        :
                                                        <button className="action-btn delete-btn" onClick={() => { handelDeleted(item.uuid) }}>
                                                            <AiFillDelete />
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            })}

                        </div>
                    </div>

                </div>
            </div>

            <Modal
                isOpen={popup}
                onClose={closePop}
                onConfirm={confirmPop}
                action={action}
            />


        </>
    )
}
export { OtherField }