import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";
import { useSelector } from "react-redux";
// import { HTMLToPlainText } from "../../../CommonComponents/HtmlToPlainText";
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";
import { uploadImage } from "../../../CommonComponents/ImageUpload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function UpdateDisplayCate() {
    let navigate = useNavigate();
    const editor = useRef(null);

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const getToken = sessionStorage.getItem('tokenKey');


    // const [parentCat, setParentCat] = useState([]);
    // console.log(parentCat, 'parentcategory');
    const [showSuggestion, setShowSuggestion] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setShowSuggestion(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    // console.log(multiplecate, 'multiplecate');



    // GET UPDATE DATA
    const product = useSelector((state) => state.AdminSlice.value.user)

    const [parentCategoryName, setParentCategoryName] = useState(product.parentCategoryDetail?.parentTitle);
    const [parentCategoryId, setParentCategoryId] = useState(product.parentCategoryDetail?.parentUuid);
    const [categoryName, setCategoryName] = useState(product.title);
    const [description, setDescription] = useState(product.description);
    const [imagePreviews, setImagePreviews] = useState(product.categoryIconUrl ? [product.categoryIconUrl] : []);

    const convertToPlainText = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };
    const descriptions = convertToPlainText(description)


    const cateValues = {
        title: categoryName,
        description: descriptions,
        // categoryIconUrl: imagePreviews.join(','),
    };


    // UPDATE ITEM
    const postUpdateItem = async (id) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/display-category/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${getToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cateValues)
            });

            const getRes = await res.json();
            if (res.ok) {
                setShow(true);
                setAction('Category Updated Successfully');
                setNavv('ok');
                console.log(getRes, 'data posted or updated');
            } else {
                setShow(true);
                setAction('something went wrong!');
                setNavv('ok');
                console.log('erron in responce')
            }
        } catch (e) {
            console.log(e, 'error in updating api');
        };
    };


    // GETING CATEGORY LIST
    const [multiplecate, setMultiplecate] = useState([]);
    const [filterCategory, setFilterCategory] = useState([...multiplecate]);



    const filterCate = (e) => {
        const getInput = e.target.value
        const newMultiple = multiplecate.filter((itm) => itm.value.toLowerCase().includes(getInput.toLowerCase()));
        setFilterCategory(newMultiple);
        // setParentCat('');
        setParentCategoryId('');
    };

    const [parentStore, setParentStore] = useState([]);
    // const [cateInput, setCateInput] = useState('');

    const addCateInput = (e) => {
        if (e && !parentStore.includes(e)) {
            // setCateInput(e.value);
            // setParentCat(e.key);
            setParentCategoryName(e.label);
            setParentCategoryId(e.value);
            setShowSuggestion(false);
        };
        // console.log(parentStore, 'parentStore');
    };



    // HANDLE POPUP
    const [show, setShow] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const navToCat = useNavigate();

    const handleOk = () => {
        navToCat('/admin/displayCat');
    };


    // HANDEL IMAGE

    const handleFileChange = async (e) => {
        const files = e.target.files;
        const uploadedUrls = [];
        // Loop through each selected file and upload
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                uploadedUrls.push(imageUrl.responsePacket);
                // setImagePreviews((prev=> [...prev, imageUrl]));
                console.log('image upload');
            }
        };
        setImagePreviews(uploadedUrls); // Update state with all uploaded image URLs
    };

    const handleDeleteImage = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this image?");
        if (confirmDelete) {
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews
        }
    };

    const [errors, setErrors] = useState({});
    // Refs for scrolling
    const titleRef = useRef(null);
    const handleValidation = () => {
        let newErrors = {};
        // Check each field
        if (!categoryName?.trim()) newErrors.title = "Title is required.";
        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }

        return true;
    };

    const scrollToField = (field) => {
        switch (field) {
            case "title":
                titleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        handleValidation();
        if (handleValidation()) {
            postUpdateItem(product.uuid);
        }
    };




    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Update Display Category</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form" ref={titleRef}>
                            {/* Title Input */}
                            <div className="add w-100 m-b2">
                                <label style={errors.title && { color: "red" }}>Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    onChange={(e) => { e.preventDefault(); setCategoryName(e.target.value) }}
                                    value={categoryName}
                                // placeholder="Tittle"
                                />
                            </div>


                            {/* <div className="d-flex w-100 m-b2 d-other"> */}
                            {/* Tax Multi Select */}
                            {/* <div className="add">
                                    <label>Select Tax (Multi Select)</label>
                                    <input
                                        type="text"
                                        name="tax"
                                        value={formData.tax}
                                        onChange={handleInputChange}
                                    />
                                </div> */}

                            {/* Time Availability */}
                            {/* <div className="add">
                                    <label>Time Availability</label>
                                    <div className="d-flex check-box-avail">
                                        <input
                                            type="checkbox"
                                            name="availableAllTime"
                                            checked={formData.availableAllTime}
                                            onChange={handleInputChange}
                                            id="check-box"
                                        />
                                        <label htmlFor="check-box">Available All Time</label>
                                    </div>
                                </div> */}
                            {/* </div> */}

                            <div className="editer m-b2">
                                {/* Jodit Editor */}
                                <div className="add w-100">
                                    <label>Description</label>
                                    {/* <JoditEditor
                                        ref={editor}
                                        value={description}
                                        tabIndex={1}
                                        onChange={(newContent) => setDescription(newContent)}
                                    /> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    "heading",
                                                    "|",
                                                    "bold",
                                                    "italic",
                                                    "link",
                                                    "bulletedList",
                                                    "numberedList",
                                                    "|",
                                                    "indent",
                                                    "outdent",
                                                    "|",
                                                    "imageUpload",
                                                    "blockQuote",
                                                    "mediaEmbed",
                                                    "undo",
                                                    "redo",
                                                ],
                                            },
                                        }}
                                        data={description}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Category Icon File Upload */}

                            {/* <div className="add w-100">
                                <label>Category Icon</label>

                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        // multiple
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews.length > 0 ?
                                            <label htmlFor="category-icon">
                                                <div className="custom-label">
                                                    <RiImageAddFill />
                                                    <span>Change Image</span>
                                                </div>
                                            </label>
                                            :
                                            <label htmlFor="category-icon" className="custom-file-input">
                                                <div className="text">
                                                    <div className="icon">
                                                        <h1><IoCloudUploadOutline /></h1>
                                                        <h4>Add Images</h4>
                                                    </div>
                                                </div>
                                            </label>
                                    }

                                </div>


                                {
                                    imagePreviews.length > 0 && <div className="image-preview-wrapper">
                                        {
                                            imagePreviews.map((preview, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img
                                                        src={preview}
                                                        alt={`Category Icon ${index + 1}`}
                                                        style={{ objectFit: 'cover' }}
                                                    />

                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteImage(index)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: '1px',
                                                            background: 'red',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div> */}


                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </div>
            </div>

            <SuccessPopUp
                isOpen={show}
                navv={navv}
                // FutherMore={'cancel'}
                action={action}
                onConfirm={handleOk}
                onClose={handleOk}
            />
        </>
    );
}

export { UpdateDisplayCate };

