import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const BASE_URL = process.env.REACT_APP_PP_URL;

const deviceId = localStorage.getItem('device_id');

export const profileData = createAsyncThunk(
    'profile',
    async () => {
        try {
            const key = localStorage.getItem('userId');
            const res = await fetch(`${BASE_URL}/customer/v1/get`, {
                headers: {
                    'Authorization': `Bearer ${key}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                return getRes
            }
        } catch (e) {
            console.log(e, 'error in profile api data')
        }
    }
);

const profileSlice = createSlice({
    name: 'profileData',
    initialState: {
        data: null,
        loading: false,
        error: null
    },
    reducers:{
        updateProfile(state,action){
            state.data = action.payload;
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(profileData.pending, (state) => {
            state.loading = true;
          })
          .addCase(profileData.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
          })
          .addCase(profileData.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
          })
    },
});

export const {updateProfile} = profileSlice.actions;
export default profileSlice.reducer;