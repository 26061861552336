import React from 'react';

function InputPopup({isOpen,message,onOk,onCancel,inValue,inChange,ok,cancel}) {

    if (!isOpen) return null;


    return (
        <>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>{message}</h3>
                    <input type="text" className='inputPopup' value={inValue} onChange={(e)=>inChange(e.target.value)}/>
                    <div className="modal-actions">
                        <button className="confirm-btn" onClick={onOk}>{ok}</button>
                        <button className="cancel-btn" onClick={onCancel}>{cancel}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export { InputPopup }