import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../CommonComponents/Navbar";
import FeaturedBrand from "./Home/FeaturedBrand";
import Footer from "../CommonComponents/Footer";
import Banners from '../CommonComponents/Slider';
import "../../../App.css";
import uuid from 'react-uuid'; // Import uuidv4
import { CartDataa, check, Getdata } from "../../../Utilities/Utils";
import { current } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartCount, fetchHeaderData, updateHeaderData } from "../../../Store/apiSlice";
import { FilterCategory } from "./Home/FilterCategor";
import { Skeleton } from "../CommonComponents/Skeleton";

function Home() {

    const getDeviceId = () => {
        let deviceId = localStorage.getItem("device_id");
        if (!deviceId) {
            deviceId = uuid();
            localStorage.setItem("device_id", deviceId);
        }
        return deviceId;
    };

    const BASE_URL = process.env.REACT_APP_PP_URL

    const [dataa, setData] = useState([]);
    const [error, setError] = useState('');

    const key = localStorage.getItem('userId');
    console.log(key, 'keyy');

    const deviceId = localStorage.getItem("device_id");


    const [loading, setLoading] = useState(true);


    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await Getdata(); // Fetch data function
                if (result) {
                    setData(result);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        }

        fetchData();
        // dispatch(fetchHeaderData());
    }, [dispatch]);

    const data = useSelector((state) => state.header.data);

    // const [cartItems, setCartItems] = useState();
    // const [filterdata, setfilterdata] = useState(data?.responsePacket?.itemDetailList);
    const [cart, setCart] = useState(data?.responsePacket);

    const AddToCart = async (itemUuid, couponCodeUuid) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            // Add Authorization header only if key exists
            if (key) {
                headers['Authorization'] = `Bearer ${key}`;
            }

            const response = await fetch(`${BASE_URL}/guest-customer/v1/cart/updateCart/${deviceId}/${data?.responsePacket?.uuid}`, {
                method: "PUT",
                headers,
                body: JSON.stringify({
                    // Send book or product data as needed
                    // addressUuid: '',
                    itemUuid: itemUuid,
                    couponCodeUuid: couponCodeUuid,
                }),
            });

            if (!response.errorCode === 0) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.errorCode === 0) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
                // CartData();
            };
            console.log(result, 'Add');
        } catch (error) {
            console.error("Fetch error in cart add:", error);
            setError('An error occurred while fetching banner data.');
        }
    };

    const itemQuantity = async (itemUuid, itemQuantity) => {
        try {

            const headers = {
                'Content-Type': 'application/json',
            };

            // Add Authorization header only if key exists
            if (key) {
                headers['Authorization'] = `Bearer ${key}`;
            }
            const response = await fetch(`${BASE_URL}/guest-customer/v1/cart/updateItemQuantity/${data?.responsePacket.uuid}/${itemUuid}/${itemQuantity}`, {
                method: "PUT",
                headers,
            });
            if (!response.errorCode === 0) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.errorCode === 0) {
                // CartData();
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
            console.log(result, 'Quantity');
        } catch (error) {
            console.error("Fetch error in cart add:", error);
            setError('An error occurred while fetching banner data.');
        }
    };

    const removeCartItem = async (itemUuid) => {
        console.log(itemUuid, 'itemUuidddddd')
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            // Add Authorization header only if key exists
            if (key) {
                headers['Authorization'] = `Bearer ${key}`;
            }
            const res = await fetch(`${BASE_URL}/guest-customer/v1/cart/removeItemFromCart/${data?.responsePacket?.uuid}/${itemUuid}`, {
                method: "PUT",
                headers,
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // CartData();
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            };
        } catch (e) {
            console.log(e, 'error in cart remove api')
        }
    };


    useEffect(() => {
        getDeviceId();
    }, [dispatch]);



    return (
        <>

            <Navbar
                filterdata={dataa?.responsePacket?.itemDetailList}
                removeAllCart={() => { }}
                cartremove={() => { }}
                cartItems={dataa?.responsePacket}
                itemaddQuantity={() => { }}
                addToCart={() => { }}
            />


            {loading ? (
                <div className="filter-category-skeleton">
                    {Array(4)
                        .fill()
                        .map((_, index) => (
                            <Skeleton
                                key={index}
                                className="filter-category-skeleton-item"
                                style={{ height: '175px', width: '100%', margin: "5px" }}
                            />
                        ))}
                </div>
            ) : (
                <FilterCategory
                    data={dataa}
                    cart={dataa?.responsePacket}
                    addToCart={() => { }}
                    itemQuantity={() => { }}
                    removeCartItem={() => { }}
                />
            )}


            {loading ? (
                <Skeleton className="banner-skeleton" style={{ height: '300px', width: '100%' }} />
            ) : (
                <Banners banner={dataa} />
            )}
            {loading ? (
                <div className="featured-skeleton-container">
                    {Array(5)
                        .fill()
                        .map((_, index) => (
                            <Skeleton
                                key={index}
                                className="featured-skeleton"
                                style={{ height: '150px', width: '200px', margin: '10px' }}
                            />
                        ))}
                </div>
            ) : (
                <FeaturedBrand featured={dataa} cart={data?.responsePacket} addToCart={AddToCart} itemQuantity={itemQuantity} removeCartItem={removeCartItem} />
            )}



            <Footer />

        </>
    );
}

export { Home };



