import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function AddressDetailChild() {
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const key = localStorage.getItem("userId");

    const location = useLocation();
    const data = location && location.state;

    const states = ["Rajasthan", "Maharashtra", "Karnataka", "Gujarat", "Delhi", "Uttar Pradesh"]; // State list

    const [formData, setFormData] = useState({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        mobileNumber: data?.mobileNumber || "",
        addressLine1: data?.addressLine1 || "",
        landMark: data?.landMark || "",
        countryTitle: "India", // Default to India
        stateTitle: data?.stateTitle || "",
        cityTitle: data?.cityTitle || "",
        pinCode: data?.pinCode || "",
    });

    const [isFormValid, setIsFormValid] = useState(false); // Form validation state
    const [errorMessage, setErrorMessage] = useState(""); // Error message state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Validate the form whenever formData changes
    useEffect(() => {
        const isValid = Object.values(formData).every((value) => value.trim() !== "");
        setIsFormValid(isValid);
        if (isValid) setErrorMessage(""); // Clear error if the form becomes valid
    }, [formData]);

    const navBack = useNavigate();

    const handleSave = () => {
        if (!isFormValid) {
            setErrorMessage("All fields are required.");
            return;
        }

        if (data) {
            UpdateAddress(data?.uuid);
        } else {
            addAddress();
        }
    };

    const addAddress = async () => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/save`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${key}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navBack(-1);
            }
        } catch (e) {
            console.log(e, "error in add Address");
        }
    };

    const UpdateAddress = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/update/${uuid}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${key}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navBack(-1);
            }
        } catch (e) {
            console.log(e, "error in update Address");
        }
    };

    // Function to check if a field is empty
    const isFieldEmpty = (fieldName) => {
        return formData[fieldName]?.trim() === "";
    };

    return (
        <>
            <div className="main-address">
                <div className="address-detail-form">
                    <div className="address-box-text">
                        <h3>{data ? "Update Address" : "Add Address"}</h3>
                    </div>
                    <div className="address-box">
                        <div className="form-row">
                            <div className="form-group">
                                <label>Country/Region</label>
                                <input
                                    type="text"
                                    name="countryTitle"
                                    value={formData.countryTitle}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label>Phone <span className={isFieldEmpty("mobileNumber") ? "required" : ""}>*</span></label>
                                <input
                                    className="in"
                                    type="number"
                                    placeholder="Enter Number"
                                    name="mobileNumber"
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>First Name <span className={isFieldEmpty("firstName") ? "required" : ""}>*</span></label>
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name <span className={isFieldEmpty("lastName") ? "required" : ""}>*</span></label>
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Apartment, Suite, etc. <span className={isFieldEmpty("addressLine1") ? "required" : ""}>*</span></label>
                            <input
                                type="text"
                                placeholder="Apartment, Suite, etc."
                                name="addressLine1"
                                value={formData.addressLine1}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Land Mark <span className={isFieldEmpty("landMark") ? "required" : ""}>*</span></label>
                            <input
                                type="text"
                                placeholder="Land Mark"
                                name="landMark"
                                value={formData.landMark}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>City <span className={isFieldEmpty("cityTitle") ? "required" : ""}>*</span></label>
                                <input
                                    type="text"
                                    placeholder="City"
                                    name="cityTitle"
                                    value={formData.cityTitle}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>State <span className={isFieldEmpty("stateTitle") ? "required" : ""}>*</span></label>
                                <select
                                    name="stateTitle"
                                    value={formData.stateTitle}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Select State</option>
                                    {states.map((state) => (
                                        <option key={state} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Pin Code <span className={isFieldEmpty("pinCode") ? "required" : ""}>*</span></label>
                                <input
                                    className="pin"
                                    type="number"
                                    placeholder="Pin Code"
                                    name="pinCode"
                                    value={formData.pinCode}
                                    onChange={handleChange}
                                />
                                
                            </div>
                        </div>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <div className="form-actions">
                            <button
                                className="cancel-button"
                                onClick={() => navBack(-1)}
                            >
                                Cancel
                            </button>
                            <button
                                className="save-button"
                                onClick={handleSave}
                            >
                                {data ? "Update" : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { AddressDetailChild };



