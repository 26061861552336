import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

const OrderPrint = ({ isOpen, orders, onClose }) => {

    console.log(orders, 'orders')
    const componentRef = useRef();
    const reactToPrintFn = useReactToPrint({ content: () => componentRef.current });

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');

    const [parsels, setParsels] = useState([]);
    const [loading, setLoading] = useState(false);

    const orderList = async () => {
        setLoading(true);
        try {
            const res = await Promise.all(
                orders?.map((id) => fetch(`${BASE_URL}/customer/v1/order/getOrderDetail/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then((re) => re.json()))
            )
            console.log(res, 'response');
            setParsels(res);
        } catch (e) {
            console.log(e, 'error in orderList api')
        } finally {
            setLoading(false); // End loading
        }
    };
    useEffect(() => {
        if (isOpen) {
            orderList();
        }
    }, [isOpen]);
    console.log("ComponentRef Content:", componentRef.current);

    const handlePrint = () => {
        window.print();
    };

    if (!isOpen) return null

    return (
        <>
            <div className="main-printer" onClick={onClose}>


                <button className="printer-button" onClick={handlePrint}><h1>print</h1></button>
                <div className="modal-printer" style={{ overflow: "scroll" }} >
                    {
                        loading ?
                            <p>loading</p>
                            :
                            <>


                                <div className="flex printingContainer">
                                    {
                                        parsels.map((itm) => {
                                            return (
                                                <>
                                                    <div className="packing-slip page-break" >
                                                        {/* Header */}
                                                        <header className="packing-slip-header">
                                                            <h1 className="packing-slip-title">THE BOOKS 24</h1>
                                                            <div className="packing-slip-order-details">
                                                                <p><strong>Order #</strong> {itm.responsePacket.orderId}</p>
                                                                <p><strong>Date:</strong></p>
                                                            </div>
                                                        </header>

                                                        {/* Address Section */}
                                                        <section className="packing-slip-address">
                                                            <div className="packing-slip-ship-to">
                                                                <h3>SHIP TO</h3>
                                                                <p>{itm.responsePacket.customerDetail?.userCustomerFirstName}</p>
                                                                <p>{itm.responsePacket?.customerAddressDetail ? itm.responsePacket?.customerAddressDetail : ''}</p>
                                                                <p>{itm.responsePacket?.customerDetail?.userCustomerIsdCode}{itm.responsePacket?.customerDetail?.userCustomerMobile}</p>
                                                            </div>
                                                            <div className="packing-slip-bill-to">
                                                                {/* <h3>BILL TO</h3>
                                                <p>{order.billTo.name}</p>
                                                <p>{order.billTo.address}</p>
                                                <p>{order.billTo.contact}</p> */}
                                                            </div>
                                                        </section>

                                                        {/* Items Table */}
                                                        <section className="packing-slip-items">
                                                            <h3>ITEMS</h3>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Item</th>
                                                                        <th>Quantity</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {itm.responsePacket.itemDetailList?.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className="packing-slip-item-details">
                                                                                    <img src={item.itemImageUrls?.[0]} alt={item.name} className="packing-slip-item-image" />
                                                                                    <p>{item.title}</p>
                                                                                    {/* <p><strong>SKU:</strong> {item.sku}</p> */}
                                                                                </div>
                                                                            </td>
                                                                            <td>{item.quantity}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </section>

                                                        {/* Footer */}
                                                        <footer className="packing-slip-footer">
                                                            <p>Thank you for shopping with us!</p>
                                                            <p><strong>The Books24</strong></p>
                                                            <p>Shri Shyam Book Depot, Jhotwara, Jaipur, Rajasthan</p>
                                                            <p>thebooks24india@gmail.com</p>
                                                        </footer>

                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    );
};

export default OrderPrint;
