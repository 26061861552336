import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";
import { uploadImage } from "../../../CommonComponents/ImageUpload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { MagnifyImage } from "../../../CommonComponents/MagnifyImage";

function UpdateCategory() {

    let navigate = useNavigate();
    const getToken = sessionStorage.getItem('tokenKey');

    const BASE_URL = process.env.REACT_APP_PP_URL;

    const convertToPlainText = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    // GET UPDATE DATA
    const location = useLocation();
    const product = location && location.state;

    const [parentCategoryId, setParentCategoryId] = useState(product.parentCategoryDetail && { value: product.parentCategoryDetail?.parentUuid, label: product.parentCategoryDetail?.parentTitle });
    const [categoryName, setCategoryName] = useState(product.title);
    const [description, setDescription] = useState(product.description);
    const [imagePreviews, setImagePreviews] = useState(product.categoryIconUrl ? [product.categoryIconUrl] : []);
    const descriptions = convertToPlainText(description);

    const cateValues = {
        parentCategoryUuid: parentCategoryId?.value,
        title: categoryName,
        description: descriptions,
        categoryIconUrl: imagePreviews?.join(','),
    };

    // UPDATE ITEM
    const postUpdateItem = async (id) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/category/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${getToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cateValues)
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setShow(true);
                setAction('Category Updated Successfully');
                setNavv('ok');
                console.log(getRes, 'data posted or updated');
            } else {
                setShow(true);
                setAction('something went wrong!');
                setNavv('ok');
                console.log('erron in responce')
            }
        } catch (e) {
            console.log(e, 'error in updating api');
        };
    };

    // GETING CATEGORY LIST
    const [multiplecate, setMultiplecate] = useState([]);
    const [filterCategory, setFilterCategory] = useState([...multiplecate]);

    const PostCate = async () => {
        try {
            const response = await fetch(`${BASE_URL}/admin/v1/category/parent-category-list-in-key-value`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getToken}`
                }
            });
            const getResponce = await response.json();
            console.log(getResponce, 'geteresponce in update carws')
            if (getResponce.errorCode === 0) {
                console.log(getResponce, 'post catgory api');
                setMultiplecate(getResponce?.responsePacket);
                setFilterCategory(getResponce?.responsePacket);
            }

        } catch (e) {
            console.log(e, 'error in update section post category parent list api')
        }
    };

    useEffect(() => {
        PostCate();
    }, []);

    // HANDLE POPUP
    const [show, setShow] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const navToCat = useNavigate();

    const handleOk = () => {
        navToCat('/admin/category');
    };

    // HANDLE IMAGE SECTION
    const handleFileChange = async (e) => {
        const files = e.target.files;
        const uploadedUrls = [];
        // Loop through each selected file and upload
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                uploadedUrls.push(imageUrl.responsePacket);
            }
        };
        setImagePreviews(uploadedUrls); // Update state with all uploaded image URLs
    };

    const handleDeleteImage = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this image?");

        if (confirmDelete) {
            // Remove the image from the preview and formData arrays
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews

        }
    };


    const [errors, setErrors] = useState({});
    // Refs for scrolling
    const titleRef = useRef(null);
    const handleValidation = () => {
        let newErrors = {};
        // Check each field
        if (!categoryName?.trim()) newErrors.title = "Title is required.";
        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }

        return true;
    };

    const scrollToField = (field) => {
        switch (field) {
            case "title":
                titleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        if (handleValidation()) {
            postUpdateItem(product.uuid)
        }
    };

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Update Category</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form">
                            <div className="d-flex w-100 m-b2 d-other">
                                {/* Select Parent Category */}
                                <div className="add parentid">
                                    <label>Select Parent Category</label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        name="colors"
                                        options={filterCategory}
                                        value={parentCategoryId}
                                        onChange={(e) => setParentCategoryId(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>

                                {/* Title Input */}
                                <div className="add" ref={titleRef}>
                                    <label style={errors.title && { color: "red" }}>Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        onChange={(e) => { setCategoryName(e.target.value) }}
                                        value={categoryName}
                                    />
                                </div>
                            </div>

                            <div className="editer m-b2">
                                <div className="add w-100">
                                    <label>Description</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    "heading",
                                                    "|",
                                                    "bold",
                                                    "italic",
                                                    "link",
                                                    "bulletedList",
                                                    "numberedList",
                                                    "|",
                                                    "indent",
                                                    "outdent",
                                                    "|",
                                                    "imageUpload",
                                                    "blockQuote",
                                                    "mediaEmbed",
                                                    "undo",
                                                    "redo",
                                                ],
                                            },
                                        }}
                                        data={description}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Category Icon File Upload */}

                            <div className="add w-100">
                                <label>Category Icon</label>
                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews.length > 0 ?
                                            <label htmlFor="category-icon" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="custom-label">
                                                    <RiImageAddFill />
                                                    <span>Change Image</span>
                                                </div>
                                            </label>
                                            :
                                            <label htmlFor="category-icon" className="custom-file-input" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="text">
                                                    <div className="icon">
                                                        <h1><IoCloudUploadOutline /></h1>
                                                        <h4>Add Images</h4>
                                                    </div>
                                                </div>
                                            </label>
                                    }

                                </div>

                                {/* Image Preview Section */}
                                {
                                    imagePreviews?.length > 0 && <div className="image-preview-wrapper">
                                        {
                                            imagePreviews.map((preview, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img
                                                        src={preview}
                                                        alt={`Category Icon ${index + 1}`}
                                                        style={{ objectFit: 'cover' }}
                                                        onClick={() => maginifyImage(preview)}
                                                    />
                                                    {/* Delete Button */}
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteImage(index)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: '1px',
                                                            background: 'red',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </div>
            </div>

            <SuccessPopUp
                isOpen={show}
                navv={navv}
                // FutherMore={'cancel'}
                action={action}
                onConfirm={handleOk}
                onClose={handleOk}
            />

            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />

        </>
    );
}

export { UpdateCategory };

