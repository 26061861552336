import { configureStore } from "@reduxjs/toolkit";
import AdminSlice from './ReduxSlice';
import itemSlice from './ItemSlice';
import staffSlice from './staffSlice';
import headerSlice from './apiSlice';
import profileSlice from './ProfileSlice';

const store = configureStore({
    reducer: {
        AdminSlice: AdminSlice,
        itemSlice: itemSlice,
        staffSlice: staffSlice,
        header: headerSlice,
        profileData: profileSlice,
    }
})
export { store }