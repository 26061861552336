import React, { useState } from "react";
import { SlideBar } from "./SliderBar";
import { Header } from "./Header";

function MergeComponents(props) {

    const [getActiveClass, setGetActiveClass] = useState(true)
    const handleActiveClass =()=>{
        setGetActiveClass(!getActiveClass)
    }

    return (
        <>
            <div className="merge">
                <div className="mainLite">
                    <SlideBar activeClass={getActiveClass}/>
                </div>
                <div className="contant">
                    <Header getActiveClass={handleActiveClass}/>
                    {props.getComponent}
                </div>
            </div>
        </>
    )
}
export { MergeComponents }