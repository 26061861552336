import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";

function UpdatePage() {

    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state
    // const id = useSelector((state) => state.AdminSlice.value.user)
    console.log(id, 'iddddd');


    const BASE_URL = process.env.REACT_APP_PP_URL
    const token = sessionStorage.getItem('tokenKey');

    const [title, setTitle] = useState(id?.title);

    const updateModal = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/update/${uuid}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: title
                })
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navBack(-1);
            }

        } catch (e) {
            console.log(e, 'error in update modal api')
        }
    };

    const assessType = [
        {
            id: 1,
            name: "CREATE",
        },
        {
            id: 2,
            name: "READ",
        },
        {
            id: 3,
            name: "UPDATE",
        },
        {
            id: 4,
            name: "DELETE",
        },
    ]


    const [showMethods, setShowMethods] = useState(false);
    const methodRef = useRef();
    const ref = useRef(null);


    const methods = [
        {
            id: 1,
            name: "GET",
        },
        {
            id: 2,
            name: "PUT",
        },
        {
            id: 3,
            name: "POST",
        },
        {
            id: 4,
            name: "DELETE",
        },
        {
            id: 1,
            name: "OTHER",
        },
    ];

    const [description, setDescription] = useState();

    console.log(description, 'description');

    const [forms, setForms] = useState([
        // { title: "", methodType: "", url: "", accessType: "", showMethods: false, showSuggestion: false },
    ]);

    // Handle input changes
    const handleChange = (index, event) => {
        const updatedForms = [...forms];
        console.log(event, 'type of event');
        if (typeof event === "string") {
            updatedForms[index]["description"] = event;
            setForms(updatedForms);
        } else {
            const { name, value } = event.target;
            updatedForms[index][name] = value;
            setForms(updatedForms);
        }
    };

    const toggleDropdown = (index, dropdownType) => {
        const updatedForms = forms.map((form, i) => ({
            ...form,
            showMethods: dropdownType === 'method' && i === index ? !form.showMethods : form.showMethods,
            showSuggestion: dropdownType === 'accessType' && i === index ? !form.showSuggestion : form.showSuggestion,
        }));
        setForms(updatedForms);
    };

    // outside click
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (!methodRef?.current?.contains(event.target)) {
    //             const updatedForms = forms.map(form => ({
    //                 ...form,
    //                 showMethods: false,
    //             }));
    //             setForms(updatedForms);
    //             console.log('mothodreff')
    //         } else if (!ref?.current?.contains(event.target)) {
    //             const updatedForms = forms.map(form => ({
    //                 ...form,
    //                 showSuggestion: false,
    //             }));
    //             setForms(updatedForms);
    //             console.log('outSide click ref')
    //         }
    //     };
    //     document.addEventListener("mousedown", handleClickOutside);

    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [forms]);


    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (!ref?.current?.contains(event.target)) {
    //             const updatedForms = forms.map(form => ({
    //                 ...form,
    //                 showSuggestion: false,
    //             }));
    //             setForms(updatedForms);
    //             console.log('outSide click ref')
    //         }
    //     }
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [forms]);

    // Add a new form
    const handleAddForm = () => {
        setForms([
            ...forms,
            { title: "", methodType: "", url: "", accessType: "", showMethods: false, showSuggestion: false },
        ]);
    };
    const handleRemoveForm = (index) => {
        setForms(forms.filter((_, i) => i !== index));
    };



    // UPDATE SECTION
    // Submit the forms data
    const navv = useNavigate();

    const handleSubmit = async () => {

        const sanitizedForms = forms.map(({ showMethods, showSuggestion, ...rest }) => rest);
        const apiUrl = `${BASE_URL}/admin/v1/module/${id?.uuid}/save-module-urls`; // Replace with your actual API endpoint

        console.log(sanitizedForms, 'senitizated form')
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(sanitizedForms),

            });

            const result = await response.json();
            if (result.errorCode === 0) {
                console.log("Data posted successfully:", result);
                navv('/admin/managePage')
            } else {
                console.error("Failed to post data:", result);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    console.log(forms, 'formssss');


    const [getForm, setGetForm] = useState([
        { title: '', url: '', methodType: '', accessType: '', showMethods: false, showSuggestion: false, isActive: false, isInactive: false }
    ]);

    useEffect(() => {
        setGetForm([...id?.urlList || []]);
    }, []);

    const changeGetForm = (index, e) => {
        const { name, value } = e.target;
        setGetForm(prevForms =>
            prevForms.map((form, i) =>
                i === index ? { ...form, [name]: value } : form
            )
        );
    };
    const toggleDrop = (index, dropdownType) => {
        setGetForm(prevForms =>
            prevForms.map((form, i) => ({
                ...form,
                showMethods: dropdownType === 'method' && i === index ? !form.showMethods : false,
                showSuggestion: dropdownType === 'accessType' && i === index ? !form.showSuggestion : false,
            }))
        );
    };


    // const [getForm, setGetForm] = useState([
    //     { title: '', url: '', methodType: '', accessType: '', showMethods: false, showSuggestion: false, isActive: false, isInactive: false }
    // ]);

    // const changeGetForm = (index, e) => {
    //     console.log(e, 'e from click');
    //     console.log(index, 'index');
    //     const update = [...getForm];
    //     const { name, value } = e.target;
    //     console.log(name, 'name');
    //     console.log(value, 'value');
    //     update[index] = { ...update[index], [name]: value };
    //     setGetForm(update);
    // };

    // const toggleDrop = (index, dropdownType) => {
    //     const updatedForms = getForm.map((form, i) => ({
    //         ...form,
    //         showMethods: dropdownType === 'method' && i === index ? !form.showMethods : form.showMethods,
    //         showSuggestion: dropdownType === 'accessType' && i === index ? !form.showSuggestion : form.showSuggestion,
    //     }));
    //     setGetForm(updatedForms);
    // };

    const handleActive = (e, index) => {
        const update = getForm.map((itm, i) => ({
            ...itm,
            isActive: i === index && e.target.checked
        }));
        setGetForm(update);
    };

    const handelInactive = (e, index) => {
        const update = getForm.map((itm, i) => ({
            ...itm,
            isInactive: i === index && e.target.checked
        }));
        setGetForm(update);
    };
    console.log(getForm, 'getform')

    const activeUrl = async (urlUuid) => {
        try {
            const res = await fetch(`http://localhost:8094/admin/v1/module/${id.uuid}/activate-module-url/${urlUuid}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const getRes = await res.json()
            if (getRes.errorCode === 0) {
                alert(getRes.message)
            }
        } catch (e) {
            console.log(e, 'error in active')
        }
    };

    const InactiveUrl = async (urlUuid) => {
        try {
            const res = await fetch(`http://localhost:8094/admin/v1/module/${id.uuid}/deactivate-module-url/${urlUuid}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const getRes = await res.json()
            if (getRes.errorCode === 0) {
                alert(getRes.message)
            }
        } catch (e) {
            console.log(e, 'error in active')
        }
    };

    const navBack = useNavigate();
    const updateUrl = async (urlUuid, bodyy) => {
        const { title, methodType, url, accessType, ...rest } = bodyy
        const postBody = { title, methodType, url, accessType }
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/${id.uuid}/update-module-url/${urlUuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(postBody)
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                if (rest.isActive === true) {
                    activeUrl(rest.uuid);
                    console.log('goBack')
                } else if (rest.isInactive === true) {
                    InactiveUrl(rest.uuid);
                    // navBack('/admin/managePage');
                    console.log('goBack')
                }
                navBack('/admin/managePage');
            } else {
                console.log('error in update url api')
            }
        } catch (e) {
            console.log(e, 'errror in update api')
        }
    };
    const toggleRefM = useRef();
    const toggleRefA = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            let updatedForms = [...forms];
            // Check if the click is outside `methodRef`
            if (methodRef.current && !methodRef.current.contains(event.target)) {
                updatedForms = updatedForms.map(form => ({
                    ...form,
                    showMethods: false,
                }));
                console.log('method aaro')
                setForms(updatedForms);
            }
            // Check if the click is outside `suggestionRef`
            if (ref.current && !ref.current.contains(event.target)) {
                updatedForms = updatedForms.map(form => ({
                    ...form,
                    showSuggestion: false,
                }));
                console.log('ref aaro');
                setForms(updatedForms);
            }

        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [forms]);




    useEffect(() => {
        const handleClickOutside = (event) => {
            let updatedForms = [...getForm];
            // Check if the click is outside `methodRef`
            if (toggleRefM.current && !toggleRefM.current.contains(event.target)) {
                updatedForms = updatedForms.map(form => ({
                    ...form,
                    showMethods: false,
                }));
                console.log('method aaro')
                setGetForm(updatedForms);
            }
            // Check if the click is outside `suggestionRef`
            if (toggleRefA.current && !toggleRefA.current.contains(event.target)) {
                updatedForms = updatedForms.map(form => ({
                    ...form,
                    showSuggestion: false,
                }));
                console.log('ref aaro');
                setGetForm(updatedForms);
            }

        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [getForm]);

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Add Modules URL</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div style={{ width: "49%" }}>
                            <div className="add-form" style={{ width: "100%" }}>
                                <div className="add w-100 m-b2">
                                    <label>Module Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </div>
                                <div className="add-field">
                                    <button onClick={() => updateModal(id?.uuid)}>Update</button>
                                </div>
                            </div>

                            {
                                getForm?.map((form, index) => {
                                    return (
                                        <>
                                            <div className="add-form" style={{ width: "100%" }}>
                                                <div className="d-flex w-100 m-b2 d-other">
                                                    <div className="add parentid">
                                                        <label>Select Title</label>
                                                        <input
                                                            type="text"
                                                            name='title'
                                                            value={form.title}
                                                            onChange={(event) => changeGetForm(index, event)}
                                                        />

                                                    </div>
                                                    <div className="add parentid">
                                                        <label>Method</label>
                                                        <input
                                                            type="text"
                                                            // placeholder="Selling Price"
                                                            name='methodType'
                                                            value={form.methodType}
                                                            onChange={(event) => changeGetForm(index, event)}
                                                            onFocus={() => toggleDrop(index, 'method')}
                                                        />
                                                        {
                                                            form.showMethods && <div className="multiple-cat" ref={toggleRefM}>
                                                                {
                                                                    methods.map((itm) => {
                                                                        return (
                                                                            <>

                                                                                {/* changeGetForm(index, { target: { name: 'methodType', value: itm.name } }) */}

                                                                                <div className="options">
                                                                                    <li onClick={() => { changeGetForm(index, { target: { name: 'methodType', value: itm.name } }); toggleDrop(index, 'method') }}>
                                                                                        {/* <span>{itm.icon}</span> */}
                                                                                        <p>{itm.name}</p>
                                                                                    </li>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="d-flex w-100 m-b2 d-other">
                                                    <div className="add ">
                                                        <label>URL</label>
                                                        <input
                                                            type="text"
                                                            name='url'
                                                            value={form.url}
                                                            onChange={(event) => changeGetForm(index, event)}
                                                        // onChange={(e) => setMrp(e.target.value)}
                                                        />


                                                    </div>
                                                    <div className="add parentid">
                                                        <label>Access Type</label>
                                                        <input
                                                            type="text"
                                                            // placeholder="Selling Price"
                                                            name='accessType'
                                                            value={form.accessType}
                                                            onChange={(event) => changeGetForm(index, event)}
                                                            onFocus={() => toggleDrop(index, 'accessType')}
                                                        />
                                                        {
                                                            form.showSuggestion && <div className="multiple-cat" ref={toggleRefA}>
                                                                {
                                                                    assessType.map((itm) => {
                                                                        return (
                                                                            <>
                                                                                <div className="options">
                                                                                    <li onClick={() => { changeGetForm(index, { target: { name: 'accessType', value: itm.name } }); toggleDrop(index, 'accessType') }}>
                                                                                        {/* <span>{itm.icon}</span> */}
                                                                                        <p>{itm.name}</p>
                                                                                    </li>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="d-flex w-100 m-b2 d-other">
                                                    {
                                                        form.active ?
                                                            <div className="add-field">
                                                                <input type="checkbox" id={`inactive-${index}`} checked={form.isInactive} onChange={(e) => handelInactive(e, index)} /><label htmlFor={`inactive-${index}`}>Inactive</label>
                                                                {/* <button className="activee" disabled={form.active ? false : true} onClick={() => InactiveUrl(form.uuid)}>Inactive</button> */}
                                                            </div>
                                                            :
                                                            <div className="add-field">
                                                                {/* <button className="activee" disabled={form.active ? true : false} onClick={() => activeUrl(form.uuid)}>Active</button> */}
                                                                <input type="checkbox" id={`active-${index}`} checked={form.isActive} onChange={(e) => handleActive(e, index)} /><label htmlFor={`active-${index}`}>Active</label>
                                                            </div>

                                                    }

                                                    <div className="add-field">
                                                        <button onClick={() => updateUrl(form.uuid, form)}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    )
                                })
                            }



                        </div>

                        <div className="add-form">
                            {
                                // moduleUuid &&
                                forms.map((form, index) => {
                                    return (
                                        <>
                                            {index !== 0 && <div className="add-field">
                                                <button onClick={() => handleRemoveForm(index)}>- Remove</button>
                                            </div>}
                                            <div className="d-flex w-100 m-b2 d-other">
                                                <div className="add parentid">
                                                    <label>Select Title</label>
                                                    <input
                                                        type="text"
                                                        name='title'
                                                        value={form.title}
                                                        onChange={(event) => handleChange(index, event)}
                                                    />

                                                </div>
                                                <div className="add parentid">
                                                    <label>Method</label>
                                                    <input
                                                        type="text"
                                                        // placeholder="Selling Price"
                                                        name='methodType'
                                                        value={form.methodType}
                                                        onChange={(event) => handleChange(index, event)}
                                                        onFocus={() => toggleDropdown(index, 'method')}
                                                    />
                                                    {
                                                        form.showMethods && <div className="multiple-cat" ref={methodRef}>
                                                            {
                                                                methods.map((itm) => {
                                                                    return (
                                                                        <>
                                                                            <div className="options">
                                                                                <li onClick={() => { handleChange(index, { target: { name: 'methodType', value: itm.name } }); toggleDropdown(index, 'method') }}>
                                                                                    {/* <span>{itm.icon}</span> */}
                                                                                    <p>{itm.name}</p>
                                                                                </li>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="d-flex w-100 m-b2 d-other">
                                                <div className="add ">
                                                    <label>URL</label>
                                                    <input
                                                        type="text"
                                                        name='url'
                                                        value={form.url}
                                                        onChange={(event) => handleChange(index, event)}
                                                    // onChange={(e) => setMrp(e.target.value)}
                                                    />


                                                </div>
                                                <div className="add parentid">
                                                    <label>Access Type</label>
                                                    <input
                                                        type="text"
                                                        // placeholder="Selling Price"
                                                        name='accessType'
                                                        value={form.accessType}
                                                        onChange={(event) => handleChange(index, event)}
                                                        onFocus={() => toggleDropdown(index, 'accessType')}
                                                    />
                                                    {
                                                        form.showSuggestion && <div className="multiple-cat" ref={ref}>
                                                            {
                                                                assessType.map((itm) => {
                                                                    return (
                                                                        <>
                                                                            <div className="options">
                                                                                <li onClick={() => { handleChange(index, { target: { name: 'accessType', value: itm.name } }); toggleDropdown(index, 'accessType') }}>
                                                                                    {/* <span>{itm.icon}</span> */}
                                                                                    <p>{itm.name}</p>
                                                                                </li>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className="editer m-b2">
                                                    <div className="add w-100">
                                                        <label>Description</label>
                                                        <JoditEditor
                                                            ref={editor}
                                                            value={description}
                                                            tabIndex={1}
                                                            onChange={(newContent) => handleChange(index, newContent)}
                                                        />
                                                    </div>
                                                </div> */}


                                        </>
                                    )
                                })
                            }
                            <div className="add-field">
                                <button onClick={handleAddForm}>+ More URL</button>
                            </div>

                            {
                                forms?.length > 0 &&
                                <div className="add-field" style={{ marginTop: '10px' }}>
                                    <button onClick={handleSubmit}>Save Modal URL</button>
                                </div>
                            }

                        </div>

                    </div>



                </div>
            </div>


        </>
    )
}
export { UpdatePage }