import React from "react";
import { DisplayCategory } from "../DisplayCatChild/DisplayCategory";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";

function DisplayCatParent() {
    return (
        <>
            <MergeComponents getComponent={<DisplayCategory />} />
        </>
    )
}
export { DisplayCatParent }