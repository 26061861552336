import React from "react";

function BarChart() {
    const bar = [
        { id: 1, value: 150 },
        { id: 2, value: 240 },
        { id: 3, value: 1800 },
        { id: 4, value: 2500 },
        { id: 5, value: 1800 },
        { id: 6, value: 1240 },
        { id: 7, value: 2430 },
        { id: 8, value: 1740 },
        { id: 9, value: 1480 },
        { id: 10, value: 1640 },
        { id: 11, value: 2000 },
        { id: 12, value: 2304 },
    ];

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const data = [
        { id: 1, value: 150, country: "Rajasthan" },
        { id: 2, value: 240, country: "Delhi" },
        { id: 3, value: 1800, country: "Jodhpur" },
        { id: 4, value: 2500, country: "Gujrat" },
        { id: 5, value: 1800, country: "kerala" }
    ];

    const maxValue = Math.max(...bar.map((val) => val.value));


    // Calculating the total sales
    const totalSales = data.reduce((acc, curr) => acc + curr.value, 0);

    // Generating pie chart CSS
    const getPieChartStyle = () => {
        let cumulativePercentage = 0;
        const colorArray = [
            "#1e1e1e", "#393939", " #717272", "#8a8b8b", "#b0b1b1"
        ];

        // Building conic-gradient CSS property
        const pieSegments = data.map((item, index) => {
            const percentage = (item.value / totalSales) * 100;
            const startPercentage = cumulativePercentage;
            cumulativePercentage += percentage;
            return `${colorArray[index % colorArray.length]} ${startPercentage}% ${cumulativePercentage}%`;
        });

        return { background: `conic-gradient(${pieSegments.join(", ")})` };
    };
    return (
        <>

            <div className="deshbord">
                <div className="row">                                                                                                                                                                                                               
                    <div className="deshbord-text">
                        <div className="text-a">
                            <h1>Sales Overview</h1>
                        </div>
                        <div className="text-b">
                            <p>Welcome to Books24 Dashboard Template</p>
                        </div>
                        <hr />
                    </div>

                    {/* Bar Chart */}
                    <div className="barChart-Container">
                        <div className="Inner-container">
                            <div className="BarChart">
                                {bar.map((value) => (
                                    <div
                                        className="Chart"
                                        key={value.id}
                                        style={{
                                            height: `${(value.value / maxValue) * 100}%`,
                                            width: "50px",
                                        }}
                                    >
                                        <span>{value.value}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="month-container">
                                {months.map((month, index) => (
                                    <div className="month" key={index}>
                                        <span>{month}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Pie Chart */}
                    <div className="pieChart-Container">
                        <div className="deshbord-text">
                            <div className="text-a">
                                <h1>Sales Distribution by State</h1>
                            </div>
                        </div>

                        <div className="pieChart" style={getPieChartStyle()}></div>
                        <div className="legend-container">
                            {data.map((item, index) => (
                                <div className="legend" key={index}>
                                    <span
                                        className="legend-color"
                                        style={{ backgroundColor: index % 2 === 0 ? "#1e1e1e" : "#b0b1b1" }}
                                    ></span>
                                    <span>{item.country}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { BarChart };

