import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { FaCheckCircle } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { FaRedo } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { MdManageAccounts } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { data } from '../../../../../Store/ReduxSlice';
import Modal from '../../../CommonComponents/Popup';
import { useRole } from '../../../../../Routing/Routing';


function ManagePage() {


    const token = sessionStorage.getItem('tokenKey');
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const { userPermissions } = useRole();
    const permission = (url) => userPermissions?.includes(url);

    const [action, setAction] = useState();
    const [popup, setPopup] = useState(false);

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);
    const [type, setType] = useState('ACTIVE');

    const getMastersList = async (data) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/list-data/${data}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
                setInpulVal('');
            }
        } catch (e) {
            console.log(e, 'error in get list of masters')
        }
    };

    useEffect(() => {
        getMastersList('Active');
        setType('ACTIVE');
    }, []);

    const [getID, setGetId] = useState();

    const activeItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/activate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                switch (type) {
                    case "ALL":
                        await getMastersList("ALL");
                        break;
                    case "ACTIVE":
                        await getMastersList("Active");
                        break;
                    case "INACTIVE":
                        await getMastersList("Inactive");
                        break;
                    case "DELETED":
                        await getMastersList('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            }
        } catch (e) {
            console.log(e, 'error in active list of manage list');
        }
    };

    const handelActive = (uuid) => {
        setAction('ACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    const inactiveItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/inactivate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                switch (type) {
                    case "ALL":
                        await getMastersList("ALL");
                        break;
                    case "ACTIVE":
                        await getMastersList("Active");
                        break;
                    case "INACTIVE":
                        await getMastersList("Inactive");
                        break;
                    case "DELETED":
                        await getMastersList('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            }
        } catch (e) {
            console.log(e, 'error in inactive list')
        }
    };

    const handelInactive = (uuid) => {
        setAction('INACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    const deleted = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/delete/${uuid}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                switch (type) {
                    case "ALL":
                        await getMastersList("ALL");
                        break;
                    case "ACTIVE":
                        await getMastersList("Active");
                        break;
                    case "INACTIVE":
                        await getMastersList("Inactive");
                        break;
                    case "DELETED":
                        await getMastersList('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            }
        } catch (e) {
            console.log(e, 'error in delete api of madule');
        }
    };

    const handelDelete = (uuid) => {
        setAction('DELETE');
        setGetId(uuid);
        setPopup(true);
    };

    const revive = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/revive/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                switch (type) {
                    case "ALL":
                        await getMastersList("ALL");
                        break;
                    case "ACTIVE":
                        await getMastersList("Active");
                        break;
                    case "INACTIVE":
                        await getMastersList("Inactive");
                        break;
                    case "DELETED":
                        await getMastersList('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            }
        } catch (e) {
            console.log(e, 'error in revive module api');
        }
    };

    const handleRevive = (uuid) => {
        setAction('REVIVE');
        setGetId(uuid);
        setPopup(true);
    };

    const confirmPop = async () => {
        if (popup) {
            switch (action) {
                case 'ACTIVE':
                    await activeItem(getID);
                    break;
                case 'INACTIVE':
                    await inactiveItem(getID);
                    break;
                case 'REVIVE':
                    await revive(getID);
                    await activeItem(getID);
                    break;
                case 'DELETE':
                    await deleted(getID);
                    break;
                default:
                    console.log('no such action available');
            }
        };
        setAction('');
        setPopup(false);
    };

    const closePop = () => {
        setPopup(false);
        setAction('');
    };

    const navId = useNavigate();

    const updatemodal = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/module/get/${uuid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navId(`/admin/updateModal/${getRes.responsePacket.uuid}`, { state: getRes?.responsePacket });
                // dispatch(data(getRes?.responsePacket));
            }

        } catch (e) {
            console.log(e, 'error in update modal api')
        }
    };

    const [inputVal, setInpulVal] = useState();

    const handelSearch = (e) => {
        setInpulVal(e.target.value);
        const res = list.filter((itm) => itm.title.toLowerCase().includes(e.target.value.toLowerCase()));
        setFilterList(res);
    };


    return (
        <>
            <div className="category">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Manage Page</h4>
                        </div>
                        <div className="category-btn">
                           {permission('admin/v1/module/save') && <Link to="/admin/addmanage">
                                <button>Add Master</button>
                            </Link>}
                        </div>
                    </div>
                    <hr />

                    <div className="category-filter">
                        <div className="filterr">
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'ALL' ? "white" : "black", color: type === 'ALL' ? "black" : "white" }} onClick={() => { getMastersList('ALL'); setType('ALL') }}>All</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'ACTIVE' ? "white" : "black", color: type === 'ACTIVE' ? "black" : "white" }} onClick={() => { getMastersList('Active'); setType('ACTIVE') }}>Active</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'INACTIVE' ? "white" : "black", color: type === 'INACTIVE' ? "black" : "white" }} onClick={() => { getMastersList('Inactive'); setType('INACTIVE') }}>Inactive</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'DELETED' ? "white" : "black", color: type === 'DELETED' ? "black" : "white" }} onClick={() => { getMastersList('Deleted'); setType('DELETED') }}><AiFillDelete /></button>
                            </div>
                        </div>
                        <div className="search">
                            <h4>Search:</h4>
                            <input type="text" value={inputVal} onChange={(e) => { handelSearch(e) }} placeholder="Search all Category" className="input" />
                        </div>
                    </div>

                    <div className="menu-table-container">
                        <div className="order-list">
                            <div className="order-list-header master-modals">
                                {/* <div className='tittle'>User Id</div> */}
                                <div className='tittle'>Title</div>
                                <div className='tittle'>Status</div>
                                <div className='tittle'>Action</div>
                            </div>

                            {
                                filterList?.map((item) => {
                                    return (
                                        <>
                                            <div key={item.uuid} className='order-list-row master-modals'>
                                                <div className='tittle'>{item.title}</div>

                                                <div className='tittle'>
                                                    <button className={`status ${item.active ? 'active-btn' : 'inactive'}`}>
                                                        {item.active ? 'Active' : 'Inactive'}
                                                    </button>
                                                </div>

                                                <div className="btn">

                                                    <div>
                                                        {
                                                            item.active ?
                                                               (permission('admin/v1/module/inactivate') &&( <button
                                                                    className={`status inactive`}
                                                                    onClick={() => { handelInactive(item.uuid) }}
                                                                ><CgUnavailable /></button>))
                                                                :
                                                               (permission('admin/v1/module/activate') &&( <button
                                                                    className={`status active-btn`}
                                                                    onClick={() => { handelActive(item.uuid) }}
                                                                ><FaCheckCircle /></button>))
                                                        }

                                                    </div>
                                                   {permission('admin/v1/module/update') && <button className="action-btn edit-btn" onClick={() => { updatemodal(item.uuid) }}><FaEdit /></button>}
                                                    {
                                                        item.deleted ?
                                                           (permission('admin/v1/module/revive') &&( <button className="action-btn revive-btn" onClick={() => { handleRevive(item.uuid) }}>
                                                                <FaRedo />
                                                            </button>))
                                                            :
                                                            (permission('admin/v1/module/delete') &&(<button className="action-btn delete-btn" onClick={() => { handelDelete(item.uuid) }}>
                                                                <AiFillDelete />
                                                            </button>))
                                                    }

                                                    <div>
                                                        <Link to='/admin/staff'>
                                                            <button className="action-btn"><MdManageAccounts /></button>
                                                        </Link>
                                                    </div>

                                                </div>


                                            </div>
                                        </>
                                    )
                                })
                            }

                        </div>
                    </div>

                </div>
            </div>


            <Modal
                isOpen={popup}
                onClose={closePop}
                onConfirm={confirmPop}
                action={action}
            />

        </>
    )
}
export { ManagePage }