import React, { useState, useEffect } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const PaginationComponent = ({ currentPage, totalItem, setCurrentPage, lengthOfPage }) => {
    const countPage = Math.ceil(lengthOfPage ? totalItem / lengthOfPage.value : totalItem / 10);
    const [activePage, setActivePage] = useState(currentPage);

    const [getInput, setGetInput] = useState();
    const changeActiveBtn = (value) => {
        setActivePage(value);
        setCurrentPage(value);
    };

    const clickToGo = () => {
        const value = Number(getInput);
        if (value >= 1 && value <= countPage) {
            setActivePage(value);
            setCurrentPage(value);
        }
    };

    useEffect(() => {
        if (currentPage === 1) {
            setActivePage(1)
        };
    }, [currentPage]);

    const init = () => {
        changeActiveBtn(1);
        updateRightContainer(1);
        updateBlockBtn(1);
    };

    const handlePrevBtn = () => {
        const curActive = activePage - 1;
        updatePaginate(curActive);
    };

    const handleNextBtn = () => {
        const curActive = activePage + 1;
        updatePaginate(curActive);
    };

    const updatePaginate = (value) => {
        changeActiveBtn(value);
        updateBlockBtn(value);
        updateLeftContainer(value);
        updateRightContainer(value);
    };

    const handleBtnNumbers = (e) => {
        const target = e.target.closest('.numberBtn')?.textContent;
        if (target) updatePaginate(Number(target));
    };

    const updateBlockBtn = (value) => {
        return {
            prevDisabled: value === 1,
            nextDisabled: value === countPage,
        };
    };

    const createButton = (text, classNames = [], disabled = false) => {
        return (
            <button className={classNames.join(' ')} disabled={disabled}>
                {text}
            </button>
        );
    };

    const updateContainer = (containerArray, array, disabledIndexes = []) => {
        return array.map((item, index) => {
            const text = disabledIndexes.includes(index) ? '...' : item;
            return createButton(
                text,
                ['w-7', 'h-7', 'mx-2', 'numberBtn', 'w-32'],
                disabledIndexes.includes(index)
            );
        });
    };

    const updateLeftContainer = (value) => {
        if (value <= 5) {
            return updateContainer([], Array.from({ length: value - 1 }, (_, i) => i + 1), []);
        } else {
            return updateContainer([], [1, 2, value - 2, value - 1], [1]);
        }
    };

    const updateRightContainer = (value) => {

        if (value >= countPage - 4) {
            const tail = countPage - value;
            return updateContainer([], Array.from({ length: tail }, (_, i) => i + value + 1), []);
        } else {
            return updateContainer([], [value + 1, value + 2, countPage - 1, countPage], [1]);
        }
    };

    const { prevDisabled, nextDisabled } = updateBlockBtn(activePage);

    useEffect(() => {
        init();
    }, []);

    const handleDirectInput = (e) => {
        if (e.key === "Enter") {
            const value = Number(e.target.value);
            if (value >= 1 && value <= countPage) {
                setCurrentPage(value);
                setActivePage(value);
            }
        }
    };

    return (
        <>
            <div className="page-main-container">

                <div className="input-page">
                    <span>Page : </span>
                    <input
                        type="number"
                        value={getInput}
                        onChange={(e) => {
                            const value = Number(e.target.value); // Convert to number
                            // Validate length constraints
                            if (value.toString().length >= 1 && value.toString().length <= countPage.toString().length) {
                                setGetInput(value); // Call your function if within length
                            }
                        }}
                        style={{ width: "20%", padding: "4px", marginLeft: "4px", marginRight: "4px" }}
                        min="1" // Minimum number allowed
                        max={countPage} // Maximum number allowed
                        onKeyDown={handleDirectInput}
                    />

                    <button className='nextBtn' style={{ margin: 0 }} onClick={clickToGo}>Go</button>

                </div>

                <div className="container-p">
                    <div className="paginate">
                        <button className="prevBtn" onClick={handlePrevBtn} disabled={prevDisabled}>
                            <span className="prevBtn-icon">
                                <IoIosArrowBack />
                            </span>
                            <span className="prevBtn-text" style={{ paddingRight: "10px" }}>Prev</span>
                        </button>

                        <div className="containerBtns" onClick={handleBtnNumbers}>
                            <div className="leftContainer">{updateLeftContainer(activePage)}</div>
                            <button className="activeBtn" disabled>
                                {activePage}
                            </button>
                            <div className="rightContainer">{updateRightContainer(activePage)}</div>
                        </div>

                        <button className="nextBtn" onClick={handleNextBtn} disabled={nextDisabled}>
                            <span className="nextBtn-text" style={{ paddingLeft: "10px" }}>Next</span>
                            <span className="nextBtn-icon">
                                <IoIosArrowForward />
                            </span>
                        </button>
                    </div>
                </div>

            </div>
        </>
    );
};

export default PaginationComponent;
