import Raect, { useState, useEffect } from 'react';
import { useRole } from '../../../../../Routing/Routing';
import PaginationComponent from '../../pagination';
import { AiFillDelete } from "react-icons/ai";
import { Link } from 'react-router-dom';
import Select from "react-select";

function Cart() {

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);

    const token = sessionStorage.getItem('tokenKey');

    // PAGINATION LOGIC
    const [currentPage, setCurrentPage] = useState(1);
    const resultPerPage = [
        { value: 10, label: "10 items per page" },
        { value: 20, label: "20 items per page" },
        { value: 50, label: "50 items per page" },
        { value: 100, label: "100 items per page" },
        { value: 200, label: "200 items per page" },
    ];
    const [lengthOfPage, setLengthOfPage] = useState(() => {
        return JSON.parse(sessionStorage.getItem("cartPerPage")) || resultPerPage[0]
    });

    useEffect(() => {
        sessionStorage.setItem("cartPerPage", JSON.stringify(lengthOfPage));
    }, [lengthOfPage]);

    // Pagination logic
    const itemsPerPage = lengthOfPage?.value; // Show 10 items per page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);
    const [type, setType] = useState('ALL');

    const items = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/order/getCartList`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in cart detail')
        }
    };

    useEffect(() => {
        items();
    }, []);

    const HRD = (time) => {
        const get = new Date(time);
        return get.toUTCString();
    };

    const [inputSearch, setInputSearch] = useState();
    const handleSearch = (e) => {
        setInputSearch(e.target.value);
        const res = list.filter((itm) => itm.customerDetail?.userCustomerFirstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            itm.customerDetail?.userCustomerLastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            itm.orderTotal.toLocaleString().includes(e.target.value.toLocaleString())
        );
        setFilterList(res);
        setCurrentPage(1);
    };


    return (
        <>
            <div className="category" style={{ display: "flex", justifyContent: "center" }}>
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Cart</h4>
                        </div>
                        <div className="category-btn">
                            <Select
                                defaultValue={resultPerPage[0]}
                                closeMenuOnSelect={true}
                                name="colors"
                                options={resultPerPage}
                                value={lengthOfPage}
                                onChange={(e) => { setLengthOfPage(e); setCurrentPage(1) }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>
                    </div>
                    <hr />

                    <div className="category-filter">
                        <div className="filter">
                            {/* <div className="btn-1">
                                <button style={{ backgroundColor: type === 'ALL' ? "white" : "black", color: type === 'ALL' ? "black" : "white" }} onClick={() => { items('ALL'); setType('ALL') }}>All</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'ACTIVE' ? "white" : "black", color: type === 'ACTIVE' ? "black" : "white" }} onClick={() => { items('Active'); setType('ACTIVE') }}>Active</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'INACTIVE' ? "white" : "black", color: type === 'INACTIVE' ? "black" : "white" }} onClick={() => { items('Inactive'); setType('INACTIVE') }}>Inactive</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'DELETED' ? "white" : "black", color: type === 'DELETED' ? "black" : "white" }} onClick={() => { items('Deleted'); setType('DELETED') }}><AiFillDelete /></button>
                            </div> */}
                        </div>
                        <div className="search">
                            <h4>Search:</h4>
                            <input type="text" value={inputSearch} onChange={(e) => handleSearch(e)} placeholder="Search...." className="input" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="menu-table-container">
                <div className="order-list ">

                    <div className="order-list-header dynamic-head sticky-container">
                        {/* <div className='tittle'>User Id</div> */}
                        <div className='tittle'>Customer Name</div>
                        <div className="tittle">Created At</div>
                        <div className='tittle'>Items</div>
                        <div className='tittle'>Cart Total</div>
                    </div>

                    {
                        filterList?.slice(indexOfFirstItem, indexOfLastItem).map((item) => {
                            return (
                                <>
                                    <Link to='/admin/cartDetail' state={item.uuid}>
                                        <div key={item} className='order-list-row dynamic-head'>

                                            <div className='tittle'>{item?.customerDetail?.userCustomerFirstName} {item?.customerDetail?.userCustomerLastName}</div>
                                            <div className='tittle'>{HRD(item.createdAtTimeStamp)}</div>
                                            <div className='tittle'>{item.itemDetailList?.length}</div>
                                            <div className="tittle">{item.orderTotal}</div>
                                        </div>
                                    </Link>
                                </>
                            )
                        })
                    }
                </div>
            </div>

            <div className="row">
                <div className="pagination">
                    <span>Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filterList?.length)} of {filterList?.length} entries</span>
                    <span className="pagination-buttons">
                        <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} totalItem={filterList?.length} lengthOfPage={lengthOfPage} />
                    </span>
                </div>
            </div>

        </>
    )
}
export { Cart }