
import React from 'react';


function Modal({ isOpen, onClose, onConfirm ,action}) {

    if (!isOpen) return null;
    
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{action}</h3>
                <div className="modal-actions">
                    <button className="confirm-btn" onClick={onConfirm}>Yes</button>
                    <button className="cancel-btn" onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
}

export default Modal;