import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { AddModule } from '../ManagePageChild/AddModules';


function AddModuleParent() {
    return (
        <>
            <MergeComponents getComponent={<AddModule />} />
        </>
    )
}
export { AddModuleParent }