import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { Publisher } from "../PublisherChild/Publisher";


function PublisherParent() {
    return (
        <>
            <MergeComponents getComponent={<Publisher />} />
        </>
    )
}
export { PublisherParent }