import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Skeleton } from "../../../../CommonComponents/Skeleton";

function AddaddressChild() {
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const key = localStorage.getItem("userId");

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const addressList = async (data) => {
        setLoading(true);
        setError(null);
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/list/${data}`, {
                headers: {
                    Authorization: `Bearer ${key}`,
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            } else {
                throw new Error(getRes.errorMessage || "Failed to fetch addresses.");
            }
        } catch (e) {
            console.error(e);
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        addressList("All");
    }, []);

    const deleteList = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/delete/${uuid}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${key}`,
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                addressList("All");
            } else {
                throw new Error(getRes.errorMessage || "Failed to delete the address.");
            }
        } catch (e) {
            console.error(e);
            alert("Error: Could not delete the address.");
        }
    };

    const navTo = useNavigate();

    const updateList = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/get/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${key}`,
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navTo("/accountparentaddressdetail", { state: getRes.responsePacket });
            } else {
                throw new Error(getRes.errorMessage || "Failed to fetch address details.");
            }
        } catch (e) {
            console.error(e);
            alert("Error: Could not fetch the address details.");
        }
    };

    return (
        <div className="my-address">
            <div className="address-header">
                <span className="title">My Address</span>
            </div>
            <div className="address-list">
                <div className="add-new-address">
                    <Link to="/accountparentaddressdetail">
                        <div className="add-icon">+</div>
                        <span>Add New Address</span>
                    </Link>
                </div>

                {loading ? (
                    <div className="skeleton-loader" style={{display:"contents"}}>
                        {Array(3).fill().map((_, index) => (
                            <Skeleton
                                key={index}
                                className="address-skeleton"
                                style={{ height: "120px", marginBottom: "15px",  }}
                            />
                        ))}
                    </div>
                ) : error ? (
                    <div className="error-message">
                        <p>Error: {error}</p>
                    </div>
                ) : filterList.length === 0 ? (
                    <div className="no-addresses">
                        <p>No addresses found. Add your first address!</p>
                    </div>
                ) : (
                    filterList.map((address, index) => (
                        <div key={index} className="address-card">
                            <div className="address-type">
                                <h2>{address.type}</h2>
                            </div>
                            <div className="address-name">
                                <h3>{address.firstName}</h3>
                            </div>
                            <div className="address-details">
                                <span>{address.addressLine1}</span>
                            </div>
                            <div className="address-phone">
                                <span>
                                    Phone: <span className="phone">{address.mobileNumber}</span>
                                </span>
                            </div>
                            <div className="address-actions">
                                <button className="edit-button" onClick={() => updateList(address.uuid)}>
                                    Edit
                                </button>
                                <button className="delete-button" onClick={() => deleteList(address.uuid)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export { AddaddressChild };
