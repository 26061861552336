import React from 'react';

function MagnifyImage({ isOpen, imgSrc, onClose }) {

    if (!isOpen) return null

    return (
        <>
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" style={{ width: "450px", height: "550px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="modal-actions" style={{ width: "100%", height: "100%", margin: 0 }}>
                        <img src={imgSrc} alt="img" style={{ height: "100%", width: "100%" }} />
                        {/* {navv && <button className="confirm-btn bg-bl-cw" onClick={onConfirm} >{navv}</button>}
                        {moreBtn && <button className="confirm-btn bg-bl-cw" onClick={onMoreBtn} >{moreBtn}</button>}
                        {FutherMore && <button className="cancel-btn" onClick={onClose}>{FutherMore}</button>} */}
                    </div>
                </div>
            </div>
        </>
    )
}
export { MagnifyImage }