import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../AdminStyle.css';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [admin, setAdmin] = useState({});
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const BASE_URL = process.env.REACT_APP_PP_URL;

    const Postdata = async () => {
        try {
            const response = await fetch(`${BASE_URL}/auth/admin-login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userName: email,
                    password: password
                })
            });
            const getRes = await response.json();

            setAdmin(getRes.responsePacket);

            // Instead of checking for "success", let's check if "accessToken" exists
            if (getRes.responsePacket && getRes.responsePacket.secretKey) {
                alert('Login successful!');
                setError('');
                // setAuth(true);
                navigate("/admin/dash");
                window.location.reload();
                sessionStorage.setItem('tokenKey', getRes?.responsePacket.secretKey);
                sessionStorage.setItem('userRole', getRes?.responsePacket.userType);
            } else {
                // Handle case where login fails
                setError(getRes.message || 'Invalid email or password');
            }

        } catch (error) {
            console.log(error, 'login API error');
            setError('An error occurred during login.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent page reload
        await Postdata();
    };

    return (
        <div className="login">
            <form className="login-form" onSubmit={handleSubmit}>
                <h1>Welcome to Books24</h1>

                <p className="subtitle">Login to your account</p>
                {error && <p className="error-message">{error}</p>}
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Enter your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="login-button">Login</button>
            </form>
        </div>
    );
}

export { Login };

