import React from "react";
import { MergeComponents } from "../../CommonComponents/MergeComponents";
import { Employee } from "./Employee";

function StaffMain() {
    return (
        <>
        <MergeComponents getComponent={<Employee/>}/>
        </>
    )
};
export { StaffMain }