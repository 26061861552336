import React from "react";
import { AccountMerge } from "../../AccountMerge";
import { ChangePasswordChild } from "../ChangePasswordChild/ChangePasswordChild";

function ChangePasswordParent(){
    return(
        <>
        <AccountMerge getComponent={<ChangePasswordChild/>}/>
        </>
    )
}
export{ChangePasswordParent}