import React from "react";
import { MyorderChild } from "../Myorderchild/Myorederchild";
import { AccountMerge } from "../../AccountMerge";

function MyorderParent(){
    return(
        <>
        <AccountMerge getComponent={<MyorderChild/>}/>
        </>
    )
}
export{MyorderParent}