import React, { useState } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation, useNavigate } from 'react-router-dom';

function UpdateCms() {
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');
    const location = useLocation();
    const { cmsData, settingKey, anotherValue } = location.state || {};
    const [cms, setCms] = useState(cmsData);

    const navBack = useNavigate();
    const updateCms = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/website-cms/${settingKey}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    settingValue: cms
                })
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                navBack('/admin/cms');
                console.log('pichepicehe')
            }
        } catch (e) {
            console.log(e, 'error in cms updartt')
        }
    }

    return (
        <>

            <div className="cms-editer">
                <div className="row">
                    <div className="editer">
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: {
                                    items: [
                                        "heading",
                                        "|",
                                        "bold",
                                        "italic",
                                        "link",
                                        "bulletedList",
                                        "numberedList",
                                        "|",
                                        "indent",
                                        "outdent",
                                        "|",
                                        "imageUpload",
                                        "blockQuote",
                                        "mediaEmbed",
                                        "undo",
                                        "redo",
                                    ],
                                },
                            }}
                            data={cms}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setCms(data);
                            }}
                        />
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={updateCms}>Submit</button>
                    </div>
                </div>
            </div>

        </>
    )
}
export { UpdateCms }