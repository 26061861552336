import React, { useState, useEffect, useRef } from "react";
import { RxCross1 } from "react-icons/rx";
import img from "../../../../Assets/Image/login.jpg";
import flag from "../../../../Assets/Image/indian flag.png";

const LoginNumber = ({ onClose, inputFocus }) => {
    const [isOtpVisible, setIsOtpVisible] = useState(false);
    const [error, setError] = useState("");
    const [mobile, setMobile] = useState("");
    const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]); // OTP state
    const [timeLeft, setTimeLeft] = useState(90); // Countdown in seconds
    const BASE_URL = process.env.REACT_APP_PP_URL;

    const otpRefs = useRef([]); // Array of refs for OTP inputs
    const inputRef = useRef();

    // Timer effect for OTP countdown
    useEffect(() => {
        let timer;
        if (isOtpVisible && timeLeft > 0) {
            timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
        } else if (timeLeft === 0) {
            resetOtp(); // Expire OTP when the timer reaches zero
        }
        return () => clearInterval(timer); // Cleanup on unmount or re-render
    }, [isOtpVisible, timeLeft]);

    // Focus on the phone input when the modal opens
    useEffect(() => {
        if (!isOtpVisible) {
            inputRef.current.focus();
        }
    }, [inputFocus, isOtpVisible]);

    // Focus on the first OTP input when OTP modal is visible
    useEffect(() => {
        if (isOtpVisible && otpRefs.current[0]) {
            otpRefs.current[0].focus();
        }
    }, [isOtpVisible]);

    const resetOtp = () => {
        setIsOtpVisible(false); // Hide OTP page
        setMobile(""); // Reset mobile input
        setOtpValues(["", "", "", "", "", ""]); // Clear OTP input
        setTimeLeft(90); // Reset timer
        setError("OTP expired. Please request a new one.");
    };

    const requestOtp = async () => {
        try {
            if (mobile.length === 10) {
                const response = await fetch(`${BASE_URL}/guest-customer/v1/generateOtp`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        isdCode: "+91",
                        mobile: mobile,
                        verificationType: "Login",
                    }),
                });
                const result = await response.json();
                if (result.errorCode === 0) {
                    setIsOtpVisible(true); // Show OTP input
                    setTimeLeft(90); // Start countdown
                } else {
                    throw new Error("Failed to send OTP");
                }
            } else {
                alert("Phone number must be exactly 10 digits");
            }
        } catch (error) {
            console.error("OTP request error:", error);
            setError("Please enter a valid number.");
        }
    };

    const verifyOtp = async () => {
        const otp = otpValues.join(""); // Combine all digits into one string
        if (otp.length === 4) {
            try {
                const response = await fetch(`${BASE_URL}/auth/customer-otp-login`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        isdCode: "+91",
                        mobile: mobile,
                        otp: otp, // Send concatenated OTP
                    }),
                });
                const result = await response.json();
                if (result.errorCode === 0) {
                    console.log("OTP verified successfully");
                    localStorage.setItem("userId", result.responsePacket.secretKey);
                    onClose();
                } else {
                    setError("Invalid OTP, please try again.");
                }
            } catch (error) {
                console.error("OTP verification error:", error);
                setError("An error occurred while verifying OTP.");
            }
        } else {
            setError("Please enter all 6 digits.");
        }
    };

    const handleOtpChange = (index, value) => {
        if (/^\d?$/.test(value)) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = value;
            setOtpValues(newOtpValues);

            // Move focus to the next input automatically
            if (value && index < otpValues.length - 1) {
                otpRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && !otpValues[index] && index > 0) {
            otpRefs.current[index - 1].focus();
        }
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    };

    return (
        <>
            <div className={`overlay-login ${isOtpVisible ? "visible" : ""}`} onClick={onClose}></div>

            {!isOtpVisible ? (
                <div className="login-modal-number">
                    <div className="img-1">
                        <div className="login-modal-header">
                            <span onClick={onClose}>
                                <RxCross1 />
                            </span>
                        </div>
                        <div className="img">
                            <img src={img} alt="Logo" />
                        </div>
                    </div>
                    <div className="text-cart">
                        <div className="em">
                            <hr />
                        </div>
                        <h3>Login or Sign Up</h3>
                        <div className="em">
                            <hr />
                        </div>
                    </div>
                    <div className="login-modal-body">
                        <div className="phone-input-container">
                            <img
                                src={flag}
                                alt="India Flag"
                                className="country-flag"
                            />
                            <input
                                ref={inputRef}
                                type="number"
                                value={mobile}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    if (input.length <= 10) {
                                        setMobile(input);
                                    }
                                }}
                                className="login-input"
                                placeholder="Enter your Phone Number"
                            />
                        </div>
                        <div className="btn">
                            <button className="login-button" onClick={requestOtp}>
                                Continue
                            </button>
                        </div>
                        {error && <div className="error-message">{error}</div>}
                    </div>
                </div>
            ) : (
                <div className="login-modal">
                    <div className="otp-modal">
                        <div className="otp-modal-header">
                            <h2>OTP Verification</h2>
                            <span onClick={onClose}>
                                <RxCross1 />
                            </span>
                        </div>
                        <div className="text">
                            <p>We have sent a verification code to</p>
                            <p>{mobile}</p>
                        </div>
                        <div className="otp-inputs">
                            {otpValues.map((value, index) => (
                                <input
                                    key={index}
                                    ref={(el) => (otpRefs.current[index] = el)}
                                    id={`otp-input-${index}`}
                                    type="text"
                                    maxLength="1"
                                    value={value}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    className="otp-input-box"
                                />
                            ))}
                        </div>
                        <div className="otp-a">
                            <p>Check your text message for the OTP</p>
                        </div>
                        <div className="otp">
                            <strong>Didn't get the OTP?</strong>
                            <p> Resend code (in {formatTime(timeLeft)})</p>
                        </div>
                        <button className="login-button" onClick={verifyOtp}>
                            Verify OTP
                        </button>
                        <div className="otp-b">
                            <p>Go back to Login method</p>
                        </div>
                        {error && <div className="error-message">{error}</div>}
                    </div>
                </div>
            )}
        </>
    );
};

export { LoginNumber };










