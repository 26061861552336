import React, { useEffect, useState } from "react";
import img from "../../../../../../Assets/Image/book1.jpg"
import { useLocation } from "react-router-dom";

function OrderDetailChild() {
    const orderItems = [
        {
            name: "Khan GS Research Center",
            price: 706.0,
            qty: 1,
            image: img,
        },
        {
            name: "Khan GS Research Center",
            price: 706.0,
            qty: 1,
            image: img,
        },
        {
            name: "Khan GS Research Center",
            price: 706.0,
            qty: 1,
            image: img,
        },
    ];

    const totals = {
        subtotal: 2118,
        discount: 100,
        taxes: 100,
        grandTotal: 2200,
    };
    const key = localStorage.getItem('userId');
    const BASE_URL = process.env.REACT_APP_PP_URL;

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([]);

    const id = useLocation();
    const getId = id && id.state

    const orderList = async (id) => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/order/getOrderDetail/${id}`, {
                headers: {
                    'Authorization': `Bearer ${key}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in order list');
        }
    };

    useEffect(() => {
        orderList(getId);
    }, []);

    console.log(filterList, 'list', getId);


    return (
        <>
            <div className="account-order-detail">

                <div className="header">
                    <span className="title">Order Details</span>
                    <span className="help">Help</span>
                </div>
                <div className="order-summary">
                    <div className="order-info">
                        Order # {filterList.orderId} | Payment Mode :- {filterList.paymentType} | Item :- {filterList.itemDetailList?.length}
                    </div>
                    <div className="items-header">Qty & Item Name</div>
                    {filterList.itemDetailList?.map((item, index) => (
                        <div key={index} className="order-item">
                            <img src={item.itemImageUrls?.[0]} alt={item.name} className="item-image" />
                            <div className="item-details">
                                <div className="item-name">{item.title}</div>
                                <div className="item-qty"><span>Qty :- {item.quantity}</span></div>
                            </div>
                            <div className="item-price">₹ {item.sellingPrice * item.quantity}</div>
                        </div>
                    ))}
                </div>
                <div className="order-totals">
                    <h3>
                        Bill Details
                    </h3>
                    <div className="totals-row">
                        <span>Sub Total</span>
                        <span>₹ {filterList.subTotal}</span>
                    </div>
                    <div className="totals-row">
                        <span>Coupon Discount</span>
                        <span>₹ {filterList.couponDiscountAmount}</span>
                    </div>
                    <div className="totals-row">
                        <span>Delivery Charges</span>
                        <span>₹ {filterList.deliveryCharges}</span>
                    </div>
                    <div className="totals-row">
                        <span>Packing Charges</span>
                        <span>₹ {filterList.packingCharges}</span>
                    </div>
                    <div className="totals-row grand-total">
                        <span>Grand Total</span>
                        <span>₹ {filterList.orderTotal-filterList.couponDiscountAmount}</span>
                    </div>
                </div>
            </div>
        </>
    )
}
export { OrderDetailChild }