import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

function Banners() {
  const BASE_URL = process.env.REACT_APP_PP_URL;
  
  const [error, setError] = useState('');
  const [data, setData] = useState(null); // State to hold fetched data
  const token = sessionStorage.getItem('tokenKey');

  const Getdata = async () => {
    try {
      const response = await fetch(`${BASE_URL}/guest-customer/v1/homePage/banner-data`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json(); // Parse the JSON data
      setData(result.responsePacket); // Store data in state
      console.log(result, 'result');
    } catch (error) {
      console.error("Fetch error:", error);
      setError('Failed to load banners');
    }
  };

  useEffect(() => {
    Getdata();
  }, []);

  return (
    <>
      {error && <p>{error}</p>}
      {data?.length > 0  &&
      <Swiper
        navigation={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 1800,
          disableOnInteraction: false,
        }}
        modules={[Navigation, Autoplay, Pagination]}
        className="mySwiper"
      >
       {
          data.map((banner, index) => (
            <SwiperSlide key={index}>
              <Link to={banner.onclickRedirectUrl}>
                <img src={banner.bannerImageUrl} alt={`Slide ${index + 1}`} className="image" />
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
        }
    </>
  );
}

export default Banners;




