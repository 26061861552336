import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    value: {
        user: ''
    }
}

const itemSlice = createSlice({
    name: "itemSlice",
    initialState,
    reducers: {
        pro: (state, action) => {
            state.value.user = action.payload
        },
    }
})
export const { pro } = itemSlice.actions

export default itemSlice.reducer