import React from "react";
import { BarChart } from "./BarChart";

function Dashboard() {
    const token = sessionStorage.getItem('tokenKey')
    console.log(token,'tokennn');
    
    return (
        <>
            <BarChart/>
        </>
    )
}
export { Dashboard }