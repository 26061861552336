import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { UpdateField } from '../OtherFieldChild/UpdateField';

function UpdateFieldParent() {
    return (
        <>
            <MergeComponents getComponent={<UpdateField />} />
        </>
    )
}
export { UpdateFieldParent }