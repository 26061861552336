import React, { useState, useEffect, useRef } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { RiImageAddFill } from "react-icons/ri";
import { SuccessPopUp } from "../../CommonComponents/SuccessPopUp";
import { uploadImage } from "../../CommonComponents/ImageUpload";
import { MagnifyImage } from "../../CommonComponents/MagnifyImage";

function AddMember() {

    const token = sessionStorage.getItem('tokenKey');
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const navigate = useNavigate();

    const [jobRole, setJobRole] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isdCode, setIsdCode] = useState(+91);
    const [mobile, setMobile] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [imagePreviews, setImagePreviews] = useState([]); // Array for storing image previews

    // HANDLE IMAGE SECTION
    const handleFileChange = async (e) => {
        const files = e.target.files;
        const uploadedUrls = [];
        // Loop through each selected file and upload
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                uploadedUrls.push(imageUrl.responsePacket);
                // setImagePreviews((prev=> [...prev, imageUrl]));
                console.log('image upload');
            };
        };
        setImagePreviews(uploadedUrls); // Update state with all uploaded image URLs
    };

    const handleDeleteImage = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
            // Remove the image from the preview and formData arrays
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews
        };
    };

    const content = {
        jobRole: jobRole,
        firstName: firstName,
        lastName: lastName,
        isdCode: isdCode,
        mobile: mobile,
        email: email,
        password: password,
        photoImageUrl: imagePreviews?.join('')
    };

    console.log(content, "content", imagePreviews);

    const members = async (okId) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/staff/save`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content)
            });

            console.log('Response Status:', res.status);
            if (res.status === 403) {
                console.error('Forbidden: Check permissions or authentication token.');
            }

            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                if (okId === 'ok') {
                    navToCat('/admin/staff');
                    setShowPop(false);
                } else if (okId === "addMore") {
                    setShowPop(false);
                    setFirstName('');
                    setLastName('');
                    setJobRole('');
                    setEmail('');
                    setIsdCode('');
                    setPassword('');
                    setMobile('');
                    setImagePreviews([]);

                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'  // Optional: Use 'auto' for instant scroll
                    });
                }
            } else {
                setShowPop(true);
                setAction('something went wrong');
                setMore('Ok');
                setExtraBtn();
                setNavv();
            }
        } catch (e) {
            console.log(e, 'error in member api');
            setShowPop(true);
            setAction('something went wrong');
            setMore('Ok');
            setExtraBtn();
            setNavv();
        }
    };

    const [showPop, setShowPop] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();

    const navToCat = useNavigate();
    const confirmPopUp = () => {
        setShowPop(false);
        members("ok");
        setNavv("ok");
    };

    const [extraBtn, setExtraBtn] = useState();

    const onMoreBtn = () => {
        setShowPop(false);
        members("addMore");
        setNavv();
    };

    const onCloseBtn = () => {
        setAction('');
        setExtraBtn();
        setMore('');
        setShowPop(false);
    };




    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    const [errors, setErrors] = useState({});
    // Refs for scrolling
    const jobRoleRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const mobileRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const handleValidation = () => {
        let newErrors = {};
        // Check each field
        if (!firstName?.trim()) newErrors.firstName = "tax Category is required.";
        if (!lastName?.trim()) newErrors.lastName = "tax Category is required.";
        if (!jobRole?.trim()) newErrors.jobRole = "Title is required.";
        if (!mobile?.trim()) newErrors.mobile = "tax Category is required.";
        if (!email?.trim()) newErrors.email = "tax Category is required.";
        if (!password?.trim()) newErrors.password = "Medium is required.";

        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }

        return true;
    };

    const scrollToField = (field) => {
        switch (field) {
            case "jobRole":
                jobRoleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "firstName":
                firstNameRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "lastName":
                lastNameRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "mobile":
                mobileRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "email":
                emailRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "password":
                passwordRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;

            default:
                break;
        }
    };


    const handleSubmit = () => {
        handleValidation();
        if (handleValidation()) {
            setAction("Confirm submit");
            setShowPop(true);
            setNavv("OK");
            setExtraBtn("Add More");
            setMore("Cancel")
        }
    };

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Add member</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form">

                            {/* NAME*/}
                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add" ref={firstNameRef}>
                                    <label style={errors.firstName && { color: "red" }}>Name</label>
                                    <input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="add" ref={lastNameRef}>
                                    <label style={errors.lastName && { color: "red" }}>Last Name</label>
                                    <input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Mobile */}
                            <div className="d-flex w-100 m-b2 d-other" ref={jobRoleRef}>
                                <div className="add">
                                    <label style={errors.jobRole && { color: "red" }}>Job Role</label>
                                    <input
                                        type="text"
                                        value={jobRole}
                                        onChange={(e) => setJobRole(e.target.value)}
                                    />
                                </div>
                                <div className="add" ref={mobileRef}>
                                    <label style={errors.mobile && { color: "red" }}>Mobile no.</label>
                                    <div className="btn-AddCat">
                                        {/* <input
                                            type="text" className="isdInput"
                                            value={isdCode}
                                            onChange={(e) => setIsdCode(e.target.value)}
                                        /> */}
                                        <input
                                            type="text"
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />

                                    </div>

                                </div>
                            </div>

                            {/* email */}
                            <div className="d-flex w-100 m-b2 d-other" ref={emailRef}>
                                <div className="add">
                                    <label style={errors.email && { color: "red" }}>Email</label>
                                    <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="add" ref={passwordRef}>
                                    <label style={errors.password && { color: "red" }}>Password</label>
                                    <input
                                        type="text"
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value) }}
                                    />
                                </div>
                            </div>

                            {/* Category Icon File Upload */}
                            <div className="add w-100">
                                <label>Image</label>
                                {/* Input Section */}
                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        // multiple
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews?.length > 0 ?
                                            <label htmlFor="category-icon" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="custom-label">
                                                    <RiImageAddFill />
                                                    <span>Change Photo</span>
                                                </div>
                                            </label>
                                            :
                                            <label htmlFor="category-icon" className="custom-file-input" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="text">
                                                    <div className="icon">
                                                        <h1><IoCloudUploadOutline /></h1>
                                                        <h4>Add Images</h4>
                                                    </div>
                                                </div>
                                            </label>
                                    }

                                </div>

                                {/* Image Preview Section */}
                                {
                                    imagePreviews?.length > 0 && <div className="image-preview-wrapper">
                                        {
                                            imagePreviews?.map((preview, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img
                                                        src={preview}
                                                        alt={`Category Icon ${index + 1}`}
                                                        style={{ objectFit: 'cover' }}
                                                        onClick={() => maginifyImage(preview)}
                                                    />
                                                    {/* Delete Button */}
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteImage(index)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: '1px',
                                                            background: 'red',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>

            <SuccessPopUp
                isOpen={showPop}
                action={action}
                navv={navv}
                FutherMore={more}
                moreBtn={extraBtn}
                onMoreBtn={onMoreBtn}
                onClose={onCloseBtn}
                onConfirm={confirmPopUp}
            />
            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />
        </>
    )
}
export { AddMember }