import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { AddItems } from "../ItemsChild/AddItems";
import '../../../AdminStyle.css';

function AdditemParent(){
    return(
        <>
        <MergeComponents getComponent={<AddItems />} />
        </>
    )
}
export{AdditemParent}