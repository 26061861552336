import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { IoMdMenu } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { MdOutlineCategory } from "react-icons/md";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { CiShoppingCart } from "react-icons/ci";
import { IoIosPeople } from "react-icons/io";
import { useRole } from "../../../Routing/Routing";
import { TfiLayoutSlider } from "react-icons/tfi";
import { FaGift } from "react-icons/fa";
import { CgDisplayGrid } from "react-icons/cg";
import { TbInputSearch } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { MdAssignmentInd } from "react-icons/md";
import { FaCartArrowDown } from "react-icons/fa";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaPeopleArrows } from "react-icons/fa6";
import notificationSound from "../../../Assets/Image/mixkit-correct-answer-tone-2870.wav";
import { AiFillNotification } from "react-icons/ai";
import { MdManageHistory } from "react-icons/md";
import { SiPostman } from "react-icons/si";

function SlideBar({ activeClass }) {

    const { access } = useRole();
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');
    // console.log(access, 'access');
    const location = useLocation();

    const ulRef = useRef(null); // Reference for the nav container
    const activeRef = useRef(null);

    const nav = useNavigate();
    const handleLogOut = () => {
        sessionStorage.removeItem('tokenKey');
        sessionStorage.removeItem('userRole');
        nav('/admin');
        window.location.reload();
    };

    // Function to determine if a menu item should be active
    const isActive = (path) => location.pathname === path;


    const [totalitems, setTotalItems] = useState([]);

    const totalCart = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/order/getCartCount`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setTotalItems(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in total cart');
        }
    };

    const [isAudioEnabled, setIsAudioEnabled] = useState(localStorage.getItem("audioEnabled"));
    const [orderTotal, setOrderTotal] = useState();
    console.log(typeof orderTotal, 'orderTotal');

    const audio = new Audio(notificationSound);
    useEffect(() => {
        if (location.pathname === '/admin/cart') {
            totalCart();
        };
        // audioRef.current = new Audio(notificationSound);
    }, []);

    // useEffect(() => {
    //     audioRef.current = new Audio(notificationSound)
    // }, []);

    // const orderCount = async () => {
    //     try {
    //         const res = await fetch(`${BASE_URL}/admin/v1/order/getOrderCount`, {
    //             method: "POST",
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         });
    //         const getRes = await res.json();
    //         if (getRes.errorCode === 0) {
    //             if (getRes.responsePacket === orderTotal && isAudioEnabled === "true") {
    //                 await audio.play();
    //                 console.log('run')
    //             };
    //             setIsAudioEnabled(localStorage.getItem("audioEnabled"))
    //             setOrderTotal(getRes.responsePacket);
    //         };
    //     } catch (e) {
    //         console.log(e, 'error in order Count api')
    //     }
    // };

    const orderCount = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/order/getOrderCount`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                const audioEnabled = localStorage.getItem("audioEnabled") === "true";
                if (getRes.responsePacket === orderTotal && audioEnabled) {
                    try {
                        console.log('Condition Met: Playing Audio');
                        // await audio.play();
                    } catch (err) {
                        console.error('Audio playback failed:', err);
                    }
                }
                setIsAudioEnabled(localStorage.getItem("audioEnabled")); // For UI update
                setOrderTotal(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'Error in order Count API');
        }
    };


    useEffect(() => {
        // const interval = setInterval(orderCount, 10000);
        // return () => clearInterval(interval);
    }, []);

    const pageList = [
        {
            path: '/admin/dash',
            title: 'Dashboard',
            icon: <MdDashboard />,
            module: 'dashboard',
        },
        {
            path: '/admin/banner',
            title: 'Banner',
            icon: <TfiLayoutSlider />,
            module: 'banner',
        },
        {
            path: '/admin/cart',
            title: 'Cart',
            icon: <FaCartArrowDown />,
            module: 'cart',
            // cartTotal: totalitems,
        },
        {
            path: '/admin/order',
            title: 'Order',
            icon: <BiSolidPurchaseTag />,
            module: 'order',
            // cartTotal: orderTotal,
        },
        {
            path: '/admin/customer',
            title: 'Customer',
            icon: <FaPeopleArrows />,
            module: 'customer'
        },
        {
            path: '/admin/notify',
            title: 'Notify Me Items',
            icon: <AiFillNotification />,
            module: 'notify_me_items'
        },
        {
            path: '/admin/category',
            title: 'Category',
            icon: <MdOutlineCategory />,
            module: 'category',
        },
        {
            path: '/admin/item',
            title: 'Item',
            icon: <MdOutlineProductionQuantityLimits />,
            module: 'item',
        },
        {
            path: "/admin/publisher",
            title: 'Publisher',
            icon: <SiPostman />,
            module: "publisher",
        },
        {
            path: '/admin/displayCat',
            title: "Display Category",
            icon: <CgDisplayGrid />,
            module: "display_category",
        },
        {
            path: '/admin/staff',
            title: 'Staff',
            icon: <IoIosPeople />,
            module: 'staff',
        },
        {
            path: '/admin/gift',
            title: 'Coupon',
            icon: < FaGift />,
            module: 'coupon',
        },
        {
            path: '/admin/managePage',
            title: 'Manage Pages',
            icon: < MdAssignmentInd />,
            module: 'manage_pages',
        },
        {
            path: '/admin/otherField',
            title: 'Other Fields',
            icon: < TbInputSearch />,
            module: 'other_fields',
        },
        {
            path: '/admin/setting',
            title: 'Setting',
            icon: <IoMdSettings />,
            module: 'setting'
        },
        {
            path: '/admin/cms',
            title: 'Cms',
            icon: <MdManageHistory />,
            module: 'cms'
        }
    ];

useEffect(() => {
        if (activeRef.current) {
            activeRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [location.pathname]);

    return (
        <>
            {/* <h2 onClick={audioEnabled}>play</h2> */}
            <div className={activeClass ? "active main" : "main"}>
                <nav className={activeClass ? "active" : ""}>
                    <ul ref={ulRef}>
                        <li>
                            {/* Conditionally render based on the "active" state */}
                            {
                                activeClass ? (
                                    <div className="admin">
                                        <h1>The Book 24</h1>
                                    </div>
                                ) : (
                                    <div className="admin-inactive">
                                        <h1>TB2</h1>
                                    </div>
                                )}
                        </li>

                        {
                            pageList.map((itm) => {
                                return (
                                    <>
                                        <div>
                                            {
                                                access?.responsePacket?.some((item) => item.moduleDetail.parentTitle === itm.module) && (
                                                    <li className={isActive(itm.path) ? 'active-menu' : ""}  ref={isActive(itm.path) ? activeRef : null}>
                                                        <Link to={itm.path} className="d-flex ali-itm">
                                                            <span className="icon">{itm.icon}</span>
                                                            <span className="title">{itm.title}</span>
                                                            {itm.cartTotal && <span className="icon">{itm.cartTotal}</span>}
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            })
                        }

                        <li>
                            <Link onClick={handleLogOut}>
                                <span className="icon"><CiLogout /></span>
                                <span className="title">Sign Out</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}

export { SlideBar };