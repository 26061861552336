import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    value: {
        user: ''
    }
}

const AdminSlice = createSlice({
    name: "AdminSlice",
    initialState,
    reducers: {
        data: (state, action) => {
            state.value.user = action.payload
        },
    }
})
export const { data } = AdminSlice.actions

export default AdminSlice.reducer