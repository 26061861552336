import React, { useState, useEffect, useRef } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { RiImageAddFill } from "react-icons/ri";
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";
import { useSelector } from "react-redux";
import { uploadImage } from "../../../CommonComponents/ImageUpload";
import { MagnifyImage } from "../../../CommonComponents/MagnifyImage";

function UpdateBanner() {
    // 
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const token = sessionStorage.getItem('tokenKey');
    const navigate = useNavigate();

    const location = useLocation();
    const data = location.state;

    const [title, setTitle] = useState(data.title);
    const [imagePreviews, setImagePreviews] = useState(data.bannerImageUrl ? [data.bannerImageUrl] : []);
    const [bannerTypeEnum, setBannerTypeEnum] = useState(data.bannerTypeEnum);
    const [onclickRedirectUrl, setOnclickRedirectUrl] = useState(data.onclickRedirectUrl);

    const content = {
        title: title,
        bannerImageUrl: imagePreviews.join(','),
        bannerTypeEnum: bannerTypeEnum,
        onclickRedirectUrl: onclickRedirectUrl
    }

    const members = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/banner/update/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(content)
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                console.log(getRes, "responce from add item");
                setAction('you banner Updated successfully !');
                setShowPop(true);
                setNavv('ok');
                setMore('cancel');
            } else {
                setShowPop(true);
                setAction('something went wrong');
                setNavv('refresh');
                setMore('cancel');
            }
        } catch (e) {
            console.log(e, 'error in member api')
        }
    };

    const [showPop, setShowPop] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();

    const navToCat = useNavigate();
    const confirmPopUp = () => {
        if (navv === 'ok') {
            setShowPop(false);
            navToCat('/admin/banner');
        } else if (navv === 'refresh') {
            setShowPop(false);
            navToCat('/admin/banner');
        }
    };

    const onCloseBtn = () => {
        if (more === 'Add more') {
            setShowPop(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'  // Optional: Use 'auto' for instant scroll
            });
        } else if (more === 'cancel') {
            navToCat('/admin/banner');
        }
    };


    const handleFileChange = async (e) => {
        const files = e.target.files;
        const uploadedUrls = [];
        // Loop through each selected file and upload
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                uploadedUrls.push(imageUrl.responsePacket);
                // setImagePreviews((prev=> [...prev, imageUrl]));
                console.log('image upload');
            }
        };
        setImagePreviews(uploadedUrls); // Update state with all uploaded image URLs
    };

    const handleDeleteImage = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to remove this image?");
        if (confirmDelete) {
            // Remove the image from the preview and formData arrays
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews
        };
    };

    const [showBannerType, setShowBannerType] = useState(false);
    const [typeOfbanner, setTypeOfBanner] = useState([
        { titel: "DashboardTop" }
    ]);
    const [filterTypeOfBanner, setFilterTypeOfBanner] = useState([...typeOfbanner]);

    const searchBannerType = (e) => {
        const res = typeOfbanner.filter((itm) => itm.titel.toLowerCase().includes(e.toLowerCase()));
        setFilterTypeOfBanner(res);
        setShowBannerType(true);
    };

    const selectBanner = (value) => {
        setBannerTypeEnum(value.titel);
        setShowBannerType(false);
        setFilterTypeOfBanner(typeOfbanner);
    };

    const dashRef = useRef();

    useEffect(() => {
        const handleCLick = (event) => {
            if (!dashRef.current?.contains(event.target)) {
                setShowBannerType(false);
            }
        }
        document.addEventListener("mousedown", handleCLick);
    }, [dashRef]);

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    const [errors, setErrors] = useState({});
    const titleRef = useRef(null);
    const bannerEnmRef = useRef(null);
    const imageRef = useRef(null);
    const urlRef = useRef(null);

    const handleValidation = () => {
        let newErrors = {};

        if (!title?.trim()) newErrors.title = "titel is required";
        if (!bannerTypeEnum?.trim()) newErrors.bannerTypeEnum = "titel is required";
        if (!onclickRedirectUrl?.trim()) newErrors.onclickRedirectUrl = "titel is required";
        if (imagePreviews?.length === 0) newErrors.imagePreviews = "titel is required";

        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }

        return true;

    };

    const scrollToField = (field) => {
        switch (field) {
            case "title":
                titleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "bannerTypeEnum":
                bannerEnmRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "imagePreviews":
                imageRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "onclickRedirectUrl":
                urlRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        handleValidation();
        if (handleValidation()) {
            members(data.uuid)
        }
    };

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Update member</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form">

                            {/* NAME*/}
                            <div className="d-flex w-100 m-b2 d-other" ref={titleRef}>
                                <div className="add">
                                    <label style={errors.title && { color: "red" }}>Title</label>
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </div>
                                <div className="add parentid" ref={bannerEnmRef}>
                                    <label style={errors.bannerTypeEnum && { color: "red" }}>Banner Type</label>
                                    <input
                                        type="text"
                                        value={bannerTypeEnum}
                                        onChange={(e) => { setBannerTypeEnum(e.target.value); searchBannerType(e.target.value) }}
                                        onClick={() => setShowBannerType(!showBannerType)}
                                    />
                                    {
                                        showBannerType &&
                                        <div className="multiple-cat" ref={dashRef}>
                                            {
                                                filterTypeOfBanner.map((itm, index) => {
                                                    return (
                                                        <>
                                                            <div className="options" key={index}>
                                                                <li onClick={() => { selectBanner(itm) }}>
                                                                    <p>{itm.titel}</p>
                                                                </li>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other" ref={urlRef}>
                                <div className="add">
                                    <label style={errors.onclickRedirectUrl && { color: "red" }}>Redirect Url</label>
                                    <input
                                        type="text"
                                        value={onclickRedirectUrl}
                                        onChange={(e) => setOnclickRedirectUrl(e.target.value)}
                                    />
                                </div>
                            </div>


                            {/* Category Icon File Upload */}
                            <div className="add w-100" ref={imageRef}>
                                <label style={errors.imagePreviews && { color: "red" }}>Image</label>
                                {/* Input Section */}
                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        // multiple
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews.length > 0 ?
                                            <label htmlFor="category-icon">
                                                <div className="custom-label">
                                                    <RiImageAddFill />
                                                    <span>Change Photo</span>
                                                </div>
                                            </label>
                                            :
                                            <label htmlFor="category-icon" className="custom-file-input">
                                                <div className="text">
                                                    <div className="icon">
                                                        <h1><IoCloudUploadOutline /></h1>
                                                        <h4>Add Images</h4>
                                                    </div>
                                                </div>
                                            </label>
                                    }

                                </div>

                                {/* Image Preview Section */}
                                {
                                    imagePreviews.length > 0 && <div className="image-preview-wrapper">
                                        {
                                            imagePreviews?.map((preview, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img
                                                        src={preview}
                                                        alt={`Category Icon ${index + 1}`}
                                                        style={{ objectFit: 'cover' }}
                                                        onClick={() => maginifyImage(preview)}
                                                    />
                                                    {/* Delete Button */}
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteImage(index)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: '1px',
                                                            background: 'red',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>

            <SuccessPopUp
                isOpen={showPop}
                action={action}
                navv={navv}
                // FutherMore={more}
                onClose={onCloseBtn}
                onConfirm={confirmPopUp}
            />
            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />
        </>
    )
}
export { UpdateBanner }