import React, { useEffect, useState } from "react";
import { useRole } from "../../../../../Routing/Routing";

function Setting() {

  const { access } = useRole();
  const BASE_URL = process.env.REACT_APP_PP_URL;
  const token = sessionStorage.getItem('tokenKey');

  const [list, setList] = useState({});

  const getData = async () => {
    try {
      const res = await fetch(`${BASE_URL}/admin/v1/setting/setting-list`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const getRes = await res.json();
      if (getRes.errorCode === 0) {
        setList(getRes.responsePacket);
        setAndroidVersion(getRes.responsePacket?.floatSettings?.AppVersion);
        setBaseUrl(getRes.responsePacket?.stringSettings?.BaseUrl);
        setNotificationEmail(getRes.responsePacket.stringSettings?.NotificationEmailTo);
        setToggleSettings(() => ({
          TestMode: +getRes.responsePacket.toggleSettings.TestMode,
          StaffLogin: +getRes.responsePacket.toggleSettings.StaffLogin,
          AdminLogin: +getRes.responsePacket.toggleSettings.AdminLogin,
          UnderMaintenance: +getRes.responsePacket.toggleSettings.UnderMaintenance,
        }));
        const parsedArray = JSON.parse(getRes.responsePacket.deliveryCharges?.settingValue); // Parse the JSON string
        setDeliveryJson(parsedArray);
      }
    } catch (e) {
      console.log(e, 'error in getting data api')
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [toggleSettings, setToggleSettings] = useState({
    TestMode: Number(list?.toggleSettings?.TestMode),
    AdminLogin: Number(list?.toggleSettings?.AdminLogin),
    StaffLogin: Number(list?.toggleSettings?.StaffLogin),
    UnderMaintenance: Number(list?.toggleSettings?.UnderMaintenance),
  });

  const updateToggleSetting = async () => {
    try {
      const res = await fetch(`${BASE_URL}/admin/v1/setting/update-settings`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {
            settings: [
              {
                settingKey: 'AdminLogin',
                settingValue: toggleSettings.AdminLogin,
              },
              {
                settingKey: 'TestMode',
                settingValue: toggleSettings.TestMode,
              },
              {
                settingKey: 'StaffLogin',
                settingValue: toggleSettings.StaffLogin,
              },
              {
                settingKey: 'UnderMaintenance',
                settingValue: toggleSettings.UnderMaintenance,
              }
            ]
          }
        )
      })
      const getRes = await res.json();
      if (getRes.errorCode === 0) {
        getData();
      };
    } catch (e) {
      console.log(e, 'error in update setting api')
    }
  };

  const handleToggle = (key) => {
    setToggleSettings((prev) => ({
      ...prev,
      [key]: prev[key] === 1 ? 0 : 1, // Toggle between 1 and 0
    }));
  };

  const [androidVersion, setAndroidVersion] = useState(list?.floatSettings?.AppVersion);
  const updateFloatSetting = async () => {
    try {
      const res = await fetch(`${BASE_URL}/admin/v1/setting/update-settings`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          settings: [
            {
              settingKey: "AppVersion",
              settingValue: androidVersion,
            }
          ]
        })
      })
      const getRes = await res.json();
      if (getRes.errorCode === 0) {
        getData();
      }
    } catch (e) {
      console.log(e, 'error in updatein floARt setting')
    }
  };

  const [baseUrl, setBaseUrl] = useState(list?.stringSettings?.BaseUrl);
  const [notificationEmail, setNotificationEmail] = useState(list?.stringSettings?.NotificationEmailTo);
  const updateStringSettings = async () => {
    try {
      const res = await fetch(`${BASE_URL}/admin/v1/setting/update-settings`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          settings: [
            {
              settingKey: "BaseUrl",
              settingValue: baseUrl,
            },
            {
              settingKey: "NotificationEmailTo",
              settingValue: notificationEmail,
            },
          ]
        })
      })
      const getRes = await res.json();
      if (getRes.errorCode === 0) {
        getData();
      }
    } catch (e) {
      console.log(e, 'error in string setting update')
    }
  };

  const [deliveryJson, setDeliveryJson] = useState([
    {
      startWeight: '',
      endWeight: '',
      standardDeliveryCharges: '',
      expressDeliveryCharges: ''
    }
  ]);

  const AddFormJosn = () => {
    setDeliveryJson([
      ...deliveryJson, {
        startWeight: '',
        endWeight: '',
        standardDeliveryCharges: '',
        expressDeliveryCharges: ''
      }
    ]);
  };

  const removeJsonForm = (index) => {
    setDeliveryJson(deliveryJson.filter((_, i) => i !== index));
  };

  const handleChangeJson = (index, e) => {
    const { name, value } = e.target;
    setDeliveryJson(prev =>
      prev.map((form, i) =>
        i === index ? { ...form, [name]: value } : form
      )
    )
  };

  const updateDeliveryCharges = async () => {
    try {
      const data = JSON.stringify(deliveryJson);
      const res = await fetch(`${BASE_URL}/admin/v1/setting/update-settings`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          settings: [
            {
              settingKey: "DeliveryChargeJson",
              settingValue: data,
            }
          ]
        })
      })
      const getRes = await res.json();
      if (getRes.errorCode === 0) {
        getData();
      }
    } catch (e) {
      console.log(e, 'error in update delivery charges api ');
    }
  };

  return (
    <>
      <div className="main-container-setting">
        <div className="settings-page">
          <div className="settings-grid">
            {/* Toggle Settings */}
            <div className="settings-card">
              <h3>Toggle Settings</h3>

              <div className="toggle-item">
                <span>Test Mode</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggleSettings["TestMode"] === 1}
                    onChange={() => handleToggle("TestMode")}
                  />
                  <span className="slider"></span>
                </label>
              </div>

              <div className="toggle-item">
                <span>Admin Login</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggleSettings["AdminLogin"] === 1}
                    onChange={() => handleToggle("AdminLogin")}
                  />
                  <span className="slider"></span>
                </label>
              </div>

              <div className="toggle-item">
                <span>Staff Login</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggleSettings["StaffLogin"] === 1}
                    onChange={() => handleToggle("StaffLogin")}
                  />
                  <span className="slider"></span>
                </label>
              </div>

              <div className="toggle-item">
                <span>UnderMaintenance</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggleSettings["UnderMaintenance"] === 1}
                    onChange={() => handleToggle("UnderMaintenance")}
                  />
                  <span className="slider"></span>
                </label>
              </div>
              <button className="submit-button" onClick={updateToggleSetting}>Submit Toggle Settings</button>
            </div>

            {/* Integer Settings */}
            {/* <div className="settings-card">
              <h3>Delivery Charges</h3>
              <label>Start Weight</label>
              <input
                type="number"
                value={appVersion}
                onChange={(e) => setAppVersion(e.target.value)}
              />
               <label>End Weight</label>
              <input
                type="number"
                value={appVersion}
                onChange={(e) => setAppVersion(e.target.value)}
              />
               <label>Standerd Delivery Chagres</label>
              <input
                type="number"
                value={appVersion}
                onChange={(e) => setAppVersion(e.target.value)}
              />
               <label>Express Delivery Charges</label>
              <input
                type="number"
                value={appVersion}
                onChange={(e) => setAppVersion(e.target.value)}
              />
              <button className="submit-button">Submit Delivery Charges</button>
            </div> */}


            {/* Float Settings */}
            <div className="settings-card">
              <h3>Float Settings</h3>
              <label>Customer App Version:</label>
              <input
                type="text"
                value={androidVersion}
                onChange={(e) => setAndroidVersion(e.target.value)}
              />
              <button className="submit-button" onClick={updateFloatSetting}>Submit Float Settings</button>
            </div>

            {/* String Settings */}
            <div className="settings-card settings-card-a">
              <h3>String Settings</h3>
              <label>BaseUrl:</label>
              <input
                type="text"
                value={baseUrl}
                onChange={(e) => setBaseUrl(e.target.value)}
              />
              <label>NotificationEmailTo:</label>
              <input
                type="email"
                value={notificationEmail}
                onChange={(e) => setNotificationEmail(e.target.value)}
              />
              <button className="submit-button" onClick={updateStringSettings}>Submit String Settings</button>
            </div>

            {/* Delivery Chrges */}
            <div className="settings-card">
              <h3>Delivery Charges</h3>
              {
                deliveryJson.map((itm, index) => {
                  return (
                    <>
                      <div className="add-field m-t2">
                        <button onClick={() => removeJsonForm(index)}>-Remove</button>
                      </div>
                      <div>
                        <label>Start Weight</label>
                        <input
                          type="number"
                          name="startWeight"
                          value={itm.startWeight}
                          onChange={(e) => handleChangeJson(index, e)}
                        />

                        <label>End Weight</label>
                        <input
                          type="number"
                          name="endWeight"
                          value={itm.endWeight}
                          onChange={(e) => handleChangeJson(index, e)}
                        />

                        <label>Standerd Delivery Charges</label>
                        <input
                          type="number"
                          name="standardDeliveryCharges"
                          value={itm.standardDeliveryCharges}
                          onChange={(e) => handleChangeJson(index, e)}
                        />

                        <label>Express Delivery Charges</label>
                        <input
                          type="number"
                          name="expressDeliveryCharges"
                          value={itm.expressDeliveryCharges}
                          onChange={(e) => handleChangeJson(index, e)}
                        />
                      </div>
                      <div className="add-field">
                        <button onClick={AddFormJosn}>+Add More</button>
                      </div>
                    </>
                  )
                })
              }
              <button className="submit-button" onClick={updateDeliveryCharges}>Submit Delivery Charges</button>
            </div>

            {/* Currency Settings */}
            {/* <div className="settings-card">
              <h3>Currency</h3>
              <label>Select Currency:</label>
              <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
                <option value="Indian Rupee">Indian Rupee</option>
                <option value="US Dollar">US Dollar</option>
                <option value="Euro">Euro</option>
              </select>
              <button className="submit-button">Submit Currency Setting</button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
export { Setting }