import React, { useState, useEffect, useRef } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { RiImageAddFill } from "react-icons/ri";
import { SuccessPopUp } from "../../CommonComponents/SuccessPopUp";
import { useSelector } from "react-redux";
import { uploadImage } from "../../CommonComponents/ImageUpload";
import { MagnifyImage } from "../../CommonComponents/MagnifyImage";

function UpdateStaff() {


    // 
    const token = sessionStorage.getItem('tokenKey');
    const navigate = useNavigate();
    const product = useSelector((state) => state.staffSlice.value.user);
    console.log(product, 'produces');

    const [jobRole, setJobRole] = useState(product.jobRole);
    const [firstName, setFirstName] = useState(product.firstName);
    const [lastName, setLastName] = useState(product.lastName);
    const [isdCode, setIsdCode] = useState(product.isdCode);
    const [mobile, setMobile] = useState(product.mobile);
    const [email, setEmail] = useState(product.email);
    const [password, setPassword] = useState(product.password);
    const [imagePreviews, setImagePreviews] = useState(product.photoImageUrl ? [product.photoImageUrl] : []); // Array for storing image previews

    const content = {
        jobRole: jobRole,
        firstName: firstName,
        lastName: lastName,
        isdCode: isdCode,
        mobile: mobile,
        email: email,
        password: password,
        photoImageUrl: imagePreviews.join(',')
    }

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const members = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/staff/update/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(content)
            });
            const getRes = await res.json();
            console.log(getRes, "responce from add item");
            if (getRes.errorCode === 0) {
                setAction('you staff Updated successfully !');
                setShowPop(true);
                setNavv('ok');
                setMore('cancel');
            } else {
                setShowPop(true);
                setAction('something went wrong');
                setNavv('Refersh');
                setMore('cancel');
            }
        } catch (e) {
            console.log(e, 'error in member api')
        }
    };

    const [showPop, setShowPop] = useState(false);
    const [action, setAction] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();

    const navToCat = useNavigate();
    const confirmPopUp = () => {
        if (navv === 'ok') {
            setShowPop(false);
            navToCat('/admin/staff');
        } else if (navv === 'refresh') {
            setShowPop(false);
            navToCat('/admin/staff');
        }
    };

    const onCloseBtn = () => {
        if (more === 'Add more') {
            setShowPop(false);
            setFirstName('');
            setLastName('');
            setJobRole('');
            setEmail('');
            setIsdCode('');
            setPassword('');
            setMobile('');
            window.scrollTo({
                top: 0,
                behavior: 'smooth'  // Optional: Use 'auto' for instant scroll
            });
        } else if (more === 'cancel') {
            navToCat('/admin/staff');
        }
    };



    const handleFileChange = async (e) => {
        const files = e.target.files;
        const uploadedUrls = [];
        // Loop through each selected file and upload
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                uploadedUrls.push(imageUrl.responsePacket);
                // setImagePreviews((prev=> [...prev, imageUrl]));
                console.log('image upload');
            }
        };
        setImagePreviews(uploadedUrls); // Update state with all uploaded image URLs
    };

    const handleDeleteImage = (index) => {

        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
            // Remove the image from the preview and formData arrays
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews
        };
    };

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    const [errors, setErrors] = useState({});
    // Refs for scrolling
    const jobRoleRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const mobileRef = useRef(null);
    const emailRef = useRef(null);


    const handleValidation = () => {
        let newErrors = {};
        // Check each field
        if (!firstName?.trim()) newErrors.firstName = "tax Category is required.";
        if (!lastName?.trim()) newErrors.lastName = "tax Category is required.";
        if (!jobRole?.trim()) newErrors.jobRole = "Title is required.";
        if (!mobile?.trim()) newErrors.mobile = "tax Category is required.";
        if (!email?.trim()) newErrors.email = "tax Category is required.";

        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }

        return true;
    };

    const scrollToField = (field) => {
        switch (field) {
            case "jobRole":
                jobRoleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "firstName":
                firstNameRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "lastName":
                lastNameRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "mobile":
                mobileRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "email":
                emailRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        handleValidation()
        if (handleValidation()) {
            members(product.uuid);

        }
    };

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Update member</h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">
                        <div className="add-form">

                            {/* NAME*/}
                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add" ref={firstNameRef}>
                                    <label style={errors.firstName && { color: "red" }}>Name</label>
                                    <input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="add" ref={lastNameRef}>
                                    <label style={errors.lastName && { color: "red" }}>Last Name</label>
                                    <input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Mobile */}
                            <div className="d-flex w-100 m-b2 d-other" ref={jobRoleRef}>
                                <div className="add">
                                    <label style={errors.jobRole && { color: "red" }}>Job Role</label>
                                    <input
                                        type="text"
                                        value={jobRole}
                                        onChange={(e) => setJobRole(e.target.value)}
                                    />
                                </div>
                                <div className="add " ref={mobileRef}>
                                    <label style={errors.mobile && { color: "red" }}>Mobile no.</label>
                                    <div className="btn-AddCat">
                                        {/* <input
                                            type="text" className="isdInput"
                                            value={isdCode}
                                            onChange={(e) => setIsdCode(e.target.value)}
                                        /> */}
                                        <input
                                            type="text"
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />

                                    </div>

                                </div>
                            </div>

                            {/* email */}
                            <div className="d-flex w-100 m-b2 d-other" ref={emailRef}>
                                <div className="add">
                                    <label style={errors.email && { color: "red" }}>Email</label>
                                    <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                            </div>

                            {/* Category Icon File Upload */}
                            <div className="add w-100">
                                <label>Image</label>
                                {/* Input Section */}
                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        // multiple
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews.length > 0 ?
                                            <label htmlFor="category-icon" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="custom-label">
                                                    <RiImageAddFill />
                                                    <span>Change Photo</span>
                                                </div>
                                            </label>
                                            :
                                            <label htmlFor="category-icon" className="custom-file-input" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="text">
                                                    <div className="icon">
                                                        <h1><IoCloudUploadOutline /></h1>
                                                        <h4>Add Images</h4>
                                                    </div>
                                                </div>
                                            </label>
                                    }

                                </div>

                                {/* Image Preview Section */}
                                {
                                    imagePreviews.length > 0 && <div className="image-preview-wrapper">
                                        {
                                            imagePreviews.map((preview, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img
                                                        src={preview}
                                                        alt={`Category Icon ${index + 1}`}
                                                        style={{ objectFit: 'cover' }}
                                                        onClick={() => maginifyImage(preview)}
                                                    />
                                                    {/* Delete Button */}
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteImage(index)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: '1px',
                                                            background: 'red',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="btn submit-btn">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>

            <SuccessPopUp
                isOpen={showPop}
                action={action}
                navv={navv}
                // FutherMore={more}
                onClose={onCloseBtn}
                onConfirm={confirmPopUp}
            />
            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />
        </>
    )
}
export { UpdateStaff }