import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import '../../../AdminStyle.css';
import { AddCoupon } from "../GiftCardChild/AddCoupon";

function AddCouponParent() {
    return (
        <>
            <MergeComponents getComponent={<AddCoupon />} />
        </>
    )
}
export { AddCouponParent }