import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const FilterCategory = ({ data, cart }) => {
    const [expandedItem, setExpandedItem] = useState(null); // Store only one open dropdown
    const dropdownRefs = useRef({}); // To store references to dropdown items

    // Toggle dropdown visibility for the clicked item
    const toggleExpand = (id) => {
        setExpandedItem((prevId) => (prevId === id ? null : id));
    };

    const handleClickOutside = (event) => {
        // Check if the click is outside of any dropdown
        const isOutsideClick = Object.keys(dropdownRefs.current).every((key) => {
            const ref = dropdownRefs.current[key];
            return ref && !ref.contains(event.target);
        });

        if (isOutsideClick) {
            // Close the open dropdown
            setExpandedItem(null);
        }
    };

    useEffect(() => {
        // Register click event listeners
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);

        return () => {
            // Cleanup event listeners
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, []);
    

    return (
        <div className="filter-category">
            <div className="filter">
                {/* <Swiper
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    slidesPerView={7} // Show 7 items per row
                   
                    className="mySwiper"
                > */}
                    {data?.responsePacket?.dashboardCategoryList?.map((itm) => (
                        // <SwiperSlide key={itm.uuid}>
                            <div
                                className="nav-item"
                                ref={(el) => (dropdownRefs.current[itm.uuid] = el)} // Assigning ref to each nav-item
                                onClick={() => toggleExpand(itm.uuid)}
                            >
                                <Link
                                    to={`/collegebook/${itm.subCategoryList?.[0]?.uuid || itm.uuid}`}
                                    state={{ itm, cart }}
                                    className="filter-link"
                                >
                                    <div className="img">
                                        <img src={itm.categoryIconUrl} alt={itm.title} />
                                    </div>
                                    <div className="text-container">
                                        <span>{itm.title}</span>
                                    </div>
                                </Link>
                            </div>
                        // </SwiperSlide>
                    ))}
                {/* </Swiper> */}
            </div>
        </div>
    );
};

export { FilterCategory };





