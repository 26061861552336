import React, { useState, useEffect, useRef } from "react";
import { IoIosSearch } from "react-icons/io";
import img from "../../../Assets/Image/Books24-Logo.webp";
import { FaUser, FaShoppingCart } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { SlArrowRight } from "react-icons/sl";
import { RxCross1 } from "react-icons/rx";
import { IoAdd } from "react-icons/io5";
import { RiSubtractLine } from "react-icons/ri";
import { RiFileList2Line } from "react-icons/ri";
import { MdDeliveryDining } from "react-icons/md";
import { MdDiscount } from "react-icons/md";
import { LoginNumber } from "./Cart/LoginNumber";
import { IoLocationSharp } from "react-icons/io5";
import { HiOutlineLogin } from "react-icons/hi";
import { FaUserLock } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { IoMdMenu } from "react-icons/io";
import { BsMenuUp } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import { VscClose } from "react-icons/vsc";
import axios from "axios";
import { NewAdressForm } from "./Cart/NewAdressForm";
import { placeOrder, cartCount, itemQuantity, removeCartItem, removeAllCart, AddToCart } from "../../../Utilities/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartCount, fetchHeaderData } from "../../../Store/apiSlice";
import { AdressForm } from "./Cart/AdressForm";
import emptyimg from "../../../Assets/Image/empty-cart.png"
import { CiDiscount1 } from "react-icons/ci";
import { SearchDetail } from "./SearchDetail";

function Navbar({ setsearchDetail }) {

    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [isCartVisible, setIsCartVisible] = useState(false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [error, setError] = useState('');
    const [couponlisttt, setcouponlist] = useState([]);
    const BASE_URL = process.env.REACT_APP_PP_URL;

    const dispatch = useDispatch();
    const data = useSelector((state) => state.header.data);



    const [showSuggestion, setShowSuggestion] = useState(false);
    const ref = useRef(null);
    const refAccount = useRef(null);
    const refSearch = useRef(null);

    const [isopenAccount, setopenAccount] = useState(false)

    const setAccount = () => {
        setopenAccount(!isopenAccount)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setShowSuggestion(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!refAccount?.current?.contains(event.target)) {
                // setShowSuggestions(false)
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [refAccount]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!refSearch?.current?.contains(event.target)) {
                // setShowSuggestions(false)
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [refSearch]);

    const dropdownRef = useRef(null);
    const placeholders = [
        'Search "Collage Book"',
        'Search "Notebook"',
        'Search "Study Materials"',
        'Search "Fiction Books"',
        'Search "Educational Toys"'
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        }, 1700);
        return () => clearInterval(interval);
    }, []);

    const toggleDropdown = () => {
        setShowSuggestion(!showSuggestion)
    };


    const toggleCart = () => {
        setIsCartVisible((prev) => !prev);
    };

    const toggleLoginModal = () => {
        setIsLoginModalVisible((prev) => !prev);
    };

    useEffect(() => {
        if (isCartVisible || isLoginModalVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isCartVisible, isLoginModalVisible]);

    const token = sessionStorage.getItem('tokenKey');
    const key = localStorage.getItem('userId');

    useEffect(() => {
        dispatch(fetchHeaderData()).then((action) => {
            if (action.type === "header/fetchHeaderData/fulfilled") {
                const cartId = action.payload?.responsePacket?.uuid;
                dispatch(fetchCartCount(cartId));
            }
        });
    }, []);

    const cartInfo = useSelector((state) => state.header.cartInfo);

    const Coupon = async () => {
        try {
            const response = await fetch(`${BASE_URL}/guest-customer/v1/couponCode/list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.errorCode === 0) {
                setcouponlist(result.responsePacket);
            }

            console.log(result, 'coupon');
        }
        catch (error) {
            console.error("Fetch error in getCartDetail:", error);
            setError('An error occurred while fetching banner data.');
        }
    };

    useEffect(() => {
        Coupon();
    }, []);

    const iconRef = useRef(null);

    const handlemenu = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            iconRef.current &&
            !iconRef.current.contains(event.target)
        ) {

        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handlemenu);
        return () => document.removeEventListener("mousedown", handlemenu);
    }, []);

    const [on, off] = useState(false);

    const btn1 = () => {
        off(true);
    };

    const btn2 = () => {
        off(false);
    };

    // --------search-------
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const handleInputChange = async (e) => {
        const search = e.target.value;
        setSearchText(search);

        if (search) {
            try {
                // API call to fetch search suggestions
                const response = await axios.get(`${BASE_URL}/guest-customer/v1/homePage/item-search`, {
                    params: { search },
                });

                const fetchedSuggestions = response.data || [];
                if (fetchedSuggestions.errorCode === 0) {
                    setsearchDetail(fetchedSuggestions.responsePacket); // Update the parent state
                    setSuggestions(fetchedSuggestions.responsePacket); // Store suggestions locally
                    setShowSuggestions(true); // Show suggestions dropdown or section
                } else {
                    console.error("Error fetching suggestions:", fetchedSuggestions.message);
                }
            } catch (error) {
                console.error("Error fetching search results:", error);
            }
        } else {
            // Clear suggestions and search details when input is empty
            setsearchDetail([]);
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };
    const inputRef = useRef(null);
    const inputReff = useRef(null);
    const locactionn = useLocation()

    useEffect(() => {
        if (locactionn.pathname === "/searchdetail") {
            inputRef.current.focus();
            inputReff.current.focus();
        }
    }, []);


    const handleSuggestionClick = (suggestion) => {
        setSearchText(suggestion.label);
        setShowSuggestions(false);
    };

    const [isOtpVisible, setIsOtpVisible] = useState(false);

    const handlelogin = () => {
        setIsOtpVisible(true); // Show OTP verification on continue
    };

    const navTo = useNavigate();

    const logOut = () => {
        localStorage.removeItem('userId');
        navTo('/');
        window.location.reload();
        setShowSuggestion(false);
    };

    // ADDRESH 
    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);

    const addressList = async (data) => {
        try {
            const res = await fetch(`${BASE_URL}/customer/v1/address/list/${data}`, {
                headers: {
                    'Authorization': `Bearer ${key}`
                },
            });

            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            };

        } catch (e) {
            console.log(e, 'error in list api of address')
        }
    };

    const [getAddress, setGetAddress] = useState();

    const placeOrderr = async (cartUuid) => {
        try {
            const res = await placeOrder(cartUuid);
            if (res.errorCode === 0) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
                toggleCart();
                window.scrollTo({ top: 0, behavior: "smooth" });
                setOpen2(true);
            }
        } catch (e) {
            console.log(e, 'error in api place order');
        }
    };

    const itemQuantityy = async (itmId, itmQuantity) => {
        try {
            const res = await itemQuantity(data?.responsePacket.uuid, itmId, itmQuantity);
            if (res) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.log(e, 'error in quantity appi')
        }
    };

    const removeCartItemm = async (itmId) => {
        try {
            const res = await removeCartItem(data?.responsePacket.uuid, itmId);
            if (res) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }

        } catch (e) {
            console.log(e, 'error in remove item')
        }
    };

    const removeAllCartt = async () => {
        try {
            const res = await removeAllCart(data?.responsePacket?.uuid);
            if (res) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.log(e, 'error in remove all item from cart api')
        }
    };

    const AddToCartt = async (couponid) => {
        try {
            const res = await AddToCart(data?.responsePacket?.uuid, null, couponid)
            if (res) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        }
        catch (e) {
            console.log(e, 'error in remove all item from cart api')
        }
    }

    const open2Ref = useRef(null);
    const [open2, setOpen2] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!open2Ref?.current?.contains(event.target)) {
                setOpen2(false);
            };
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [open2Ref]);


    const [showAddingAddress, setShowAddingAddress] = useState(false);

    const [isPromoDrawerVisible, setPromoDrawerVisible] = useState(false);
    const handleTogglePromoDrawer = () => {
        setPromoDrawerVisible(!isPromoDrawerVisible);
    };

    useEffect(() => {
        if (isOtpVisible && !showAddingAddress) {
            addressList('All');
        }
    }, [showAddingAddress, isOtpVisible]);

    const [couponName, setCouponName] = useState();

    const [updateAddreshUuid, setUpdateAddressUuid] = useState();

    const [isShow, setIsShow] = useState({
        add: false,
        edit: false
    })

    const [action, setAction] = useState();


    const navigate = useNavigate();
    const location = useLocation();

    const searchInpute = () => {

        if (location.pathname !== "/searchdetail") {
            navigate("/searchdetail");
        }
    };

    return (
        <>
            <div className="navbar">
                <header className="navbar">
                    <div className="header-top">
                        <p>Quality Goods, Exceptional Service</p>
                        <p className="p">
                            <marquee behavior="smooth" direction="left">
                                CASH ON DELIVERY NOT AVAILABLE | Call Us: +91 9782938018 | Track Your Order
                            </marquee>
                        </p>
                        <div className="contact-links">
                            <a href="mailto:Thebooks24india@gmail.com">Thebooks24india@gmail.com </a>
                            <a href="/help-center">Help Center</a>
                        </div>
                    </div>
                    <hr />
                    <div className="header-main">
                        <div className="logo">
                            <Link to="/">
                                <img src={img} alt="Syam Book Logo" />
                            </Link>
                        </div>
                        <div className="search-bar" >
                            <div className="input" >
                                <input
                                    ref={inputRef}
                                    type="text"
                                    placeholder={placeholders[placeholderIndex]}
                                    value={searchText}
                                    onChange={handleInputChange}
                                    onClick={searchInpute}
                                // onKeyDown={handleKeyDown}
                                />
                            </div>
                            {/* {showSuggestions && (
                                <div className="search-dropdown" >
                                    <ul>
                                        {suggestions?.map((suggestion, index) => (
                                            <Link to={`/pagedetail/${suggestion.value}`} >
                                                <li key={index} onClick={() => handleSuggestionClick(suggestion)}
                                                //  className={index === activeSuggestionIndex ? "active" : ""}
                                                //     onMouseEnter={() => setActiveSuggestionIndex(index)}
                                                >
                                                    {suggestion.title}
                                                    <VscClose />
                                                </li>
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <button>
                                <i className="fas fa-search"></i>
                            </button> */}
                        </div>
                        <div className="header-links">
                            {
                                key &&
                                <div className="account" onClick={toggleDropdown} style={{ cursor: "pointer" }}>
                                    <span>Account</span>
                                    <span className="rotate">
                                        <IoMdArrowDropdown />
                                    </span>
                                </div>
                            }

                            {
                                showSuggestion && (
                                    <div className="dropdown-menu" ref={ref}>
                                        <ul>
                                            <ul>
                                                {key ?
                                                    <>
                                                        <Link to="/accountparentprofile">
                                                            <li><CgProfile /> Profile</li>
                                                        </Link>

                                                        <Link to="/accountparentmyorder">
                                                            <li><BsMenuUp /> My Orders</li>
                                                        </Link>

                                                        <Link to="/accountparentaddaddress">
                                                            <li><IoLocationSharp />My Address</li>
                                                        </Link>

                                                        <li><FaUserLock /> Privacy</li>

                                                        <Link to="/changepassword">
                                                            <li><RiLockPasswordFill /> Change Password</li>
                                                        </Link>

                                                        <li onClick={() => logOut()}><HiOutlineLogin /> Log Out</li>

                                                    </>
                                                    :
                                                    <>
                                                        <li onClick={toggleLoginModal}><CgProfile /> Profile</li>

                                                        <li onClick={toggleLoginModal}><BsMenuUp /> My Orders</li>

                                                        <li onClick={toggleLoginModal}><IoLocationSharp />My Address</li>

                                                        <li onClick={toggleLoginModal}><FaUserLock /> Privacy</li>

                                                        <li onClick={toggleLoginModal}><RiLockPasswordFill /> Change Password</li>
                                                    </>
                                                }

                                            </ul>
                                        </ul>
                                    </div>
                                )
                            }
                            {
                                !key && <div className="user" onClick={toggleLoginModal} style={{ cursor: "pointer" }}>
                                    <span><FaUser /></span>
                                    <span>Login</span>
                                </div>
                            }

                            <div className="cart-button" onClick={toggleCart} style={{ cursor: "pointer" }}>
                                <FaShoppingCart className="cart-icon" />
                                {cartInfo?.itemCount === 0 && cartInfo?.itemTotal === 0 ? (
                                    <span>Cart</span>
                                ) : (
                                    <div className="cart-info">
                                        <span className="number">{cartInfo?.itemCount} Item</span>
                                        <span className="price">₹{cartInfo?.itemTotal}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            {/*--Responsive-- */}
            <div className="res-main">
                <div className="res-navbar">
                    <div className="logo">
                        <div onClick={btn1} className={on === true ? "menu" : ""} ref={dropdownRef} style={{ cursor: "pointer" }}>
                            <h3><IoMdMenu /></h3>
                        </div>
                        <Link to="/">
                            <div className="img">
                                <img src={img} alt="Syam Book Logo" />
                            </div>
                        </Link>
                    </div>
                    <div className="header-links">
                        {
                            key &&
                            <div className="account" onClick={setAccount} style={{ cursor: "pointer" }}>
                                <span>Account</span>
                                <span className="rotate">
                                    <IoMdArrowDropdown />
                                </span>
                            </div>
                        }

                        {
                            isopenAccount && (
                                <div className="dropdown-menu" ref={refAccount}>
                                    <ul>
                                        <ul>
                                            {key ?
                                                <>
                                                    <Link to="/accountparentprofile">
                                                        <li><CgProfile /> Profile</li>
                                                    </Link>

                                                    <Link to="/accountparentmyorder">
                                                        <li><BsMenuUp /> My Orders</li>
                                                    </Link>

                                                    <Link to="/accountparentaddaddress">
                                                        <li><IoLocationSharp />My Address</li>
                                                    </Link>

                                                    <li><FaUserLock /> Privacy</li>

                                                    <Link to="/changepassword">
                                                        <li><RiLockPasswordFill /> Change Password</li>
                                                    </Link>

                                                    <li onClick={() => logOut()}><HiOutlineLogin /> Log Out</li>

                                                </>
                                                :
                                                <>
                                                    <li onClick={toggleLoginModal}><CgProfile /> Profile</li>

                                                    <li onClick={toggleLoginModal}><BsMenuUp /> My Orders</li>

                                                    <li onClick={toggleLoginModal}><IoLocationSharp />My Address</li>

                                                    <li onClick={toggleLoginModal}><FaUserLock /> Privacy</li>

                                                    <li onClick={toggleLoginModal}><RiLockPasswordFill /> Change Password</li>
                                                </>
                                            }

                                        </ul>
                                    </ul>
                                </div>
                            )
                        }
                        {
                            !key && <div className="user" onClick={toggleLoginModal} style={{ cursor: "pointer" }}>
                                <span><FaUser /></span>
                                <span>Login</span>
                            </div>
                        }
                        <div className="res-btn">
                            <div className="cart-button" onClick={toggleCart} style={{ cursor: "pointer" }}>
                                <FaShoppingCart className="cart-icon" />
                                {cartInfo?.itemCount === 0 && cartInfo?.itemTotal === 0 ? (
                                    <span>Cart</span>
                                ) : (
                                    <div className="cart-info">
                                        <span className="number">{cartInfo?.itemCount} Item</span>
                                        <span className="price">₹{cartInfo?.itemTotal}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className="res-btn">
                        <div className="cart-button" onClick={toggleCart} style={{ cursor: "pointer" }}>
                            <FaShoppingCart className="cart-icon" />
                            {cartInfo?.itemCount === 0 && cartInfo?.itemTotal === 0 ? (
                                <span>Cart</span>
                            ) : (
                                <div className="cart-info">
                                    <span className="number">{cartInfo?.itemCount} Item</span>
                                    <span className="price">₹{cartInfo?.itemTotal}</span>
                                </div>
                            )}
                        </div>
                    </div> */}
                </div>
                <div className="res-nav-b">
                    <div className="search-bar" >
                        <div className="input" >
                            <input
                                ref={inputReff}
                                type="text"
                                placeholder={placeholders[placeholderIndex]}
                                value={searchText}
                                onChange={handleInputChange}
                                onClick={searchInpute}
                            // onKeyDown={handleKeyDown}
                            />
                        </div>
                        {/* {showSuggestions && (
                            <div className="search-dropdown" ref={refSearch}>
                                <ul>
                                    {suggestions?.map((suggestion, index) => (
                                        <Link to={`/pagedetail/${suggestion.value}`}>
                                            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                                {suggestion.label}
                                                <VscClose />
                                            </li>

                                        </Link>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <button>
                            <i className="fas fa-search"></i>
                        </button> */}
                    </div>
                </div>

                <div className={`block-d ${on === true ? "dropdown-menu" : "dropdown-menu-b"}`} >
                    <ul>
                        <div className="drop-text">
                            <h2>Account</h2>
                            <p ref={iconRef} onClick={btn2} className={on === false ? "close1" : ""}><RxCross1 /></p>

                        </div>
                        <hr />
                        {key ?
                            <>
                                <Link to="/accountparentprofile">
                                    <li><CgProfile /> Profile</li>
                                </Link>

                                <Link to="/accountparentmyorder">
                                    <li><BsMenuUp /> My Orders</li>
                                </Link>

                                <Link to="/accountparentaddaddress">
                                    <li><IoLocationSharp />My Address</li>
                                </Link>

                                <li><FaUserLock /> Privacy</li>

                                <Link to="/changepassword">
                                    <li><RiLockPasswordFill /> Change Password</li>
                                </Link>

                                <li onClick={() => logOut()}><HiOutlineLogin /> Log Out</li>

                            </>
                            :
                            <>
                                <li onClick={toggleLoginModal}><CgProfile /> Profile</li>

                                <li onClick={toggleLoginModal}><BsMenuUp /> My Orders</li>

                                <li onClick={toggleLoginModal}><IoLocationSharp />My Address</li>

                                <li onClick={toggleLoginModal}><FaUserLock /> Privacy</li>

                                <li onClick={toggleLoginModal}><RiLockPasswordFill /> Change Password</li>
                            </>
                        }
                    </ul>
                </div>

            </div>

            {/* Cart Section */}
            {isCartVisible && <div className="overlay-cart" onClick={toggleCart}></div>}
            <div className={`cart-container ${isCartVisible ? 'cart-visible' : 'cart-hidden'}`}>

                <div className="cart-header">

                    <div className="cart-center">
                        <div></div>
                        <h2>My Cart</h2>
                        <span onClick={toggleCart}><RxCross1 /></span>
                    </div>
                </div>
                <div className="cart-fix-btn">
                    {data?.responsePacket?.itemDetailList?.length > 0 ? (
                        <>

                            <div className="cart-items">
                                <CartItem
                                    togleclose={setIsCartVisible}
                                    filterdata={data?.responsePacket?.itemDetailList}
                                    cartremove={removeCartItemm}
                                    cartItems={data?.responsePacket}
                                    itemaddQuantity={itemQuantityy}
                                />
                            </div>

                            <div className="promo">
                                <div className="promo-code" onClick={handleTogglePromoDrawer}>
                                    <div className="promo-left">
                                        <div className="promo-icon">
                                            <CiDiscount1 />
                                        </div>
                                        <div className="promo-details">
                                            {couponName && key ? (
                                                <>
                                                    <span className="promo-message">{couponName}</span>
                                                    <span className="promo-status"> Coupon Applied</span>
                                                </>
                                            ) : (
                                                <span className="promo-message">View Coupons & Offers</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="promo-arrow">
                                        <SlArrowRight />
                                    </div>
                                </div>
                            </div>

                            <BillDetails cartItems={data?.responsePacket} />


                            <div className="total-amountt">
                                {
                                    getAddress && <div className="delivery-location" onClick={handlelogin}>
                                        <div className="main-location-a">
                                            <div className="location">
                                                <h3><IoLocationSharp /></h3>
                                            </div>
                                            <div className="location-details">
                                                <p><strong>{getAddress?.addressLine1}</strong></p>
                                                {/* <p className="p">Lalkothi, Jaipur</p> */}
                                            </div>
                                        </div>
                                        <SlArrowRight />
                                    </div>
                                }

                                {
                                    getAddress ?
                                        <div className="total-amount">
                                            <button className="proceed-button-flex" onClick={() => { placeOrderr(data?.responsePacket?.uuid); setGetAddress('') }}>
                                                <div className="total-amo-text2-flex" >
                                                    <p>Proced to Pay</p>
                                                    <span>₹{data?.responsePacket?.orderTotal - data?.responsePacket?.couponDiscountAmount}</span>
                                                </div>
                                            </button>
                                        </div>
                                        :
                                        (key ?
                                            <>
                                                <div className={` ${isOtpVisible ? "visible" : ""}`} ></div>
                                                <div className="total-amount" onClick={() => { setIsOtpVisible(true); addressList("All") }}>
                                                    <button className="proceed-button">
                                                        <div className="total-amo-text2" >
                                                            <div></div>
                                                            <p>Add Address to Proced</p>
                                                            <span className="arrow"><SlArrowRight /></span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </>
                                            :
                                            <div className="total-amount">
                                                <button className="proceed-button" onClick={toggleLoginModal}>
                                                    <div className="total-amo-text2">
                                                        <div></div>
                                                        <p>Login to Proceed</p>
                                                        <span className="arrow"><SlArrowRight /></span>
                                                    </div>
                                                </button>
                                            </div>
                                        )
                                }
                            </div>
                        </>
                    ) : (
                        <div className="empty-cart-container">
                            <div className="dis">
                                <div className="cart-image">
                                    <img src={emptyimg} alt="" />
                                </div>
                                <h3>Your cart is empty</h3>
                                <p>Add items to get started</p>
                                <Link to="/">
                                    <button className="start-shopping-btn" onClick={toggleCart}>Start Shopping</button>
                                </Link>
                            </div>
                        </div>

                    )}

                </div>

            </div >
            <PromoCode isOpen={isPromoDrawerVisible} onRemoveAll={() => removeAllCartt()} couponlisttt={couponlisttt} cartItems={data?.responsePacket} Cartdetail={AddToCartt} Close={setPromoDrawerVisible} setCouponName={setCouponName} />
            <NewAdressForm action={setAction} updateAddreshUuid={setUpdateAddressUuid} isOpen={isOtpVisible} onClose={() => setIsOtpVisible(false)} filterList={filterList} setGetAddress={setGetAddress} setShowAddingAddress={setShowAddingAddress} />

            {/* Login Modal */}
            {isLoginModalVisible && <LoginNumber onClose={toggleLoginModal} />}

            <AdressForm action={action} modal={isShow.add} modalType='add' isOpen={showAddingAddress} onClose={() => setShowAddingAddress(false)} addressList={addressList} setIsOtpVisible={setIsOtpVisible} updateAddreshUuid={updateAddreshUuid} />

            {
                open2 &&
                <div className="modal-overlay-c" ref={open2Ref}>
                    <div className="confirmation-container">
                        <div className="confirmation-box">
                            <div className="check-icon">
                                <span>&#10004;</span>
                            </div>
                            {/* <h2>Hey Anup Srivastava,</h2> */}
                            <h1>Your Order is Confirmed!</h1>
                            <p>
                                We’ll send you a shipping confirmation email as soon as your order ships.
                            </p>
                            <button className="cancel-btn-c" onClick={() => setOpen2(false)}>OK</button>
                        </div>
                    </div>
                </div>
            }

        </>
    );
};

const CartItem = ({ addToCart, cartremove, filterdata, cartItems, itemaddQuantity, togleclose }) => (
    <>
        <div className="cart-item-add-more">
            {
                filterdata?.map((book, index) => {
                    return (
                        <>

                            <div className="cart-item">
                                <div className="cart-item-info">
                                    <div className="cart-item-image">
                                        <img src={book.itemImageUrls?.[0]} alt={book.title} />
                                    </div>

                                    <div className="cart-item-details">
                                        <div className="p">
                                            <p className="cart-item-name">{book.title}</p>
                                        </div>
                                        <div className="cart-item-price">
                                            <p className="discounted-price">&#8377;{book.sellingPrice}</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !cartItems?.itemDetailList?.some((itm) => itm.itemUuid === book.itemUuid) ? (
                                        <button onClick={() => addToCart(book.itemUuid)}>ADDdd</button>
                                    ) : (
                                        <div className="quantity-control">
                                            <div className="quantity-control-b">
                                                <span onClick={() => {
                                                    const quantity = cartItems?.itemDetailList?.find((itm) => itm.itemUuid === book.itemUuid)
                                                    { quantity.quantity === 1 ? cartremove(quantity.itemUuid) : itemaddQuantity(book.itemUuid, quantity.quantity - 1) }
                                                }
                                                }><RiSubtractLine /></span>
                                                <p>{cartItems?.itemDetailList?.find((itm) => itm.itemUuid === book.itemUuid).quantity}</p>
                                                <span onClick={() => {
                                                    const quantity = cartItems?.itemDetailList?.find((itm) => itm.itemUuid === book.itemUuid).quantity
                                                    itemaddQuantity(book.itemUuid, quantity + 1)
                                                }}><IoAdd /></span>
                                            </div>
                                            {/* <div className="btn">
                                            <button onClick={() => cartremove(book.itemUuid)} ><RiDeleteBin7Line /></button>
                                        </div> */}
                                        </div>
                                    )
                                }




                            </div>


                        </>
                    )
                })
            }
            <div className="sec-btn" >
                <h4>Missed Something?</h4>
                <Link to="/">
                    <button onClick={() => togleclose(false)}>+ Add More Items</button>
                </Link>
            </div>
        </div>
    </>

);

const PromoCode = ({ isOpen, onRemoveAll, couponlisttt, Cartdetail, Close, setCouponName }) => {

    const RenderHTML = ({ htmlContent }) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
    };

    if (!isOpen) return null

    return (
        <>
            <div className="promo">

                <>
                    <div className="promo-drawer">
                        <div className="promo-header">
                            <div className="promo-flex">
                                <div></div>
                                <h2>Apply Promo Code</h2>
                                <button className="close-button" onClick={() => Close(false)}>
                                    <span><RxCross1 /></span>
                                </button>
                            </div>
                        </div>
                        <div className="promo-content">
                            <ul>
                                {couponlisttt?.map((itm) => (
                                    <li key={itm.uuid} className="promo-item">
                                        <div className="promo-card">
                                            <div className="coupon-icon">
                                                <CiDiscount1 />
                                            </div>
                                            <div className="promo-details">
                                                <h3 className="promo-title">{itm.couponCode}</h3>
                                                <p className="promo-description">
                                                    <RenderHTML htmlContent={itm.description} />
                                                </p>
                                                <p className="promo-description">
                                                    Save{" "}
                                                    {itm.discountType === "Amount" ? (
                                                        `₹${itm.discountValue}` // Show ₹ if discountType is "amount"
                                                    ) : (
                                                        `${itm.discountValue}%` // Show % if discountType is "percentage"
                                                    )}{" "}
                                                    on this order
                                                </p>
                                            </div>

                                        </div>
                                        <div className="promo-apply">
                                            <button

                                                onClick={() => {
                                                    Cartdetail(itm.uuid);
                                                    Close(false);
                                                    setCouponName(itm.couponCode);
                                                }}
                                            >
                                                Apply Coupon
                                            </button>
                                        </div>

                                    </li>
                                ))}
                            </ul>
                        </div>

                    </div>

                    {/* Background Overlay */}
                    <div
                        className="background-overlay"
                        onClick={() => Close(false)}
                    ></div>
                </>
                {/* )} */}
            </div>
        </>
    );
};

const BillDetails = ({ couponDiscount, cartItems, }) => {
    const key = localStorage.getItem('userId');
    return (
        <>
            <div className="bill-details">
                <p className="bill-title">Bill Details</p>
                <div className="bill-row">
                    <span><span className="gift"><RiFileList2Line /></span>Items Total</span>
                    <span>₹{cartItems?.subTotal}</span>
                </div>
                {
                    key &&
                    <div className="bill-row">
                        <span><span className="gift"><MdDeliveryDining /></span>Delivery charge</span>
                        <span>₹{(cartItems?.packingCharges || 0) + (cartItems?.deliveryCharges || 0)}</span>
                    </div>
                }
                {!cartItems?.couponDiscountAmount == 0 && <div className="bill-row">
                    <span><span className="gift"><MdDiscount /></span>Coupon Discount</span>
                    <span>- ₹{cartItems?.couponDiscountAmount}</span>
                </div>}
            </div>

            <div className="total-row">
                <span>Total Amount</span>
                <span>₹{cartItems?.orderTotal - cartItems?.couponDiscountAmount}</span>
            </div>
        </>
    )
}
    ;


export { Navbar };