import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { Setting } from '../SettingChild/Setting';

function SettingParent() {
    return (
        <>
            <MergeComponents getComponent={<Setting/>}/>
        </>
    )
}
export { SettingParent }