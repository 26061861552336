import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { Notifyme } from '../NotifyMeChild/Notifyme';

function NotifymeParent() {
    return (
        <>
            <MergeComponents getComponent={<Notifyme />} />
        </>
    )
}
export { NotifymeParent }