import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { OtherField } from '../OtherFieldChild/OtherField';

function OtherFieldParent() {
    return (
        <>
            <MergeComponents getComponent={<OtherField />} />
        </>
    )
}
export { OtherFieldParent }