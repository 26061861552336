import React from "react";
import { FaAddressCard, FaClipboardList } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { MdPrivacyTip } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { TbLogout2 } from "react-icons/tb";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

function AccountSlide({ onLogout }) {
    const location = useLocation();
    const currentPath = location.pathname;
    const data = useSelector((state) => state.profileData.data);

    const navTo = useNavigate();

    // Logout Function
    const logOut = () => {
        localStorage.removeItem("userId");
        navTo("/");
        window.location.reload();
    };

    // Navigation Details
    const detail = [
        { icon: <FaAddressCard />, text: "Profile", link: "/accountparentprofile" },
        { icon: <FaClipboardList />, text: "My Orders", link: "/accountparentmyorder" },
        { icon: <ImLocation />, text: "My Address", link: "/accountparentaddaddress" },
        { icon: <MdPrivacyTip />, text: "Privacy", link: "/privacy" },
        { icon: <RiLockPasswordFill />, text: "Change Password", link: "/changepassword" },
        { icon: <TbLogout2 />, text: "Logout", onClick: logOut },
    ];

    return (
        <div className="account-slide">
            <div className="account-holder">
                <div className="name">
                    <h2>{data?.responsePacket?.firstName || "Guest"} {data?.responsePacket?.lastName || ""}</h2>
                    <h3>{data?.responsePacket?.mobile || "No mobile provided"}</h3>
                </div>
            </div>
            {detail.map((item, index) => (
                item.link ? (
                    <Link
                        to={item.link}
                        key={index}
                        className={`account-slide-item ${currentPath === item.link ? "active" : ""}`}
                    >
                        <div>
                            <span className="icon">{item.icon}</span>
                            <span className="text">{item.text}</span>
                        </div>
                    </Link>
                ) : (
                    <div
                        key={index}
                        className="account-slide-item"
                        onClick={item.onClick}
                    >
                        <span className="icon">{item.icon}</span>
                        <span className="text">{item.text}</span>
                    </div>
                )
            ))}
        </div>
    );
}

export { AccountSlide };





