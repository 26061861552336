import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Skeleton } from "../../../../CommonComponents/Skeleton";


function MyorderChild() {
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const deviceId = localStorage.getItem("device_id");
    const token = sessionStorage.getItem("tokenKey");
    const key = localStorage.getItem("userId");

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [loading, setLoading] = useState(true);

    const order = async () => {
        try {
            setLoading(true);
            const res = await fetch(`${BASE_URL}/customer/v1/order/getOrderList`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${key}`,
                },
            });

            const getRes = await res.json();

            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            }
        } catch (e) {
            console.error(e, 'Error fetching orders');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        order();
    }, []);

    return (
        <div className="myorder-list">
            <div className="address-header">
                <span className="title">My Order</span>
            </div>

            {loading ? (
                <div className="order-skeleton-container">
                    {Array(3).fill().map((_, index) => (
                        <Skeleton
                            key={index}
                            className="order-card-skeleton"
                            style={{ height: "150px", width: "100%", margin: "10px 0" }}
                        />
                    ))}
                </div>
            ) : filterList.length === 0 ? (
                <div
                    className="no-orders-message"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "50vh",
                        alignItems: "center",
                    }}
                >
                    <p>Oops, you haven't placed an order yet.</p>
                </div>
            ) : (
                filterList.map((order, index) => (
                    <div key={index} className="order-card">
                        <div className="order-header">
                            <span>Order # {order.orderId} |</span>
                            <span>Payment Mode: {order.paymentType} |</span>
                            <span>Items: {order?.itemDetailList?.length}</span>
                        </div>
                        <hr />
                        <div className="order-items">
                            {order.itemDetailList?.map((item, index) => (
                                <div key={index} className="order-item">
                                    <span className="item-name">{item.title}</span>
                                    <span className="item-quantity">x{item.quantity}</span>
                                </div>
                            ))}
                        </div>
                        <hr />
                        <div className="order-footer">
                            <span className="total">Total ₹{order.orderTotal}</span>
                            <Link to="/accountparentoderdetail" state={order.orderId}>
                                <button className="order-details-button">Order Details</button>
                            </Link>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export { MyorderChild };
