import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { UpdateStaff } from "../UpdateStaff";

function UpdateStaffParent() {
    return (
        <>
<MergeComponents  getComponent={<UpdateStaff/>} />
        </>
    )
};
export { UpdateStaffParent }