import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { AddField } from '../OtherFieldChild/AddField';

function AddFieldParent() {
    return (
        <>
            <MergeComponents getComponent={<AddField />} />
        </>
    )
}
export { AddFieldParent }