import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";
import '../../../AdminStyle.css';
import { SuccessPopUp } from "../../../CommonComponents/SuccessPopUp";
import { uploadImage } from "../../../CommonComponents/ImageUpload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { MagnifyImage } from "../../../CommonComponents/MagnifyImage";

function AddItems() {

    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_PP_URL;

    const [imagePreviews, setImagePreviews] = useState([]);
    // HANDLE IMAGE SECTION    
    const handleFileChange = async (e) => {
        const files = e.target.files;
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                setImagePreviews((prev => [...prev, imageUrl]));
                console.log('image upload');
            }
        };
    };

    const handleDeleteImage = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
            const newPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(newPreviews); // Update previews

        }
    };

    const capitalizeWords = (value) => {
        return value
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
    };

    // SUCCESS POPUP
    const [action, setAction] = useState();
    const [showPop, setShowPop] = useState(false);
    const [extraBtn, setExtraBtn] = useState();
    const [navv, setNavv] = useState();
    const [more, setMore] = useState();
    const navToCat = useNavigate();
    const confirmPopUp = () => {
        setShowPop(false);
        setNavv('ok');
        saveItem("ok");
    };

    const onCloseBtn = () => {
        setAction('');
        setExtraBtn();
        setMore('');
        setShowPop(false);
    };

    const onMoreBtn = () => {
        setNavv('');
        saveItem("addMore")
        setShowPop(false);
        setExtraBtn("addMore")
    };

    const token = sessionStorage.getItem('tokenKey');

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState();
    const [weight, setWeight] = useState();
    const [mrp, setMrp] = useState();
    const [sequenceNo, setSequenceNo] = useState();
    const [sellingPrice, setSellingPrice] = useState();
    const [parentCategoryUuids, setParentCategoryUuids] = useState([]);
    const [subCategoryUuids, setSubCategoryUuids] = useState([]);
    const [offerApplicable, setOfferApplicable] = useState(true);
    const [taxUuid, setTaxUuid] = useState();
    const [publisherUuid, setPublisherUuid] = useState();
    const [getMedium, setGetMedium] = useState();
    const [otherDataJson, setOtherDataJson] = useState({
        medium: getMedium?.label,
        author: "",
        noOfPages: "",
        publicationYear: "",
        hsnCode: "4901",
    });
    useEffect(() => {
        setOtherDataJson((prev) => ({
            ...prev, medium: getMedium?.label
        }))
    }, [getMedium]);


    const content = {
        title: title,
        sequenceNo: 0,
        description: description,
        weight: weight,
        mrp: mrp,
        sequenceNo: sequenceNo,
        itemImageUrls: imagePreviews.map((itm) => itm?.responsePacket),
        sellingPrice: sellingPrice,
        parentCategoryUuids: parentCategoryUuids?.map(itm => itm.value),
        subCategoryUuids: subCategoryUuids?.map(itm => itm.value),
        offerApplicable: offerApplicable,
        taxUuid: taxUuid?.value,
        publisherUuid: publisherUuid?.value,
        otherDataJson: JSON.stringify(otherDataJson)
    };

    const [selectMedium, setSelectMedium] = useState([
        { value: "1", label: "Hindi", },
        { value: "2", label: "English" },
        { value: "3", label: "Bilingual" },
        { value: "4", label: "Urdu" },
        { value: "5", label: "Sanskrit" },
    ]);

    const handleOnChange = (key, value) => {
        setOtherDataJson((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleTex = (text) => {
        if (text === "Excempted") {
            setOtherDataJson((prev) => ({
                ...prev, hsnCode: "4901"
            }))
        } else if (otherDataJson.taxCat !== "Excempted") {
            setOtherDataJson((prev) => ({
                ...prev, hsnCode: ''
            }))
        }
    };

    // SAVE ITEMS API
    const saveItem = async (okk) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/item/save`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(content)
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                console.log(getRes, "responce from add item");
                // setAction('you item added successfully !');
                // setShowPop(true);
                // setNavv('ok');
                if (okk === "addMore") {
                    setTitle('');
                    setDescription('');
                    setWeight('');
                    setMrp('');
                    setSequenceNo('');
                    setSellingPrice('');
                    setParentCategoryUuids([]);
                    setSubCategoryUuids([]);
                    setSubCat([]);
                    setGetMedium('');
                    setOtherDataJson({
                        medium: '',
                        author: "",
                        noOfPages: "",
                        publicationYear: "",
                        hsnCode: "4901",
                    });
                    setImagePreviews([]);
                    setPublisherUuid('')
                    if (subCat !== '') {
                        setFilterSubCat([...subCat]);
                        setManageSubCat([]);
                    };
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'  // Optional: Use 'auto' for instant scroll
                    });

                    setAction('');
                    setExtraBtn();
                    setMore('');
                } else if (okk === 'ok') {
                    navToCat('/admin/item');
                }
                // setMore('Add more');
            } else {
                setShowPop(true);
                setAction('something went wrong');
                setNavv();
                setMore('OK');
                setExtraBtn();
            }
        } catch (e) {
            console.log(e, "error in add itme api");
            setShowPop(true);
            setAction('something went wrong');
            setMore("OK");
            setNavv();
            setExtraBtn();
        }
    };

    // SELECT CATEGORY 
    const [seletedCat, setSelectedCat] = useState([]);
    const [filterCat, setFilterCat] = useState([]);

    const saveCat = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/category/parent-category-list-in-key-value`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setSelectedCat(getRes.responsePacket);
                setFilterCat(getRes.responsePacket);
            }
            console.log(getRes)
        } catch (e) {
            console.log(e, 'errorn in add item select parent cate api')
        }
    };

    useEffect(() => {
        const parentCategoryData = seletedCat.map((itm) => ({
            value: itm.value,
            label: itm.label,
        }));
        setFilterCat(parentCategoryData);
    }, [seletedCat]);

    useEffect(() => {
        saveCat();
    }, []);

    // SUB CATEGPRY
    const [subCat, setSubCat] = useState([]);
    const [filterSubCat, setFilterSubCat] = useState([]);
    useEffect(() => {
        const subCateDataset = subCat.map((itm) => ({
            value: itm.value,
            label: itm.label
        }));
        setFilterSubCat(subCateDataset);
    }, [subCat]);

    const saveSub = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/category/sub-category-list-in-key-value`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    parentCategoryUuids: parentCategoryUuids?.map(itm => itm.value)
                })
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setSubCat(getRes.responsePacket);
            } else {
                console.log('error in savasub api responce')
            }
        } catch (e) {
            console.log(e, 'error in sub category in add  item')
        }
    };

    useEffect(() => {
        if (parentCategoryUuids !== '') {
            saveSub();
        }
        if (parentCategoryUuids.length === 0) {
            setSubCat([]);
            setFilterSubCat([]);
            setManageSubCat([]);
            setSubCategoryUuids([]);
        }
    }, [parentCategoryUuids]);

    useEffect(() => {
        const updatedManageSubCat = manageSubCat.filter((item) =>
            subCat.some((subItem) => subItem.value === item.value));
        const updateSubUuid = subCategoryUuids.filter((item) =>
            subCat.some((subItem) => subItem.value === item.value)
        )
        setSubCategoryUuids(updateSubUuid);
        setManageSubCat(updatedManageSubCat);
    }, [subCat]);

    const [manageSubCat, setManageSubCat] = useState([]);

    const [errors, setErrors] = useState({});
    // Refs for scrolling
    const titleRef = useRef(null);
    const mrpRef = useRef(null);
    const sellingPriceRef = useRef(null);
    const weightRef = useRef(null);
    const parentCateRef = useRef(null);
    const mediumReff = useRef(null);
    const publisherRef = useRef(null);
    const hsnCodeRef = useRef(null);
    const taxRef = useRef(null);

    const handleValidation = () => {
        let newErrors = {};
        // Check each field
        if (!title?.trim()) newErrors.title = "Title is required.";
        if (parentCategoryUuids?.length === 0 && subCategoryUuids?.length === 0) newErrors.parentCategoryUuids = "tax Category is required.";
        if (!mrp?.trim()) newErrors.mrp = "tax Category is required.";
        if (!sellingPrice?.trim()) newErrors.sellingPrice = "tax Category is required.";
        if (!weight?.trim()) newErrors.weight = "tax Category is required.";
        if (!otherDataJson?.medium) newErrors.medium = "Medium is required.";
        if (!publisherUuid) newErrors.publisher = "Publisher is required.";
        if (!taxUuid) newErrors.taxCat = "tax Category is required.";
        if (!otherDataJson?.hsnCode.trim()) newErrors.hsnCode = "HSN Code is required.";
        setErrors(newErrors);

        // If there are errors, scroll to the first one
        if (Object.keys(newErrors).length > 0) {
            const firstErrorField = Object.keys(newErrors)[0];
            scrollToField(firstErrorField);
            return false;
        }

        return true;
    };

    const scrollToField = (field) => {
        switch (field) {
            case "title":
                titleRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "medium":
                mediumReff.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "publisher":
                publisherRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "hsnCode":
                hsnCodeRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "taxCat":
                taxRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "weight":
                weightRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "sellingPrice":
                sellingPriceRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "mrp":
                mrpRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            case "parentCategoryUuids":
                parentCateRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
                break;
            default:
                break;
        }
    };


    const handleSubmit = () => {
        if (handleValidation()) {
            setAction("Confirm submit");
            setShowPop(true);
            setNavv("OK");
            setExtraBtn("Add More");
            setMore("Cancel")
        }
    };

    const [publisherList, setPublisherList] = useState([]);
    const [filterPublisherList, setFilterPublisherList] = useState([...publisherList]);

    const getPublisherList = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/publisher/list-in-key-value`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setPublisherList(getRes.responsePacket);
                setFilterPublisherList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in publisher api')
        }
    };
    useEffect(() => {
        getPublisherList();
    }, []);

    const [taxList, setTaxList] = useState([]);
    const [filtertaxList, setFilterTaxList] = useState([...taxList]);
    useEffect(() => {
        setTaxUuid({ value: filtertaxList[0]?.value, label: filtertaxList[0]?.label });
    }, [filtertaxList]);
    
    const getTaxList = async () => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/tax/list-in-key-value`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setTaxList(getRes.responsePacket);
                setFilterTaxList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in publisher api')
        }
    };
    useEffect(() => {
        getTaxList();
    }, []);

    // Image Magifinify
    const [imgUrl, setImgUrl] = useState();
    const [showImg, setShowImg] = useState(false);
    const maginifyImage = (e) => {
        setShowImg(true);
        setImgUrl(e);
    };

    return (
        <>
            <div className="add-item">
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Add Item </h4>
                        </div>
                        <div className="category-btn">
                            <button onClick={() => navigate(-1)}><RxCross1 /></button>
                        </div>
                    </div>
                    <hr />

                    <div className="main-container">

                        <div className="add-form" ref={titleRef}>
                            {/* Title Input */}
                            <div className="add w-100 m-b2">
                                <label style={errors.title && { color: "red" }}>Title *</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={capitalizeWords(title)}
                                    style={{ textTransform: 'capitalize' }}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            {/* Category Input */}
                            <div className="d-flex w-100 m-b2 d-other" ref={parentCateRef}>
                                <div className="add parentid">
                                    <label style={errors.parentCategoryUuids && { color: "red" }}>Category</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        name="colors"
                                        options={filterCat}
                                        value={parentCategoryUuids}
                                        onChange={(e) => setParentCategoryUuids(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>

                                <div className="add parentid">
                                    <label style={errors.parentCategoryUuids && { color: "red" }}>Select Sub Category</label>

                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        name="colors"
                                        options={filterSubCat}
                                        value={subCategoryUuids}
                                        onChange={(e) => setSubCategoryUuids(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>
                            </div>

                            {/* MRP Input */}
                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add" ref={mrpRef}>
                                    <label style={errors.mrp && { color: "red" }}>MRP *</label>
                                    <input
                                        type="number"
                                        value={mrp}
                                        onChange={(e) => setMrp(e.target.value)}
                                    />
                                </div>
                                <div className="add " ref={sellingPriceRef}>
                                    <label style={errors.sellingPrice && { color: "red" }}>Selling Price *</label>
                                    <input
                                        type="number"
                                        // placeholder="Selling Price"
                                        onChange={(e) => setSellingPrice(e.target.value)}
                                        value={sellingPrice}
                                    />
                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add">
                                    <div className="d-flex check-box-avail">
                                        <input
                                            type="checkbox"
                                            name="availableAllTime"
                                            checked={offerApplicable}
                                            onChange={() => setOfferApplicable(!offerApplicable)}
                                            id="check-box"
                                        />
                                        <label htmlFor="check-box">Coupon available for item</label>
                                    </div>
                                </div>

                                <div className="add" ref={weightRef}>
                                    <label style={errors.weight && { color: "red" }}>Weight *</label>
                                    <div className="btn-AddCat" >
                                        <input
                                            type="number"
                                            onChange={(e) => setWeight(e.target.value)}
                                            value={weight}
                                        />
                                        <button>gm</button>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">
                                <div className="add parentid" ref={mediumReff}>
                                    <label style={errors.medium && { color: "red" }}>Medium *</label>
                                
                                    <Select
                                        closeMenuOnSelect={true}
                                        name="colors"
                                        options={selectMedium}
                                        value={getMedium}
                                        onChange={(e) => setGetMedium(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>

                                <div className="add parentid" ref={publisherRef}>
                                    <label style={errors.publisher && { color: "red" }}>Publisher *</label>
                                    <Select
                                        // defaultValue={[temp[2], temp[3]]}
                                        closeMenuOnSelect={true}
                                        name="colors"
                                        options={filterPublisherList}
                                        value={publisherUuid}
                                        onChange={(e) => setPublisherUuid(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">

                                <div className="add ">
                                    <label>Author</label>
                                    <input
                                        type="text"
                                        value={capitalizeWords(otherDataJson.author)}
                                        style={{ textTransform: 'capitalize' }}
                                        onChange={(e) => handleOnChange("author", e.target.value)}
                                    />
                                </div>

                                <div className="add ">
                                    <label>No. of Pages</label>
                                    <input
                                        type="number"
                                        value={otherDataJson.noOfPages}
                                        onChange={(e) => handleOnChange("noOfPages", e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other">

                                <div className="add" >
                                    <label>Publication Year</label>
                                    <input
                                        type="number"
                                        value={otherDataJson.publicationYear}
                                        onChange={(e) => handleOnChange("publicationYear", e.target.value)}
                                    />
                                </div>

                                <div className="add" ref={taxRef}>
                                    <label style={errors.taxCat && { color: "red" }}>Tax Category *</label>
                                    <Select
                                        defaultValue={taxUuid?.[0]}
                                        closeMenuOnSelect={true}
                                        name="colors"
                                        options={filtertaxList}
                                        value={taxUuid}
                                        onChange={(e) => setTaxUuid(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />

                                </div>
                            </div>

                            <div className="d-flex w-100 m-b2 d-other" >
                                <div className="add parentid" ref={hsnCodeRef}>
                                    <label style={errors.hsnCode && { color: "red" }}>HSN Code *</label>
                                    <input
                                        type="number"
                                        value={otherDataJson.hsnCode}
                                        onChange={(e) => { handleOnChange("hsnCode", e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className="editer m-b2">
                                <div className="add w-100">
                                    <label>Description</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    "heading",
                                                    "|",
                                                    "bold",
                                                    "italic",
                                                    "link",
                                                    "bulletedList",
                                                    "numberedList",
                                                    "|",
                                                    "indent",
                                                    "outdent",
                                                    "|",
                                                    "imageUpload",
                                                    "blockQuote",
                                                    "mediaEmbed",
                                                    "undo",
                                                    "redo",
                                                ],
                                            },
                                        }}
                                        data={description}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                        }}
                                    />

                                </div>



                            </div>

                            {/* Category Icon File Upload */}
                            <div className="add w-100">
                                <label>Item Images</label>
                                {/* Input Section */}
                                <div className="input-img">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        multiple
                                        id="category-icon"
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    {
                                        imagePreviews.length > 0 ?
                                            <label htmlFor="category-icon" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="custom-label">
                                                    <RiImageAddFill />
                                                    <span>Add more</span>
                                                </div>
                                            </label>
                                            :
                                            <label htmlFor="category-icon" className="custom-file-input" tabIndex="0" onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("category-icon").click();
                                                }
                                            }}>
                                                <div className="text">
                                                    <div className="icon">
                                                        <h1><IoCloudUploadOutline /></h1>
                                                        <h4>Add Images</h4>
                                                    </div>
                                                </div>
                                            </label>
                                    }
                                </div>

                                {/* Image Preview Section */}
                                {
                                    imagePreviews.length > 0 && <div className="image-preview-wrapper">
                                        {
                                            imagePreviews.map((preview, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img
                                                        src={preview.responsePacket}
                                                        alt={`Category Icon ${index + 1}`}
                                                        style={{ objectFit: 'cover' }}
                                                        onClick={() => maginifyImage(preview.responsePacket)}
                                                    />
                                                    {/* Delete Button */}
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDeleteImage(index)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-5px',
                                                            left: '-5px',
                                                            background: 'red',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '50%',
                                                            width: '25px',
                                                            height: '25px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div>

                        </div>

                    </div>

                    <div className="btn submit-btn">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>


            {/* POPUP MODAL */}

            <SuccessPopUp
                isOpen={showPop}
                action={action}
                moreBtn={extraBtn}
                onMoreBtn={onMoreBtn}
                navv={navv}
                FutherMore={more}
                onClose={onCloseBtn}
                onConfirm={confirmPopUp}
            />

            <MagnifyImage isOpen={showImg} imgSrc={imgUrl} onClose={() => setShowImg(false)} />

        </>
    );
}

export { AddItems };


