import React from "react";
import { MergeComponents } from "../../../CommonComponents/MergeComponents";
import { Cms } from "../CmsChild/Cms";

function CmsParent() {
    return (
        <>
            <MergeComponents getComponent={<Cms />} />
        </>
    )
}
export { CmsParent }