import React from 'react';
import { MergeComponents } from '../../../CommonComponents/MergeComponents';
import { AddBanner } from '../BannerChild/AddBanner';

function AddBannerParent() {
    return (
        <>
        <MergeComponents getComponent={<AddBanner/>}/>
        </>
    )
}
export { AddBannerParent }