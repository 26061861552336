import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../../../CommonComponents/Navbar";
import Footer from "../../../CommonComponents/Footer";
import { IoAdd } from "react-icons/io5";
import { RiSubtractLine } from "react-icons/ri";
import "../../../../../App.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { AddToCart, itemQuantity, removeCartItem } from "../../../../../Utilities/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartCount, fetchHeaderData } from "../../../../../Store/apiSlice";
import { Skeleton } from "../../../CommonComponents/Skeleton";
import { TfiMenuAlt } from "react-icons/tfi";

import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";

function CollageBooksCollection1() {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    console.log(isPopupOpen, "isPopupOpen");
    const BASE_URL = process.env.REACT_APP_PP_URL;
    const { id } = useParams();
    const token = sessionStorage.getItem("tokenKey");
    const location = useLocation();
    const getData = location && location.state.itm;

    const [itemDetail, setItemDetail] = useState([]);
    console.log(itemDetail, "itemDetailssssssss");
    const [showAddButtons, setShowAddButtons] = useState([]);
    const [activeCategory, setActiveCategory] = useState(id);
    const [loading, setLoading] = useState(true);



    const [sortOption, setSortOption] = useState("");


    const handleSortChange = (e) => {
        const selectedOption = e.target.value;
        setSortOption(selectedOption);

        if (selectedOption) {
            setItemDetail((prevDetails) =>
                prevDetails.map((detail) => ({
                    ...detail,
                    displayCategoryItemList: [...detail.displayCategoryItemList].sort((a, b) => {
                        switch (selectedOption) {
                            case "price-low-to-high":
                                return a.sellingPrice - b.sellingPrice;
                            case "price-high-to-low":
                                return b.sellingPrice - a.sellingPrice;
                            case "name-a-to-z":
                                return a.title.localeCompare(b.title);
                            case "name-z-to-a":
                                return b.title.localeCompare(a.title) * -1;
                            default:
                                return 0;
                        }
                    }),
                }))
            );
        }
    };




    const dispatch = useDispatch();
    const data = useSelector((state) => state.header.data);

    useEffect(() => {
        dispatch(fetchHeaderData());
    }, [dispatch]);

    const getItem = async (categoryUuid) => {
        setLoading(true);
        try {
            const res = await fetch(`${BASE_URL}/guest-customer/v1/homePage/get-category-item-details/${categoryUuid}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setItemDetail([getRes.responsePacket]);
                setShowAddButtons(getRes.responsePacket.displayCategoryItemList.map(() => false));
            }
        } catch (e) {
            console.error("Error fetching item details:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getItem(id);
    }, [id]);

    const handleAddToCart = async (itemId) => {
        try {
            const result = await AddToCart(data?.responsePacket?.uuid, itemId);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.error("Error in AddToCart API:", e);
        }
    };

    const handleItemQuantity = async (itemId, quantity) => {
        try {
            const result = await itemQuantity(data?.responsePacket?.uuid, itemId, quantity);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.error("Error updating item quantity:", e);
        }
    };

    const handleRemoveCartItem = async (itemId) => {
        try {
            const result = await removeCartItem(data?.responsePacket?.uuid, itemId);
            if (result) {
                dispatch(fetchHeaderData()).then((action) => {
                    if (action.type === "header/fetchHeaderData/fulfilled") {
                        const cartId = action.payload?.responsePacket?.uuid;
                        dispatch(fetchCartCount(cartId));
                    }
                });
            }
        } catch (e) {
            console.error("Error removing cart item:", e);
        }
    };



    return (
        <>
            <Navbar />
            {loading ? (
                <div className="category-skeleton-container">
                    {Array(4)
                        .fill()
                        .map((_, index) => (
                            <Skeleton
                                key={index}
                                className="category-skeleton-item"
                                style={{ height: "200px", width: "100%", margin: "10px" }}
                            />
                        ))}
                </div>
            ) : (
                itemDetail.map((itm) => (
                    <div key={itm.uuid}>
                        <div className="sort-2" style={{ display: "none" }} >
                            <div className="text-book" >
                                <h2>{itm.title}</h2>
                                <div className="price-select">
                                    <span>Sort By</span>
                                    <select
                                        value={sortOption}
                                        onChange={handleSortChange}
                                        className="book-price-short">
                                        <option value="">Select Sorting Option</option>
                                        <option value="price-low-to-high">Price: Low to High</option>
                                        <option value="price-high-to-low">Price: High to Low</option>
                                        <option value="name-a-to-z">Name: A to Z</option>
                                        {/* <option value="name-z-to-a">Name: Z to A</option> */}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="springboard-notes">
                            <div className="home-row">
                                {getData?.subCategoryList && (
                                    <div className="left-book">
                                        {getData.subCategoryList.map((subCategory) => (
                                            <div
                                                key={subCategory.uuid}
                                                className={`flex ${activeCategory === subCategory.uuid ? "active-aa" : ""}`}
                                                onClick={() => {
                                                    setActiveCategory(subCategory.uuid);
                                                    getItem(subCategory.uuid);
                                                }}
                                            >
                                                <div className="flex-2">
                                                    {/* <div className="img-a"> */}
                                                    <div className="img">
                                                        <img src={subCategory.categoryIconUrl} alt="" />
                                                    </div>
                                                    {/* </div> */}
                                                    <div className="text">
                                                        <h3>{subCategory.title}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}




                                {/* -------responsive-------- */}
                                {/* <div className="main-menu ">
                                    <Swiper
                                        scrollbar={{
                                            hide: true,
                                        }}
                                        slidesPerView={6} // Show 5 items at a time
                                        spaceBetween={10} // Adjust space between slides if needed

                                        modules={[Scrollbar]}
                                        className="mySwiper-category"
                                    >
                                        {getData?.subCategoryList && (
                                            <div className="left-book-b">

                                                {getData.subCategoryList.map((subCategory) => (
                                                    <SwiperSlide className="mySwiper-category">
                                                        <div
                                                            key={subCategory.uuid}
                                                            className={`flex ${activeCategory === subCategory.uuid ? "active-aa" : ""}`}
                                                            onClick={() => {
                                                                setActiveCategory(subCategory.uuid);
                                                                getItem(subCategory.uuid);
                                                            }}
                                                        >
                                                            <div className="flex-2">
                                                                <div className="img">
                                                                    <img src={subCategory.categoryIconUrl} alt="" />
                                                                </div>
                                                                <h3>{subCategory.title}</h3>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </div>
                                        )}
                                    </Swiper>
                                    <div className="menu-btn">
                                        <h2 onClick={() => setIsPopupOpen(true)}><TfiMenuAlt /></h2>
                                    </div>
                                </div>

                                {
                                   <div className={`popup-overlay ${isPopupOpen ? "active-bb" : ""}`}>
                                   <div
                                     className={`popup-container ${
                                       isPopupOpen ? "category-menu" : "category-menu-b"
                                     }`}
                                   >
                                     <div className="popup-header">
                                       <h2>All Categories</h2>
                                       <button
                                         className="close-btn"
                                         onClick={() => setIsPopupOpen(false)}
                                       >
                                         &times;
                                       </button>
                                     </div>
                                     <div className="popup-content">
                                       <ul className="menu-list">
                                         {getData?.subCategoryList?.map((subCategory) => (
                                           <li
                                             key={subCategory.uuid}
                                             onClick={() => {
                                               setActiveCategory(subCategory.uuid);
                                               getItem(subCategory.uuid);
                                               setIsPopupOpen(false); // Optional: close on selection
                                             }}
                                           >
                                             <span className="menu-item">{subCategory.title}</span>
                                           </li>
                                         ))}
                                       </ul>
                                     </div>
                                   </div>
                                 </div>


                                } */}



                                <div className="main-sort">
                                    <div className="text-book">
                                        <h2>{itm.title}</h2>
                                        <div className="price-select">
                                            <span>Sort By</span>
                                            <select
                                                value={sortOption}
                                                onChange={handleSortChange}
                                                className="book-price-short">
                                                <option value="">Select Sorting Option</option>
                                                <option value="price-low-to-high">Price: Low to High</option>
                                                <option value="price-high-to-low">Price: High to Low</option>
                                                <option value="name-a-to-z">Name: A to Z</option>
                                                {/* <option value="name-z-to-a">Name: Z to A</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="books-container">


                                        {itm.displayCategoryItemList.map((book) => (
                                            <div className="book-card" key={book.uuid}>

                                                <div className="book-image">

                                                    <Link to={`/pagedetail2/${itm.uuid}`} state={book}>
                                                        {book.mrp > book.sellingPrice && (
                                                            <div className="discount-badge">
                                                                <p>{Math.round(((book.mrp - book.sellingPrice) / book.mrp) * 100)}% OFF</p>
                                                            </div>
                                                        )}
                                                        <img src={book.itemImageUrls?.[0]} alt={book.subtitle} />
                                                    </Link>
                                                </div>

                                                <h3>{book.title}</h3>
                                                <div className="price-section">
                                                    <div className="price">
                                                        <p className="p">
                                                            <span className="mrp-price">&#8377;{book.sellingPrice}</span>
                                                        </p>
                                                        {book.sellingPrice !== book.mrp && (
                                                            <strike>
                                                                <span className="original-price">&#8377;{book.mrp}</span>
                                                            </strike>
                                                        )}
                                                    </div>
                                                    {book.stockOut ? (
                                                        <button>Notify Me</button>
                                                    ) : !data?.responsePacket?.itemDetailList?.some(
                                                        (itm) => itm.itemUuid === book.uuid
                                                    ) ? (
                                                        <button onClick={() => handleAddToCart(book.uuid)}>ADD</button>
                                                    ) : (
                                                        <div className="add-btn">
                                                            <span
                                                                onClick={() => {
                                                                    const quantity = data?.responsePacket?.itemDetailList?.find(
                                                                        (itm) => itm.itemUuid === book.uuid
                                                                    ).quantity;
                                                                    if (quantity === 1) {
                                                                        handleRemoveCartItem(book.uuid);
                                                                    } else {
                                                                        handleItemQuantity(book.uuid, quantity - 1);
                                                                    }
                                                                }}
                                                            >
                                                                <RiSubtractLine />
                                                            </span>
                                                            <p className="pp">
                                                                {
                                                                    data?.responsePacket?.itemDetailList?.find(
                                                                        (itm) => itm.itemUuid === book.uuid
                                                                    ).quantity
                                                                }
                                                            </p>
                                                            <span
                                                                onClick={() => {
                                                                    const quantity = data?.responsePacket?.itemDetailList?.find(
                                                                        (itm) => itm.itemUuid === book.uuid
                                                                    ).quantity;
                                                                    handleItemQuantity(book.uuid, quantity + 1);
                                                                }}
                                                            >
                                                                <IoAdd />
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))
            )}
            <Footer />
        </>
    );
}

export { CollageBooksCollection1 };



