import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRole } from '../../../../../Routing/Routing';
import { AiFillDelete } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { FaRedo } from "react-icons/fa";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdTextIncrease } from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Modal from "../../../CommonComponents/Popup";

function Customer() {

    const BASE_URL = process.env.REACT_APP_PP_URL;
    const { userPermissions } = useRole();
    const permission = (url) => userPermissions.includes(url);
    const token = sessionStorage.getItem('tokenKey');

    const [list, setList] = useState([]);
    const [filterList, setFilterList] = useState([...list]);
    const [inputSearch, setInputSearch] = useState();
    const [getID, setGetId] = useState();
    const [action, setAction] = useState();
    const [popup, setPopup] = useState(false);

    const [type, setType] = useState('ALL');

    const items = async (data) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/customer/list-data/${data}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                setList(getRes.responsePacket);
                setFilterList(getRes.responsePacket);
            }
        } catch (e) {
            console.log(e, 'error in filed apiaa')
        }
    };

    useEffect(() => {
        items('All');
    }, []);

    // Active Items
    const activeItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/customer/activate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in active itme api')
        }
    };

    // INACTIVE ITEMS
    const inactiveItem = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/customer/inactivate/${uuid}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in inactive api of itme')
        }
    };

    // DELTED ITEMS FROM ITEMS
    const removeItems = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/customer/delete/${uuid}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in delted itms api')
        }
    };

    // // REVIVE ITEMS
    const revive = async (uuid) => {
        try {
            const res = await fetch(`${BASE_URL}/admin/v1/customer/revive/${uuid}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const getRes = await res.json();
            if (getRes.errorCode === 0) {
                // Assuming listType is a variable that determines which data list to set
                switch (type) {
                    case "ALL":
                        await items("ALL");
                        break;
                    case "ACTIVE":
                        await items("Active");
                        break;
                    case "INACTIVE":
                        await items("Inactive");
                        break;
                    case "DELETED":
                        await items('Deleted');
                        break;
                    default:
                        console.log("Unknown list type");
                }
            } else {
                console.log('Error in response');
            }
            setInputSearch("");
        } catch (e) {
            console.log(e, 'error in revive api')
        }
    };

    // HANDEL REVIVE API
    const handelRevive = (uuid) => {
        setAction('RESTORE');
        setPopup(true);
        setGetId(uuid);
    };

    // HANDEL DELTED
    const handelDeleted = (uuid) => {
        setAction('DELETED');
        setGetId(uuid);
        setPopup(true);
    };

    // HANDEL ACTIVE AND INACTIVE SECTIONS
    const handelActive = (uuid) => {
        setAction('ACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    const handelInactive = (uuid) => {
        setAction('INACTIVE');
        setGetId(uuid);
        setPopup(true);
    };

    // const updateItem = async (uuid) => {
    //     try {
    //         const res = await fetch(`${BASE_URL}/admin/v1/dynamicField/get/${uuid}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         });
    //         const getRes = await res.json();
    //         if (getRes.errorCode === 0) {
    //             navToUpdate('/admin/updateField', { state: getRes.responsePacket })
    //         }
    //     } catch (e) {
    //         console.log(e, 'erron in get Update in api of fields');
    //     }
    // };

    // CONFIRM POP UP
    const confirmPop = async () => {
        if (popup) {
            switch (action) {
                case 'ACTIVE': await activeItem(getID);
                    break;
                case 'INACTIVE':
                    await inactiveItem(getID);
                    break;
                case "DELETED":
                    await removeItems(getID);
                    break;
                case 'RESTORE':
                    await revive(getID);
                    await activeItem(getID)
                    break;
                case 'UPDATE':
                    // await updateItem(getID)
                    break;
                case 'Out Of Stoke':
                    // await stoke(getID);
                    break;

                default:
                    console.log('no such action available');
            }
        };
        setAction('');
        setPopup(false);
    };

    // CANCEL THE POPUP
    const closePop = () => {
        setPopup(false);
        setAction('');
    };

    const handleSearch = (e) => {
        const get = e.target.value;
        setInputSearch(get);
        const res = list.filter((itm) => itm.firstName?.toLowerCase()?.includes(get.toLowerCase()) ||
            itm.lastName?.toLowerCase()?.includes(get.toLowerCase()) ||
            itm.mobile?.toLowerCase()?.includes(get.toLowerCase())
        );
        setFilterList(res);
    };

    return (
        <>
            <div className="category" style={{ display: "flex", justifyContent: "center", }}>
                <div className="row">
                    <div className="category-nav">
                        <div className="category-text">
                            <h4>Customers</h4>
                        </div>
                        <div className="category-btn">
                        </div>
                    </div>
                    <hr />

                    <div className="category-filter">
                        <div className="filterr">
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'ALL' ? "white" : "black", color: type === 'ALL' ? "black" : "white" }} onClick={() => { items('ALL'); setType('ALL') }}>All</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'ACTIVE' ? "white" : "black", color: type === 'ACTIVE' ? "black" : "white" }} onClick={() => { items('Active'); setType('ACTIVE') }}>Active</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'INACTIVE' ? "white" : "black", color: type === 'INACTIVE' ? "black" : "white" }} onClick={() => { items('Inactive'); setType('INACTIVE') }}>Inactive</button>
                            </div>
                            <div className="btn-1">
                                <button style={{ backgroundColor: type === 'DELETED' ? "white" : "black", color: type === 'DELETED' ? "black" : "white" }} onClick={() => { items('Deleted'); setType('DELETED') }}><AiFillDelete /></button>
                            </div>
                        </div>
                        <div className="search">
                            <h4>Search:</h4>
                            <input type="text" value={inputSearch} onChange={(e) => handleSearch(e)} placeholder="Search all Category" className="input" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu-table-container">
                <div className="order-list  sticky-container">
                    <div className="order-list-header dynamic-head">
                        {/* <div className='tittle'>User Id</div> */}
                        <div className='tittle'>Name</div>
                        <div className="tittle">Mobile</div>
                        <div className='tittle'>Status</div>
                        <div className='tittle'>Action</div>
                    </div>
                </div>

                {
                    filterList.map((item) => {
                        return (
                            <>
                                <div key={item} className='order-list-row dynamic-head'>
                                    <div className='tittle'>{item.firstName} {item.lastName}</div>
                                    <div className='tittle'>{item.mobile}</div>
                                    <div className='tittle'>
                                        <button className={`status ${item.active ? 'active-btn' : 'inactive'}`}>
                                            {item.active ? 'Active' : 'Inactive'}
                                        </button>
                                    </div>
                                    <div className='btn flex-btns'>


                                        {
                                            item.active ?
                                                (permission("admin/v1/customer/inactivate") && (<button className={`status inactive`} title='Inactive' onClick={() => handelInactive(item.uuid)} ><CgUnavailable /> Inactive</button>))
                                                :
                                                (permission("admin/v1/customer/activate") && (<button className={`status activee`} title='Active' onClick={() => handelActive(item.uuid)} ><FaCheckCircle /> Active</button>))
                                        }


                                        {/* <button className="action-btn edit-btn" onClick={() => updateItem(item.uuid)}><FaEdit /></button> */}
                                        {
                                            item.deleted ?
                                                (permission("admin/v1/customer/revive") && (<button className="status active-btn" title="Revive" onClick={() => { handelRevive(item.uuid) }}>
                                                    <FaRedo /> Revive
                                                </button>))
                                                :
                                                (permission("admin/v1/customer/delete") && (<button className="status inactive" title="Delete" onClick={() => { handelDeleted(item.uuid) }}>
                                                    <AiFillDelete /> Delete
                                                </button>))
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>



            <Modal
                isOpen={popup}
                onClose={closePop}
                onConfirm={confirmPop}
                action={`Are you sure you want to ${action} this Customer ?`}
            />
        </>
    )
}
export { Customer }